<template>
  <main class="main">
    <div class="home">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="home__content">
              <h1 class="home__title">DegenPunks</h1>
              <p class="home__text">Buy, List, and Sell NFTs - DegenPunks Holders receive 3% BNB Rewards.</p>
              <div class="home__btns">
                <router-link to="/marketplace" class="home__btn home__btn--clr">Browse</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <section class="row row--grid">
        <!-- title -->
        <div class="col-12">
          <div class="main__title">
            <h2>Recently listed</h2>
          </div>
        </div>
        <!-- end title -->

        <!-- carousel -->
        <div class="col-12">
          <div class="main__carousel-wrap">
            <div class="main__carousel main__carousel--live owl-carousel" id="live">

              <div v-for="(item) in listedPunks" v-bind:key="item.id" class="card">
                <router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}" class="card__cover">
                  <img :src="item.nft.data.image_url" alt="" class="collection-image">
                  <span class="card__time listed">
                    <vue-moments-ago prefix="Listed" suffix="ago" :date="item.listed" lang="en"></vue-moments-ago>
                  </span>

                  <span class="card__time">{{ item.nft.data.rarity }}</span>
                  <span class="card__time rank">Rank: {{ item.nft.data.rank }}</span>

                </router-link>
                <h3 class="card__title"><router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}">{{ item.nft.data.name }}</router-link></h3>
                <div class="card__author card__author--verified">
                  <img src="@/assets/img/avatars/avatar3.jpg" alt="">
                  <a href="#">{{ item.seller_address.substr(0, 15) + "..." }}</a>
                </div>
                <div class="card__info">
                  <div class="card__price">
                    <span>Current price</span>
                    <span>{{ parseFloat(item.ask_price).toFixed(2) }} BNB</span>
                  </div>

                </div>
              </div>

            </div>

            <button class="main__nav main__nav--prev" data-nav="#live" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg></button>
            <button class="main__nav main__nav--next" data-nav="#live" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg></button>
          </div>
        </div>
        <!-- end carousel -->
      </section>


      <div class="row row--grid">
        <div class="col-12">
          <div class="main__title main__title--border-top">
            <h2>Get Started Selling Your NFTs</h2>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="feature"> <span class="feature__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z"/>
          </svg>
          </span>
            <h3 class="feature__title">Set up your wallet</h3>
            <p class="feature__text">Once you’ve set up your wallet of choice, connect it to NFT marketplace by clicking NOT CONNECTED in the header.</p>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="feature"> <span class="feature__icon feature__icon--purple">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M22.71,6.29a1,1,0,0,0-1.42,0L20,7.59V2a1,1,0,0,0-2,0V7.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3A1,1,0,0,0,22.71,6.29ZM19,13a1,1,0,0,0-1,1v.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.7L9.41,11.12a2.85,2.85,0,0,0-3.93,0L4,12.6V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V14A1,1,0,0,0,19,13ZM5,20a1,1,0,0,1-1-1V15.43l2.9-2.9a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15.46,20Zm13-1a.89.89,0,0,1-.18.53L13.31,15l.7-.7a.77.77,0,0,1,1.1,0L18,17.21Z"/>
          </svg>
          </span>
            <h3 class="feature__title">Add your NFTs</h3>
            <p class="feature__text">Click on Connected in the header and select Create Sale.  Select your NFT.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="feature feature--last"> <span class="feature__icon feature__icon--red">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15,12a1,1,0,1,0,1-1A1,1,0,0,0,15,12Zm6.71-.71-5-5A1,1,0,0,0,16,6H5A3,3,0,0,0,2,9v6a3,3,0,0,0,3,3H16a1,1,0,0,0,.71-.29l5-5A1,1,0,0,0,21.71,11.29ZM15.59,16H5a1,1,0,0,1-1-1V9A1,1,0,0,1,5,8H15.59l4,4Z"/>
          </svg>
          </span>
            <h3 class="feature__title">List them for Sale</h3>
            <p class="feature__text">Choose your sale price and let the market work its magic.</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import VueMomentsAgo from 'vue-moments-ago'
import axios from "axios"
import Vue from 'vue'

export default {
  name: "Home",
  components: {
    VueMomentsAgo
  },
  data () {
    return {
      listedPunks: {}
    }
  },
  methods: {
    installOwlCarousel: function(){
      $('.main__carousel--live').owlCarousel({
        mouseDrag: true,
        touchDrag: true,
        dots: true,
        loop: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        smartSpeed: 600,
        margin: 20,
        autoHeight: false,
        responsive: {
          0: {
            items: 1
          },
          576: {
            items: 2
          },
          768: {
            items: 3,
            margin: 30
          },
          992: {
            items: 4,
            margin: 30
          },
          1200: {
            items: 4,
            margin: 30,
            mouseDrag: true,
            dots: true
          }
        }
      });
    }
  },
  mounted () {
    let vm = this;

    axios
      .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_DEGENPUNKS_ADDR + "?order_by=listed&limit=30&filters=sold:false")
      .then((response) => {
          vm.listedPunks = response.data
          Vue.nextTick(function(){
            vm.installOwlCarousel();
          }.bind(vm));
        }
      )
  }
}
</script>
