<template>
  <div id="modal-list" class="modal-bg">
    <div class="zoom-anim-dialog modal modal--form">
      <button class="modal__close" type="button" data-dismiss="modal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <h4 class="sign__title">List Item For Sale</h4>
      <div class="sign__group sign__group--row">
        <label class="sign__label" for="placebid">Set Price in BNB</label>
        <input autocomplete="off" type="text" id="placebid" class="sign__input" v-model="price">
        <small class="text_min" v-if="price<0.1">Minimum Listing Price : 0.10 BNB</small>
        <span class="sign__text sign__text--small" id="modal-instructions">Click List Item to Submit</span>
      </div>
      <button class="sign__btn setprice_btn" id="modal-btn-list" type="button" @click="listToken()">
        <template v-if="!processing">List Item</template>
        <template v-else><div class="ld ld-ring ld-spin"></div></template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalList',
  data () {
    return {
      price: 0.10,
    }
  },
  props: {
    processing: Boolean,
    globalError: String
  },
  methods: {
    listToken() {
      this.$emit('listToken', this.price)
    }
  }
}
</script>
