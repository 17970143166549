<template>
  <div id="app">
    <!-- header -->
    <header class="header">
      <div class="header__content">
        <div class="header__logo">
          <router-link to="/" class="header__link">DegenPunks</router-link>
        </div>
        <!--
        <form action="#" class="header__search">
          <input type="text" placeholder="Search items and collections" id="btn_search">
          <button type="button" onClick="beginSearch();">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/>
            </svg>
          </button>
          <button type="button" class="close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/>
            </svg>
          </button>
        </form>
        -->
        <div class="header__nav-item">
          <router-link to="/" class="header__nav-link">Home</router-link>
        </div>

        <div class="header__nav-item">
          <router-link to="/marketplace" class="header__nav-link">Marketplace</router-link>
        </div>

        <div class="header__nav-item">
          <router-link to="/roadmap" class="header__nav-link">Roadmap</router-link>
        </div>

        <div class="header__menu"> </div>
        <div class="header__actions">
          <div class="header__action header__action--search">
            <button class="header__action-btn" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/>
              </svg>
            </button>
          </div>

          <template v-if="!web3Modal.active">
            <div class="header__action header__action--profile">
              <a class="header__profile-btn" @click="connect" href="#" id="dropdownMenuProfile" role="" data-toggle="" aria-haspopup="true" aria-expanded="false">
                <img src="@/assets/img/avatars/avatar.jpg" class="profile_picture " id="connectionIcon" alt="">
                <div>
                  <p id="connectionTextUpper">Not Connected</p>
                  <span id="connectionTextLower">
                      Click to Connect
                    </span>
                </div>
              </a>
            </div>
          </template>
          <template v-else>
            <div class="header__action header__action--profile header__profile-btn--verified">
              <a class="header__profile-btn " href="#" id="dropdownMenuProfile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img :src="this.$store.getters.getAvatar" class="profile_picture " id="connectionIcon" alt="">
                <div>
                  <p id="connectionTextUpper">Connected</p>
                  <span v-if="web3Modal.active" id="connectionTextLower">
                    {{ this.$store.getters.getBnbBalance | toBNB }} BNB
                  </span>
                </div>

                <svg id="connectionDropDown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                </svg>
              </a>

              <ul class="dropdown-menu header__profile-menu" aria-labelledby="dropdownMenuProfile">
                <li>
                  <router-link to="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/>
                    </svg>
                    <span>Profile</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/sale">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z"/>
                    </svg>
                    <span>Create Sale</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/rewards">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z"/>
                    </svg>
                    <span>Rewards</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </header>
    <!-- end header -->

    <!-- begin veritcal socials -->
    <nav class="vertical-social">
      <ul>
        <li><a href="https://t.me/degenpunks"><i class="fab fa-telegram" aria-hidden="true"></i></a></li>
        <li><a href="https://twitter.com/DegenPunks"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
        <li><a href=""><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
      </ul>
    </nav>
    <!-- end vertical socials -->

    <template v-if="(this.$store.getters.getChainId !== 56 && this.$store.getters.getChainId !== '0x38') && this.$store.getters.getChainId !== 0">
      <main class="main">
        <div class="container">
          <div class="row row--grid">
            <div class="col-12">
              <div class="page-404">
                <div class="page-404__wrap" >
                  <div class="page-404__content" style="min-width: 100% !important;">
                    <h4 class="page-404__title" style="font-size: 350% !important;">Wrong network ({{this.$store.getters.getChainId}})</h4>
                    <p class="page-404__text"><br><br>You are connected to wrong network, please change network to Binance Smart Chain (0x38 / 56)</p>
                    <!-- <a href="index.html" class="page-404__btn">go back</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-4 order-md-1 order-lg-4 order-xl-1">
            <div class="footer__logo"><img src="img/dp/dp_icon-32x32.png" alt=""></div>
            <p class="footer__tagline">Degen Punks NFT Marketplace for buying and selling all NFT tokens.  DegenPunks is in no way affiliated with Larva Labs and/or CryptoPunks, PolyPunks, CryptoPhunks, CovidPunks, ShitPunks, SolPunks, ThetaPunks.</p>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-lg-3 order-md-4 order-xl-4"></div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
            <h6 class="footer__title">NTF Marketplace</h6>
            <div class="footer__nav">
              <router-link to="/marketplace">
                Gallery
              </router-link>
              <router-link to="/profile">
                Rewards
              </router-link>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-6 col-xl-4 order-3 order-lg-2 order-md-3 order-xl-3">
            <h6 class="footer__title">Degen Punks</h6>
            <div class="footer__nav">
              <a href="https://twitter.com/DegenPunks">Twitter</a>
              <a href="https://t.me/degenpunks">Telegram</a>
              <a href="https://discord.gg/tXMmAqPukk">Discord</a>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <web3-modal-vue
      ref="web3modal"
      :theme="theme"
      :provider-options="providerOptions"
      cache-provider
    />
  </div>
</template>

<style>
  @import './assets/css/bootstrap-reboot.min.css';
  @import './assets/css/bootstrap-grid.min.css';
  @import './assets/css/owl.carousel.min.css';
  @import './assets/css/magnific-popup.css';
  @import './assets/css/select2.min.css';
  @import './assets/css/ldbtn.min.css';
  @import './assets/css/loading.min.css';
  @import './assets/css/main.css';
</style>

<script>
  import jQuery from "jquery";
  window.jQuery = window.$ = jQuery

  require('./assets/js/bootstrap.bundle.min.js')
  require('./assets/js/widgets.js')
  require('./assets/js/jquery.magnific-popup.min.js')
  require('./assets/js/owl.carousel.min.js')
  require('./assets/js/select2.min')
  require('./assets/js/jquery.countdown.min')

  import Web3ModalVue from "web3modal-vue";
  import WalletConnectProvider from "@walletconnect/web3-provider";
  import {mapState} from "vuex";

  export default {
    components: {
      Web3ModalVue
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    data() {
      return {
        theme: 'light',
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                56: "https://bsc-dataseed.binance.org/"
              },
              network: 'binance'
            }
          }
        },
        number: 0,
      }
    },
    created() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.theme = 'dark'
      }
    },
    mounted() {
      this.$nextTick(async () => {
        const web3modal = this.$refs.web3modal;
        this.$store.commit('setWeb3Modal', web3modal)
        if (web3modal.cacheProvider) {
          await this.$store.dispatch('connect')
        } else {
          console.log("Test")
        }
      })
    },
    methods: {
      async connect() {
        await this.$store.dispatch('connect')
      },
    }
  }
</script>
