<template>
  <div class="loader" v-if="loading">
    <div class="sk-fading-circle-large">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
  <main class="main" id="author_main" v-else>
    <div class="main__author home"></div>
    <div class="container">
      <div class="row row--grid">
        <div class="col-12 col-xl-6">
        </div>

        <div class="col-12 col-xl-6">
        </div>

        <div class="col-12 col-xl-9">
          <div class="profile">
            <h1 class="home__title">MULTI-WRAPPER</h1>
          </div>

          <div class="row row--grid">
            <div class="col-12">
              <hr>
              <br>
              <p style="color:white">After clicking WRAP button, MetaMask will popup for each degen once.</p>
              <p style="color:white">Unfortunately it is not possible to wrap em all with single transaction because of
                a bug in old contract...</p>
            </div>
          </div>

          <div class="reward_button_div">
            <button class="asset__btn--clr ld-ext running rew_button" type="button" v-on:click="massWrap"
                    :disabled="wrapping">
              <template v-if="!wrapping">
                Start wrapping for {{ web3Modal.oldOwnedTokenIds.length }} DegenPunks
              </template>
              <template v-else>
                <div class="ld ld-ring ld-spin"></div>
              </template>
            </button>
          </div>
          <p style="color:white; font-size: 125%;">
            Non wrapped Degens left: {{web3Modal.oldOwnedTokenIds.length}}<br>
            Wrapped Degens: {{wrapped}}
          </p>
          <h1 style="color: white;">Next Degen to wrap</h1>

          <div class="row row--grid" id="main_gallery">
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 main_card multi-wrapping"
                 v-for="(item) in this.$store.getters.getOwnedTokens"
                 v-if="web3Modal.oldOwnedTokenIds.includes(Number(item.name.split('#')[1]))"
            >
              <img class="card_image" :src="item.image_url" alt="">
              <span class="card__time rank">Rank: {{ item.name.split('#')[1]|rr('rank') }}</span>
              <h3 class="card__title">
                <span class="card__cover">{{ item.name }}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Web3ModalVue from "web3modal-vue";
import {mapState} from "vuex";
import {getOldDegensContract} from "@/utils/contract";

const oldDegen = getOldDegensContract()

export default {
  name: "MiddleDegen",
  components: {
    Web3ModalVue
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  methods: {
    async wrapSingle(oldDegenId) {
      let vm = this
      vm.wrapping = true

      const oldDegenSigner = oldDegen.connect(vm.web3Modal.signer)

      await oldDegenSigner["safeTransferFrom(address,address,uint256)"](vm.$store.getters.currentAccount, process.env.VUE_APP_DEGENPUNKS_ADDR, oldDegenId)
        .then((tx) => {
          return tx.wait().then(() => {
            vm.wrapping = false
            vm.$store.getters.getOldOwnedTokenIds.splice(0,1)
            vm.wrapped += 1
          }, (error) => {
            console.log(error)
            vm.wrapping = false
          });
        })
        .catch(() => {
          vm.wrapping = false
        })

    },
    async massWrap() {
      let vm = this
      const oldDegens = Array.from(vm.$store.getters.getOldOwnedTokenIds)
      for (let oldDegenId of oldDegens) {
        await this.wrapSingle(oldDegenId)
      }
    },
  },
  data() {
    return {
      portfolioPunks: [],
      wrapping: false,
      loading: false,
      processing: false,
      wrapped: 0
    }
  }
}
</script>
