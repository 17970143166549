<template>
  <div class="loader" v-if="loading || !listing.nft.collection">
    <div class="sk-fading-circle-large">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
  <main class="main" v-else>
    <div class="container" id="div_main">
      <div class="row row--grid">
        <div class="col-12">
          <div style='margin-bottom: 5px;' class="main__title main__title--page">
            <h1 id="nft_name" class="nft_name_main">{{ listing.nft.data.name }}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="asset__item">
            <a class="asset__img" href="" id="nft_img_link">
              <img class="nft_image_item" alt="NFT_Image" id="nft_img" :src="listing.nft.data.image_url|ipfs">
            </a>

            <div class="share share--asset full-list">
              <a @click="copyUrlClipboard" class="share__link share__link--link cursor">
                <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z"/>
                </svg>
                <span>Copy Link</span>
              </a>
              <a href="https://twitter.com/share?ref_srgc=twsrc%5Etfw" class="twitter-share-button" data-size="large" data-show-count="false">Tweet</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4">
          <div class="asset__info">
            <div class="asset__desc">
              <div id="nft_Attributes" style="padding-bottom: 10px;">
                <h2>Properties</h2>
                <div class="row row--grid" id="row_property">
                  <div class="col-6" v-for="property in listing.nft.data.attributes" v-bind:key="property.trait_type" >
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">{{ property.trait_type }}</div>
                      <div class="description_value">{{ property.value }}</div>
                      <!--<div class="osStats">1.29% (not working)</div>-->
                    </div>
                  </div>

                  <div class="col-6">
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">Rank</div>
                      <div class="description_value">{{ listing.nft.data.rank }}</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">Rarity</div>
                      <div class="description_value">{{ listing.nft.data.rarity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="asset_top center_group creator_group">
              <div>
                <span class="asset_top_text ">Owner</span>
                <div class="asset__author">
                  <img class="creator_val" src="@/assets/img/avatars/avatar.jpg" alt="" id="creator_img">
                </div>
              </div>
              <div style="padding-left: 50px;">
                <span class="asset_top_text collection_group">Collection</span>
                <div class="asset__author ">
                  <img class="collection_val" src="https://www.degenpunks.com/newpunks/punk-9196.png" alt="" id="collection_img">
                  <a href="" class="colection" id="collection_name">DegenPunks</a>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs asset__tabs detail_tablist" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="false">Details</a>
              </li>
            </ul>
            <div class="tab-content detail_tablist">
              <div class="tab-pane fade " id="tab-3" role="tabpanel">
                <div class="asset__actions asset__actions--scroll" id="asset__actions--scroll">
                  <div id='tab_history_item'>

                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="tab-2" role="tabpanel">
                <div class="asset__actions"></div>
              </div>
              <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                <ul class="asset__authors asset__authors--tab">
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Contract Address</p>
                    </div>
                    <div style="text-align: right; width: 50%;" id="tab_data_contract">
                      <p><b><a v-bind:href="explorerAddress + listing.nft.collection">{{ listing.nft.collection.substr(0, 15) + "..." }}</a></b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Token ID</p>
                    </div>
                    <div style="text-align: right; width: 50%;" id="tab_data_tokenid">
                      <p><b>{{ listing.nft.nft_id }}</b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Token Standard</p>
                    </div>
                    <div style="text-align: right; width: 50%;">
                      <p><b>ERC-721</b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Blockchain</p>
                    </div>
                    <div style="text-align: right; width: 50%;">
                      <p><b>BSC</b></p>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <template v-if="listing.seller_address === this.$store.getters.currentAccount.toLowerCase()">
              <div class="asset__btns" id="nft_Owned">
                <button class="asset__btn asset__btn--clr ld-ext running" type="button" v-on:click="cancel" id="nft_CancelSale">
                  <template v-if="!processing">
                    Cancel Sale
                  </template>
                  <template v-else>
                    <div class="ld ld-ring ld-spin"></div>
                  </template>
                </button>
                <a href="#modal-cancel-sale" class="asset__btn open-modal disabled-link">{{ parseFloat(listing.ask_price).toFixed(2) }} BNB</a>
              </div>
            </template>
            <template v-else>
              <div class="asset__btns" id="nft_ForSale">
                <button class="asset__btn asset__btn--clr ld-ext running" type="button" v-on:click="buyToken">
                  <template v-if="!processing">
                    <div id="nft_ForSaleText">{{ parseFloat(listing.ask_price).toFixed(2) }} BNB</div>
                  </template>
                  <template v-else>
                    <div class="ld ld-ring ld-spin"></div>
                  </template>
                </button>
              </div>
            </template>

            <div class="view_fees">
              <br>
              <a href="#" data-toggle="modal" data-target="#modal-info">
                <h4>
                  Fees and Rewards?
                </h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalInfo></ModalInfo>
  </main>
</template>


<script>
  import {getMarketplaceContract} from "@/utils/contract";
  import ModalInfo from "@/components/ModalInfo";
  import Web3ModalVue from "web3modal-vue";
  import {mapState} from "vuex";
  import {ethers} from "ethers"
  import axios from "axios"

  const marketplace = getMarketplaceContract()

  export default {
    name: "Marketplace",
    components: {
      Web3ModalVue,
      ModalInfo
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    methods: {
      async copyUrlClipboard () {
        await navigator.clipboard.writeText(window.location.href);
      },
      buyToken () {
        this.processing = true;

        const signer = marketplace.connect(this.web3Modal.signer)

        signer["buyItem"](this.$route.params.id, {value:ethers.utils.parseEther(this.listing.ask_price.toString())})
          .then((tx) => {
            return tx.wait().then(() => {
              this.$router.push({
                name: 'CollectionItem',
                params: {
                  collection: this.collectionAddress,
                  id: this.tokenId
                }
              })
            }, (error) => {
              this.processing = false
              console.log(error)
            });
          })
          .catch((error)=>{
            this.processing = false
            console.log(error)
          });
      },
      cancel () {
        this.processing = true;
        const signer = marketplace.connect(this.web3Modal.signer)
        signer["cancelSale"](this.$route.params.id, {value:this.cancelFee})
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
            }, (error) => {
              this.processing = false
            });
          })
          .catch(()=>{
            this.processing = false
          });
      }
    },
    data () {
      return {
        explorerAddress: process.env.VUE_APP_EXPLORER_ADDRESS_URL,
        processing: false,
        listing: {
          nft: {
            collection: "",
            data: {
            }
          }
        },
        cancelFee: 0,
        loading: true
      }
    },
    async beforeCreate () {
      const forSale = await marketplace['itemsForSale'](this.$route.params.id)
      if (forSale.isSold) {
        await this.$router.push({
          name: 'CollectionItem',
          params: {
            collection: forSale.tokenAddress,
            id: forSale.tokenId.toNumber()
          }
        })
      } else {
        this.cancelFee = await marketplace['_degenPunkCancelFee']()
      }
    },
    mounted () {
      axios
        .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_DEGENPUNKS_ADDR + "/" + this.$route.params.id)
        .then(response => (this.listing = response.data))

      this.loading = false
    }
  }
</script>
