<template>
  <div id="modal-info" class="modal-bg">
    <div class="zoom-anim-dialog modal modal--form">
      <button class="modal__close" type="button" data-dismiss="modal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <h4 class="sign__title">Fees and Rewards</h4>
      <div class="modal-header modal-body"> ALL NFTs</div>
      <div class="modal-body-entry">2% fee from NFT sales for Marketplace development and maintenance</div>
      <div class="modal-header modal-body">DegenPunk NFTs</div>
      <div class="modal-body-entry"><b>Holder Rewards:</b><br>3% of sale price of DegenPunks NFTs to Current Holders</div>
      <br>
      <div class="modal-body-entry"><b>Original Minter Royalties:</b><br> 5% of sale price of specific DegenPunk NFT to the Original Minter Wallet</div>
      <br>
      <div class="modal-body-entry " style="margin-top:5px;"><b>Listing fee:</b><br>0.018 BNB Paid to Degen Punk Holders<br>(updates every 24 hours to match $10)</div>
      <br>
      <div class="modal-body-entry "><b>Canceling fee:</b><br>0.018 BNB Paid to Degen Punk Holders<br>(updates every 24 hours to match $10)</div>
      <br>
      <div class="modal-body-entry "><b>Minimum listing price:</b><br>0.10 BNB</div>
      <br>
      <div class="modal-body-entry modal-end"><small>Ownership of DegenPunks required for rewards, except for Minters who receive their Minting reward for Life</small></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modalInfo'
}
</script>
