<template>
  <div id="modal-transfer" class="modal-bg">
    <div class="zoom-anim-dialog modal modal--form">
      <button class="modal__close" type="button" data-dismiss="modal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
        </svg>
      </button>
      <h4 class="sign__title">Transfer Token</h4>
      <div class="sign__group sign__group--row">
        <label class="sign__label" for="modal-transfer-address">Recipient address</label>
        <input id="modal-transfer-address" type="text" name="transfer-address" class="sign__input" v-model="transferTo">
        <small id="modal-transfer-error" class="text_min">{{ globalError }}</small>
      </div>

      <button class="sign__btn setprice_btn" id="modal-btn-transfer" type="button" @click="transferToken()">
        <template v-if="!processing">Transfer</template>
        <template v-else><div class="ld ld-ring ld-spin"></div></template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTransfer',
  data () {
    return {
      transferTo: ""
    }
  },
  props: {
    processing: Boolean,
    globalError: String
  },
  methods: {
    transferToken() {
      this.$emit('transferToken', this.transferTo)
    }
  }
}
</script>
