import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Marketplace from '../views/Marketplace'
import CollectionItem from '../views/CollectionItem'
import Profile from '../views/Profile'
import MarketplaceItem from '../views/MarketplaceItem'
import AuctionMarketplaceItem from '../views/AuctionMarketplaceItem'
import MiddleDegen from '../views/MiddleDegen'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace
  },
  {
    path: '/multi-wrap',
    name: 'MiddleDegen',
    component: MiddleDegen
  },

  {
    path: '/roadmap',
    name: 'Roadmap',
    component: () => import('../views/Roadmap.vue')
  },
  {
    path: '/profile/:tab?',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/item/:collection/:id',
    name: 'CollectionItem',
    component: CollectionItem
  },
  {
    path: '/marketplace/:id',
    name: 'MarketplaceItem',
    component: MarketplaceItem
  },
  {
    path: '/auction/:collectionAddress/:tokenId',
    name: 'AuctionMarketplaceItem',
    component: AuctionMarketplaceItem
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
