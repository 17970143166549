<template>
  <main class="main">
    <div class="container">
      <div class="row row--grid">
        <div class="col-12">
          <div><img class="gallery_img hidden" id="collection_image" src="img/avatars/avatar.jpg" alt="Collection"></div>
          <div class="main__title main__title--page" >
            <h1 id="gallery_header"></h1><br>
            <p id="gallery_desc"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-3 order-xl-2">
          <div class="filter-wrap">
            <button class="filter-wrap__btn" type="button" data-toggle="collapse" data-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">Open Filter</button>
            <div class="collapse filter-wrap__content" id="collapseFilter">
              <div class="filter">
                <h4 class="filter__title">Filters
                  <button type="button" @click="clear()">Clear all</button>
                </h4>
                <div class="filter__group">
                  <ul class="filter__checkboxes">
                    <li>
                      <input id="type-common" type="checkbox" name="type" value="common" v-model="filterRarity" v-on:change="loadPunks()">
                      <label for="type-common">Common</label>
                    </li>
                    <li>
                      <input id="type-uncommon" type="checkbox" name="type" value="uncommon" v-model="filterRarity" v-on:change="loadPunks()">
                      <label for="type-uncommon">Uncommon</label>
                    </li>
                    <li>
                      <input id="type-rare" type="checkbox" name="type" value="rare" v-model="filterRarity" v-on:change="loadPunks()">
                      <label for="type-rare">Rare</label>
                    </li>
                    <li>
                      <input id="type-epic" type="checkbox" name="type" value="epic" v-model="filterRarity" v-on:change="loadPunks()">
                      <label for="type-epic">Epic</label>
                    </li>
                    <li>
                      <input id="type-legendary" type="checkbox" name="type" value="legendary" v-model="filterRarity" v-on:change="loadPunks()">
                      <label for="type-legendary">Legendary</label>
                    </li>
                  </ul>
                </div>
                <!--<div class="filter__group">
                  <label class="filter__label">Keyword:</label>
                  <input type="text" class="filter__input" placeholder="Keyword" id="sort_keyword">
                </div>-->
                <div class="filter__group">
                  <label for="filter-sort" class="filter__label">Sort by:</label>
                  <div class="filter__select-wrap">
                    <select id="filter-sort" class="filter__select" v-model="filterSort" @change="sortChanged($event)">
                      <option value="rank:asc">Rank: Low to High</option>
                      <option value="rank:desc">Rank: High to Low</option>
                      <option value="price:asc">Price: Low to High</option>
                      <option value="price:desc">Price: High to Low</option>
                      <option value="listed:desc">Recently Listed</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-9 order-xl-1 bottom-margin-15">
          <div class="row row--grid" id="gallery_main">
            <div v-for="(item) in listedPunks" v-bind:key="item.id" class="col-12 col-sm-6 col-lg-4 main_card">
              <router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}" class="card__cover">
                <img class="card_image" :src="item.nft.data.image_url" alt="">
                <span class="card__time">{{ item.nft.data.rarity }}</span>
                <span class="card__time rank">Rank: {{ item.nft.data.rank }}</span>
              </router-link>
              <h3 class="card__title">
                <router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}" name="title">{{ item.nft.data.name }}</router-link>
              </h3>
              <div class="card__author">
                <img src="@/assets/img/avatars/avatar.jpg" class="avatar_pic" alt="">
                <router-link class="disabled-link" to="/author">{{ item.seller_address.substr(0, 15) + "..." }}</router-link>
              </div>
              <div class="card__info">
                <div class="card__price">
                  <span>Current Price</span><span>{{ parseFloat(item.ask_price).toFixed(2) }} BNB</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="main__load" type="button" id="btn_load_more" v-on:click="loadMore" >Load more</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios"

export default {
  name: "Marketplace",
  data () {
    return {
      listedPunks: {},
      index: 0,
      limit: 12,
      filterSort: "rank:asc",
      filtersSold: "false",
      filtersRarity: "",
      filterRarity: [],
    }
  },
  methods: {
    loadMore () {
      this.limit += 12
      this.loadPunks()
    },
    sortChanged () {
      this.loadPunks()
    },
    clear () {
      this.filterSort = "rank:asc"
      this.filterRarity = []
      this.filtersSold = "false"
      this.loadPunks()
    },
    loadPunks () {
      if (this.filterRarity.length > 0) {
        this.filtersRarity = "rarity:"
        this.filtersRarity += this.filterRarity.join()
        this.filtersRarity += ";"
      } else {
        this.filtersRarity = ""
      }

      let [orderBy, orderDirection] = this.filterSort.split(":")

      axios
        .get(
          process.env.VUE_APP_API_URL +
          "/marketplace/" +
          process.env.VUE_APP_DEGENPUNKS_ADDR +
          "?order_by=" + orderBy +
          "&limit=" + this.limit +
          "&filters=" +
          this.filtersRarity +
          "sold:" + this.filtersSold +
          "&direction=" + orderDirection
        )
        .then(response => (this.listedPunks = response.data))
    }
  },
  mounted () {
    this.loadPunks()
  }
}
</script>
