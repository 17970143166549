import router from "@/router";

const rr = {
  0: {'rank': 9662, 'rarity': 'Common'},
  1: {'rank': 8287, 'rarity': 'Common'},
  2: {'rank': 559, 'rarity': 'Epic'},
  3: {'rank': 9342, 'rarity': 'Common'},
  4: {'rank': 3844, 'rarity': 'Uncommon'},
  5: {'rank': 3547, 'rarity': 'Uncommon'},
  6: {'rank': 2541, 'rarity': 'Uncommon'},
  7: {'rank': 764, 'rarity': 'Epic'},
  8: {'rank': 3287, 'rarity': 'Uncommon'},
  9: {'rank': 6780, 'rarity': 'Common'},
  10: {'rank': 5935, 'rarity': 'Common'},
  11: {'rank': 5764, 'rarity': 'Common'},
  12: {'rank': 9875, 'rarity': 'Common'},
  13: {'rank': 6782, 'rarity': 'Common'},
  14: {'rank': 6656, 'rarity': 'Common'},
  15: {'rank': 8430, 'rarity': 'Common'},
  16: {'rank': 6353, 'rarity': 'Common'},
  17: {'rank': 9438, 'rarity': 'Common'},
  18: {'rank': 4603, 'rarity': 'Uncommon'},
  19: {'rank': 8411, 'rarity': 'Common'},
  20: {'rank': 9634, 'rarity': 'Common'},
  21: {'rank': 575, 'rarity': 'Epic'},
  22: {'rank': 8435, 'rarity': 'Common'},
  23: {'rank': 2935, 'rarity': 'Uncommon'},
  24: {'rank': 1320, 'rarity': 'Rare'},
  25: {'rank': 4449, 'rarity': 'Uncommon'},
  26: {'rank': 6468, 'rarity': 'Common'},
  27: {'rank': 9235, 'rarity': 'Common'},
  28: {'rank': 2610, 'rarity': 'Uncommon'},
  29: {'rank': 5816, 'rarity': 'Common'},
  30: {'rank': 6833, 'rarity': 'Common'},
  31: {'rank': 5413, 'rarity': 'Common'},
  32: {'rank': 7416, 'rarity': 'Common'},
  33: {'rank': 6100, 'rarity': 'Common'},
  34: {'rank': 6514, 'rarity': 'Common'},
  35: {'rank': 8433, 'rarity': 'Common'},
  36: {'rank': 7134, 'rarity': 'Common'},
  37: {'rank': 3932, 'rarity': 'Uncommon'},
  38: {'rank': 2953, 'rarity': 'Uncommon'},
  39: {'rank': 8175, 'rarity': 'Common'},
  40: {'rank': 2639, 'rarity': 'Uncommon'},
  41: {'rank': 6720, 'rarity': 'Common'},
  42: {'rank': 3689, 'rarity': 'Uncommon'},
  43: {'rank': 156, 'rarity': 'Epic'},
  44: {'rank': 2114, 'rarity': 'Rare'},
  45: {'rank': 638, 'rarity': 'Epic'},
  46: {'rank': 4487, 'rarity': 'Uncommon'},
  47: {'rank': 9334, 'rarity': 'Common'},
  48: {'rank': 2630, 'rarity': 'Uncommon'},
  49: {'rank': 2558, 'rarity': 'Uncommon'},
  50: {'rank': 4551, 'rarity': 'Uncommon'},
  51: {'rank': 2543, 'rarity': 'Uncommon'},
  52: {'rank': 4994, 'rarity': 'Uncommon'},
  53: {'rank': 3138, 'rarity': 'Uncommon'},
  54: {'rank': 326, 'rarity': 'Epic'},
  55: {'rank': 159, 'rarity': 'Epic'},
  56: {'rank': 9467, 'rarity': 'Common'},
  57: {'rank': 2246, 'rarity': 'Rare'},
  58: {'rank': 8657, 'rarity': 'Common'},
  59: {'rank': 5148, 'rarity': 'Common'},
  60: {'rank': 569, 'rarity': 'Epic'},
  61: {'rank': 6719, 'rarity': 'Common'},
  62: {'rank': 5609, 'rarity': 'Common'},
  63: {'rank': 910, 'rarity': 'Epic'},
  64: {'rank': 6284, 'rarity': 'Common'},
  65: {'rank': 1696, 'rarity': 'Rare'},
  66: {'rank': 7555, 'rarity': 'Common'},
  67: {'rank': 3612, 'rarity': 'Uncommon'},
  68: {'rank': 4952, 'rarity': 'Uncommon'},
  69: {'rank': 6013, 'rarity': 'Common'},
  70: {'rank': 9165, 'rarity': 'Common'},
  71: {'rank': 789, 'rarity': 'Epic'},
  72: {'rank': 5418, 'rarity': 'Common'},
  73: {'rank': 5014, 'rarity': 'Common'},
  74: {'rank': 4863, 'rarity': 'Uncommon'},
  75: {'rank': 4721, 'rarity': 'Uncommon'},
  76: {'rank': 8991, 'rarity': 'Common'},
  77: {'rank': 1158, 'rarity': 'Rare'},
  78: {'rank': 7229, 'rarity': 'Common'},
  79: {'rank': 3096, 'rarity': 'Uncommon'},
  80: {'rank': 4944, 'rarity': 'Uncommon'},
  81: {'rank': 8573, 'rarity': 'Common'},
  82: {'rank': 7998, 'rarity': 'Common'},
  83: {'rank': 8856, 'rarity': 'Common'},
  84: {'rank': 4431, 'rarity': 'Uncommon'},
  85: {'rank': 2148, 'rarity': 'Rare'},
  86: {'rank': 5367, 'rarity': 'Common'},
  87: {'rank': 1600, 'rarity': 'Rare'},
  88: {'rank': 1975, 'rarity': 'Rare'},
  89: {'rank': 7559, 'rarity': 'Common'},
  90: {'rank': 5044, 'rarity': 'Common'},
  91: {'rank': 9286, 'rarity': 'Common'},
  92: {'rank': 4306, 'rarity': 'Uncommon'},
  93: {'rank': 7237, 'rarity': 'Common'},
  94: {'rank': 8330, 'rarity': 'Common'},
  95: {'rank': 1272, 'rarity': 'Rare'},
  96: {'rank': 686, 'rarity': 'Epic'},
  97: {'rank': 5557, 'rarity': 'Common'},
  98: {'rank': 3436, 'rarity': 'Uncommon'},
  99: {'rank': 5521, 'rarity': 'Common'},
  100: {'rank': 2656, 'rarity': 'Uncommon'},
  101: {'rank': 8193, 'rarity': 'Common'},
  102: {'rank': 8367, 'rarity': 'Common'},
  103: {'rank': 8090, 'rarity': 'Common'},
  104: {'rank': 9032, 'rarity': 'Common'},
  105: {'rank': 22, 'rarity': 'Legendary'},
  106: {'rank': 7557, 'rarity': 'Common'},
  107: {'rank': 8896, 'rarity': 'Common'},
  108: {'rank': 4955, 'rarity': 'Uncommon'},
  109: {'rank': 4104, 'rarity': 'Uncommon'},
  110: {'rank': 6194, 'rarity': 'Common'},
  111: {'rank': 4421, 'rarity': 'Uncommon'},
  112: {'rank': 8844, 'rarity': 'Common'},
  113: {'rank': 9042, 'rarity': 'Common'},
  114: {'rank': 4300, 'rarity': 'Uncommon'},
  115: {'rank': 9405, 'rarity': 'Common'},
  116: {'rank': 6371, 'rarity': 'Common'},
  117: {'rank': 5994, 'rarity': 'Common'},
  118: {'rank': 3947, 'rarity': 'Uncommon'},
  119: {'rank': 7908, 'rarity': 'Common'},
  120: {'rank': 8057, 'rarity': 'Common'},
  121: {'rank': 8903, 'rarity': 'Common'},
  122: {'rank': 7331, 'rarity': 'Common'},
  123: {'rank': 5910, 'rarity': 'Common'},
  124: {'rank': 1581, 'rarity': 'Rare'},
  125: {'rank': 5256, 'rarity': 'Common'},
  126: {'rank': 5232, 'rarity': 'Common'},
  127: {'rank': 4907, 'rarity': 'Uncommon'},
  128: {'rank': 81, 'rarity': 'Legendary'},
  129: {'rank': 6868, 'rarity': 'Common'},
  130: {'rank': 1905, 'rarity': 'Rare'},
  131: {'rank': 2501, 'rarity': 'Uncommon'},
  132: {'rank': 3799, 'rarity': 'Uncommon'},
  133: {'rank': 6848, 'rarity': 'Common'},
  134: {'rank': 444, 'rarity': 'Epic'},
  135: {'rank': 6064, 'rarity': 'Common'},
  136: {'rank': 1077, 'rarity': 'Rare'},
  137: {'rank': 3414, 'rarity': 'Uncommon'},
  138: {'rank': 3273, 'rarity': 'Uncommon'},
  139: {'rank': 5804, 'rarity': 'Common'},
  140: {'rank': 1618, 'rarity': 'Rare'},
  141: {'rank': 9663, 'rarity': 'Common'},
  142: {'rank': 219, 'rarity': 'Epic'},
  143: {'rank': 2243, 'rarity': 'Rare'},
  144: {'rank': 8332, 'rarity': 'Common'},
  145: {'rank': 7734, 'rarity': 'Common'},
  146: {'rank': 4676, 'rarity': 'Uncommon'},
  147: {'rank': 8031, 'rarity': 'Common'},
  148: {'rank': 8231, 'rarity': 'Common'},
  149: {'rank': 4757, 'rarity': 'Uncommon'},
  150: {'rank': 6817, 'rarity': 'Common'},
  151: {'rank': 9449, 'rarity': 'Common'},
  152: {'rank': 5830, 'rarity': 'Common'},
  153: {'rank': 4110, 'rarity': 'Uncommon'},
  154: {'rank': 8569, 'rarity': 'Common'},
  155: {'rank': 886, 'rarity': 'Epic'},
  156: {'rank': 2882, 'rarity': 'Uncommon'},
  157: {'rank': 7914, 'rarity': 'Common'},
  158: {'rank': 8997, 'rarity': 'Common'},
  159: {'rank': 3686, 'rarity': 'Uncommon'},
  160: {'rank': 8505, 'rarity': 'Common'},
  161: {'rank': 5067, 'rarity': 'Common'},
  162: {'rank': 1547, 'rarity': 'Rare'},
  163: {'rank': 8107, 'rarity': 'Common'},
  164: {'rank': 5954, 'rarity': 'Common'},
  165: {'rank': 5091, 'rarity': 'Common'},
  166: {'rank': 3657, 'rarity': 'Uncommon'},
  167: {'rank': 2464, 'rarity': 'Rare'},
  168: {'rank': 134, 'rarity': 'Epic'},
  169: {'rank': 7347, 'rarity': 'Common'},
  170: {'rank': 7541, 'rarity': 'Common'},
  171: {'rank': 8536, 'rarity': 'Common'},
  172: {'rank': 7894, 'rarity': 'Common'},
  173: {'rank': 4029, 'rarity': 'Uncommon'},
  174: {'rank': 5697, 'rarity': 'Common'},
  175: {'rank': 3611, 'rarity': 'Uncommon'},
  176: {'rank': 8762, 'rarity': 'Common'},
  177: {'rank': 8729, 'rarity': 'Common'},
  178: {'rank': 9619, 'rarity': 'Common'},
  179: {'rank': 8124, 'rarity': 'Common'},
  180: {'rank': 5228, 'rarity': 'Common'},
  181: {'rank': 6165, 'rarity': 'Common'},
  182: {'rank': 5120, 'rarity': 'Common'},
  183: {'rank': 3738, 'rarity': 'Uncommon'},
  184: {'rank': 5316, 'rarity': 'Common'},
  185: {'rank': 9073, 'rarity': 'Common'},
  186: {'rank': 5098, 'rarity': 'Common'},
  187: {'rank': 6770, 'rarity': 'Common'},
  188: {'rank': 5018, 'rarity': 'Common'},
  189: {'rank': 9076, 'rarity': 'Common'},
  190: {'rank': 1121, 'rarity': 'Rare'},
  191: {'rank': 4117, 'rarity': 'Uncommon'},
  192: {'rank': 7646, 'rarity': 'Common'},
  193: {'rank': 7375, 'rarity': 'Common'},
  194: {'rank': 6673, 'rarity': 'Common'},
  195: {'rank': 8127, 'rarity': 'Common'},
  196: {'rank': 3951, 'rarity': 'Uncommon'},
  197: {'rank': 6466, 'rarity': 'Common'},
  198: {'rank': 7850, 'rarity': 'Common'},
  199: {'rank': 7533, 'rarity': 'Common'},
  200: {'rank': 8937, 'rarity': 'Common'},
  201: {'rank': 5729, 'rarity': 'Common'},
  202: {'rank': 3291, 'rarity': 'Uncommon'},
  203: {'rank': 5763, 'rarity': 'Common'},
  204: {'rank': 1375, 'rarity': 'Rare'},
  205: {'rank': 6431, 'rarity': 'Common'},
  206: {'rank': 3759, 'rarity': 'Uncommon'},
  207: {'rank': 3306, 'rarity': 'Uncommon'},
  208: {'rank': 5354, 'rarity': 'Common'},
  209: {'rank': 8647, 'rarity': 'Common'},
  210: {'rank': 8759, 'rarity': 'Common'},
  211: {'rank': 6299, 'rarity': 'Common'},
  212: {'rank': 6551, 'rarity': 'Common'},
  213: {'rank': 9489, 'rarity': 'Common'},
  214: {'rank': 1191, 'rarity': 'Rare'},
  215: {'rank': 8770, 'rarity': 'Common'},
  216: {'rank': 2420, 'rarity': 'Rare'},
  217: {'rank': 1572, 'rarity': 'Rare'},
  218: {'rank': 7336, 'rarity': 'Common'},
  219: {'rank': 5414, 'rarity': 'Common'},
  220: {'rank': 2358, 'rarity': 'Rare'},
  221: {'rank': 1682, 'rarity': 'Rare'},
  222: {'rank': 1629, 'rarity': 'Rare'},
  223: {'rank': 3380, 'rarity': 'Uncommon'},
  224: {'rank': 1100, 'rarity': 'Rare'},
  225: {'rank': 3342, 'rarity': 'Uncommon'},
  226: {'rank': 3303, 'rarity': 'Uncommon'},
  227: {'rank': 8550, 'rarity': 'Common'},
  228: {'rank': 7198, 'rarity': 'Common'},
  229: {'rank': 3996, 'rarity': 'Uncommon'},
  230: {'rank': 6103, 'rarity': 'Common'},
  231: {'rank': 7815, 'rarity': 'Common'},
  232: {'rank': 9499, 'rarity': 'Common'},
  233: {'rank': 4209, 'rarity': 'Uncommon'},
  234: {'rank': 5647, 'rarity': 'Common'},
  235: {'rank': 2899, 'rarity': 'Uncommon'},
  236: {'rank': 1157, 'rarity': 'Rare'},
  237: {'rank': 6420, 'rarity': 'Common'},
  238: {'rank': 8149, 'rarity': 'Common'},
  239: {'rank': 4978, 'rarity': 'Uncommon'},
  240: {'rank': 9081, 'rarity': 'Common'},
  241: {'rank': 3229, 'rarity': 'Uncommon'},
  242: {'rank': 9712, 'rarity': 'Common'},
  243: {'rank': 5570, 'rarity': 'Common'},
  244: {'rank': 7515, 'rarity': 'Common'},
  245: {'rank': 2202, 'rarity': 'Rare'},
  246: {'rank': 3392, 'rarity': 'Uncommon'},
  247: {'rank': 4949, 'rarity': 'Uncommon'},
  248: {'rank': 4417, 'rarity': 'Uncommon'},
  249: {'rank': 1637, 'rarity': 'Rare'},
  250: {'rank': 4018, 'rarity': 'Uncommon'},
  251: {'rank': 3687, 'rarity': 'Uncommon'},
  252: {'rank': 3318, 'rarity': 'Uncommon'},
  253: {'rank': 3588, 'rarity': 'Uncommon'},
  254: {'rank': 6376, 'rarity': 'Common'},
  255: {'rank': 9723, 'rarity': 'Common'},
  256: {'rank': 8428, 'rarity': 'Common'},
  257: {'rank': 9996, 'rarity': 'Common'},
  258: {'rank': 5064, 'rarity': 'Common'},
  259: {'rank': 117, 'rarity': 'Epic'},
  260: {'rank': 2270, 'rarity': 'Rare'},
  261: {'rank': 972, 'rarity': 'Epic'},
  262: {'rank': 8391, 'rarity': 'Common'},
  263: {'rank': 6870, 'rarity': 'Common'},
  264: {'rank': 7190, 'rarity': 'Common'},
  265: {'rank': 2973, 'rarity': 'Uncommon'},
  266: {'rank': 2386, 'rarity': 'Rare'},
  267: {'rank': 1987, 'rarity': 'Rare'},
  268: {'rank': 3930, 'rarity': 'Uncommon'},
  269: {'rank': 1528, 'rarity': 'Rare'},
  270: {'rank': 1589, 'rarity': 'Rare'},
  271: {'rank': 4379, 'rarity': 'Uncommon'},
  272: {'rank': 1539, 'rarity': 'Rare'},
  273: {'rank': 6586, 'rarity': 'Common'},
  274: {'rank': 2162, 'rarity': 'Rare'},
  275: {'rank': 1718, 'rarity': 'Rare'},
  276: {'rank': 1283, 'rarity': 'Rare'},
  277: {'rank': 241, 'rarity': 'Epic'},
  278: {'rank': 5622, 'rarity': 'Common'},
  279: {'rank': 1084, 'rarity': 'Rare'},
  280: {'rank': 4010, 'rarity': 'Uncommon'},
  281: {'rank': 6085, 'rarity': 'Common'},
  282: {'rank': 5814, 'rarity': 'Common'},
  283: {'rank': 6936, 'rarity': 'Common'},
  284: {'rank': 2124, 'rarity': 'Rare'},
  285: {'rank': 3398, 'rarity': 'Uncommon'},
  286: {'rank': 2418, 'rarity': 'Rare'},
  287: {'rank': 5851, 'rarity': 'Common'},
  288: {'rank': 4601, 'rarity': 'Uncommon'},
  289: {'rank': 3092, 'rarity': 'Uncommon'},
  290: {'rank': 2596, 'rarity': 'Uncommon'},
  291: {'rank': 9530, 'rarity': 'Common'},
  292: {'rank': 7281, 'rarity': 'Common'},
  293: {'rank': 7583, 'rarity': 'Common'},
  294: {'rank': 3275, 'rarity': 'Uncommon'},
  295: {'rank': 7045, 'rarity': 'Common'},
  296: {'rank': 1470, 'rarity': 'Rare'},
  297: {'rank': 9641, 'rarity': 'Common'},
  298: {'rank': 760, 'rarity': 'Epic'},
  299: {'rank': 5769, 'rarity': 'Common'},
  300: {'rank': 6600, 'rarity': 'Common'},
  301: {'rank': 6685, 'rarity': 'Common'},
  302: {'rank': 1864, 'rarity': 'Rare'},
  303: {'rank': 9316, 'rarity': 'Common'},
  304: {'rank': 2712, 'rarity': 'Uncommon'},
  305: {'rank': 68, 'rarity': 'Legendary'},
  306: {'rank': 9842, 'rarity': 'Common'},
  307: {'rank': 2007, 'rarity': 'Rare'},
  308: {'rank': 8885, 'rarity': 'Common'},
  309: {'rank': 213, 'rarity': 'Epic'},
  310: {'rank': 1549, 'rarity': 'Rare'},
  311: {'rank': 2835, 'rarity': 'Uncommon'},
  312: {'rank': 2166, 'rarity': 'Rare'},
  313: {'rank': 2121, 'rarity': 'Rare'},
  314: {'rank': 342, 'rarity': 'Epic'},
  315: {'rank': 5163, 'rarity': 'Common'},
  316: {'rank': 7718, 'rarity': 'Common'},
  317: {'rank': 1790, 'rarity': 'Rare'},
  318: {'rank': 2961, 'rarity': 'Uncommon'},
  319: {'rank': 6359, 'rarity': 'Common'},
  320: {'rank': 5962, 'rarity': 'Common'},
  321: {'rank': 6426, 'rarity': 'Common'},
  322: {'rank': 3339, 'rarity': 'Uncommon'},
  323: {'rank': 3528, 'rarity': 'Uncommon'},
  324: {'rank': 4269, 'rarity': 'Uncommon'},
  325: {'rank': 5133, 'rarity': 'Common'},
  326: {'rank': 4975, 'rarity': 'Uncommon'},
  327: {'rank': 1665, 'rarity': 'Rare'},
  328: {'rank': 4825, 'rarity': 'Uncommon'},
  329: {'rank': 4846, 'rarity': 'Uncommon'},
  330: {'rank': 3626, 'rarity': 'Uncommon'},
  331: {'rank': 410, 'rarity': 'Epic'},
  332: {'rank': 4885, 'rarity': 'Uncommon'},
  333: {'rank': 3875, 'rarity': 'Uncommon'},
  334: {'rank': 6320, 'rarity': 'Common'},
  335: {'rank': 6537, 'rarity': 'Common'},
  336: {'rank': 8580, 'rarity': 'Common'},
  337: {'rank': 5154, 'rarity': 'Common'},
  338: {'rank': 1781, 'rarity': 'Rare'},
  339: {'rank': 9525, 'rarity': 'Common'},
  340: {'rank': 63, 'rarity': 'Legendary'},
  341: {'rank': 8874, 'rarity': 'Common'},
  342: {'rank': 5259, 'rarity': 'Common'},
  343: {'rank': 2298, 'rarity': 'Rare'},
  344: {'rank': 3958, 'rarity': 'Uncommon'},
  345: {'rank': 5643, 'rarity': 'Common'},
  346: {'rank': 5912, 'rarity': 'Common'},
  347: {'rank': 1091, 'rarity': 'Rare'},
  348: {'rank': 7209, 'rarity': 'Common'},
  349: {'rank': 4665, 'rarity': 'Uncommon'},
  350: {'rank': 9900, 'rarity': 'Common'},
  351: {'rank': 6457, 'rarity': 'Common'},
  352: {'rank': 8132, 'rarity': 'Common'},
  353: {'rank': 1335, 'rarity': 'Rare'},
  354: {'rank': 6614, 'rarity': 'Common'},
  355: {'rank': 5730, 'rarity': 'Common'},
  356: {'rank': 7498, 'rarity': 'Common'},
  357: {'rank': 5100, 'rarity': 'Common'},
  358: {'rank': 5713, 'rarity': 'Common'},
  359: {'rank': 535, 'rarity': 'Epic'},
  360: {'rank': 7524, 'rarity': 'Common'},
  361: {'rank': 5635, 'rarity': 'Common'},
  362: {'rank': 9474, 'rarity': 'Common'},
  363: {'rank': 2577, 'rarity': 'Uncommon'},
  364: {'rank': 792, 'rarity': 'Epic'},
  365: {'rank': 9660, 'rarity': 'Common'},
  366: {'rank': 5805, 'rarity': 'Common'},
  367: {'rank': 1430, 'rarity': 'Rare'},
  368: {'rank': 9738, 'rarity': 'Common'},
  369: {'rank': 6248, 'rarity': 'Common'},
  370: {'rank': 2601, 'rarity': 'Uncommon'},
  371: {'rank': 6145, 'rarity': 'Common'},
  372: {'rank': 3640, 'rarity': 'Uncommon'},
  373: {'rank': 38, 'rarity': 'Legendary'},
  374: {'rank': 4340, 'rarity': 'Uncommon'},
  375: {'rank': 8281, 'rarity': 'Common'},
  376: {'rank': 4989, 'rarity': 'Uncommon'},
  377: {'rank': 4605, 'rarity': 'Uncommon'},
  378: {'rank': 3351, 'rarity': 'Uncommon'},
  379: {'rank': 9479, 'rarity': 'Common'},
  380: {'rank': 6773, 'rarity': 'Common'},
  381: {'rank': 3732, 'rarity': 'Uncommon'},
  382: {'rank': 2903, 'rarity': 'Uncommon'},
  383: {'rank': 2316, 'rarity': 'Rare'},
  384: {'rank': 9786, 'rarity': 'Common'},
  385: {'rank': 9728, 'rarity': 'Common'},
  386: {'rank': 5295, 'rarity': 'Common'},
  387: {'rank': 1461, 'rarity': 'Rare'},
  388: {'rank': 9175, 'rarity': 'Common'},
  389: {'rank': 4935, 'rarity': 'Uncommon'},
  390: {'rank': 124, 'rarity': 'Epic'},
  391: {'rank': 5391, 'rarity': 'Common'},
  392: {'rank': 1752, 'rarity': 'Rare'},
  393: {'rank': 9454, 'rarity': 'Common'},
  394: {'rank': 4940, 'rarity': 'Uncommon'},
  395: {'rank': 9335, 'rarity': 'Common'},
  396: {'rank': 4289, 'rarity': 'Uncommon'},
  397: {'rank': 8530, 'rarity': 'Common'},
  398: {'rank': 3135, 'rarity': 'Uncommon'},
  399: {'rank': 7581, 'rarity': 'Common'},
  400: {'rank': 5873, 'rarity': 'Common'},
  401: {'rank': 6543, 'rarity': 'Common'},
  402: {'rank': 5901, 'rarity': 'Common'},
  403: {'rank': 865, 'rarity': 'Epic'},
  404: {'rank': 7034, 'rarity': 'Common'},
  405: {'rank': 6509, 'rarity': 'Common'},
  406: {'rank': 8144, 'rarity': 'Common'},
  407: {'rank': 3200, 'rarity': 'Uncommon'},
  408: {'rank': 1831, 'rarity': 'Rare'},
  409: {'rank': 2180, 'rarity': 'Rare'},
  410: {'rank': 7737, 'rarity': 'Common'},
  411: {'rank': 2502, 'rarity': 'Uncommon'},
  412: {'rank': 6829, 'rarity': 'Common'},
  413: {'rank': 515, 'rarity': 'Epic'},
  414: {'rank': 8072, 'rarity': 'Common'},
  415: {'rank': 4380, 'rarity': 'Uncommon'},
  416: {'rank': 7362, 'rarity': 'Common'},
  417: {'rank': 6436, 'rarity': 'Common'},
  418: {'rank': 9431, 'rarity': 'Common'},
  419: {'rank': 2331, 'rarity': 'Rare'},
  420: {'rank': 6988, 'rarity': 'Common'},
  421: {'rank': 2883, 'rarity': 'Uncommon'},
  422: {'rank': 9226, 'rarity': 'Common'},
  423: {'rank': 5172, 'rarity': 'Common'},
  424: {'rank': 1326, 'rarity': 'Rare'},
  425: {'rank': 8611, 'rarity': 'Common'},
  426: {'rank': 7196, 'rarity': 'Common'},
  427: {'rank': 9333, 'rarity': 'Common'},
  428: {'rank': 8461, 'rarity': 'Common'},
  429: {'rank': 2870, 'rarity': 'Uncommon'},
  430: {'rank': 4151, 'rarity': 'Uncommon'},
  431: {'rank': 2287, 'rarity': 'Rare'},
  432: {'rank': 6583, 'rarity': 'Common'},
  433: {'rank': 5265, 'rarity': 'Common'},
  434: {'rank': 8370, 'rarity': 'Common'},
  435: {'rank': 3596, 'rarity': 'Uncommon'},
  436: {'rank': 480, 'rarity': 'Epic'},
  437: {'rank': 9250, 'rarity': 'Common'},
  438: {'rank': 3985, 'rarity': 'Uncommon'},
  439: {'rank': 4167, 'rarity': 'Uncommon'},
  440: {'rank': 1042, 'rarity': 'Rare'},
  441: {'rank': 2878, 'rarity': 'Uncommon'},
  442: {'rank': 88, 'rarity': 'Legendary'},
  443: {'rank': 4959, 'rarity': 'Uncommon'},
  444: {'rank': 7519, 'rarity': 'Common'},
  445: {'rank': 3904, 'rarity': 'Uncommon'},
  446: {'rank': 2787, 'rarity': 'Uncommon'},
  447: {'rank': 9294, 'rarity': 'Common'},
  448: {'rank': 666, 'rarity': 'Epic'},
  449: {'rank': 2252, 'rarity': 'Rare'},
  450: {'rank': 1584, 'rarity': 'Rare'},
  451: {'rank': 7151, 'rarity': 'Common'},
  452: {'rank': 9617, 'rarity': 'Common'},
  453: {'rank': 6220, 'rarity': 'Common'},
  454: {'rank': 7418, 'rarity': 'Common'},
  455: {'rank': 7064, 'rarity': 'Common'},
  456: {'rank': 9233, 'rarity': 'Common'},
  457: {'rank': 5809, 'rarity': 'Common'},
  458: {'rank': 562, 'rarity': 'Epic'},
  459: {'rank': 8541, 'rarity': 'Common'},
  460: {'rank': 3481, 'rarity': 'Uncommon'},
  461: {'rank': 9359, 'rarity': 'Common'},
  462: {'rank': 979, 'rarity': 'Epic'},
  463: {'rank': 715, 'rarity': 'Epic'},
  464: {'rank': 9242, 'rarity': 'Common'},
  465: {'rank': 8044, 'rarity': 'Common'},
  466: {'rank': 4115, 'rarity': 'Uncommon'},
  467: {'rank': 2657, 'rarity': 'Uncommon'},
  468: {'rank': 3360, 'rarity': 'Uncommon'},
  469: {'rank': 1327, 'rarity': 'Rare'},
  470: {'rank': 4320, 'rarity': 'Uncommon'},
  471: {'rank': 7082, 'rarity': 'Common'},
  472: {'rank': 2510, 'rarity': 'Uncommon'},
  473: {'rank': 7393, 'rarity': 'Common'},
  474: {'rank': 3406, 'rarity': 'Uncommon'},
  475: {'rank': 1820, 'rarity': 'Rare'},
  476: {'rank': 7806, 'rarity': 'Common'},
  477: {'rank': 6311, 'rarity': 'Common'},
  478: {'rank': 3975, 'rarity': 'Uncommon'},
  479: {'rank': 2568, 'rarity': 'Uncommon'},
  480: {'rank': 8298, 'rarity': 'Common'},
  481: {'rank': 6318, 'rarity': 'Common'},
  482: {'rank': 9935, 'rarity': 'Common'},
  483: {'rank': 8285, 'rarity': 'Common'},
  484: {'rank': 2723, 'rarity': 'Uncommon'},
  485: {'rank': 9750, 'rarity': 'Common'},
  486: {'rank': 2982, 'rarity': 'Uncommon'},
  487: {'rank': 5819, 'rarity': 'Common'},
  488: {'rank': 4044, 'rarity': 'Uncommon'},
  489: {'rank': 2565, 'rarity': 'Uncommon'},
  490: {'rank': 7978, 'rarity': 'Common'},
  491: {'rank': 1005, 'rarity': 'Rare'},
  492: {'rank': 9043, 'rarity': 'Common'},
  493: {'rank': 5919, 'rarity': 'Common'},
  494: {'rank': 7261, 'rarity': 'Common'},
  495: {'rank': 1172, 'rarity': 'Rare'},
  496: {'rank': 9537, 'rarity': 'Common'},
  497: {'rank': 1877, 'rarity': 'Rare'},
  498: {'rank': 104, 'rarity': 'Epic'},
  499: {'rank': 4504, 'rarity': 'Uncommon'},
  500: {'rank': 5244, 'rarity': 'Common'},
  501: {'rank': 5556, 'rarity': 'Common'},
  502: {'rank': 3014, 'rarity': 'Uncommon'},
  503: {'rank': 4653, 'rarity': 'Uncommon'},
  504: {'rank': 2037, 'rarity': 'Rare'},
  505: {'rank': 8060, 'rarity': 'Common'},
  506: {'rank': 9453, 'rarity': 'Common'},
  507: {'rank': 4384, 'rarity': 'Uncommon'},
  508: {'rank': 1569, 'rarity': 'Rare'},
  509: {'rank': 3097, 'rarity': 'Uncommon'},
  510: {'rank': 7487, 'rarity': 'Common'},
  511: {'rank': 7061, 'rarity': 'Common'},
  512: {'rank': 2210, 'rarity': 'Rare'},
  513: {'rank': 5182, 'rarity': 'Common'},
  514: {'rank': 604, 'rarity': 'Epic'},
  515: {'rank': 6706, 'rarity': 'Common'},
  516: {'rank': 9685, 'rarity': 'Common'},
  517: {'rank': 944, 'rarity': 'Epic'},
  518: {'rank': 9555, 'rarity': 'Common'},
  519: {'rank': 3879, 'rarity': 'Uncommon'},
  520: {'rank': 1265, 'rarity': 'Rare'},
  521: {'rank': 6759, 'rarity': 'Common'},
  522: {'rank': 9640, 'rarity': 'Common'},
  523: {'rank': 9276, 'rarity': 'Common'},
  524: {'rank': 8956, 'rarity': 'Common'},
  525: {'rank': 4455, 'rarity': 'Uncommon'},
  526: {'rank': 2480, 'rarity': 'Rare'},
  527: {'rank': 6448, 'rarity': 'Common'},
  528: {'rank': 8197, 'rarity': 'Common'},
  529: {'rank': 3761, 'rarity': 'Uncommon'},
  530: {'rank': 8500, 'rarity': 'Common'},
  531: {'rank': 6975, 'rarity': 'Common'},
  532: {'rank': 9036, 'rarity': 'Common'},
  533: {'rank': 582, 'rarity': 'Epic'},
  534: {'rank': 7648, 'rarity': 'Common'},
  535: {'rank': 1714, 'rarity': 'Rare'},
  536: {'rank': 4078, 'rarity': 'Uncommon'},
  537: {'rank': 8181, 'rarity': 'Common'},
  538: {'rank': 5274, 'rarity': 'Common'},
  539: {'rank': 1941, 'rarity': 'Rare'},
  540: {'rank': 1977, 'rarity': 'Rare'},
  541: {'rank': 4484, 'rarity': 'Uncommon'},
  542: {'rank': 5469, 'rarity': 'Common'},
  543: {'rank': 7780, 'rarity': 'Common'},
  544: {'rank': 6153, 'rarity': 'Common'},
  545: {'rank': 4717, 'rarity': 'Uncommon'},
  546: {'rank': 691, 'rarity': 'Epic'},
  547: {'rank': 3688, 'rarity': 'Uncommon'},
  548: {'rank': 3968, 'rarity': 'Uncommon'},
  549: {'rank': 4302, 'rarity': 'Uncommon'},
  550: {'rank': 6635, 'rarity': 'Common'},
  551: {'rank': 4142, 'rarity': 'Uncommon'},
  552: {'rank': 7208, 'rarity': 'Common'},
  553: {'rank': 6118, 'rarity': 'Common'},
  554: {'rank': 3015, 'rarity': 'Uncommon'},
  555: {'rank': 401, 'rarity': 'Epic'},
  556: {'rank': 1509, 'rarity': 'Rare'},
  557: {'rank': 750, 'rarity': 'Epic'},
  558: {'rank': 7791, 'rarity': 'Common'},
  559: {'rank': 8798, 'rarity': 'Common'},
  560: {'rank': 9365, 'rarity': 'Common'},
  561: {'rank': 4937, 'rarity': 'Uncommon'},
  562: {'rank': 9002, 'rarity': 'Common'},
  563: {'rank': 787, 'rarity': 'Epic'},
  564: {'rank': 2686, 'rarity': 'Uncommon'},
  565: {'rank': 8403, 'rarity': 'Common'},
  566: {'rank': 8689, 'rarity': 'Common'},
  567: {'rank': 1922, 'rarity': 'Rare'},
  568: {'rank': 4268, 'rarity': 'Uncommon'},
  569: {'rank': 3030, 'rarity': 'Uncommon'},
  570: {'rank': 2461, 'rarity': 'Rare'},
  571: {'rank': 2977, 'rarity': 'Uncommon'},
  572: {'rank': 1413, 'rarity': 'Rare'},
  573: {'rank': 2740, 'rarity': 'Uncommon'},
  574: {'rank': 1266, 'rarity': 'Rare'},
  575: {'rank': 5822, 'rarity': 'Common'},
  576: {'rank': 3331, 'rarity': 'Uncommon'},
  577: {'rank': 6789, 'rarity': 'Common'},
  578: {'rank': 7561, 'rarity': 'Common'},
  579: {'rank': 5896, 'rarity': 'Common'},
  580: {'rank': 839, 'rarity': 'Epic'},
  581: {'rank': 4866, 'rarity': 'Uncommon'},
  582: {'rank': 8450, 'rarity': 'Common'},
  583: {'rank': 5553, 'rarity': 'Common'},
  584: {'rank': 4902, 'rarity': 'Uncommon'},
  585: {'rank': 6383, 'rarity': 'Common'},
  586: {'rank': 2259, 'rarity': 'Rare'},
  587: {'rank': 3066, 'rarity': 'Uncommon'},
  588: {'rank': 8943, 'rarity': 'Common'},
  589: {'rank': 2805, 'rarity': 'Uncommon'},
  590: {'rank': 3850, 'rarity': 'Uncommon'},
  591: {'rank': 2508, 'rarity': 'Uncommon'},
  592: {'rank': 7620, 'rarity': 'Common'},
  593: {'rank': 622, 'rarity': 'Epic'},
  594: {'rank': 7807, 'rarity': 'Common'},
  595: {'rank': 6497, 'rarity': 'Common'},
  596: {'rank': 7615, 'rarity': 'Common'},
  597: {'rank': 499, 'rarity': 'Epic'},
  598: {'rank': 9102, 'rarity': 'Common'},
  599: {'rank': 1483, 'rarity': 'Rare'},
  600: {'rank': 1034, 'rarity': 'Rare'},
  601: {'rank': 2505, 'rarity': 'Uncommon'},
  602: {'rank': 7136, 'rarity': 'Common'},
  603: {'rank': 3489, 'rarity': 'Uncommon'},
  604: {'rank': 1623, 'rarity': 'Rare'},
  605: {'rank': 228, 'rarity': 'Epic'},
  606: {'rank': 5918, 'rarity': 'Common'},
  607: {'rank': 2924, 'rarity': 'Uncommon'},
  608: {'rank': 5145, 'rarity': 'Common'},
  609: {'rank': 1848, 'rarity': 'Rare'},
  610: {'rank': 3909, 'rarity': 'Uncommon'},
  611: {'rank': 8486, 'rarity': 'Common'},
  612: {'rank': 7943, 'rarity': 'Common'},
  613: {'rank': 3329, 'rarity': 'Uncommon'},
  614: {'rank': 9387, 'rarity': 'Common'},
  615: {'rank': 4159, 'rarity': 'Uncommon'},
  616: {'rank': 1690, 'rarity': 'Rare'},
  617: {'rank': 4087, 'rarity': 'Uncommon'},
  618: {'rank': 4317, 'rarity': 'Uncommon'},
  619: {'rank': 1879, 'rarity': 'Rare'},
  620: {'rank': 8086, 'rarity': 'Common'},
  621: {'rank': 5581, 'rarity': 'Common'},
  622: {'rank': 4375, 'rarity': 'Uncommon'},
  623: {'rank': 6243, 'rarity': 'Common'},
  624: {'rank': 8096, 'rarity': 'Common'},
  625: {'rank': 2753, 'rarity': 'Uncommon'},
  626: {'rank': 8820, 'rarity': 'Common'},
  627: {'rank': 5357, 'rarity': 'Common'},
  628: {'rank': 5095, 'rarity': 'Common'},
  629: {'rank': 2055, 'rarity': 'Rare'},
  630: {'rank': 4765, 'rarity': 'Uncommon'},
  631: {'rank': 9886, 'rarity': 'Common'},
  632: {'rank': 2239, 'rarity': 'Rare'},
  633: {'rank': 9675, 'rarity': 'Common'},
  634: {'rank': 4755, 'rarity': 'Uncommon'},
  635: {'rank': 1522, 'rarity': 'Rare'},
  636: {'rank': 2393, 'rarity': 'Rare'},
  637: {'rank': 1468, 'rarity': 'Rare'},
  638: {'rank': 4332, 'rarity': 'Uncommon'},
  639: {'rank': 6419, 'rarity': 'Common'},
  640: {'rank': 2462, 'rarity': 'Rare'},
  641: {'rank': 1107, 'rarity': 'Rare'},
  642: {'rank': 8419, 'rarity': 'Common'},
  643: {'rank': 5239, 'rarity': 'Common'},
  644: {'rank': 314, 'rarity': 'Epic'},
  645: {'rank': 6269, 'rarity': 'Common'},
  646: {'rank': 5759, 'rarity': 'Common'},
  647: {'rank': 6132, 'rarity': 'Common'},
  648: {'rank': 9140, 'rarity': 'Common'},
  649: {'rank': 7310, 'rarity': 'Common'},
  650: {'rank': 2012, 'rarity': 'Rare'},
  651: {'rank': 556, 'rarity': 'Epic'},
  652: {'rank': 438, 'rarity': 'Epic'},
  653: {'rank': 5081, 'rarity': 'Common'},
  654: {'rank': 3719, 'rarity': 'Uncommon'},
  655: {'rank': 1995, 'rarity': 'Rare'},
  656: {'rank': 9508, 'rarity': 'Common'},
  657: {'rank': 3631, 'rarity': 'Uncommon'},
  658: {'rank': 6062, 'rarity': 'Common'},
  659: {'rank': 3524, 'rarity': 'Uncommon'},
  660: {'rank': 4995, 'rarity': 'Uncommon'},
  661: {'rank': 7398, 'rarity': 'Common'},
  662: {'rank': 1279, 'rarity': 'Rare'},
  663: {'rank': 7637, 'rarity': 'Common'},
  664: {'rank': 9930, 'rarity': 'Common'},
  665: {'rank': 6670, 'rarity': 'Common'},
  666: {'rank': 5723, 'rarity': 'Common'},
  667: {'rank': 1268, 'rarity': 'Rare'},
  668: {'rank': 771, 'rarity': 'Epic'},
  669: {'rank': 9630, 'rarity': 'Common'},
  670: {'rank': 8854, 'rarity': 'Common'},
  671: {'rank': 2873, 'rarity': 'Uncommon'},
  672: {'rank': 3002, 'rarity': 'Uncommon'},
  673: {'rank': 377, 'rarity': 'Epic'},
  674: {'rank': 8692, 'rarity': 'Common'},
  675: {'rank': 364, 'rarity': 'Epic'},
  676: {'rank': 9945, 'rarity': 'Common'},
  677: {'rank': 7105, 'rarity': 'Common'},
  678: {'rank': 3217, 'rarity': 'Uncommon'},
  679: {'rank': 4580, 'rarity': 'Uncommon'},
  680: {'rank': 853, 'rarity': 'Epic'},
  681: {'rank': 5472, 'rarity': 'Common'},
  682: {'rank': 9190, 'rarity': 'Common'},
  683: {'rank': 4888, 'rarity': 'Uncommon'},
  684: {'rank': 7238, 'rarity': 'Common'},
  685: {'rank': 1449, 'rarity': 'Rare'},
  686: {'rank': 7580, 'rarity': 'Common'},
  687: {'rank': 4373, 'rarity': 'Uncommon'},
  688: {'rank': 3533, 'rarity': 'Uncommon'},
  689: {'rank': 796, 'rarity': 'Epic'},
  690: {'rank': 9721, 'rarity': 'Common'},
  691: {'rank': 4761, 'rarity': 'Uncommon'},
  692: {'rank': 7126, 'rarity': 'Common'},
  693: {'rank': 5303, 'rarity': 'Common'},
  694: {'rank': 3564, 'rarity': 'Uncommon'},
  695: {'rank': 6986, 'rarity': 'Common'},
  696: {'rank': 1756, 'rarity': 'Rare'},
  697: {'rank': 4751, 'rarity': 'Uncommon'},
  698: {'rank': 2385, 'rarity': 'Rare'},
  699: {'rank': 1838, 'rarity': 'Rare'},
  700: {'rank': 4767, 'rarity': 'Uncommon'},
  701: {'rank': 1697, 'rarity': 'Rare'},
  702: {'rank': 4490, 'rarity': 'Uncommon'},
  703: {'rank': 3820, 'rarity': 'Uncommon'},
  704: {'rank': 3027, 'rarity': 'Uncommon'},
  705: {'rank': 4416, 'rarity': 'Uncommon'},
  706: {'rank': 8385, 'rarity': 'Common'},
  707: {'rank': 3622, 'rarity': 'Uncommon'},
  708: {'rank': 8884, 'rarity': 'Common'},
  709: {'rank': 7167, 'rarity': 'Common'},
  710: {'rank': 601, 'rarity': 'Epic'},
  711: {'rank': 9007, 'rarity': 'Common'},
  712: {'rank': 6781, 'rarity': 'Common'},
  713: {'rank': 5576, 'rarity': 'Common'},
  714: {'rank': 1492, 'rarity': 'Rare'},
  715: {'rank': 6177, 'rarity': 'Common'},
  716: {'rank': 5493, 'rarity': 'Common'},
  717: {'rank': 1008, 'rarity': 'Rare'},
  718: {'rank': 8924, 'rarity': 'Common'},
  719: {'rank': 5504, 'rarity': 'Common'},
  720: {'rank': 1708, 'rarity': 'Rare'},
  721: {'rank': 9976, 'rarity': 'Common'},
  722: {'rank': 2198, 'rarity': 'Rare'},
  723: {'rank': 7852, 'rarity': 'Common'},
  724: {'rank': 6026, 'rarity': 'Common'},
  725: {'rank': 3118, 'rarity': 'Uncommon'},
  726: {'rank': 8563, 'rarity': 'Common'},
  727: {'rank': 5595, 'rarity': 'Common'},
  728: {'rank': 72, 'rarity': 'Legendary'},
  729: {'rank': 2183, 'rarity': 'Rare'},
  730: {'rank': 1616, 'rarity': 'Rare'},
  731: {'rank': 6967, 'rarity': 'Common'},
  732: {'rank': 3344, 'rarity': 'Uncommon'},
  733: {'rank': 6162, 'rarity': 'Common'},
  734: {'rank': 8035, 'rarity': 'Common'},
  735: {'rank': 8853, 'rarity': 'Common'},
  736: {'rank': 4768, 'rarity': 'Uncommon'},
  737: {'rank': 6824, 'rarity': 'Common'},
  738: {'rank': 7230, 'rarity': 'Common'},
  739: {'rank': 8177, 'rarity': 'Common'},
  740: {'rank': 5174, 'rarity': 'Common'},
  741: {'rank': 1127, 'rarity': 'Rare'},
  742: {'rank': 887, 'rarity': 'Epic'},
  743: {'rank': 9216, 'rarity': 'Common'},
  744: {'rank': 7428, 'rarity': 'Common'},
  745: {'rank': 7242, 'rarity': 'Common'},
  746: {'rank': 8791, 'rarity': 'Common'},
  747: {'rank': 7574, 'rarity': 'Common'},
  748: {'rank': 7722, 'rarity': 'Common'},
  749: {'rank': 9307, 'rarity': 'Common'},
  750: {'rank': 8083, 'rarity': 'Common'},
  751: {'rank': 7437, 'rarity': 'Common'},
  752: {'rank': 7814, 'rarity': 'Common'},
  753: {'rank': 7940, 'rarity': 'Common'},
  754: {'rank': 7979, 'rarity': 'Common'},
  755: {'rank': 8900, 'rarity': 'Common'},
  756: {'rank': 403, 'rarity': 'Epic'},
  757: {'rank': 9603, 'rarity': 'Common'},
  758: {'rank': 980, 'rarity': 'Epic'},
  759: {'rank': 3950, 'rarity': 'Uncommon'},
  760: {'rank': 5374, 'rarity': 'Common'},
  761: {'rank': 7302, 'rarity': 'Common'},
  762: {'rank': 655, 'rarity': 'Epic'},
  763: {'rank': 6106, 'rarity': 'Common'},
  764: {'rank': 4837, 'rarity': 'Uncommon'},
  765: {'rank': 9515, 'rarity': 'Common'},
  766: {'rank': 4508, 'rarity': 'Uncommon'},
  767: {'rank': 2064, 'rarity': 'Rare'},
  768: {'rank': 1997, 'rarity': 'Rare'},
  769: {'rank': 9026, 'rarity': 'Common'},
  770: {'rank': 9055, 'rarity': 'Common'},
  771: {'rank': 2122, 'rarity': 'Rare'},
  772: {'rank': 5231, 'rarity': 'Common'},
  773: {'rank': 7985, 'rarity': 'Common'},
  774: {'rank': 8722, 'rarity': 'Common'},
  775: {'rank': 7983, 'rarity': 'Common'},
  776: {'rank': 4744, 'rarity': 'Uncommon'},
  777: {'rank': 823, 'rarity': 'Epic'},
  778: {'rank': 9763, 'rarity': 'Common'},
  779: {'rank': 9594, 'rarity': 'Common'},
  780: {'rank': 4160, 'rarity': 'Uncommon'},
  781: {'rank': 6845, 'rarity': 'Common'},
  782: {'rank': 9951, 'rarity': 'Common'},
  783: {'rank': 7079, 'rarity': 'Common'},
  784: {'rank': 316, 'rarity': 'Epic'},
  785: {'rank': 4990, 'rarity': 'Uncommon'},
  786: {'rank': 7586, 'rarity': 'Common'},
  787: {'rank': 862, 'rarity': 'Epic'},
  788: {'rank': 4600, 'rarity': 'Uncommon'},
  789: {'rank': 2572, 'rarity': 'Uncommon'},
  790: {'rank': 218, 'rarity': 'Epic'},
  791: {'rank': 3359, 'rarity': 'Uncommon'},
  792: {'rank': 5470, 'rarity': 'Common'},
  793: {'rank': 3234, 'rarity': 'Uncommon'},
  794: {'rank': 238, 'rarity': 'Epic'},
  795: {'rank': 9577, 'rarity': 'Common'},
  796: {'rank': 1621, 'rarity': 'Rare'},
  797: {'rank': 8866, 'rarity': 'Common'},
  798: {'rank': 155, 'rarity': 'Epic'},
  799: {'rank': 9735, 'rarity': 'Common'},
  800: {'rank': 6683, 'rarity': 'Common'},
  801: {'rank': 7469, 'rarity': 'Common'},
  802: {'rank': 3908, 'rarity': 'Uncommon'},
  803: {'rank': 3828, 'rarity': 'Uncommon'},
  804: {'rank': 7946, 'rarity': 'Common'},
  805: {'rank': 4352, 'rarity': 'Uncommon'},
  806: {'rank': 7040, 'rarity': 'Common'},
  807: {'rank': 86, 'rarity': 'Legendary'},
  808: {'rank': 1793, 'rarity': 'Rare'},
  809: {'rank': 169, 'rarity': 'Epic'},
  810: {'rank': 4731, 'rarity': 'Uncommon'},
  811: {'rank': 8053, 'rarity': 'Common'},
  812: {'rank': 4405, 'rarity': 'Uncommon'},
  813: {'rank': 3944, 'rarity': 'Uncommon'},
  814: {'rank': 4346, 'rarity': 'Uncommon'},
  815: {'rank': 753, 'rarity': 'Epic'},
  816: {'rank': 7707, 'rarity': 'Common'},
  817: {'rank': 7035, 'rarity': 'Common'},
  818: {'rank': 3912, 'rarity': 'Uncommon'},
  819: {'rank': 1160, 'rarity': 'Rare'},
  820: {'rank': 4066, 'rarity': 'Uncommon'},
  821: {'rank': 3999, 'rarity': 'Uncommon'},
  822: {'rank': 3107, 'rarity': 'Uncommon'},
  823: {'rank': 6846, 'rarity': 'Common'},
  824: {'rank': 7141, 'rarity': 'Common'},
  825: {'rank': 5451, 'rarity': 'Common'},
  826: {'rank': 7339, 'rarity': 'Common'},
  827: {'rank': 5636, 'rarity': 'Common'},
  828: {'rank': 4005, 'rarity': 'Uncommon'},
  829: {'rank': 9860, 'rarity': 'Common'},
  830: {'rank': 6063, 'rarity': 'Common'},
  831: {'rank': 3175, 'rarity': 'Uncommon'},
  832: {'rank': 574, 'rarity': 'Epic'},
  833: {'rank': 1550, 'rarity': 'Rare'},
  834: {'rank': 4367, 'rarity': 'Uncommon'},
  835: {'rank': 5992, 'rarity': 'Common'},
  836: {'rank': 7535, 'rarity': 'Common'},
  837: {'rank': 3278, 'rarity': 'Uncommon'},
  838: {'rank': 4012, 'rarity': 'Uncommon'},
  839: {'rank': 5659, 'rarity': 'Common'},
  840: {'rank': 6809, 'rarity': 'Common'},
  841: {'rank': 9816, 'rarity': 'Common'},
  842: {'rank': 7016, 'rarity': 'Common'},
  843: {'rank': 8761, 'rarity': 'Common'},
  844: {'rank': 5845, 'rarity': 'Common'},
  845: {'rank': 4831, 'rarity': 'Uncommon'},
  846: {'rank': 8635, 'rarity': 'Common'},
  847: {'rank': 8153, 'rarity': 'Common'},
  848: {'rank': 8268, 'rarity': 'Common'},
  849: {'rank': 1750, 'rarity': 'Rare'},
  850: {'rank': 665, 'rarity': 'Epic'},
  851: {'rank': 5251, 'rarity': 'Common'},
  852: {'rank': 6302, 'rarity': 'Common'},
  853: {'rank': 4752, 'rarity': 'Uncommon'},
  854: {'rank': 6876, 'rarity': 'Common'},
  855: {'rank': 4666, 'rarity': 'Uncommon'},
  856: {'rank': 9112, 'rarity': 'Common'},
  857: {'rank': 9551, 'rarity': 'Common'},
  858: {'rank': 7578, 'rarity': 'Common'},
  859: {'rank': 1882, 'rarity': 'Rare'},
  860: {'rank': 4154, 'rarity': 'Uncommon'},
  861: {'rank': 2063, 'rarity': 'Rare'},
  862: {'rank': 7471, 'rarity': 'Common'},
  863: {'rank': 6018, 'rarity': 'Common'},
  864: {'rank': 2356, 'rarity': 'Rare'},
  865: {'rank': 6043, 'rarity': 'Common'},
  866: {'rank': 4809, 'rarity': 'Uncommon'},
  867: {'rank': 9740, 'rarity': 'Common'},
  868: {'rank': 3884, 'rarity': 'Uncommon'},
  869: {'rank': 7505, 'rarity': 'Common'},
  870: {'rank': 5033, 'rarity': 'Common'},
  871: {'rank': 3167, 'rarity': 'Uncommon'},
  872: {'rank': 6599, 'rarity': 'Common'},
  873: {'rank': 7176, 'rarity': 'Common'},
  874: {'rank': 2529, 'rarity': 'Uncommon'},
  875: {'rank': 82, 'rarity': 'Legendary'},
  876: {'rank': 8907, 'rarity': 'Common'},
  877: {'rank': 8583, 'rarity': 'Common'},
  878: {'rank': 7819, 'rarity': 'Common'},
  879: {'rank': 1776, 'rarity': 'Rare'},
  880: {'rank': 3976, 'rarity': 'Uncommon'},
  881: {'rank': 1140, 'rarity': 'Rare'},
  882: {'rank': 5462, 'rarity': 'Common'},
  883: {'rank': 8347, 'rarity': 'Common'},
  884: {'rank': 1402, 'rarity': 'Rare'},
  885: {'rank': 9001, 'rarity': 'Common'},
  886: {'rank': 3982, 'rarity': 'Uncommon'},
  887: {'rank': 1754, 'rarity': 'Rare'},
  888: {'rank': 6928, 'rarity': 'Common'},
  889: {'rank': 5768, 'rarity': 'Common'},
  890: {'rank': 4562, 'rarity': 'Uncommon'},
  891: {'rank': 2131, 'rarity': 'Rare'},
  892: {'rank': 6322, 'rarity': 'Common'},
  893: {'rank': 5662, 'rarity': 'Common'},
  894: {'rank': 227, 'rarity': 'Epic'},
  895: {'rank': 7869, 'rarity': 'Common'},
  896: {'rank': 6122, 'rarity': 'Common'},
  897: {'rank': 7682, 'rarity': 'Common'},
  898: {'rank': 7661, 'rarity': 'Common'},
  899: {'rank': 268, 'rarity': 'Epic'},
  900: {'rank': 5315, 'rarity': 'Common'},
  901: {'rank': 1039, 'rarity': 'Rare'},
  902: {'rank': 7797, 'rarity': 'Common'},
  903: {'rank': 2904, 'rarity': 'Uncommon'},
  904: {'rank': 8892, 'rarity': 'Common'},
  905: {'rank': 6544, 'rarity': 'Common'},
  906: {'rank': 4871, 'rarity': 'Uncommon'},
  907: {'rank': 2716, 'rarity': 'Uncommon'},
  908: {'rank': 9129, 'rarity': 'Common'},
  909: {'rank': 5142, 'rarity': 'Common'},
  910: {'rank': 9434, 'rarity': 'Common'},
  911: {'rank': 5441, 'rarity': 'Common'},
  912: {'rank': 3357, 'rarity': 'Uncommon'},
  913: {'rank': 8134, 'rarity': 'Common'},
  914: {'rank': 3693, 'rarity': 'Uncommon'},
  915: {'rank': 1086, 'rarity': 'Rare'},
  916: {'rank': 5738, 'rarity': 'Common'},
  917: {'rank': 8101, 'rarity': 'Common'},
  918: {'rank': 8063, 'rarity': 'Common'},
  919: {'rank': 5002, 'rarity': 'Common'},
  920: {'rank': 1059, 'rarity': 'Rare'},
  921: {'rank': 8522, 'rarity': 'Common'},
  922: {'rank': 5339, 'rarity': 'Common'},
  923: {'rank': 1728, 'rarity': 'Rare'},
  924: {'rank': 830, 'rarity': 'Epic'},
  925: {'rank': 9713, 'rarity': 'Common'},
  926: {'rank': 2766, 'rarity': 'Uncommon'},
  927: {'rank': 1119, 'rarity': 'Rare'},
  928: {'rank': 4444, 'rarity': 'Uncommon'},
  929: {'rank': 8420, 'rarity': 'Common'},
  930: {'rank': 4741, 'rarity': 'Uncommon'},
  931: {'rank': 2975, 'rarity': 'Uncommon'},
  932: {'rank': 4568, 'rarity': 'Uncommon'},
  933: {'rank': 5074, 'rarity': 'Common'},
  934: {'rank': 5337, 'rarity': 'Common'},
  935: {'rank': 5682, 'rarity': 'Common'},
  936: {'rank': 4112, 'rarity': 'Uncommon'},
  937: {'rank': 9184, 'rarity': 'Common'},
  938: {'rank': 3249, 'rarity': 'Uncommon'},
  939: {'rank': 1734, 'rarity': 'Rare'},
  940: {'rank': 3897, 'rarity': 'Uncommon'},
  941: {'rank': 2206, 'rarity': 'Rare'},
  942: {'rank': 6042, 'rarity': 'Common'},
  943: {'rank': 2512, 'rarity': 'Uncommon'},
  944: {'rank': 6495, 'rarity': 'Common'},
  945: {'rank': 5062, 'rarity': 'Common'},
  946: {'rank': 2901, 'rarity': 'Uncommon'},
  947: {'rank': 6375, 'rarity': 'Common'},
  948: {'rank': 9751, 'rarity': 'Common'},
  949: {'rank': 1282, 'rarity': 'Rare'},
  950: {'rank': 1458, 'rarity': 'Rare'},
  951: {'rank': 1112, 'rarity': 'Rare'},
  952: {'rank': 7168, 'rarity': 'Common'},
  953: {'rank': 9245, 'rarity': 'Common'},
  954: {'rank': 7315, 'rarity': 'Common'},
  955: {'rank': 9753, 'rarity': 'Common'},
  956: {'rank': 9851, 'rarity': 'Common'},
  957: {'rank': 8475, 'rarity': 'Common'},
  958: {'rank': 3284, 'rarity': 'Uncommon'},
  959: {'rank': 3439, 'rarity': 'Uncommon'},
  960: {'rank': 6390, 'rarity': 'Common'},
  961: {'rank': 2004, 'rarity': 'Rare'},
  962: {'rank': 1383, 'rarity': 'Rare'},
  963: {'rank': 9695, 'rarity': 'Common'},
  964: {'rank': 3635, 'rarity': 'Uncommon'},
  965: {'rank': 5599, 'rarity': 'Common'},
  966: {'rank': 7965, 'rarity': 'Common'},
  967: {'rank': 1256, 'rarity': 'Rare'},
  968: {'rank': 3237, 'rarity': 'Uncommon'},
  969: {'rank': 322, 'rarity': 'Epic'},
  970: {'rank': 5009, 'rarity': 'Common'},
  971: {'rank': 4634, 'rarity': 'Uncommon'},
  972: {'rank': 3907, 'rarity': 'Uncommon'},
  973: {'rank': 7369, 'rarity': 'Common'},
  974: {'rank': 7312, 'rarity': 'Common'},
  975: {'rank': 8399, 'rarity': 'Common'},
  976: {'rank': 6158, 'rarity': 'Common'},
  977: {'rank': 7517, 'rarity': 'Common'},
  978: {'rank': 8291, 'rarity': 'Common'},
  979: {'rank': 1946, 'rarity': 'Rare'},
  980: {'rank': 3347, 'rarity': 'Uncommon'},
  981: {'rank': 5887, 'rarity': 'Common'},
  982: {'rank': 7750, 'rarity': 'Common'},
  983: {'rank': 1727, 'rarity': 'Rare'},
  984: {'rank': 9544, 'rarity': 'Common'},
  985: {'rank': 8119, 'rarity': 'Common'},
  986: {'rank': 496, 'rarity': 'Epic'},
  987: {'rank': 1907, 'rarity': 'Rare'},
  988: {'rank': 1899, 'rarity': 'Rare'},
  989: {'rank': 3412, 'rarity': 'Uncommon'},
  990: {'rank': 7287, 'rarity': 'Common'},
  991: {'rank': 3264, 'rarity': 'Uncommon'},
  992: {'rank': 9967, 'rarity': 'Common'},
  993: {'rank': 7858, 'rarity': 'Common'},
  994: {'rank': 6716, 'rarity': 'Common'},
  995: {'rank': 3529, 'rarity': 'Uncommon'},
  996: {'rank': 6092, 'rarity': 'Common'},
  997: {'rank': 6334, 'rarity': 'Common'},
  998: {'rank': 7575, 'rarity': 'Common'},
  999: {'rank': 9621, 'rarity': 'Common'},
  1000: {'rank': 5758, 'rarity': 'Common'},
  1001: {'rank': 3132, 'rarity': 'Uncommon'},
  1002: {'rank': 4546, 'rarity': 'Uncommon'},
  1003: {'rank': 7685, 'rarity': 'Common'},
  1004: {'rank': 483, 'rarity': 'Epic'},
  1005: {'rank': 1778, 'rarity': 'Rare'},
  1006: {'rank': 3257, 'rarity': 'Uncommon'},
  1007: {'rank': 3503, 'rarity': 'Uncommon'},
  1008: {'rank': 7290, 'rarity': 'Common'},
  1009: {'rank': 3322, 'rarity': 'Uncommon'},
  1010: {'rank': 7632, 'rarity': 'Common'},
  1011: {'rank': 1128, 'rarity': 'Rare'},
  1012: {'rank': 1967, 'rarity': 'Rare'},
  1013: {'rank': 6506, 'rarity': 'Common'},
  1014: {'rank': 2500, 'rarity': 'Rare'},
  1015: {'rank': 7820, 'rarity': 'Common'},
  1016: {'rank': 1624, 'rarity': 'Rare'},
  1017: {'rank': 9206, 'rarity': 'Common'},
  1018: {'rank': 7488, 'rarity': 'Common'},
  1019: {'rank': 2125, 'rarity': 'Rare'},
  1020: {'rank': 7846, 'rarity': 'Common'},
  1021: {'rank': 7447, 'rarity': 'Common'},
  1022: {'rank': 826, 'rarity': 'Epic'},
  1023: {'rank': 1814, 'rarity': 'Rare'},
  1024: {'rank': 4448, 'rarity': 'Uncommon'},
  1025: {'rank': 8738, 'rarity': 'Common'},
  1026: {'rank': 8601, 'rarity': 'Common'},
  1027: {'rank': 870, 'rarity': 'Epic'},
  1028: {'rank': 3087, 'rarity': 'Uncommon'},
  1029: {'rank': 3054, 'rarity': 'Uncommon'},
  1030: {'rank': 897, 'rarity': 'Epic'},
  1031: {'rank': 8010, 'rarity': 'Common'},
  1032: {'rank': 6087, 'rarity': 'Common'},
  1033: {'rank': 3356, 'rarity': 'Uncommon'},
  1034: {'rank': 4919, 'rarity': 'Uncommon'},
  1035: {'rank': 6478, 'rarity': 'Common'},
  1036: {'rank': 5083, 'rarity': 'Common'},
  1037: {'rank': 5907, 'rarity': 'Common'},
  1038: {'rank': 833, 'rarity': 'Epic'},
  1039: {'rank': 4321, 'rarity': 'Uncommon'},
  1040: {'rank': 8970, 'rarity': 'Common'},
  1041: {'rank': 1576, 'rarity': 'Rare'},
  1042: {'rank': 7214, 'rarity': 'Common'},
  1043: {'rank': 5825, 'rarity': 'Common'},
  1044: {'rank': 2014, 'rarity': 'Rare'},
  1045: {'rank': 7043, 'rarity': 'Common'},
  1046: {'rank': 2921, 'rarity': 'Uncommon'},
  1047: {'rank': 7269, 'rarity': 'Common'},
  1048: {'rank': 9082, 'rarity': 'Common'},
  1049: {'rank': 5516, 'rarity': 'Common'},
  1050: {'rank': 7944, 'rarity': 'Common'},
  1051: {'rank': 5037, 'rarity': 'Common'},
  1052: {'rank': 37, 'rarity': 'Legendary'},
  1053: {'rank': 8880, 'rarity': 'Common'},
  1054: {'rank': 1168, 'rarity': 'Rare'},
  1055: {'rank': 3859, 'rarity': 'Uncommon'},
  1056: {'rank': 2997, 'rarity': 'Uncommon'},
  1057: {'rank': 3957, 'rarity': 'Uncommon'},
  1058: {'rank': 4435, 'rarity': 'Uncommon'},
  1059: {'rank': 3647, 'rarity': 'Uncommon'},
  1060: {'rank': 3731, 'rarity': 'Uncommon'},
  1061: {'rank': 3772, 'rarity': 'Uncommon'},
  1062: {'rank': 8245, 'rarity': 'Common'},
  1063: {'rank': 6715, 'rarity': 'Common'},
  1064: {'rank': 4906, 'rarity': 'Uncommon'},
  1065: {'rank': 681, 'rarity': 'Epic'},
  1066: {'rank': 1795, 'rarity': 'Rare'},
  1067: {'rank': 8472, 'rarity': 'Common'},
  1068: {'rank': 4738, 'rarity': 'Uncommon'},
  1069: {'rank': 6496, 'rarity': 'Common'},
  1070: {'rank': 8030, 'rarity': 'Common'},
  1071: {'rank': 3101, 'rarity': 'Uncommon'},
  1072: {'rank': 4950, 'rarity': 'Uncommon'},
  1073: {'rank': 9684, 'rarity': 'Common'},
  1074: {'rank': 8434, 'rarity': 'Common'},
  1075: {'rank': 3690, 'rarity': 'Uncommon'},
  1076: {'rank': 7703, 'rarity': 'Common'},
  1077: {'rank': 6461, 'rarity': 'Common'},
  1078: {'rank': 1702, 'rarity': 'Rare'},
  1079: {'rank': 804, 'rarity': 'Epic'},
  1080: {'rank': 3555, 'rarity': 'Uncommon'},
  1081: {'rank': 7980, 'rarity': 'Common'},
  1082: {'rank': 5168, 'rarity': 'Common'},
  1083: {'rank': 2602, 'rarity': 'Uncommon'},
  1084: {'rank': 147, 'rarity': 'Epic'},
  1085: {'rank': 1668, 'rarity': 'Rare'},
  1086: {'rank': 4210, 'rarity': 'Uncommon'},
  1087: {'rank': 7484, 'rarity': 'Common'},
  1088: {'rank': 3147, 'rarity': 'Uncommon'},
  1089: {'rank': 3681, 'rarity': 'Uncommon'},
  1090: {'rank': 4060, 'rarity': 'Uncommon'},
  1091: {'rank': 8735, 'rarity': 'Common'},
  1092: {'rank': 8904, 'rarity': 'Common'},
  1093: {'rank': 3492, 'rarity': 'Uncommon'},
  1094: {'rank': 5796, 'rarity': 'Common'},
  1095: {'rank': 6439, 'rarity': 'Common'},
  1096: {'rank': 236, 'rarity': 'Epic'},
  1097: {'rank': 1925, 'rarity': 'Rare'},
  1098: {'rank': 2378, 'rarity': 'Rare'},
  1099: {'rank': 5650, 'rarity': 'Common'},
  1100: {'rank': 4133, 'rarity': 'Uncommon'},
  1101: {'rank': 458, 'rarity': 'Epic'},
  1102: {'rank': 8126, 'rarity': 'Common'},
  1103: {'rank': 516, 'rarity': 'Epic'},
  1104: {'rank': 6985, 'rarity': 'Common'},
  1105: {'rank': 3205, 'rarity': 'Uncommon'},
  1106: {'rank': 8706, 'rarity': 'Common'},
  1107: {'rank': 913, 'rarity': 'Epic'},
  1108: {'rank': 7298, 'rarity': 'Common'},
  1109: {'rank': 6735, 'rarity': 'Common'},
  1110: {'rank': 2523, 'rarity': 'Uncommon'},
  1111: {'rank': 3115, 'rarity': 'Uncommon'},
  1112: {'rank': 7530, 'rarity': 'Common'},
  1113: {'rank': 8438, 'rarity': 'Common'},
  1114: {'rank': 2999, 'rarity': 'Uncommon'},
  1115: {'rank': 1557, 'rarity': 'Rare'},
  1116: {'rank': 6908, 'rarity': 'Common'},
  1117: {'rank': 6687, 'rarity': 'Common'},
  1118: {'rank': 2644, 'rarity': 'Uncommon'},
  1119: {'rank': 4616, 'rarity': 'Uncommon'},
  1120: {'rank': 9527, 'rarity': 'Common'},
  1121: {'rank': 5159, 'rarity': 'Common'},
  1122: {'rank': 3472, 'rarity': 'Uncommon'},
  1123: {'rank': 5568, 'rarity': 'Common'},
  1124: {'rank': 9234, 'rarity': 'Common'},
  1125: {'rank': 5972, 'rarity': 'Common'},
  1126: {'rank': 3456, 'rarity': 'Uncommon'},
  1127: {'rank': 4804, 'rarity': 'Uncommon'},
  1128: {'rank': 9572, 'rarity': 'Common'},
  1129: {'rank': 7619, 'rarity': 'Common'},
  1130: {'rank': 5087, 'rarity': 'Common'},
  1131: {'rank': 5714, 'rarity': 'Common'},
  1132: {'rank': 4233, 'rarity': 'Uncommon'},
  1133: {'rank': 5949, 'rarity': 'Common'},
  1134: {'rank': 828, 'rarity': 'Epic'},
  1135: {'rank': 6225, 'rarity': 'Common'},
  1136: {'rank': 9346, 'rarity': 'Common'},
  1137: {'rank': 6164, 'rarity': 'Common'},
  1138: {'rank': 8, 'rarity': 'Legendary'},
  1139: {'rank': 6757, 'rarity': 'Common'},
  1140: {'rank': 4585, 'rarity': 'Uncommon'},
  1141: {'rank': 6791, 'rarity': 'Common'},
  1142: {'rank': 2258, 'rarity': 'Rare'},
  1143: {'rank': 246, 'rarity': 'Epic'},
  1144: {'rank': 3667, 'rarity': 'Uncommon'},
  1145: {'rank': 6925, 'rarity': 'Common'},
  1146: {'rank': 1083, 'rarity': 'Rare'},
  1147: {'rank': 9455, 'rarity': 'Common'},
  1148: {'rank': 9199, 'rarity': 'Common'},
  1149: {'rank': 2061, 'rarity': 'Rare'},
  1150: {'rank': 1588, 'rarity': 'Rare'},
  1151: {'rank': 7445, 'rarity': 'Common'},
  1152: {'rank': 5777, 'rarity': 'Common'},
  1153: {'rank': 7571, 'rarity': 'Common'},
  1154: {'rank': 538, 'rarity': 'Epic'},
  1155: {'rank': 7273, 'rarity': 'Common'},
  1156: {'rank': 3211, 'rarity': 'Uncommon'},
  1157: {'rank': 9246, 'rarity': 'Common'},
  1158: {'rank': 5437, 'rarity': 'Common'},
  1159: {'rank': 8064, 'rarity': 'Common'},
  1160: {'rank': 7796, 'rarity': 'Common'},
  1161: {'rank': 1333, 'rarity': 'Rare'},
  1162: {'rank': 312, 'rarity': 'Epic'},
  1163: {'rank': 4591, 'rarity': 'Uncommon'},
  1164: {'rank': 32, 'rarity': 'Legendary'},
  1165: {'rank': 966, 'rarity': 'Epic'},
  1166: {'rank': 719, 'rarity': 'Epic'},
  1167: {'rank': 2567, 'rarity': 'Uncommon'},
  1168: {'rank': 3498, 'rarity': 'Uncommon'},
  1169: {'rank': 8827, 'rarity': 'Common'},
  1170: {'rank': 8806, 'rarity': 'Common'},
  1171: {'rank': 4543, 'rarity': 'Uncommon'},
  1172: {'rank': 4737, 'rarity': 'Uncommon'},
  1173: {'rank': 6747, 'rarity': 'Common'},
  1174: {'rank': 4074, 'rarity': 'Uncommon'},
  1175: {'rank': 1111, 'rarity': 'Rare'},
  1176: {'rank': 6066, 'rarity': 'Common'},
  1177: {'rank': 8446, 'rarity': 'Common'},
  1178: {'rank': 6613, 'rarity': 'Common'},
  1179: {'rank': 6938, 'rarity': 'Common'},
  1180: {'rank': 7345, 'rarity': 'Common'},
  1181: {'rank': 1460, 'rarity': 'Rare'},
  1182: {'rank': 4517, 'rarity': 'Uncommon'},
  1183: {'rank': 4966, 'rarity': 'Uncommon'},
  1184: {'rank': 8412, 'rarity': 'Common'},
  1185: {'rank': 6527, 'rarity': 'Common'},
  1186: {'rank': 9409, 'rarity': 'Common'},
  1187: {'rank': 788, 'rarity': 'Epic'},
  1188: {'rank': 8707, 'rarity': 'Common'},
  1189: {'rank': 8685, 'rarity': 'Common'},
  1190: {'rank': 9797, 'rarity': 'Common'},
  1191: {'rank': 1258, 'rarity': 'Rare'},
  1192: {'rank': 3668, 'rarity': 'Uncommon'},
  1193: {'rank': 6300, 'rarity': 'Common'},
  1194: {'rank': 3493, 'rarity': 'Uncommon'},
  1195: {'rank': 5834, 'rarity': 'Common'},
  1196: {'rank': 4188, 'rarity': 'Uncommon'},
  1197: {'rank': 445, 'rarity': 'Epic'},
  1198: {'rank': 9722, 'rarity': 'Common'},
  1199: {'rank': 9035, 'rarity': 'Common'},
  1200: {'rank': 300, 'rarity': 'Epic'},
  1201: {'rank': 9265, 'rarity': 'Common'},
  1202: {'rank': 3431, 'rarity': 'Uncommon'},
  1203: {'rank': 85, 'rarity': 'Legendary'},
  1204: {'rank': 2636, 'rarity': 'Uncommon'},
  1205: {'rank': 9364, 'rarity': 'Common'},
  1206: {'rank': 2988, 'rarity': 'Uncommon'},
  1207: {'rank': 1951, 'rarity': 'Rare'},
  1208: {'rank': 7137, 'rarity': 'Common'},
  1209: {'rank': 9597, 'rarity': 'Common'},
  1210: {'rank': 5097, 'rarity': 'Common'},
  1211: {'rank': 5868, 'rarity': 'Common'},
  1212: {'rank': 9050, 'rarity': 'Common'},
  1213: {'rank': 8663, 'rarity': 'Common'},
  1214: {'rank': 7859, 'rarity': 'Common'},
  1215: {'rank': 3255, 'rarity': 'Uncommon'},
  1216: {'rank': 1794, 'rarity': 'Rare'},
  1217: {'rank': 1079, 'rarity': 'Rare'},
  1218: {'rank': 1747, 'rarity': 'Rare'},
  1219: {'rank': 7624, 'rarity': 'Common'},
  1220: {'rank': 3174, 'rarity': 'Uncommon'},
  1221: {'rank': 8887, 'rarity': 'Common'},
  1222: {'rank': 231, 'rarity': 'Epic'},
  1223: {'rank': 432, 'rarity': 'Epic'},
  1224: {'rank': 6553, 'rarity': 'Common'},
  1225: {'rank': 6795, 'rarity': 'Common'},
  1226: {'rank': 8911, 'rarity': 'Common'},
  1227: {'rank': 8832, 'rarity': 'Common'},
  1228: {'rank': 2046, 'rarity': 'Rare'},
  1229: {'rank': 9457, 'rarity': 'Common'},
  1230: {'rank': 9373, 'rarity': 'Common'},
  1231: {'rank': 6805, 'rarity': 'Common'},
  1232: {'rank': 6392, 'rarity': 'Common'},
  1233: {'rank': 9037, 'rarity': 'Common'},
  1234: {'rank': 4272, 'rarity': 'Uncommon'},
  1235: {'rank': 9903, 'rarity': 'Common'},
  1236: {'rank': 9336, 'rarity': 'Common'},
  1237: {'rank': 9922, 'rarity': 'Common'},
  1238: {'rank': 2581, 'rarity': 'Uncommon'},
  1239: {'rank': 8441, 'rarity': 'Common'},
  1240: {'rank': 4553, 'rarity': 'Uncommon'},
  1241: {'rank': 5634, 'rarity': 'Common'},
  1242: {'rank': 834, 'rarity': 'Epic'},
  1243: {'rank': 3308, 'rarity': 'Uncommon'},
  1244: {'rank': 2335, 'rarity': 'Rare'},
  1245: {'rank': 1732, 'rarity': 'Rare'},
  1246: {'rank': 580, 'rarity': 'Epic'},
  1247: {'rank': 504, 'rarity': 'Epic'},
  1248: {'rank': 8355, 'rarity': 'Common'},
  1249: {'rank': 1346, 'rarity': 'Rare'},
  1250: {'rank': 907, 'rarity': 'Epic'},
  1251: {'rank': 6231, 'rarity': 'Common'},
  1252: {'rank': 9041, 'rarity': 'Common'},
  1253: {'rank': 2818, 'rarity': 'Uncommon'},
  1254: {'rank': 6704, 'rarity': 'Common'},
  1255: {'rank': 7589, 'rarity': 'Common'},
  1256: {'rank': 1247, 'rarity': 'Rare'},
  1257: {'rank': 9382, 'rarity': 'Common'},
  1258: {'rank': 9702, 'rarity': 'Common'},
  1259: {'rank': 223, 'rarity': 'Epic'},
  1260: {'rank': 4498, 'rarity': 'Uncommon'},
  1261: {'rank': 9198, 'rarity': 'Common'},
  1262: {'rank': 1289, 'rarity': 'Rare'},
  1263: {'rank': 1230, 'rarity': 'Rare'},
  1264: {'rank': 5642, 'rarity': 'Common'},
  1265: {'rank': 7317, 'rarity': 'Common'},
  1266: {'rank': 1078, 'rarity': 'Rare'},
  1267: {'rank': 5965, 'rarity': 'Common'},
  1268: {'rank': 6511, 'rarity': 'Common'},
  1269: {'rank': 5915, 'rarity': 'Common'},
  1270: {'rank': 9749, 'rarity': 'Common'},
  1271: {'rank': 9066, 'rarity': 'Common'},
  1272: {'rank': 1456, 'rarity': 'Rare'},
  1273: {'rank': 1892, 'rarity': 'Rare'},
  1274: {'rank': 6041, 'rarity': 'Common'},
  1275: {'rank': 2058, 'rarity': 'Rare'},
  1276: {'rank': 6539, 'rarity': 'Common'},
  1277: {'rank': 4700, 'rarity': 'Uncommon'},
  1278: {'rank': 2769, 'rarity': 'Uncommon'},
  1279: {'rank': 3294, 'rarity': 'Uncommon'},
  1280: {'rank': 9372, 'rarity': 'Common'},
  1281: {'rank': 3407, 'rarity': 'Uncommon'},
  1282: {'rank': 1362, 'rarity': 'Rare'},
  1283: {'rank': 8618, 'rarity': 'Common'},
  1284: {'rank': 3465, 'rarity': 'Uncommon'},
  1285: {'rank': 3598, 'rarity': 'Uncommon'},
  1286: {'rank': 8652, 'rarity': 'Common'},
  1287: {'rank': 4053, 'rarity': 'Uncommon'},
  1288: {'rank': 3095, 'rarity': 'Uncommon'},
  1289: {'rank': 2157, 'rarity': 'Rare'},
  1290: {'rank': 1027, 'rarity': 'Rare'},
  1291: {'rank': 2219, 'rarity': 'Rare'},
  1292: {'rank': 7811, 'rarity': 'Common'},
  1293: {'rank': 8766, 'rarity': 'Common'},
  1294: {'rank': 4318, 'rarity': 'Uncommon'},
  1295: {'rank': 9171, 'rarity': 'Common'},
  1296: {'rank': 6672, 'rarity': 'Common'},
  1297: {'rank': 5780, 'rarity': 'Common'},
  1298: {'rank': 6963, 'rarity': 'Common'},
  1299: {'rank': 9729, 'rarity': 'Common'},
  1300: {'rank': 7770, 'rarity': 'Common'},
  1301: {'rank': 5022, 'rarity': 'Common'},
  1302: {'rank': 7854, 'rarity': 'Common'},
  1303: {'rank': 6665, 'rarity': 'Common'},
  1304: {'rank': 9123, 'rarity': 'Common'},
  1305: {'rank': 9433, 'rarity': 'Common'},
  1306: {'rank': 7565, 'rarity': 'Common'},
  1307: {'rank': 5236, 'rarity': 'Common'},
  1308: {'rank': 7929, 'rarity': 'Common'},
  1309: {'rank': 8135, 'rarity': 'Common'},
  1310: {'rank': 5552, 'rarity': 'Common'},
  1311: {'rank': 4778, 'rarity': 'Uncommon'},
  1312: {'rank': 7053, 'rarity': 'Common'},
  1313: {'rank': 2705, 'rarity': 'Uncommon'},
  1314: {'rank': 6142, 'rarity': 'Common'},
  1315: {'rank': 1251, 'rarity': 'Rare'},
  1316: {'rank': 8790, 'rarity': 'Common'},
  1317: {'rank': 9531, 'rarity': 'Common'},
  1318: {'rank': 628, 'rarity': 'Epic'},
  1319: {'rank': 2143, 'rarity': 'Rare'},
  1320: {'rank': 3280, 'rarity': 'Uncommon'},
  1321: {'rank': 4256, 'rarity': 'Uncommon'},
  1322: {'rank': 1436, 'rarity': 'Rare'},
  1323: {'rank': 1525, 'rarity': 'Rare'},
  1324: {'rank': 6380, 'rarity': 'Common'},
  1325: {'rank': 7403, 'rarity': 'Common'},
  1326: {'rank': 7602, 'rarity': 'Common'},
  1327: {'rank': 5928, 'rarity': 'Common'},
  1328: {'rank': 3210, 'rarity': 'Uncommon'},
  1329: {'rank': 5727, 'rarity': 'Common'},
  1330: {'rank': 9819, 'rarity': 'Common'},
  1331: {'rank': 5708, 'rarity': 'Common'},
  1332: {'rank': 8304, 'rarity': 'Common'},
  1333: {'rank': 3910, 'rarity': 'Uncommon'},
  1334: {'rank': 3680, 'rarity': 'Uncommon'},
  1335: {'rank': 51, 'rarity': 'Legendary'},
  1336: {'rank': 2994, 'rarity': 'Uncommon'},
  1337: {'rank': 9151, 'rarity': 'Common'},
  1338: {'rank': 7383, 'rarity': 'Common'},
  1339: {'rank': 3198, 'rarity': 'Uncommon'},
  1340: {'rank': 5068, 'rarity': 'Common'},
  1341: {'rank': 6335, 'rarity': 'Common'},
  1342: {'rank': 1650, 'rarity': 'Rare'},
  1343: {'rank': 2045, 'rarity': 'Rare'},
  1344: {'rank': 9807, 'rarity': 'Common'},
  1345: {'rank': 8656, 'rarity': 'Common'},
  1346: {'rank': 2970, 'rarity': 'Uncommon'},
  1347: {'rank': 9277, 'rarity': 'Common'},
  1348: {'rank': 8739, 'rarity': 'Common'},
  1349: {'rank': 3698, 'rarity': 'Uncommon'},
  1350: {'rank': 6831, 'rarity': 'Common'},
  1351: {'rank': 132, 'rarity': 'Epic'},
  1352: {'rank': 5748, 'rarity': 'Common'},
  1353: {'rank': 9659, 'rarity': 'Common'},
  1354: {'rank': 8033, 'rarity': 'Common'},
  1355: {'rank': 4495, 'rarity': 'Uncommon'},
  1356: {'rank': 7252, 'rarity': 'Common'},
  1357: {'rank': 9943, 'rarity': 'Common'},
  1358: {'rank': 4244, 'rarity': 'Uncommon'},
  1359: {'rank': 1971, 'rarity': 'Rare'},
  1360: {'rank': 4392, 'rarity': 'Uncommon'},
  1361: {'rank': 8407, 'rarity': 'Common'},
  1362: {'rank': 1448, 'rarity': 'Rare'},
  1363: {'rank': 4667, 'rarity': 'Uncommon'},
  1364: {'rank': 8787, 'rarity': 'Common'},
  1365: {'rank': 7135, 'rarity': 'Common'},
  1366: {'rank': 7709, 'rarity': 'Common'},
  1367: {'rank': 8015, 'rarity': 'Common'},
  1368: {'rank': 1479, 'rarity': 'Rare'},
  1369: {'rank': 6559, 'rarity': 'Common'},
  1370: {'rank': 6169, 'rarity': 'Common'},
  1371: {'rank': 9430, 'rarity': 'Common'},
  1372: {'rank': 2317, 'rarity': 'Rare'},
  1373: {'rank': 758, 'rarity': 'Epic'},
  1374: {'rank': 5526, 'rarity': 'Common'},
  1375: {'rank': 8955, 'rarity': 'Common'},
  1376: {'rank': 6079, 'rarity': 'Common'},
  1377: {'rank': 810, 'rarity': 'Epic'},
  1378: {'rank': 7066, 'rarity': 'Common'},
  1379: {'rank': 2703, 'rarity': 'Uncommon'},
  1380: {'rank': 185, 'rarity': 'Epic'},
  1381: {'rank': 8745, 'rarity': 'Common'},
  1382: {'rank': 1214, 'rarity': 'Rare'},
  1383: {'rank': 360, 'rarity': 'Epic'},
  1384: {'rank': 5281, 'rarity': 'Common'},
  1385: {'rank': 2791, 'rarity': 'Uncommon'},
  1386: {'rank': 8877, 'rarity': 'Common'},
  1387: {'rank': 6858, 'rarity': 'Common'},
  1388: {'rank': 5749, 'rarity': 'Common'},
  1389: {'rank': 5859, 'rarity': 'Common'},
  1390: {'rank': 3703, 'rarity': 'Uncommon'},
  1391: {'rank': 4659, 'rarity': 'Uncommon'},
  1392: {'rank': 5654, 'rarity': 'Common'},
  1393: {'rank': 2097, 'rarity': 'Rare'},
  1394: {'rank': 3247, 'rarity': 'Uncommon'},
  1395: {'rank': 139, 'rarity': 'Epic'},
  1396: {'rank': 9970, 'rarity': 'Common'},
  1397: {'rank': 121, 'rarity': 'Epic'},
  1398: {'rank': 4819, 'rarity': 'Uncommon'},
  1399: {'rank': 7346, 'rarity': 'Common'},
  1400: {'rank': 52, 'rarity': 'Legendary'},
  1401: {'rank': 5781, 'rarity': 'Common'},
  1402: {'rank': 56, 'rarity': 'Legendary'},
  1403: {'rank': 2950, 'rarity': 'Uncommon'},
  1404: {'rank': 7329, 'rarity': 'Common'},
  1405: {'rank': 1354, 'rarity': 'Rare'},
  1406: {'rank': 941, 'rarity': 'Epic'},
  1407: {'rank': 5195, 'rarity': 'Common'},
  1408: {'rank': 6473, 'rarity': 'Common'},
  1409: {'rank': 2283, 'rarity': 'Rare'},
  1410: {'rank': 2909, 'rarity': 'Uncommon'},
  1411: {'rank': 4873, 'rarity': 'Uncommon'},
  1412: {'rank': 1765, 'rarity': 'Rare'},
  1413: {'rank': 2150, 'rarity': 'Rare'},
  1414: {'rank': 1982, 'rarity': 'Rare'},
  1415: {'rank': 937, 'rarity': 'Epic'},
  1416: {'rank': 486, 'rarity': 'Epic'},
  1417: {'rank': 2680, 'rarity': 'Uncommon'},
  1418: {'rank': 7021, 'rarity': 'Common'},
  1419: {'rank': 1687, 'rarity': 'Rare'},
  1420: {'rank': 9832, 'rarity': 'Common'},
  1421: {'rank': 8691, 'rarity': 'Common'},
  1422: {'rank': 6924, 'rarity': 'Common'},
  1423: {'rank': 9288, 'rarity': 'Common'},
  1424: {'rank': 520, 'rarity': 'Epic'},
  1425: {'rank': 4492, 'rarity': 'Uncommon'},
  1426: {'rank': 1017, 'rarity': 'Rare'},
  1427: {'rank': 4446, 'rarity': 'Uncommon'},
  1428: {'rank': 9813, 'rarity': 'Common'},
  1429: {'rank': 838, 'rarity': 'Epic'},
  1430: {'rank': 3070, 'rarity': 'Uncommon'},
  1431: {'rank': 266, 'rarity': 'Epic'},
  1432: {'rank': 3468, 'rarity': 'Uncommon'},
  1433: {'rank': 8405, 'rarity': 'Common'},
  1434: {'rank': 1815, 'rarity': 'Rare'},
  1435: {'rank': 5971, 'rarity': 'Common'},
  1436: {'rank': 9973, 'rarity': 'Common'},
  1437: {'rank': 9547, 'rarity': 'Common'},
  1438: {'rank': 8756, 'rarity': 'Common'},
  1439: {'rank': 1028, 'rarity': 'Rare'},
  1440: {'rank': 1324, 'rarity': 'Rare'},
  1441: {'rank': 1441, 'rarity': 'Rare'},
  1442: {'rank': 3013, 'rarity': 'Uncommon'},
  1443: {'rank': 8913, 'rarity': 'Common'},
  1444: {'rank': 2956, 'rarity': 'Uncommon'},
  1445: {'rank': 7194, 'rarity': 'Common'},
  1446: {'rank': 3783, 'rarity': 'Uncommon'},
  1447: {'rank': 6674, 'rarity': 'Common'},
  1448: {'rank': 6919, 'rarity': 'Common'},
  1449: {'rank': 7490, 'rarity': 'Common'},
  1450: {'rank': 757, 'rarity': 'Epic'},
  1451: {'rank': 8615, 'rarity': 'Common'},
  1452: {'rank': 7250, 'rarity': 'Common'},
  1453: {'rank': 661, 'rarity': 'Epic'},
  1454: {'rank': 1906, 'rarity': 'Rare'},
  1455: {'rank': 5395, 'rarity': 'Common'},
  1456: {'rank': 2719, 'rarity': 'Uncommon'},
  1457: {'rank': 8842, 'rarity': 'Common'},
  1458: {'rank': 2264, 'rarity': 'Rare'},
  1459: {'rank': 4674, 'rarity': 'Uncommon'},
  1460: {'rank': 7638, 'rarity': 'Common'},
  1461: {'rank': 3682, 'rarity': 'Uncommon'},
  1462: {'rank': 2392, 'rarity': 'Rare'},
  1463: {'rank': 3708, 'rarity': 'Uncommon'},
  1464: {'rank': 8517, 'rarity': 'Common'},
  1465: {'rank': 5802, 'rarity': 'Common'},
  1466: {'rank': 7092, 'rarity': 'Common'},
  1467: {'rank': 548, 'rarity': 'Epic'},
  1468: {'rank': 3408, 'rarity': 'Uncommon'},
  1469: {'rank': 7368, 'rarity': 'Common'},
  1470: {'rank': 4497, 'rarity': 'Uncommon'},
  1471: {'rank': 1524, 'rarity': 'Rare'},
  1472: {'rank': 797, 'rarity': 'Epic'},
  1473: {'rank': 4454, 'rarity': 'Uncommon'},
  1474: {'rank': 5934, 'rarity': 'Common'},
  1475: {'rank': 9690, 'rarity': 'Common'},
  1476: {'rank': 9105, 'rarity': 'Common'},
  1477: {'rank': 1703, 'rarity': 'Rare'},
  1478: {'rank': 7757, 'rarity': 'Common'},
  1479: {'rank': 9549, 'rarity': 'Common'},
  1480: {'rank': 2134, 'rarity': 'Rare'},
  1481: {'rank': 7590, 'rarity': 'Common'},
  1482: {'rank': 2721, 'rarity': 'Uncommon'},
  1483: {'rank': 6690, 'rarity': 'Common'},
  1484: {'rank': 4163, 'rarity': 'Uncommon'},
  1485: {'rank': 4878, 'rarity': 'Uncommon'},
  1486: {'rank': 3983, 'rarity': 'Uncommon'},
  1487: {'rank': 4264, 'rarity': 'Uncommon'},
  1488: {'rank': 3426, 'rarity': 'Uncommon'},
  1489: {'rank': 4241, 'rarity': 'Uncommon'},
  1490: {'rank': 6955, 'rarity': 'Common'},
  1491: {'rank': 1902, 'rarity': 'Rare'},
  1492: {'rank': 5970, 'rarity': 'Common'},
  1493: {'rank': 5012, 'rarity': 'Common'},
  1494: {'rank': 3691, 'rarity': 'Uncommon'},
  1495: {'rank': 8477, 'rarity': 'Common'},
  1496: {'rank': 8972, 'rarity': 'Common'},
  1497: {'rank': 5715, 'rarity': 'Common'},
  1498: {'rank': 939, 'rarity': 'Epic'},
  1499: {'rank': 1355, 'rarity': 'Rare'},
  1500: {'rank': 1231, 'rarity': 'Rare'},
  1501: {'rank': 8313, 'rarity': 'Common'},
  1502: {'rank': 7491, 'rarity': 'Common'},
  1503: {'rank': 8678, 'rarity': 'Common'},
  1504: {'rank': 7603, 'rarity': 'Common'},
  1505: {'rank': 6529, 'rarity': 'Common'},
  1506: {'rank': 7860, 'rarity': 'Common'},
  1507: {'rank': 8538, 'rarity': 'Common'},
  1508: {'rank': 6567, 'rarity': 'Common'},
  1509: {'rank': 6276, 'rarity': 'Common'},
  1510: {'rank': 9868, 'rarity': 'Common'},
  1511: {'rank': 7729, 'rarity': 'Common'},
  1512: {'rank': 5004, 'rarity': 'Common'},
  1513: {'rank': 3151, 'rarity': 'Uncommon'},
  1514: {'rank': 2347, 'rarity': 'Rare'},
  1515: {'rank': 9377, 'rarity': 'Common'},
  1516: {'rank': 1921, 'rarity': 'Rare'},
  1517: {'rank': 3925, 'rarity': 'Uncommon'},
  1518: {'rank': 674, 'rarity': 'Epic'},
  1519: {'rank': 4853, 'rarity': 'Uncommon'},
  1520: {'rank': 3352, 'rarity': 'Uncommon'},
  1521: {'rank': 1943, 'rarity': 'Rare'},
  1522: {'rank': 818, 'rarity': 'Epic'},
  1523: {'rank': 5258, 'rarity': 'Common'},
  1524: {'rank': 3905, 'rarity': 'Uncommon'},
  1525: {'rank': 7096, 'rarity': 'Common'},
  1526: {'rank': 8960, 'rarity': 'Common'},
  1527: {'rank': 7360, 'rarity': 'Common'},
  1528: {'rank': 9352, 'rarity': 'Common'},
  1529: {'rank': 3995, 'rarity': 'Uncommon'},
  1530: {'rank': 1332, 'rarity': 'Rare'},
  1531: {'rank': 1132, 'rarity': 'Rare'},
  1532: {'rank': 153, 'rarity': 'Epic'},
  1533: {'rank': 4011, 'rarity': 'Uncommon'},
  1534: {'rank': 2115, 'rarity': 'Rare'},
  1535: {'rank': 6677, 'rarity': 'Common'},
  1536: {'rank': 1594, 'rarity': 'Rare'},
  1537: {'rank': 5385, 'rarity': 'Common'},
  1538: {'rank': 8000, 'rarity': 'Common'},
  1539: {'rank': 1003, 'rarity': 'Rare'},
  1540: {'rank': 1491, 'rarity': 'Rare'},
  1541: {'rank': 7472, 'rarity': 'Common'},
  1542: {'rank': 2588, 'rarity': 'Uncommon'},
  1543: {'rank': 9638, 'rarity': 'Common'},
  1544: {'rank': 3197, 'rarity': 'Uncommon'},
  1545: {'rank': 9401, 'rarity': 'Common'},
  1546: {'rank': 8228, 'rarity': 'Common'},
  1547: {'rank': 798, 'rarity': 'Epic'},
  1548: {'rank': 263, 'rarity': 'Epic'},
  1549: {'rank': 5649, 'rarity': 'Common'},
  1550: {'rank': 5776, 'rarity': 'Common'},
  1551: {'rank': 6885, 'rarity': 'Common'},
  1552: {'rank': 9127, 'rarity': 'Common'},
  1553: {'rank': 2322, 'rarity': 'Rare'},
  1554: {'rank': 8558, 'rarity': 'Common'},
  1555: {'rank': 9826, 'rarity': 'Common'},
  1556: {'rank': 2277, 'rarity': 'Rare'},
  1557: {'rank': 1571, 'rarity': 'Rare'},
  1558: {'rank': 1496, 'rarity': 'Rare'},
  1559: {'rank': 7938, 'rarity': 'Common'},
  1560: {'rank': 189, 'rarity': 'Epic'},
  1561: {'rank': 6379, 'rarity': 'Common'},
  1562: {'rank': 7400, 'rarity': 'Common'},
  1563: {'rank': 3485, 'rarity': 'Uncommon'},
  1564: {'rank': 173, 'rarity': 'Epic'},
  1565: {'rank': 5276, 'rarity': 'Common'},
  1566: {'rank': 7656, 'rarity': 'Common'},
  1567: {'rank': 4526, 'rarity': 'Uncommon'},
  1568: {'rank': 4033, 'rarity': 'Uncommon'},
  1569: {'rank': 3806, 'rarity': 'Uncommon'},
  1570: {'rank': 4338, 'rarity': 'Uncommon'},
  1571: {'rank': 1011, 'rarity': 'Rare'},
  1572: {'rank': 5065, 'rarity': 'Common'},
  1573: {'rank': 5365, 'rarity': 'Common'},
  1574: {'rank': 1487, 'rarity': 'Rare'},
  1575: {'rank': 426, 'rarity': 'Epic'},
  1576: {'rank': 7283, 'rarity': 'Common'},
  1577: {'rank': 4877, 'rarity': 'Uncommon'},
  1578: {'rank': 1154, 'rarity': 'Rare'},
  1579: {'rank': 9356, 'rarity': 'Common'},
  1580: {'rank': 1614, 'rarity': 'Rare'},
  1581: {'rank': 5720, 'rarity': 'Common'},
  1582: {'rank': 7282, 'rarity': 'Common'},
  1583: {'rank': 6973, 'rarity': 'Common'},
  1584: {'rank': 7373, 'rarity': 'Common'},
  1585: {'rank': 5126, 'rarity': 'Common'},
  1586: {'rank': 8881, 'rarity': 'Common'},
  1587: {'rank': 1307, 'rarity': 'Rare'},
  1588: {'rank': 6136, 'rarity': 'Common'},
  1589: {'rank': 9412, 'rarity': 'Common'},
  1590: {'rank': 6280, 'rarity': 'Common'},
  1591: {'rank': 8139, 'rarity': 'Common'},
  1592: {'rank': 1812, 'rarity': 'Rare'},
  1593: {'rank': 6156, 'rarity': 'Common'},
  1594: {'rank': 3548, 'rarity': 'Uncommon'},
  1595: {'rank': 4661, 'rarity': 'Uncommon'},
  1596: {'rank': 2463, 'rarity': 'Rare'},
  1597: {'rank': 5313, 'rarity': 'Common'},
  1598: {'rank': 3941, 'rarity': 'Uncommon'},
  1599: {'rank': 7041, 'rarity': 'Common'},
  1600: {'rank': 1478, 'rarity': 'Rare'},
  1601: {'rank': 4981, 'rarity': 'Uncommon'},
  1602: {'rank': 3765, 'rarity': 'Uncommon'},
  1603: {'rank': 8702, 'rarity': 'Common'},
  1604: {'rank': 5571, 'rarity': 'Common'},
  1605: {'rank': 6545, 'rarity': 'Common'},
  1606: {'rank': 8950, 'rarity': 'Common'},
  1607: {'rank': 249, 'rarity': 'Epic'},
  1608: {'rank': 1729, 'rarity': 'Rare'},
  1609: {'rank': 6518, 'rarity': 'Common'},
  1610: {'rank': 4249, 'rarity': 'Uncommon'},
  1611: {'rank': 6028, 'rarity': 'Common'},
  1612: {'rank': 727, 'rarity': 'Epic'},
  1613: {'rank': 7665, 'rarity': 'Common'},
  1614: {'rank': 3427, 'rarity': 'Uncommon'},
  1615: {'rank': 7497, 'rarity': 'Common'},
  1616: {'rank': 5707, 'rarity': 'Common'},
  1617: {'rank': 7764, 'rarity': 'Common'},
  1618: {'rank': 4248, 'rarity': 'Uncommon'},
  1619: {'rank': 4199, 'rarity': 'Uncommon'},
  1620: {'rank': 9389, 'rarity': 'Common'},
  1621: {'rank': 8023, 'rarity': 'Common'},
  1622: {'rank': 8588, 'rarity': 'Common'},
  1623: {'rank': 620, 'rarity': 'Epic'},
  1624: {'rank': 5879, 'rarity': 'Common'},
  1625: {'rank': 8864, 'rarity': 'Common'},
  1626: {'rank': 475, 'rarity': 'Epic'},
  1627: {'rank': 697, 'rarity': 'Epic'},
  1628: {'rank': 2263, 'rarity': 'Rare'},
  1629: {'rank': 5522, 'rarity': 'Common'},
  1630: {'rank': 2938, 'rarity': 'Uncommon'},
  1631: {'rank': 2359, 'rarity': 'Rare'},
  1632: {'rank': 5432, 'rarity': 'Common'},
  1633: {'rank': 1429, 'rarity': 'Rare'},
  1634: {'rank': 9881, 'rarity': 'Common'},
  1635: {'rank': 4197, 'rarity': 'Uncommon'},
  1636: {'rank': 3379, 'rarity': 'Uncommon'},
  1637: {'rank': 627, 'rarity': 'Epic'},
  1638: {'rank': 7112, 'rarity': 'Common'},
  1639: {'rank': 2406, 'rarity': 'Rare'},
  1640: {'rank': 3538, 'rarity': 'Uncommon'},
  1641: {'rank': 652, 'rarity': 'Epic'},
  1642: {'rank': 9731, 'rarity': 'Common'},
  1643: {'rank': 943, 'rarity': 'Epic'},
  1644: {'rank': 6190, 'rarity': 'Common'},
  1645: {'rank': 2595, 'rarity': 'Uncommon'},
  1646: {'rank': 6096, 'rarity': 'Common'},
  1647: {'rank': 631, 'rarity': 'Epic'},
  1648: {'rank': 4153, 'rarity': 'Uncommon'},
  1649: {'rank': 2511, 'rarity': 'Uncommon'},
  1650: {'rank': 8372, 'rarity': 'Common'},
  1651: {'rank': 7101, 'rarity': 'Common'},
  1652: {'rank': 9480, 'rarity': 'Common'},
  1653: {'rank': 3319, 'rarity': 'Uncommon'},
  1654: {'rank': 92, 'rarity': 'Legendary'},
  1655: {'rank': 7529, 'rarity': 'Common'},
  1656: {'rank': 9436, 'rarity': 'Common'},
  1657: {'rank': 3209, 'rarity': 'Uncommon'},
  1658: {'rank': 9315, 'rarity': 'Common'},
  1659: {'rank': 2109, 'rarity': 'Rare'},
  1660: {'rank': 9326, 'rarity': 'Common'},
  1661: {'rank': 2642, 'rarity': 'Uncommon'},
  1662: {'rank': 938, 'rarity': 'Epic'},
  1663: {'rank': 5501, 'rarity': 'Common'},
  1664: {'rank': 8342, 'rarity': 'Common'},
  1665: {'rank': 9229, 'rarity': 'Common'},
  1666: {'rank': 7267, 'rarity': 'Common'},
  1667: {'rank': 9194, 'rarity': 'Common'},
  1668: {'rank': 2737, 'rarity': 'Uncommon'},
  1669: {'rank': 7279, 'rarity': 'Common'},
  1670: {'rank': 2597, 'rarity': 'Uncommon'},
  1671: {'rank': 9794, 'rarity': 'Common'},
  1672: {'rank': 5144, 'rarity': 'Common'},
  1673: {'rank': 8825, 'rarity': 'Common'},
  1674: {'rank': 3627, 'rarity': 'Uncommon'},
  1675: {'rank': 471, 'rarity': 'Epic'},
  1676: {'rank': 8989, 'rarity': 'Common'},
  1677: {'rank': 6993, 'rarity': 'Common'},
  1678: {'rank': 6772, 'rarity': 'Common'},
  1679: {'rank': 2550, 'rarity': 'Uncommon'},
  1680: {'rank': 4678, 'rarity': 'Uncommon'},
  1681: {'rank': 5823, 'rarity': 'Common'},
  1682: {'rank': 180, 'rarity': 'Epic'},
  1683: {'rank': 2366, 'rarity': 'Rare'},
  1684: {'rank': 8416, 'rarity': 'Common'},
  1685: {'rank': 9132, 'rarity': 'Common'},
  1686: {'rank': 1874, 'rarity': 'Rare'},
  1687: {'rank': 858, 'rarity': 'Epic'},
  1688: {'rank': 4364, 'rarity': 'Uncommon'},
  1689: {'rank': 8946, 'rarity': 'Common'},
  1690: {'rank': 3445, 'rarity': 'Uncommon'},
  1691: {'rank': 3890, 'rarity': 'Uncommon'},
  1692: {'rank': 1473, 'rarity': 'Rare'},
  1693: {'rank': 1991, 'rarity': 'Rare'},
  1694: {'rank': 7249, 'rarity': 'Common'},
  1695: {'rank': 6070, 'rarity': 'Common'},
  1696: {'rank': 4889, 'rarity': 'Uncommon'},
  1697: {'rank': 3003, 'rarity': 'Uncommon'},
  1698: {'rank': 9999, 'rarity': 'Common'},
  1699: {'rank': 8429, 'rarity': 'Common'},
  1700: {'rank': 6017, 'rarity': 'Common'},
  1701: {'rank': 3581, 'rarity': 'Uncommon'},
  1702: {'rank': 5392, 'rarity': 'Common'},
  1703: {'rank': 4898, 'rarity': 'Uncommon'},
  1704: {'rank': 4024, 'rarity': 'Uncommon'},
  1705: {'rank': 6452, 'rarity': 'Common'},
  1706: {'rank': 5252, 'rarity': 'Common'},
  1707: {'rank': 398, 'rarity': 'Epic'},
  1708: {'rank': 4500, 'rarity': 'Uncommon'},
  1709: {'rank': 9383, 'rarity': 'Common'},
  1710: {'rank': 4212, 'rarity': 'Uncommon'},
  1711: {'rank': 7674, 'rarity': 'Common'},
  1712: {'rank': 6601, 'rarity': 'Common'},
  1713: {'rank': 2042, 'rarity': 'Rare'},
  1714: {'rank': 2585, 'rarity': 'Uncommon'},
  1715: {'rank': 6998, 'rarity': 'Common'},
  1716: {'rank': 473, 'rarity': 'Epic'},
  1717: {'rank': 4712, 'rarity': 'Uncommon'},
  1718: {'rank': 5692, 'rarity': 'Common'},
  1719: {'rank': 3642, 'rarity': 'Uncommon'},
  1720: {'rank': 4704, 'rarity': 'Uncommon'},
  1721: {'rank': 2293, 'rarity': 'Rare'},
  1722: {'rank': 1554, 'rarity': 'Rare'},
  1723: {'rank': 5737, 'rarity': 'Common'},
  1724: {'rank': 2147, 'rarity': 'Rare'},
  1725: {'rank': 8498, 'rarity': 'Common'},
  1726: {'rank': 1582, 'rarity': 'Rare'},
  1727: {'rank': 4808, 'rarity': 'Uncommon'},
  1728: {'rank': 3385, 'rarity': 'Uncommon'},
  1729: {'rank': 1391, 'rarity': 'Rare'},
  1730: {'rank': 964, 'rarity': 'Epic'},
  1731: {'rank': 2306, 'rarity': 'Rare'},
  1732: {'rank': 9254, 'rarity': 'Common'},
  1733: {'rank': 9057, 'rarity': 'Common'},
  1734: {'rank': 8757, 'rarity': 'Common'},
  1735: {'rank': 5438, 'rarity': 'Common'},
  1736: {'rank': 2280, 'rarity': 'Rare'},
  1737: {'rank': 6197, 'rarity': 'Common'},
  1738: {'rank': 1048, 'rarity': 'Rare'},
  1739: {'rank': 9699, 'rarity': 'Common'},
  1740: {'rank': 1235, 'rarity': 'Rare'},
  1741: {'rank': 7286, 'rarity': 'Common'},
  1742: {'rank': 5983, 'rarity': 'Common'},
  1743: {'rank': 8981, 'rarity': 'Common'},
  1744: {'rank': 2673, 'rarity': 'Uncommon'},
  1745: {'rank': 2130, 'rarity': 'Rare'},
  1746: {'rank': 3584, 'rarity': 'Uncommon'},
  1747: {'rank': 7576, 'rarity': 'Common'},
  1748: {'rank': 363, 'rarity': 'Epic'},
  1749: {'rank': 8754, 'rarity': 'Common'},
  1750: {'rank': 1898, 'rarity': 'Rare'},
  1751: {'rank': 1613, 'rarity': 'Rare'},
  1752: {'rank': 4464, 'rarity': 'Uncommon'},
  1753: {'rank': 2489, 'rarity': 'Rare'},
  1754: {'rank': 9542, 'rarity': 'Common'},
  1755: {'rank': 2013, 'rarity': 'Rare'},
  1756: {'rank': 2224, 'rarity': 'Rare'},
  1757: {'rank': 6329, 'rarity': 'Common'},
  1758: {'rank': 2640, 'rarity': 'Uncommon'},
  1759: {'rank': 343, 'rarity': 'Epic'},
  1760: {'rank': 3710, 'rarity': 'Uncommon'},
  1761: {'rank': 8364, 'rarity': 'Common'},
  1762: {'rank': 5840, 'rarity': 'Common'},
  1763: {'rank': 3797, 'rarity': 'Uncommon'},
  1764: {'rank': 9103, 'rarity': 'Common'},
  1765: {'rank': 1841, 'rarity': 'Rare'},
  1766: {'rank': 4023, 'rarity': 'Uncommon'},
  1767: {'rank': 2362, 'rarity': 'Rare'},
  1768: {'rank': 6155, 'rarity': 'Common'},
  1769: {'rank': 4711, 'rarity': 'Uncommon'},
  1770: {'rank': 4258, 'rarity': 'Uncommon'},
  1771: {'rank': 3558, 'rarity': 'Uncommon'},
  1772: {'rank': 1627, 'rarity': 'Rare'},
  1773: {'rank': 6639, 'rarity': 'Common'},
  1774: {'rank': 3168, 'rarity': 'Uncommon'},
  1775: {'rank': 2761, 'rarity': 'Uncommon'},
  1776: {'rank': 2310, 'rarity': 'Rare'},
  1777: {'rank': 7678, 'rarity': 'Common'},
  1778: {'rank': 3320, 'rarity': 'Uncommon'},
  1779: {'rank': 3245, 'rarity': 'Uncommon'},
  1780: {'rank': 3258, 'rarity': 'Uncommon'},
  1781: {'rank': 2684, 'rarity': 'Uncommon'},
  1782: {'rank': 6090, 'rarity': 'Common'},
  1783: {'rank': 5864, 'rarity': 'Common'},
  1784: {'rank': 5984, 'rarity': 'Common'},
  1785: {'rank': 2907, 'rarity': 'Uncommon'},
  1786: {'rank': 2509, 'rarity': 'Uncommon'},
  1787: {'rank': 6902, 'rarity': 'Common'},
  1788: {'rank': 9914, 'rarity': 'Common'},
  1789: {'rank': 4179, 'rarity': 'Uncommon'},
  1790: {'rank': 8595, 'rarity': 'Common'},
  1791: {'rank': 4719, 'rarity': 'Uncommon'},
  1792: {'rank': 2295, 'rarity': 'Rare'},
  1793: {'rank': 1686, 'rarity': 'Rare'},
  1794: {'rank': 2024, 'rarity': 'Rare'},
  1795: {'rank': 2995, 'rarity': 'Uncommon'},
  1796: {'rank': 1113, 'rarity': 'Rare'},
  1797: {'rank': 7692, 'rarity': 'Common'},
  1798: {'rank': 416, 'rarity': 'Epic'},
  1799: {'rank': 3230, 'rarity': 'Uncommon'},
  1800: {'rank': 4393, 'rarity': 'Uncommon'},
  1801: {'rank': 6637, 'rarity': 'Common'},
  1802: {'rank': 292, 'rarity': 'Epic'},
  1803: {'rank': 5314, 'rarity': 'Common'},
  1804: {'rank': 9086, 'rarity': 'Common'},
  1805: {'rank': 6363, 'rarity': 'Common'},
  1806: {'rank': 4910, 'rarity': 'Uncommon'},
  1807: {'rank': 1408, 'rarity': 'Rare'},
  1808: {'rank': 7700, 'rarity': 'Common'},
  1809: {'rank': 2431, 'rarity': 'Rare'},
  1810: {'rank': 4657, 'rarity': 'Uncommon'},
  1811: {'rank': 202, 'rarity': 'Epic'},
  1812: {'rank': 5894, 'rarity': 'Common'},
  1813: {'rank': 5456, 'rarity': 'Common'},
  1814: {'rank': 6084, 'rarity': 'Common'},
  1815: {'rank': 6517, 'rarity': 'Common'},
  1816: {'rank': 6650, 'rarity': 'Common'},
  1817: {'rank': 965, 'rarity': 'Epic'},
  1818: {'rank': 9407, 'rarity': 'Common'},
  1819: {'rank': 1918, 'rarity': 'Rare'},
  1820: {'rank': 3891, 'rarity': 'Uncommon'},
  1821: {'rank': 8567, 'rarity': 'Common'},
  1822: {'rank': 1094, 'rarity': 'Rare'},
  1823: {'rank': 1242, 'rarity': 'Rare'},
  1824: {'rank': 1070, 'rarity': 'Rare'},
  1825: {'rank': 3221, 'rarity': 'Uncommon'},
  1826: {'rank': 6137, 'rarity': 'Common'},
  1827: {'rank': 6501, 'rarity': 'Common'},
  1828: {'rank': 656, 'rarity': 'Epic'},
  1829: {'rank': 570, 'rarity': 'Epic'},
  1830: {'rank': 5455, 'rarity': 'Common'},
  1831: {'rank': 5343, 'rarity': 'Common'},
  1832: {'rank': 4787, 'rarity': 'Uncommon'},
  1833: {'rank': 2980, 'rarity': 'Uncommon'},
  1834: {'rank': 3378, 'rarity': 'Uncommon'},
  1835: {'rank': 4009, 'rarity': 'Uncommon'},
  1836: {'rank': 724, 'rarity': 'Epic'},
  1837: {'rank': 8769, 'rarity': 'Common'},
  1838: {'rank': 9477, 'rarity': 'Common'},
  1839: {'rank': 8592, 'rarity': 'Common'},
  1840: {'rank': 533, 'rarity': 'Epic'},
  1841: {'rank': 4437, 'rarity': 'Uncommon'},
  1842: {'rank': 3842, 'rarity': 'Uncommon'},
  1843: {'rank': 6571, 'rarity': 'Common'},
  1844: {'rank': 2430, 'rarity': 'Rare'},
  1845: {'rank': 5260, 'rarity': 'Common'},
  1846: {'rank': 2065, 'rarity': 'Rare'},
  1847: {'rank': 747, 'rarity': 'Epic'},
  1848: {'rank': 9099, 'rarity': 'Common'},
  1849: {'rank': 3928, 'rarity': 'Uncommon'},
  1850: {'rank': 8835, 'rarity': 'Common'},
  1851: {'rank': 6154, 'rarity': 'Common'},
  1852: {'rank': 4369, 'rarity': 'Uncommon'},
  1853: {'rank': 8670, 'rarity': 'Common'},
  1854: {'rank': 734, 'rarity': 'Epic'},
  1855: {'rank': 7789, 'rarity': 'Common'},
  1856: {'rank': 908, 'rarity': 'Epic'},
  1857: {'rank': 4559, 'rarity': 'Uncommon'},
  1858: {'rank': 1796, 'rarity': 'Rare'},
  1859: {'rank': 1587, 'rarity': 'Rare'},
  1860: {'rank': 7926, 'rarity': 'Common'},
  1861: {'rank': 8544, 'rarity': 'Common'},
  1862: {'rank': 5710, 'rarity': 'Common'},
  1863: {'rank': 8456, 'rarity': 'Common'},
  1864: {'rank': 5747, 'rarity': 'Common'},
  1865: {'rank': 8836, 'rarity': 'Common'},
  1866: {'rank': 560, 'rarity': 'Epic'},
  1867: {'rank': 1536, 'rarity': 'Rare'},
  1868: {'rank': 3676, 'rarity': 'Uncommon'},
  1869: {'rank': 5783, 'rarity': 'Common'},
  1870: {'rank': 9608, 'rarity': 'Common'},
  1871: {'rank': 1378, 'rarity': 'Rare'},
  1872: {'rank': 745, 'rarity': 'Epic'},
  1873: {'rank': 3430, 'rarity': 'Uncommon'},
  1874: {'rank': 5744, 'rarity': 'Common'},
  1875: {'rank': 278, 'rarity': 'Epic'},
  1876: {'rank': 8763, 'rarity': 'Common'},
  1877: {'rank': 111, 'rarity': 'Epic'},
  1878: {'rank': 6783, 'rarity': 'Common'},
  1879: {'rank': 8621, 'rarity': 'Common'},
  1880: {'rank': 6008, 'rarity': 'Common'},
  1881: {'rank': 5583, 'rarity': 'Common'},
  1882: {'rank': 6093, 'rarity': 'Common'},
  1883: {'rank': 4273, 'rarity': 'Uncommon'},
  1884: {'rank': 8664, 'rarity': 'Common'},
  1885: {'rank': 1257, 'rarity': 'Rare'},
  1886: {'rank': 4560, 'rarity': 'Uncommon'},
  1887: {'rank': 5211, 'rarity': 'Common'},
  1888: {'rank': 2189, 'rarity': 'Rare'},
  1889: {'rank': 544, 'rarity': 'Epic'},
  1890: {'rank': 3266, 'rarity': 'Uncommon'},
  1891: {'rank': 4681, 'rarity': 'Uncommon'},
  1892: {'rank': 6411, 'rarity': 'Common'},
  1893: {'rank': 7200, 'rarity': 'Common'},
  1894: {'rank': 7080, 'rarity': 'Common'},
  1895: {'rank': 5874, 'rarity': 'Common'},
  1896: {'rank': 4519, 'rarity': 'Uncommon'},
  1897: {'rank': 7554, 'rarity': 'Common'},
  1898: {'rank': 768, 'rarity': 'Epic'},
  1899: {'rank': 1035, 'rarity': 'Rare'},
  1900: {'rank': 3109, 'rarity': 'Uncommon'},
  1901: {'rank': 9000, 'rarity': 'Common'},
  1902: {'rank': 462, 'rarity': 'Epic'},
  1903: {'rank': 8731, 'rarity': 'Common'},
  1904: {'rank': 4445, 'rarity': 'Uncommon'},
  1905: {'rank': 6810, 'rarity': 'Common'},
  1906: {'rank': 6421, 'rarity': 'Common'},
  1907: {'rank': 7901, 'rarity': 'Common'},
  1908: {'rank': 4037, 'rarity': 'Uncommon'},
  1909: {'rank': 4397, 'rarity': 'Uncommon'},
  1910: {'rank': 4049, 'rarity': 'Uncommon'},
  1911: {'rank': 460, 'rarity': 'Epic'},
  1912: {'rank': 507, 'rarity': 'Epic'},
  1913: {'rank': 5327, 'rarity': 'Common'},
  1914: {'rank': 3224, 'rarity': 'Uncommon'},
  1915: {'rank': 3321, 'rarity': 'Uncommon'},
  1916: {'rank': 6900, 'rarity': 'Common'},
  1917: {'rank': 1551, 'rarity': 'Rare'},
  1918: {'rank': 8213, 'rarity': 'Common'},
  1919: {'rank': 313, 'rarity': 'Epic'},
  1920: {'rank': 8503, 'rarity': 'Common'},
  1921: {'rank': 1489, 'rarity': 'Rare'},
  1922: {'rank': 1129, 'rarity': 'Rare'},
  1923: {'rank': 8510, 'rarity': 'Common'},
  1924: {'rank': 1026, 'rarity': 'Rare'},
  1925: {'rank': 9581, 'rarity': 'Common'},
  1926: {'rank': 5529, 'rarity': 'Common'},
  1927: {'rank': 4589, 'rarity': 'Uncommon'},
  1928: {'rank': 6479, 'rarity': 'Common'},
  1929: {'rank': 1341, 'rarity': 'Rare'},
  1930: {'rank': 2796, 'rarity': 'Uncommon'},
  1931: {'rank': 8788, 'rarity': 'Common'},
  1932: {'rank': 2447, 'rarity': 'Rare'},
  1933: {'rank': 8009, 'rarity': 'Common'},
  1934: {'rank': 3515, 'rarity': 'Uncommon'},
  1935: {'rank': 1662, 'rarity': 'Rare'},
  1936: {'rank': 9605, 'rarity': 'Common'},
  1937: {'rank': 5670, 'rarity': 'Common'},
  1938: {'rank': 1317, 'rarity': 'Rare'},
  1939: {'rank': 4152, 'rarity': 'Uncommon'},
  1940: {'rank': 4760, 'rarity': 'Uncommon'},
  1941: {'rank': 7180, 'rarity': 'Common'},
  1942: {'rank': 7961, 'rarity': 'Common'},
  1943: {'rank': 3931, 'rarity': 'Uncommon'},
  1944: {'rank': 6520, 'rarity': 'Common'},
  1945: {'rank': 1041, 'rarity': 'Rare'},
  1946: {'rank': 8395, 'rarity': 'Common'},
  1947: {'rank': 4336, 'rarity': 'Uncommon'},
  1948: {'rank': 1073, 'rarity': 'Rare'},
  1949: {'rank': 7088, 'rarity': 'Common'},
  1950: {'rank': 4972, 'rarity': 'Uncommon'},
  1951: {'rank': 4095, 'rarity': 'Uncommon'},
  1952: {'rank': 1713, 'rarity': 'Rare'},
  1953: {'rank': 7824, 'rarity': 'Common'},
  1954: {'rank': 9496, 'rarity': 'Common'},
  1955: {'rank': 5961, 'rarity': 'Common'},
  1956: {'rank': 8941, 'rarity': 'Common'},
  1957: {'rank': 1723, 'rarity': 'Rare'},
  1958: {'rank': 4691, 'rarity': 'Uncommon'},
  1959: {'rank': 9854, 'rarity': 'Common'},
  1960: {'rank': 7364, 'rarity': 'Common'},
  1961: {'rank': 5436, 'rarity': 'Common'},
  1962: {'rank': 8660, 'rarity': 'Common'},
  1963: {'rank': 9282, 'rarity': 'Common'},
  1964: {'rank': 3788, 'rarity': 'Uncommon'},
  1965: {'rank': 6937, 'rarity': 'Common'},
  1966: {'rank': 3155, 'rarity': 'Uncommon'},
  1967: {'rank': 9548, 'rarity': 'Common'},
  1968: {'rank': 5207, 'rarity': 'Common'},
  1969: {'rank': 2959, 'rarity': 'Uncommon'},
  1970: {'rank': 9402, 'rarity': 'Common'},
  1971: {'rank': 6012, 'rarity': 'Common'},
  1972: {'rank': 23, 'rarity': 'Legendary'},
  1973: {'rank': 6337, 'rarity': 'Common'},
  1974: {'rank': 6178, 'rarity': 'Common'},
  1975: {'rank': 6854, 'rarity': 'Common'},
  1976: {'rank': 7246, 'rarity': 'Common'},
  1977: {'rank': 8397, 'rarity': 'Common'},
  1978: {'rank': 5056, 'rarity': 'Common'},
  1979: {'rank': 5253, 'rarity': 'Common'},
  1980: {'rank': 1263, 'rarity': 'Rare'},
  1981: {'rank': 8076, 'rarity': 'Common'},
  1982: {'rank': 1980, 'rarity': 'Rare'},
  1983: {'rank': 1323, 'rarity': 'Rare'},
  1984: {'rank': 8356, 'rarity': 'Common'},
  1985: {'rank': 8749, 'rarity': 'Common'},
  1986: {'rank': 5546, 'rarity': 'Common'},
  1987: {'rank': 252, 'rarity': 'Epic'},
  1988: {'rank': 4692, 'rarity': 'Uncommon'},
  1989: {'rank': 1896, 'rarity': 'Rare'},
  1990: {'rank': 1051, 'rarity': 'Rare'},
  1991: {'rank': 6460, 'rarity': 'Common'},
  1992: {'rank': 5008, 'rarity': 'Common'},
  1993: {'rank': 813, 'rarity': 'Epic'},
  1994: {'rank': 7138, 'rarity': 'Common'},
  1995: {'rank': 9540, 'rarity': 'Common'},
  1996: {'rank': 5454, 'rarity': 'Common'},
  1997: {'rank': 4237, 'rarity': 'Uncommon'},
  1998: {'rank': 5210, 'rarity': 'Common'},
  1999: {'rank': 8650, 'rarity': 'Common'},
  2000: {'rank': 7636, 'rarity': 'Common'},
  2001: {'rank': 417, 'rarity': 'Epic'},
  2002: {'rank': 7270, 'rarity': 'Common'},
  2003: {'rank': 4920, 'rarity': 'Uncommon'},
  2004: {'rank': 7523, 'rarity': 'Common'},
  2005: {'rank': 6554, 'rarity': 'Common'},
  2006: {'rank': 3898, 'rarity': 'Uncommon'},
  2007: {'rank': 5631, 'rarity': 'Common'},
  2008: {'rank': 5798, 'rarity': 'Common'},
  2009: {'rank': 8570, 'rarity': 'Common'},
  2010: {'rank': 2269, 'rarity': 'Rare'},
  2011: {'rank': 2998, 'rarity': 'Uncommon'},
  2012: {'rank': 8275, 'rarity': 'Common'},
  2013: {'rank': 4025, 'rarity': 'Uncommon'},
  2014: {'rank': 9034, 'rarity': 'Common'},
  2015: {'rank': 5227, 'rarity': 'Common'},
  2016: {'rank': 756, 'rarity': 'Epic'},
  2017: {'rank': 7739, 'rarity': 'Common'},
  2018: {'rank': 9253, 'rarity': 'Common'},
  2019: {'rank': 2537, 'rarity': 'Uncommon'},
  2020: {'rank': 194, 'rarity': 'Epic'},
  2021: {'rank': 7962, 'rarity': 'Common'},
  2022: {'rank': 4749, 'rarity': 'Uncommon'},
  2023: {'rank': 2324, 'rarity': 'Rare'},
  2024: {'rank': 7125, 'rarity': 'Common'},
  2025: {'rank': 1397, 'rarity': 'Rare'},
  2026: {'rank': 4208, 'rarity': 'Uncommon'},
  2027: {'rank': 5921, 'rarity': 'Common'},
  2028: {'rank': 754, 'rarity': 'Epic'},
  2029: {'rank': 1000, 'rarity': 'Epic'},
  2030: {'rank': 1851, 'rarity': 'Rare'},
  2031: {'rank': 6826, 'rarity': 'Common'},
  2032: {'rank': 6515, 'rarity': 'Common'},
  2033: {'rank': 3504, 'rarity': 'Uncommon'},
  2034: {'rank': 8494, 'rarity': 'Common'},
  2035: {'rank': 9559, 'rarity': 'Common'},
  2036: {'rank': 537, 'rarity': 'Epic'},
  2037: {'rank': 8746, 'rarity': 'Common'},
  2038: {'rank': 7185, 'rarity': 'Common'},
  2039: {'rank': 7423, 'rarity': 'Common'},
  2040: {'rank': 2976, 'rarity': 'Uncommon'},
  2041: {'rank': 391, 'rarity': 'Epic'},
  2042: {'rank': 7976, 'rarity': 'Common'},
  2043: {'rank': 331, 'rarity': 'Epic'},
  2044: {'rank': 4862, 'rarity': 'Uncommon'},
  2045: {'rank': 1904, 'rarity': 'Rare'},
  2046: {'rank': 4561, 'rarity': 'Uncommon'},
  2047: {'rank': 5699, 'rarity': 'Common'},
  2048: {'rank': 4941, 'rarity': 'Uncommon'},
  2049: {'rank': 5773, 'rarity': 'Common'},
  2050: {'rank': 7903, 'rarity': 'Common'},
  2051: {'rank': 2985, 'rarity': 'Uncommon'},
  2052: {'rank': 9425, 'rarity': 'Common'},
  2053: {'rank': 1206, 'rarity': 'Rare'},
  2054: {'rank': 9426, 'rarity': 'Common'},
  2055: {'rank': 775, 'rarity': 'Epic'},
  2056: {'rank': 1398, 'rarity': 'Rare'},
  2057: {'rank': 6369, 'rarity': 'Common'},
  2058: {'rank': 3812, 'rarity': 'Uncommon'},
  2059: {'rank': 6089, 'rarity': 'Common'},
  2060: {'rank': 2622, 'rarity': 'Uncommon'},
  2061: {'rank': 3883, 'rarity': 'Uncommon'},
  2062: {'rank': 7280, 'rarity': 'Common'},
  2063: {'rank': 6689, 'rarity': 'Common'},
  2064: {'rank': 6648, 'rarity': 'Common'},
  2065: {'rank': 3017, 'rarity': 'Uncommon'},
  2066: {'rank': 9155, 'rarity': 'Common'},
  2067: {'rank': 9593, 'rarity': 'Common'},
  2068: {'rank': 6578, 'rarity': 'Common'},
  2069: {'rank': 7183, 'rarity': 'Common'},
  2070: {'rank': 6697, 'rarity': 'Common'},
  2071: {'rank': 9719, 'rarity': 'Common'},
  2072: {'rank': 8301, 'rarity': 'Common'},
  2073: {'rank': 5291, 'rarity': 'Common'},
  2074: {'rank': 5778, 'rarity': 'Common'},
  2075: {'rank': 1741, 'rarity': 'Rare'},
  2076: {'rank': 4257, 'rarity': 'Uncommon'},
  2077: {'rank': 6152, 'rarity': 'Common'},
  2078: {'rank': 7399, 'rarity': 'Common'},
  2079: {'rank': 3519, 'rarity': 'Uncommon'},
  2080: {'rank': 8233, 'rarity': 'Common'},
  2081: {'rank': 7800, 'rarity': 'Common'},
  2082: {'rank': 5318, 'rarity': 'Common'},
  2083: {'rank': 2307, 'rarity': 'Rare'},
  2084: {'rank': 8869, 'rarity': 'Common'},
  2085: {'rank': 2902, 'rarity': 'Uncommon'},
  2086: {'rank': 6254, 'rarity': 'Common'},
  2087: {'rank': 3666, 'rarity': 'Uncommon'},
  2088: {'rank': 1782, 'rarity': 'Rare'},
  2089: {'rank': 5189, 'rarity': 'Common'},
  2090: {'rank': 2853, 'rarity': 'Uncommon'},
  2091: {'rank': 9906, 'rarity': 'Common'},
  2092: {'rank': 7412, 'rarity': 'Common'},
  2093: {'rank': 8747, 'rarity': 'Common'},
  2094: {'rank': 2947, 'rarity': 'Uncommon'},
  2095: {'rank': 3131, 'rarity': 'Uncommon'},
  2096: {'rank': 678, 'rarity': 'Epic'},
  2097: {'rank': 1410, 'rarity': 'Rare'},
  2098: {'rank': 2819, 'rarity': 'Uncommon'},
  2099: {'rank': 3160, 'rarity': 'Uncommon'},
  2100: {'rank': 2018, 'rarity': 'Rare'},
  2101: {'rank': 7370, 'rarity': 'Common'},
  2102: {'rank': 5173, 'rarity': 'Common'},
  2103: {'rank': 6640, 'rarity': 'Common'},
  2104: {'rank': 8482, 'rarity': 'Common'},
  2105: {'rank': 2401, 'rarity': 'Rare'},
  2106: {'rank': 6442, 'rarity': 'Common'},
  2107: {'rank': 4781, 'rarity': 'Uncommon'},
  2108: {'rank': 8926, 'rarity': 'Common'},
  2109: {'rank': 3589, 'rarity': 'Uncommon'},
  2110: {'rank': 6484, 'rarity': 'Common'},
  2111: {'rank': 73, 'rarity': 'Legendary'},
  2112: {'rank': 7786, 'rarity': 'Common'},
  2113: {'rank': 7932, 'rarity': 'Common'},
  2114: {'rank': 2319, 'rarity': 'Rare'},
  2115: {'rank': 7723, 'rarity': 'Common'},
  2116: {'rank': 6788, 'rarity': 'Common'},
  2117: {'rank': 8934, 'rarity': 'Common'},
  2118: {'rank': 7259, 'rarity': 'Common'},
  2119: {'rank': 2444, 'rarity': 'Rare'},
  2120: {'rank': 9419, 'rarity': 'Common'},
  2121: {'rank': 1936, 'rarity': 'Rare'},
  2122: {'rank': 6923, 'rarity': 'Common'},
  2123: {'rank': 7907, 'rarity': 'Common'},
  2124: {'rank': 2186, 'rarity': 'Rare'},
  2125: {'rank': 4403, 'rarity': 'Uncommon'},
  2126: {'rank': 4072, 'rarity': 'Uncommon'},
  2127: {'rank': 4614, 'rarity': 'Uncommon'},
  2128: {'rank': 6653, 'rarity': 'Common'},
  2129: {'rank': 7343, 'rarity': 'Common'},
  2130: {'rank': 8671, 'rarity': 'Common'},
  2131: {'rank': 8813, 'rarity': 'Common'},
  2132: {'rank': 5219, 'rarity': 'Common'},
  2133: {'rank': 820, 'rarity': 'Epic'},
  2134: {'rank': 5446, 'rarity': 'Common'},
  2135: {'rank': 7024, 'rarity': 'Common'},
  2136: {'rank': 493, 'rarity': 'Epic'},
  2137: {'rank': 1498, 'rarity': 'Rare'},
  2138: {'rank': 5860, 'rarity': 'Common'},
  2139: {'rank': 3734, 'rarity': 'Uncommon'},
  2140: {'rank': 1869, 'rarity': 'Rare'},
  2141: {'rank': 8511, 'rarity': 'Common'},
  2142: {'rank': 3238, 'rarity': 'Uncommon'},
  2143: {'rank': 6351, 'rarity': 'Common'},
  2144: {'rank': 5089, 'rarity': 'Common'},
  2145: {'rank': 1811, 'rarity': 'Rare'},
  2146: {'rank': 6563, 'rarity': 'Common'},
  2147: {'rank': 4301, 'rarity': 'Uncommon'},
  2148: {'rank': 5607, 'rarity': 'Common'},
  2149: {'rank': 987, 'rarity': 'Epic'},
  2150: {'rank': 2168, 'rarity': 'Rare'},
  2151: {'rank': 609, 'rarity': 'Epic'},
  2152: {'rank': 7036, 'rarity': 'Common'},
  2153: {'rank': 1878, 'rarity': 'Rare'},
  2154: {'rank': 2333, 'rarity': 'Rare'},
  2155: {'rank': 5209, 'rarity': 'Common'},
  2156: {'rank': 9679, 'rarity': 'Common'},
  2157: {'rank': 9524, 'rarity': 'Common'},
  2158: {'rank': 5813, 'rarity': 'Common'},
  2159: {'rank': 2238, 'rarity': 'Rare'},
  2160: {'rank': 4499, 'rarity': 'Uncommon'},
  2161: {'rank': 9248, 'rarity': 'Common'},
  2162: {'rank': 9051, 'rarity': 'Common'},
  2163: {'rank': 2181, 'rarity': 'Rare'},
  2164: {'rank': 321, 'rarity': 'Epic'},
  2165: {'rank': 5551, 'rarity': 'Common'},
  2166: {'rank': 929, 'rarity': 'Epic'},
  2167: {'rank': 6257, 'rarity': 'Common'},
  2168: {'rank': 1072, 'rarity': 'Rare'},
  2169: {'rank': 2618, 'rarity': 'Uncommon'},
  2170: {'rank': 4145, 'rarity': 'Uncommon'},
  2171: {'rank': 5854, 'rarity': 'Common'},
  2172: {'rank': 4557, 'rarity': 'Uncommon'},
  2173: {'rank': 1952, 'rarity': 'Rare'},
  2174: {'rank': 4835, 'rarity': 'Uncommon'},
  2175: {'rank': 836, 'rarity': 'Epic'},
  2176: {'rank': 7451, 'rarity': 'Common'},
  2177: {'rank': 6727, 'rarity': 'Common'},
  2178: {'rank': 258, 'rarity': 'Epic'},
  2179: {'rank': 6262, 'rarity': 'Common'},
  2180: {'rank': 1299, 'rarity': 'Rare'},
  2181: {'rank': 2520, 'rarity': 'Uncommon'},
  2182: {'rank': 5054, 'rarity': 'Common'},
  2183: {'rank': 6277, 'rarity': 'Common'},
  2184: {'rank': 6982, 'rarity': 'Common'},
  2185: {'rank': 9269, 'rarity': 'Common'},
  2186: {'rank': 4982, 'rarity': 'Uncommon'},
  2187: {'rank': 3663, 'rarity': 'Uncommon'},
  2188: {'rank': 8373, 'rarity': 'Common'},
  2189: {'rank': 6538, 'rarity': 'Common'},
  2190: {'rank': 962, 'rarity': 'Epic'},
  2191: {'rank': 3301, 'rarity': 'Uncommon'},
  2192: {'rank': 1376, 'rarity': 'Rare'},
  2193: {'rank': 1467, 'rarity': 'Rare'},
  2194: {'rank': 5181, 'rarity': 'Common'},
  2195: {'rank': 4979, 'rarity': 'Uncommon'},
  2196: {'rank': 5486, 'rarity': 'Common'},
  2197: {'rank': 931, 'rarity': 'Epic'},
  2198: {'rank': 1486, 'rarity': 'Rare'},
  2199: {'rank': 9592, 'rarity': 'Common'},
  2200: {'rank': 954, 'rarity': 'Epic'},
  2201: {'rank': 1604, 'rarity': 'Rare'},
  2202: {'rank': 3063, 'rarity': 'Uncommon'},
  2203: {'rank': 6360, 'rarity': 'Common'},
  2204: {'rank': 4419, 'rarity': 'Uncommon'},
  2205: {'rank': 6250, 'rarity': 'Common'},
  2206: {'rank': 9897, 'rarity': 'Common'},
  2207: {'rank': 9385, 'rarity': 'Common'},
  2208: {'rank': 1704, 'rarity': 'Rare'},
  2209: {'rank': 1633, 'rarity': 'Rare'},
  2210: {'rank': 8693, 'rarity': 'Common'},
  2211: {'rank': 6482, 'rarity': 'Common'},
  2212: {'rank': 8674, 'rarity': 'Common'},
  2213: {'rank': 8133, 'rarity': 'Common'},
  2214: {'rank': 9205, 'rarity': 'Common'},
  2215: {'rank': 7002, 'rarity': 'Common'},
  2216: {'rank': 4223, 'rarity': 'Uncommon'},
  2217: {'rank': 5190, 'rarity': 'Common'},
  2218: {'rank': 1592, 'rarity': 'Rare'},
  2219: {'rank': 9686, 'rarity': 'Common'},
  2220: {'rank': 9324, 'rarity': 'Common'},
  2221: {'rank': 6054, 'rarity': 'Common'},
  2222: {'rank': 4400, 'rarity': 'Uncommon'},
  2223: {'rank': 1210, 'rarity': 'Rare'},
  2224: {'rank': 3387, 'rarity': 'Uncommon'},
  2225: {'rank': 9403, 'rarity': 'Common'},
  2226: {'rank': 1773, 'rarity': 'Rare'},
  2227: {'rank': 8089, 'rarity': 'Common'},
  2228: {'rank': 709, 'rarity': 'Epic'},
  2229: {'rank': 9460, 'rarity': 'Common'},
  2230: {'rank': 1683, 'rarity': 'Rare'},
  2231: {'rank': 7957, 'rarity': 'Common'},
  2232: {'rank': 3810, 'rarity': 'Uncommon'},
  2233: {'rank': 4769, 'rarity': 'Uncommon'},
  2234: {'rank': 5567, 'rarity': 'Common'},
  2235: {'rank': 3117, 'rarity': 'Uncommon'},
  2236: {'rank': 87, 'rarity': 'Legendary'},
  2237: {'rank': 4120, 'rarity': 'Uncommon'},
  2238: {'rank': 2832, 'rarity': 'Uncommon'},
  2239: {'rank': 8996, 'rarity': 'Common'},
  2240: {'rank': 138, 'rarity': 'Epic'},
  2241: {'rank': 4584, 'rarity': 'Uncommon'},
  2242: {'rank': 1446, 'rarity': 'Rare'},
  2243: {'rank': 3045, 'rarity': 'Uncommon'},
  2244: {'rank': 9308, 'rarity': 'Common'},
  2245: {'rank': 5705, 'rarity': 'Common'},
  2246: {'rank': 6641, 'rarity': 'Common'},
  2247: {'rank': 1229, 'rarity': 'Rare'},
  2248: {'rank': 7664, 'rarity': 'Common'},
  2249: {'rank': 2789, 'rarity': 'Uncommon'},
  2250: {'rank': 9131, 'rarity': 'Common'},
  2251: {'rank': 8805, 'rarity': 'Common'},
  2252: {'rank': 848, 'rarity': 'Epic'},
  2253: {'rank': 9758, 'rarity': 'Common'},
  2254: {'rank': 9556, 'rarity': 'Common'},
  2255: {'rank': 3187, 'rarity': 'Uncommon'},
  2256: {'rank': 4963, 'rarity': 'Uncommon'},
  2257: {'rank': 3086, 'rarity': 'Uncommon'},
  2258: {'rank': 2885, 'rarity': 'Uncommon'},
  2259: {'rank': 6516, 'rarity': 'Common'},
  2260: {'rank': 1934, 'rarity': 'Rare'},
  2261: {'rank': 1414, 'rarity': 'Rare'},
  2262: {'rank': 6304, 'rarity': 'Common'},
  2263: {'rank': 6283, 'rarity': 'Common'},
  2264: {'rank': 6890, 'rarity': 'Common'},
  2265: {'rank': 4511, 'rarity': 'Uncommon'},
  2266: {'rank': 4727, 'rarity': 'Uncommon'},
  2267: {'rank': 5278, 'rarity': 'Common'},
  2268: {'rank': 6168, 'rarity': 'Common'},
  2269: {'rank': 4470, 'rarity': 'Uncommon'},
  2270: {'rank': 1385, 'rarity': 'Rare'},
  2271: {'rank': 1301, 'rarity': 'Rare'},
  2272: {'rank': 642, 'rarity': 'Epic'},
  2273: {'rank': 5681, 'rarity': 'Common'},
  2274: {'rank': 9840, 'rarity': 'Common'},
  2275: {'rank': 5138, 'rarity': 'Common'},
  2276: {'rank': 8940, 'rarity': 'Common'},
  2277: {'rank': 5505, 'rarity': 'Common'},
  2278: {'rank': 4345, 'rarity': 'Uncommon'},
  2279: {'rank': 5917, 'rarity': 'Common'},
  2280: {'rank': 2701, 'rarity': 'Uncommon'},
  2281: {'rank': 6455, 'rarity': 'Common'},
  2282: {'rank': 8325, 'rarity': 'Common'},
  2283: {'rank': 9130, 'rarity': 'Common'},
  2284: {'rank': 161, 'rarity': 'Epic'},
  2285: {'rank': 5906, 'rarity': 'Common'},
  2286: {'rank': 6031, 'rarity': 'Common'},
  2287: {'rank': 2939, 'rarity': 'Uncommon'},
  2288: {'rank': 5222, 'rarity': 'Common'},
  2289: {'rank': 7834, 'rarity': 'Common'},
  2290: {'rank': 3373, 'rarity': 'Uncommon'},
  2291: {'rank': 8987, 'rarity': 'Common'},
  2292: {'rank': 9784, 'rarity': 'Common'},
  2293: {'rank': 2800, 'rarity': 'Uncommon'},
  2294: {'rank': 4974, 'rarity': 'Uncommon'},
  2295: {'rank': 1393, 'rarity': 'Rare'},
  2296: {'rank': 3652, 'rarity': 'Uncommon'},
  2297: {'rank': 1545, 'rarity': 'Rare'},
  2298: {'rank': 8272, 'rarity': 'Common'},
  2299: {'rank': 7463, 'rarity': 'Common'},
  2300: {'rank': 1595, 'rarity': 'Rare'},
  2301: {'rank': 8948, 'rarity': 'Common'},
  2302: {'rank': 9413, 'rarity': 'Common'},
  2303: {'rank': 6750, 'rarity': 'Common'},
  2304: {'rank': 9237, 'rarity': 'Common'},
  2305: {'rank': 7457, 'rarity': 'Common'},
  2306: {'rank': 2613, 'rarity': 'Uncommon'},
  2307: {'rank': 8024, 'rarity': 'Common'},
  2308: {'rank': 835, 'rarity': 'Epic'},
  2309: {'rank': 4410, 'rarity': 'Uncommon'},
  2310: {'rank': 892, 'rarity': 'Epic'},
  2311: {'rank': 4126, 'rarity': 'Uncommon'},
  2312: {'rank': 7341, 'rarity': 'Common'},
  2313: {'rank': 2653, 'rarity': 'Uncommon'},
  2314: {'rank': 4828, 'rarity': 'Uncommon'},
  2315: {'rank': 1593, 'rarity': 'Rare'},
  2316: {'rank': 6441, 'rarity': 'Common'},
  2317: {'rank': 3324, 'rarity': 'Uncommon'},
  2318: {'rank': 3413, 'rarity': 'Uncommon'},
  2319: {'rank': 7562, 'rarity': 'Common'},
  2320: {'rank': 9091, 'rarity': 'Common'},
  2321: {'rank': 3706, 'rarity': 'Uncommon'},
  2322: {'rank': 3787, 'rarity': 'Uncommon'},
  2323: {'rank': 2778, 'rarity': 'Uncommon'},
  2324: {'rank': 2756, 'rarity': 'Uncommon'},
  2325: {'rank': 9582, 'rarity': 'Common'},
  2326: {'rank': 6860, 'rarity': 'Common'},
  2327: {'rank': 2812, 'rarity': 'Uncommon'},
  2328: {'rank': 7689, 'rarity': 'Common'},
  2329: {'rank': 2806, 'rarity': 'Uncommon'},
  2330: {'rank': 367, 'rarity': 'Epic'},
  2331: {'rank': 6987, 'rarity': 'Common'},
  2332: {'rank': 1445, 'rarity': 'Rare'},
  2333: {'rank': 8173, 'rarity': 'Common'},
  2334: {'rank': 6233, 'rarity': 'Common'},
  2335: {'rank': 7476, 'rarity': 'Common'},
  2336: {'rank': 285, 'rarity': 'Epic'},
  2337: {'rank': 6407, 'rarity': 'Common'},
  2338: {'rank': 9673, 'rarity': 'Common'},
  2339: {'rank': 526, 'rarity': 'Epic'},
  2340: {'rank': 7671, 'rarity': 'Common'},
  2341: {'rank': 358, 'rarity': 'Epic'},
  2342: {'rank': 6951, 'rarity': 'Common'},
  2343: {'rank': 1807, 'rarity': 'Rare'},
  2344: {'rank': 1903, 'rarity': 'Rare'},
  2345: {'rank': 4172, 'rarity': 'Uncommon'},
  2346: {'rank': 8176, 'rarity': 'Common'},
  2347: {'rank': 9615, 'rarity': 'Common'},
  2348: {'rank': 525, 'rarity': 'Epic'},
  2349: {'rank': 3345, 'rarity': 'Uncommon'},
  2350: {'rank': 7588, 'rarity': 'Common'},
  2351: {'rank': 2524, 'rarity': 'Uncommon'},
  2352: {'rank': 1185, 'rarity': 'Rare'},
  2353: {'rank': 8195, 'rarity': 'Common'},
  2354: {'rank': 6803, 'rarity': 'Common'},
  2355: {'rank': 8545, 'rarity': 'Common'},
  2356: {'rank': 58, 'rarity': 'Legendary'},
  2357: {'rank': 1131, 'rarity': 'Rare'},
  2358: {'rank': 6159, 'rarity': 'Common'},
  2359: {'rank': 261, 'rarity': 'Epic'},
  2360: {'rank': 4187, 'rarity': 'Uncommon'},
  2361: {'rank': 5304, 'rarity': 'Common'},
  2362: {'rank': 3572, 'rarity': 'Uncommon'},
  2363: {'rank': 7443, 'rarity': 'Common'},
  2364: {'rank': 9181, 'rarity': 'Common'},
  2365: {'rank': 1049, 'rarity': 'Rare'},
  2366: {'rank': 9300, 'rarity': 'Common'},
  2367: {'rank': 250, 'rarity': 'Epic'},
  2368: {'rank': 6101, 'rarity': 'Common'},
  2369: {'rank': 6667, 'rarity': 'Common'},
  2370: {'rank': 3053, 'rarity': 'Uncommon'},
  2371: {'rank': 3446, 'rarity': 'Uncommon'},
  2372: {'rank': 3362, 'rarity': 'Uncommon'},
  2373: {'rank': 1719, 'rarity': 'Rare'},
  2374: {'rank': 2954, 'rarity': 'Uncommon'},
  2375: {'rank': 4572, 'rarity': 'Uncommon'},
  2376: {'rank': 9915, 'rarity': 'Common'},
  2377: {'rank': 6895, 'rarity': 'Common'},
  2378: {'rank': 3658, 'rarity': 'Uncommon'},
  2379: {'rank': 3675, 'rarity': 'Uncommon'},
  2380: {'rank': 368, 'rarity': 'Epic'},
  2381: {'rank': 7171, 'rarity': 'Common'},
  2382: {'rank': 2229, 'rarity': 'Rare'},
  2383: {'rank': 565, 'rarity': 'Epic'},
  2384: {'rank': 9730, 'rarity': 'Common'},
  2385: {'rank': 3400, 'rarity': 'Uncommon'},
  2386: {'rank': 1309, 'rarity': 'Rare'},
  2387: {'rank': 35, 'rarity': 'Legendary'},
  2388: {'rank': 7103, 'rarity': 'Common'},
  2389: {'rank': 7910, 'rarity': 'Common'},
  2390: {'rank': 2847, 'rarity': 'Uncommon'},
  2391: {'rank': 4648, 'rarity': 'Uncommon'},
  2392: {'rank': 736, 'rarity': 'Epic'},
  2393: {'rank': 49, 'rarity': 'Legendary'},
  2394: {'rank': 1144, 'rarity': 'Rare'},
  2395: {'rank': 841, 'rarity': 'Epic'},
  2396: {'rank': 4073, 'rarity': 'Uncommon'},
  2397: {'rank': 4570, 'rarity': 'Uncommon'},
  2398: {'rank': 730, 'rarity': 'Epic'},
  2399: {'rank': 5980, 'rarity': 'Common'},
  2400: {'rank': 4312, 'rarity': 'Uncommon'},
  2401: {'rank': 6547, 'rarity': 'Common'},
  2402: {'rank': 6430, 'rarity': 'Common'},
  2403: {'rank': 6758, 'rarity': 'Common'},
  2404: {'rank': 6752, 'rarity': 'Common'},
  2405: {'rank': 5948, 'rarity': 'Common'},
  2406: {'rank': 2944, 'rarity': 'Uncommon'},
  2407: {'rank': 2165, 'rarity': 'Rare'},
  2408: {'rank': 8443, 'rarity': 'Common'},
  2409: {'rank': 2632, 'rarity': 'Uncommon'},
  2410: {'rank': 4069, 'rarity': 'Uncommon'},
  2411: {'rank': 7146, 'rarity': 'Common'},
  2412: {'rank': 9887, 'rarity': 'Common'},
  2413: {'rank': 1340, 'rarity': 'Rare'},
  2414: {'rank': 4143, 'rarity': 'Uncommon'},
  2415: {'rank': 1173, 'rarity': 'Rare'},
  2416: {'rank': 1926, 'rarity': 'Rare'},
  2417: {'rank': 6094, 'rarity': 'Common'},
  2418: {'rank': 1036, 'rarity': 'Rare'},
  2419: {'rank': 591, 'rarity': 'Epic'},
  2420: {'rank': 9167, 'rarity': 'Common'},
  2421: {'rank': 9386, 'rarity': 'Common'},
  2422: {'rank': 9522, 'rarity': 'Common'},
  2423: {'rank': 2946, 'rarity': 'Uncommon'},
  2424: {'rank': 8958, 'rarity': 'Common'},
  2425: {'rank': 7359, 'rarity': 'Common'},
  2426: {'rank': 9497, 'rarity': 'Common'},
  2427: {'rank': 5771, 'rarity': 'Common'},
  2428: {'rank': 4028, 'rarity': 'Uncommon'},
  2429: {'rank': 1139, 'rarity': 'Rare'},
  2430: {'rank': 519, 'rarity': 'Epic'},
  2431: {'rank': 9159, 'rarity': 'Common'},
  2432: {'rank': 5561, 'rarity': 'Common'},
  2433: {'rank': 6451, 'rarity': 'Common'},
  2434: {'rank': 9428, 'rarity': 'Common'},
  2435: {'rank': 7494, 'rarity': 'Common'},
  2436: {'rank': 8065, 'rarity': 'Common'},
  2437: {'rank': 442, 'rarity': 'Epic'},
  2438: {'rank': 6014, 'rarity': 'Common'},
  2439: {'rank': 5679, 'rarity': 'Common'},
  2440: {'rank': 8049, 'rarity': 'Common'},
  2441: {'rank': 9792, 'rarity': 'Common'},
  2442: {'rank': 5233, 'rarity': 'Common'},
  2443: {'rank': 7026, 'rarity': 'Common'},
  2444: {'rank': 8189, 'rarity': 'Common'},
  2445: {'rank': 4507, 'rarity': 'Uncommon'},
  2446: {'rank': 5292, 'rarity': 'Common'},
  2447: {'rank': 7528, 'rarity': 'Common'},
  2448: {'rank': 5542, 'rarity': 'Common'},
  2449: {'rank': 2525, 'rarity': 'Uncommon'},
  2450: {'rank': 5166, 'rarity': 'Common'},
  2451: {'rank': 6714, 'rarity': 'Common'},
  2452: {'rank': 2848, 'rarity': 'Uncommon'},
  2453: {'rank': 639, 'rarity': 'Epic'},
  2454: {'rank': 552, 'rarity': 'Epic'},
  2455: {'rank': 1223, 'rarity': 'Rare'},
  2456: {'rank': 6476, 'rarity': 'Common'},
  2457: {'rank': 395, 'rarity': 'Epic'},
  2458: {'rank': 405, 'rarity': 'Epic'},
  2459: {'rank': 783, 'rarity': 'Epic'},
  2460: {'rank': 7300, 'rarity': 'Common'},
  2461: {'rank': 6491, 'rarity': 'Common'},
  2462: {'rank': 177, 'rarity': 'Epic'},
  2463: {'rank': 3522, 'rarity': 'Uncommon'},
  2464: {'rank': 7845, 'rarity': 'Common'},
  2465: {'rank': 3679, 'rarity': 'Uncommon'},
  2466: {'rank': 3178, 'rarity': 'Uncommon'},
  2467: {'rank': 8013, 'rarity': 'Common'},
  2468: {'rank': 7223, 'rarity': 'Common'},
  2469: {'rank': 1416, 'rarity': 'Rare'},
  2470: {'rank': 1961, 'rarity': 'Rare'},
  2471: {'rank': 5386, 'rarity': 'Common'},
  2472: {'rank': 8983, 'rarity': 'Common'},
  2473: {'rank': 5362, 'rarity': 'Common'},
  2474: {'rank': 7547, 'rarity': 'Common'},
  2475: {'rank': 5398, 'rarity': 'Common'},
  2476: {'rank': 3832, 'rarity': 'Uncommon'},
  2477: {'rank': 8765, 'rarity': 'Common'},
  2478: {'rank': 2355, 'rarity': 'Rare'},
  2479: {'rank': 1365, 'rarity': 'Rare'},
  2480: {'rank': 4283, 'rarity': 'Uncommon'},
  2481: {'rank': 6844, 'rarity': 'Common'},
  2482: {'rank': 855, 'rarity': 'Epic'},
  2483: {'rank': 6972, 'rarity': 'Common'},
  2484: {'rank': 7680, 'rarity': 'Common'},
  2485: {'rank': 25, 'rarity': 'Legendary'},
  2486: {'rank': 9289, 'rarity': 'Common'},
  2487: {'rank': 1089, 'rarity': 'Rare'},
  2488: {'rank': 2762, 'rarity': 'Uncommon'},
  2489: {'rank': 8846, 'rarity': 'Common'},
  2490: {'rank': 8560, 'rarity': 'Common'},
  2491: {'rank': 8054, 'rarity': 'Common'},
  2492: {'rank': 7540, 'rarity': 'Common'},
  2493: {'rank': 9742, 'rarity': 'Common'},
  2494: {'rank': 5200, 'rarity': 'Common'},
  2495: {'rank': 6032, 'rarity': 'Common'},
  2496: {'rank': 4967, 'rarity': 'Uncommon'},
  2497: {'rank': 6705, 'rarity': 'Common'},
  2498: {'rank': 6471, 'rarity': 'Common'},
  2499: {'rank': 3579, 'rarity': 'Uncommon'},
  2500: {'rank': 4730, 'rarity': 'Uncommon'},
  2501: {'rank': 2395, 'rarity': 'Rare'},
  2502: {'rank': 3869, 'rarity': 'Uncommon'},
  2503: {'rank': 3576, 'rarity': 'Uncommon'},
  2504: {'rank': 9044, 'rarity': 'Common'},
  2505: {'rank': 7732, 'rarity': 'Common'},
  2506: {'rank': 2171, 'rarity': 'Rare'},
  2507: {'rank': 2003, 'rarity': 'Rare'},
  2508: {'rank': 733, 'rarity': 'Epic'},
  2509: {'rank': 1508, 'rarity': 'Rare'},
  2510: {'rank': 9888, 'rarity': 'Common'},
  2511: {'rank': 8116, 'rarity': 'Common'},
  2512: {'rank': 1591, 'rarity': 'Rare'},
  2513: {'rank': 2713, 'rarity': 'Uncommon'},
  2514: {'rank': 1350, 'rarity': 'Rare'},
  2515: {'rank': 6463, 'rarity': 'Common'},
  2516: {'rank': 9554, 'rarity': 'Common'},
  2517: {'rank': 8309, 'rarity': 'Common'},
  2518: {'rank': 1601, 'rarity': 'Rare'},
  2519: {'rank': 8977, 'rarity': 'Common'},
  2520: {'rank': 4051, 'rarity': 'Uncommon'},
  2521: {'rank': 4855, 'rarity': 'Uncommon'},
  2522: {'rank': 1218, 'rarity': 'Rare'},
  2523: {'rank': 2751, 'rarity': 'Uncommon'},
  2524: {'rank': 3313, 'rarity': 'Uncommon'},
  2525: {'rank': 6979, 'rarity': 'Common'},
  2526: {'rank': 8264, 'rarity': 'Common'},
  2527: {'rank': 3721, 'rarity': 'Uncommon'},
  2528: {'rank': 1712, 'rarity': 'Rare'},
  2529: {'rank': 1476, 'rarity': 'Rare'},
  2530: {'rank': 4316, 'rarity': 'Uncommon'},
  2531: {'rank': 7611, 'rarity': 'Common'},
  2532: {'rank': 1771, 'rarity': 'Rare'},
  2533: {'rank': 282, 'rarity': 'Epic'},
  2534: {'rank': 5101, 'rarity': 'Common'},
  2535: {'rank': 99, 'rarity': 'Legendary'},
  2536: {'rank': 5683, 'rarity': 'Common'},
  2537: {'rank': 1937, 'rarity': 'Rare'},
  2538: {'rank': 2859, 'rarity': 'Uncommon'},
  2539: {'rank': 3500, 'rarity': 'Uncommon'},
  2540: {'rank': 3, 'rarity': 'Legendary'},
  2541: {'rank': 1431, 'rarity': 'Rare'},
  2542: {'rank': 8162, 'rarity': 'Common'},
  2543: {'rank': 2282, 'rarity': 'Rare'},
  2544: {'rank': 1602, 'rarity': 'Rare'},
  2545: {'rank': 7748, 'rarity': 'Common'},
  2546: {'rank': 3448, 'rarity': 'Uncommon'},
  2547: {'rank': 9361, 'rarity': 'Common'},
  2548: {'rank': 2163, 'rarity': 'Rare'},
  2549: {'rank': 4201, 'rarity': 'Uncommon'},
  2550: {'rank': 2027, 'rarity': 'Rare'},
  2551: {'rank': 4554, 'rarity': 'Uncommon'},
  2552: {'rank': 6298, 'rarity': 'Common'},
  2553: {'rank': 2193, 'rarity': 'Rare'},
  2554: {'rank': 2433, 'rarity': 'Rare'},
  2555: {'rank': 8968, 'rarity': 'Common'},
  2556: {'rank': 8348, 'rarity': 'Common'},
  2557: {'rank': 1993, 'rarity': 'Rare'},
  2558: {'rank': 3881, 'rarity': 'Uncommon'},
  2559: {'rank': 2914, 'rarity': 'Uncommon'},
  2560: {'rank': 5598, 'rarity': 'Common'},
  2561: {'rank': 6530, 'rarity': 'Common'},
  2562: {'rank': 6449, 'rarity': 'Common'},
  2563: {'rank': 7248, 'rarity': 'Common'},
  2564: {'rank': 1199, 'rarity': 'Rare'},
  2565: {'rank': 4807, 'rarity': 'Uncommon'},
  2566: {'rank': 1722, 'rarity': 'Rare'},
  2567: {'rank': 8273, 'rarity': 'Common'},
  2568: {'rank': 9632, 'rarity': 'Common'},
  2569: {'rank': 6236, 'rarity': 'Common'},
  2570: {'rank': 14, 'rarity': 'Legendary'},
  2571: {'rank': 4471, 'rarity': 'Uncommon'},
  2572: {'rank': 6607, 'rarity': 'Common'},
  2573: {'rank': 3846, 'rarity': 'Uncommon'},
  2574: {'rank': 9207, 'rarity': 'Common'},
  2575: {'rank': 8449, 'rarity': 'Common'},
  2576: {'rank': 4174, 'rarity': 'Uncommon'},
  2577: {'rank': 2933, 'rarity': 'Uncommon'},
  2578: {'rank': 6552, 'rarity': 'Common'},
  2579: {'rank': 7891, 'rarity': 'Common'},
  2580: {'rank': 7653, 'rarity': 'Common'},
  2581: {'rank': 6102, 'rarity': 'Common'},
  2582: {'rank': 9519, 'rarity': 'Common'},
  2583: {'rank': 3873, 'rarity': 'Uncommon'},
  2584: {'rank': 9471, 'rarity': 'Common'},
  2585: {'rank': 8108, 'rarity': 'Common'},
  2586: {'rank': 8539, 'rarity': 'Common'},
  2587: {'rank': 8194, 'rarity': 'Common'},
  2588: {'rank': 6572, 'rarity': 'Common'},
  2589: {'rank': 7210, 'rarity': 'Common'},
  2590: {'rank': 6582, 'rarity': 'Common'},
  2591: {'rank': 5055, 'rarity': 'Common'},
  2592: {'rank': 9222, 'rarity': 'Common'},
  2593: {'rank': 9647, 'rarity': 'Common'},
  2594: {'rank': 9106, 'rarity': 'Common'},
  2595: {'rank': 8572, 'rarity': 'Common'},
  2596: {'rank': 8879, 'rarity': 'Common'},
  2597: {'rank': 8183, 'rarity': 'Common'},
  2598: {'rank': 3144, 'rarity': 'Uncommon'},
  2599: {'rank': 4192, 'rarity': 'Uncommon'},
  2600: {'rank': 5402, 'rarity': 'Common'},
  2601: {'rank': 9212, 'rarity': 'Common'},
  2602: {'rank': 4670, 'rarity': 'Uncommon'},
  2603: {'rank': 3001, 'rarity': 'Uncommon'},
  2604: {'rank': 6039, 'rarity': 'Common'},
  2605: {'rank': 2034, 'rarity': 'Rare'},
  2606: {'rank': 5554, 'rarity': 'Common'},
  2607: {'rank': 9861, 'rarity': 'Common'},
  2608: {'rank': 7842, 'rarity': 'Common'},
  2609: {'rank': 6004, 'rarity': 'Common'},
  2610: {'rank': 6843, 'rarity': 'Common'},
  2611: {'rank': 4413, 'rarity': 'Uncommon'},
  2612: {'rank': 7997, 'rarity': 'Common'},
  2613: {'rank': 8609, 'rarity': 'Common'},
  2614: {'rank': 4035, 'rarity': 'Uncommon'},
  2615: {'rank': 7390, 'rarity': 'Common'},
  2616: {'rank': 2648, 'rarity': 'Uncommon'},
  2617: {'rank': 9974, 'rarity': 'Common'},
  2618: {'rank': 9989, 'rarity': 'Common'},
  2619: {'rank': 2414, 'rarity': 'Rare'},
  2620: {'rank': 9221, 'rarity': 'Common'},
  2621: {'rank': 2507, 'rarity': 'Uncommon'},
  2622: {'rank': 5157, 'rarity': 'Common'},
  2623: {'rank': 4489, 'rarity': 'Uncommon'},
  2624: {'rank': 2987, 'rarity': 'Uncommon'},
  2625: {'rank': 1433, 'rarity': 'Rare'},
  2626: {'rank': 2351, 'rarity': 'Rare'},
  2627: {'rank': 6060, 'rarity': 'Common'},
  2628: {'rank': 3517, 'rarity': 'Uncommon'},
  2629: {'rank': 283, 'rarity': 'Epic'},
  2630: {'rank': 7205, 'rarity': 'Common'},
  2631: {'rank': 7975, 'rarity': 'Common'},
  2632: {'rank': 844, 'rarity': 'Epic'},
  2633: {'rank': 9670, 'rarity': 'Common'},
  2634: {'rank': 2634, 'rarity': 'Uncommon'},
  2635: {'rank': 453, 'rarity': 'Epic'},
  2636: {'rank': 5079, 'rarity': 'Common'},
  2637: {'rank': 1352, 'rarity': 'Rare'},
  2638: {'rank': 3800, 'rarity': 'Uncommon'},
  2639: {'rank': 8251, 'rarity': 'Common'},
  2640: {'rank': 8627, 'rarity': 'Common'},
  2641: {'rank': 1676, 'rarity': 'Rare'},
  2642: {'rank': 4527, 'rarity': 'Uncommon'},
  2643: {'rank': 1927, 'rarity': 'Rare'},
  2644: {'rank': 2062, 'rarity': 'Rare'},
  2645: {'rank': 3955, 'rarity': 'Uncommon'},
  2646: {'rank': 5176, 'rarity': 'Common'},
  2647: {'rank': 2136, 'rarity': 'Rare'},
  2648: {'rank': 5333, 'rarity': 'Common'},
  2649: {'rank': 4640, 'rarity': 'Uncommon'},
  2650: {'rank': 3208, 'rarity': 'Uncommon'},
  2651: {'rank': 4144, 'rarity': 'Uncommon'},
  2652: {'rank': 9174, 'rarity': 'Common'},
  2653: {'rank': 2450, 'rarity': 'Rare'},
  2654: {'rank': 4903, 'rarity': 'Uncommon'},
  2655: {'rank': 3746, 'rarity': 'Uncommon'},
  2656: {'rank': 1216, 'rarity': 'Rare'},
  2657: {'rank': 536, 'rarity': 'Epic'},
  2658: {'rank': 8529, 'rarity': 'Common'},
  2659: {'rank': 7178, 'rarity': 'Common'},
  2660: {'rank': 2304, 'rarity': 'Rare'},
  2661: {'rank': 1519, 'rarity': 'Rare'},
  2662: {'rank': 9811, 'rarity': 'Common'},
  2663: {'rank': 8323, 'rarity': 'Common'},
  2664: {'rank': 7446, 'rarity': 'Common'},
  2665: {'rank': 4859, 'rarity': 'Uncommon'},
  2666: {'rank': 4928, 'rarity': 'Uncommon'},
  2667: {'rank': 452, 'rarity': 'Epic'},
  2668: {'rank': 5048, 'rarity': 'Common'},
  2669: {'rank': 6679, 'rarity': 'Common'},
  2670: {'rank': 3628, 'rarity': 'Uncommon'},
  2671: {'rank': 4893, 'rarity': 'Uncommon'},
  2672: {'rank': 2522, 'rarity': 'Uncommon'},
  2673: {'rank': 5019, 'rarity': 'Common'},
  2674: {'rank': 1791, 'rarity': 'Rare'},
  2675: {'rank': 1559, 'rarity': 'Rare'},
  2676: {'rank': 3145, 'rarity': 'Uncommon'},
  2677: {'rank': 2176, 'rarity': 'Rare'},
  2678: {'rank': 2096, 'rarity': 'Rare'},
  2679: {'rank': 7508, 'rarity': 'Common'},
  2680: {'rank': 9410, 'rarity': 'Common'},
  2681: {'rank': 5989, 'rarity': 'Common'},
  2682: {'rank': 6261, 'rarity': 'Common'},
  2683: {'rank': 553, 'rarity': 'Epic'},
  2684: {'rank': 9287, 'rarity': 'Common'},
  2685: {'rank': 9896, 'rarity': 'Common'},
  2686: {'rank': 206, 'rarity': 'Epic'},
  2687: {'rank': 5515, 'rarity': 'Common'},
  2688: {'rank': 47, 'rarity': 'Legendary'},
  2689: {'rank': 7988, 'rarity': 'Common'},
  2690: {'rank': 8809, 'rarity': 'Common'},
  2691: {'rank': 7245, 'rarity': 'Common'},
  2692: {'rank': 6140, 'rarity': 'Common'},
  2693: {'rank': 3545, 'rarity': 'Uncommon'},
  2694: {'rank': 2866, 'rarity': 'Uncommon'},
  2695: {'rank': 9225, 'rarity': 'Common'},
  2696: {'rank': 1187, 'rarity': 'Rare'},
  2697: {'rank': 3726, 'rarity': 'Uncommon'},
  2698: {'rank': 5387, 'rarity': 'Common'},
  2699: {'rank': 8442, 'rarity': 'Common'},
  2700: {'rank': 6481, 'rarity': 'Common'},
  2701: {'rank': 384, 'rarity': 'Epic'},
  2702: {'rank': 8467, 'rarity': 'Common'},
  2703: {'rank': 4573, 'rarity': 'Uncommon'},
  2704: {'rank': 8314, 'rarity': 'Common'},
  2705: {'rank': 7353, 'rarity': 'Common'},
  2706: {'rank': 1684, 'rarity': 'Rare'},
  2707: {'rank': 1153, 'rarity': 'Rare'},
  2708: {'rank': 116, 'rarity': 'Epic'},
  2709: {'rank': 3712, 'rarity': 'Uncommon'},
  2710: {'rank': 5624, 'rarity': 'Common'},
  2711: {'rank': 8150, 'rarity': 'Common'},
  2712: {'rank': 2397, 'rarity': 'Rare'},
  2713: {'rank': 5618, 'rarity': 'Common'},
  2714: {'rank': 8775, 'rarity': 'Common'},
  2715: {'rank': 7873, 'rarity': 'Common'},
  2716: {'rank': 2498, 'rarity': 'Rare'},
  2717: {'rank': 26, 'rarity': 'Legendary'},
  2718: {'rank': 6569, 'rarity': 'Common'},
  2719: {'rank': 8590, 'rarity': 'Common'},
  2720: {'rank': 5732, 'rarity': 'Common'},
  2721: {'rank': 6239, 'rarity': 'Common'},
  2722: {'rank': 2132, 'rarity': 'Rare'},
  2723: {'rank': 8606, 'rarity': 'Common'},
  2724: {'rank': 3038, 'rarity': 'Uncommon'},
  2725: {'rank': 7715, 'rarity': 'Common'},
  2726: {'rank': 7062, 'rarity': 'Common'},
  2727: {'rank': 9741, 'rarity': 'Common'},
  2728: {'rank': 657, 'rarity': 'Epic'},
  2729: {'rank': 602, 'rarity': 'Epic'},
  2730: {'rank': 2576, 'rarity': 'Uncommon'},
  2731: {'rank': 626, 'rarity': 'Epic'},
  2732: {'rank': 603, 'rarity': 'Epic'},
  2733: {'rank': 7133, 'rarity': 'Common'},
  2734: {'rank': 6883, 'rarity': 'Common'},
  2735: {'rank': 9332, 'rarity': 'Common'},
  2736: {'rank': 1510, 'rarity': 'Rare'},
  2737: {'rank': 5376, 'rarity': 'Common'},
  2738: {'rank': 7124, 'rarity': 'Common'},
  2739: {'rank': 8850, 'rarity': 'Common'},
  2740: {'rank': 4099, 'rarity': 'Uncommon'},
  2741: {'rank': 4545, 'rarity': 'Uncommon'},
  2742: {'rank': 1010, 'rarity': 'Rare'},
  2743: {'rank': 4905, 'rarity': 'Uncommon'},
  2744: {'rank': 1785, 'rarity': 'Rare'},
  2745: {'rank': 1240, 'rarity': 'Rare'},
  2746: {'rank': 3646, 'rarity': 'Uncommon'},
  2747: {'rank': 857, 'rarity': 'Epic'},
  2748: {'rank': 9871, 'rarity': 'Common'},
  2749: {'rank': 6623, 'rarity': 'Common'},
  2750: {'rank': 2184, 'rarity': 'Rare'},
  2751: {'rank': 4677, 'rarity': 'Uncommon'},
  2752: {'rank': 4227, 'rarity': 'Uncommon'},
  2753: {'rank': 3858, 'rarity': 'Uncommon'},
  2754: {'rank': 1680, 'rarity': 'Rare'},
  2755: {'rank': 3714, 'rarity': 'Uncommon'},
  2756: {'rank': 517, 'rarity': 'Epic'},
  2757: {'rank': 8508, 'rarity': 'Common'},
  2758: {'rank': 2886, 'rarity': 'Uncommon'},
  2759: {'rank': 9924, 'rarity': 'Common'},
  2760: {'rank': 8789, 'rarity': 'Common'},
  2761: {'rank': 6901, 'rarity': 'Common'},
  2762: {'rank': 6856, 'rarity': 'Common'},
  2763: {'rank': 1501, 'rarity': 'Rare'},
  2764: {'rank': 8346, 'rarity': 'Common'},
  2765: {'rank': 5500, 'rarity': 'Common'},
  2766: {'rank': 7772, 'rarity': 'Common'},
  2767: {'rank': 3903, 'rarity': 'Uncommon'},
  2768: {'rank': 6801, 'rarity': 'Common'},
  2769: {'rank': 5960, 'rarity': 'Common'},
  2770: {'rank': 5321, 'rarity': 'Common'},
  2771: {'rank': 9005, 'rarity': 'Common'},
  2772: {'rank': 4334, 'rarity': 'Uncommon'},
  2773: {'rank': 3664, 'rarity': 'Uncommon'},
  2774: {'rank': 2453, 'rarity': 'Rare'},
  2775: {'rank': 6771, 'rarity': 'Common'},
  2776: {'rank': 8613, 'rarity': 'Common'},
  2777: {'rank': 1726, 'rarity': 'Rare'},
  2778: {'rank': 3172, 'rarity': 'Uncommon'},
  2779: {'rank': 1161, 'rarity': 'Rare'},
  2780: {'rank': 1586, 'rarity': 'Rare'},
  2781: {'rank': 6875, 'rarity': 'Common'},
  2782: {'rank': 3593, 'rarity': 'Uncommon'},
  2783: {'rank': 4538, 'rarity': 'Uncommon'},
  2784: {'rank': 8400, 'rarity': 'Common'},
  2785: {'rank': 776, 'rarity': 'Epic'},
  2786: {'rank': 8265, 'rarity': 'Common'},
  2787: {'rank': 7548, 'rarity': 'Common'},
  2788: {'rank': 4991, 'rarity': 'Uncommon'},
  2789: {'rank': 3060, 'rarity': 'Uncommon'},
  2790: {'rank': 3534, 'rarity': 'Uncommon'},
  2791: {'rank': 9987, 'rarity': 'Common'},
  2792: {'rank': 1150, 'rarity': 'Rare'},
  2793: {'rank': 79, 'rarity': 'Legendary'},
  2794: {'rank': 6044, 'rarity': 'Common'},
  2795: {'rank': 5161, 'rarity': 'Common'},
  2796: {'rank': 3615, 'rarity': 'Uncommon'},
  2797: {'rank': 5287, 'rarity': 'Common'},
  2798: {'rank': 3323, 'rarity': 'Uncommon'},
  2799: {'rank': 3685, 'rarity': 'Uncommon'},
  2800: {'rank': 9535, 'rarity': 'Common'},
  2801: {'rank': 1138, 'rarity': 'Rare'},
  2802: {'rank': 1357, 'rarity': 'Rare'},
  2803: {'rank': 4535, 'rarity': 'Uncommon'},
  2804: {'rank': 5514, 'rarity': 'Common'},
  2805: {'rank': 510, 'rarity': 'Epic'},
  2806: {'rank': 8719, 'rarity': 'Common'},
  2807: {'rank': 8489, 'rarity': 'Common'},
  2808: {'rank': 6485, 'rarity': 'Common'},
  2809: {'rank': 5130, 'rarity': 'Common'},
  2810: {'rank': 765, 'rarity': 'Epic'},
  2811: {'rank': 6793, 'rarity': 'Common'},
  2812: {'rank': 840, 'rarity': 'Epic'},
  2813: {'rank': 5499, 'rarity': 'Common'},
  2814: {'rank': 9926, 'rarity': 'Common'},
  2815: {'rank': 6182, 'rarity': 'Common'},
  2816: {'rank': 2592, 'rarity': 'Uncommon'},
  2817: {'rank': 1832, 'rarity': 'Rare'},
  2818: {'rank': 5001, 'rarity': 'Common'},
  2819: {'rank': 3901, 'rarity': 'Uncommon'},
  2820: {'rank': 7577, 'rarity': 'Common'},
  2821: {'rank': 3970, 'rarity': 'Uncommon'},
  2822: {'rank': 1374, 'rarity': 'Rare'},
  2823: {'rank': 6849, 'rarity': 'Common'},
  2824: {'rank': 3190, 'rarity': 'Uncommon'},
  2825: {'rank': 5086, 'rarity': 'Common'},
  2826: {'rank': 1288, 'rarity': 'Rare'},
  2827: {'rank': 3927, 'rarity': 'Uncommon'},
  2828: {'rank': 3865, 'rarity': 'Uncommon'},
  2829: {'rank': 7694, 'rarity': 'Common'},
  2830: {'rank': 2185, 'rarity': 'Rare'},
  2831: {'rank': 3125, 'rarity': 'Uncommon'},
  2832: {'rank': 255, 'rarity': 'Epic'},
  2833: {'rank': 3186, 'rarity': 'Uncommon'},
  2834: {'rank': 4456, 'rarity': 'Uncommon'},
  2835: {'rank': 2475, 'rarity': 'Rare'},
  2836: {'rank': 563, 'rarity': 'Epic'},
  2837: {'rank': 660, 'rarity': 'Epic'},
  2838: {'rank': 5225, 'rarity': 'Common'},
  2839: {'rank': 3830, 'rarity': 'Uncommon'},
  2840: {'rank': 3354, 'rarity': 'Uncommon'},
  2841: {'rank': 3047, 'rarity': 'Uncommon'},
  2842: {'rank': 8340, 'rarity': 'Common'},
  2843: {'rank': 4518, 'rarity': 'Uncommon'},
  2844: {'rank': 5266, 'rarity': 'Common'},
  2845: {'rank': 2808, 'rarity': 'Uncommon'},
  2846: {'rank': 24, 'rarity': 'Legendary'},
  2847: {'rank': 7350, 'rarity': 'Common'},
  2848: {'rank': 3340, 'rarity': 'Uncommon'},
  2849: {'rank': 3583, 'rarity': 'Uncommon'},
  2850: {'rank': 7613, 'rarity': 'Common'},
  2851: {'rank': 4824, 'rarity': 'Uncommon'},
  2852: {'rank': 5007, 'rarity': 'Common'},
  2853: {'rank': 7904, 'rarity': 'Common'},
  2854: {'rank': 414, 'rarity': 'Epic'},
  2855: {'rank': 524, 'rarity': 'Epic'},
  2856: {'rank': 2586, 'rarity': 'Uncommon'},
  2857: {'rank': 9991, 'rarity': 'Common'},
  2858: {'rank': 4382, 'rarity': 'Uncommon'},
  2859: {'rank': 6550, 'rarity': 'Common'},
  2860: {'rank': 3140, 'rarity': 'Uncommon'},
  2861: {'rank': 174, 'rarity': 'Epic'},
  2862: {'rank': 4748, 'rarity': 'Uncommon'},
  2863: {'rank': 9420, 'rarity': 'Common'},
  2864: {'rank': 5999, 'rarity': 'Common'},
  2865: {'rank': 407, 'rarity': 'Epic'},
  2866: {'rank': 2837, 'rarity': 'Uncommon'},
  2867: {'rank': 1563, 'rarity': 'Rare'},
  2868: {'rank': 9672, 'rarity': 'Common'},
  2869: {'rank': 2025, 'rarity': 'Rare'},
  2870: {'rank': 3585, 'rarity': 'Uncommon'},
  2871: {'rank': 8128, 'rarity': 'Common'},
  2872: {'rank': 3035, 'rarity': 'Uncommon'},
  2873: {'rank': 9709, 'rarity': 'Common'},
  2874: {'rank': 6999, 'rarity': 'Common'},
  2875: {'rank': 7427, 'rarity': 'Common'},
  2876: {'rank': 3021, 'rarity': 'Uncommon'},
  2877: {'rank': 5745, 'rarity': 'Common'},
  2878: {'rank': 3730, 'rarity': 'Uncommon'},
  2879: {'rank': 7391, 'rarity': 'Common'},
  2880: {'rank': 5049, 'rarity': 'Common'},
  2881: {'rank': 2470, 'rarity': 'Rare'},
  2882: {'rank': 598, 'rarity': 'Epic'},
  2883: {'rank': 5739, 'rarity': 'Common'},
  2884: {'rank': 4882, 'rarity': 'Uncommon'},
  2885: {'rank': 723, 'rarity': 'Epic'},
  2886: {'rank': 9180, 'rarity': 'Common'},
  2887: {'rank': 1475, 'rarity': 'Rare'},
  2888: {'rank': 9290, 'rarity': 'Common'},
  2889: {'rank': 564, 'rarity': 'Epic'},
  2890: {'rank': 4796, 'rarity': 'Uncommon'},
  2891: {'rank': 9077, 'rarity': 'Common'},
  2892: {'rank': 1047, 'rarity': 'Rare'},
  2893: {'rank': 309, 'rarity': 'Epic'},
  2894: {'rank': 9606, 'rarity': 'Common'},
  2895: {'rank': 2305, 'rarity': 'Rare'},
  2896: {'rank': 3629, 'rarity': 'Uncommon'},
  2897: {'rank': 6680, 'rarity': 'Common'},
  2898: {'rank': 5803, 'rarity': 'Common'},
  2899: {'rank': 505, 'rarity': 'Epic'},
  2900: {'rank': 245, 'rarity': 'Epic'},
  2901: {'rank': 8369, 'rarity': 'Common'},
  2902: {'rank': 5359, 'rarity': 'Common'},
  2903: {'rank': 708, 'rarity': 'Epic'},
  2904: {'rank': 5914, 'rarity': 'Common'},
  2905: {'rank': 6027, 'rarity': 'Common'},
  2906: {'rank': 7714, 'rarity': 'Common'},
  2907: {'rank': 5498, 'rarity': 'Common'},
  2908: {'rank': 1165, 'rarity': 'Rare'},
  2909: {'rank': 1227, 'rarity': 'Rare'},
  2910: {'rank': 762, 'rarity': 'Epic'},
  2911: {'rank': 7599, 'rarity': 'Common'},
  2912: {'rank': 5893, 'rarity': 'Common'},
  2913: {'rank': 9986, 'rarity': 'Common'},
  2914: {'rank': 4689, 'rarity': 'Uncommon'},
  2915: {'rank': 4118, 'rarity': 'Uncommon'},
  2916: {'rank': 663, 'rarity': 'Epic'},
  2917: {'rank': 9215, 'rarity': 'Common'},
  2918: {'rank': 7162, 'rarity': 'Common'},
  2919: {'rank': 8838, 'rarity': 'Common'},
  2920: {'rank': 2424, 'rarity': 'Rare'},
  2921: {'rank': 5084, 'rarity': 'Common'},
  2922: {'rank': 1705, 'rarity': 'Rare'},
  2923: {'rank': 3915, 'rarity': 'Uncommon'},
  2924: {'rank': 9238, 'rarity': 'Common'},
  2925: {'rank': 722, 'rarity': 'Epic'},
  2926: {'rank': 1080, 'rarity': 'Rare'},
  2927: {'rank': 1295, 'rarity': 'Rare'},
  2928: {'rank': 2802, 'rarity': 'Uncommon'},
  2929: {'rank': 2110, 'rarity': 'Rare'},
  2930: {'rank': 827, 'rarity': 'Epic'},
  2931: {'rank': 1596, 'rarity': 'Rare'},
  2932: {'rank': 2204, 'rarity': 'Rare'},
  2933: {'rank': 1979, 'rarity': 'Rare'},
  2934: {'rank': 8046, 'rarity': 'Common'},
  2935: {'rank': 5178, 'rarity': 'Common'},
  2936: {'rank': 9938, 'rarity': 'Common'},
  2937: {'rank': 1233, 'rarity': 'Rare'},
  2938: {'rank': 9610, 'rarity': 'Common'},
  2939: {'rank': 8917, 'rarity': 'Common'},
  2940: {'rank': 1552, 'rarity': 'Rare'},
  2941: {'rank': 1560, 'rarity': 'Rare'},
  2942: {'rank': 9574, 'rarity': 'Common'},
  2943: {'rank': 5963, 'rarity': 'Common'},
  2944: {'rank': 1452, 'rarity': 'Rare'},
  2945: {'rank': 437, 'rarity': 'Epic'},
  2946: {'rank': 9260, 'rarity': 'Common'},
  2947: {'rank': 5613, 'rarity': 'Common'},
  2948: {'rank': 6470, 'rarity': 'Common'},
  2949: {'rank': 4260, 'rarity': 'Uncommon'},
  2950: {'rank': 5856, 'rarity': 'Common'},
  2951: {'rank': 5779, 'rarity': 'Common'},
  2952: {'rank': 9766, 'rarity': 'Common'},
  2953: {'rank': 2288, 'rarity': 'Rare'},
  2954: {'rank': 5214, 'rarity': 'Common'},
  2955: {'rank': 1276, 'rarity': 'Rare'},
  2956: {'rank': 2267, 'rarity': 'Rare'},
  2957: {'rank': 9737, 'rarity': 'Common'},
  2958: {'rank': 761, 'rarity': 'Epic'},
  2959: {'rank': 5898, 'rarity': 'Common'},
  2960: {'rank': 1110, 'rarity': 'Rare'},
  2961: {'rank': 3391, 'rarity': 'Uncommon'},
  2962: {'rank': 5348, 'rarity': 'Common'},
  2963: {'rank': 9120, 'rarity': 'Common'},
  2964: {'rank': 9696, 'rarity': 'Common'},
  2965: {'rank': 8666, 'rarity': 'Common'},
  2966: {'rank': 4063, 'rarity': 'Uncommon'},
  2967: {'rank': 8823, 'rarity': 'Common'},
  2968: {'rank': 2675, 'rarity': 'Uncommon'},
  2969: {'rank': 8554, 'rarity': 'Common'},
  2970: {'rank': 6816, 'rarity': 'Common'},
  2971: {'rank': 643, 'rarity': 'Epic'},
  2972: {'rank': 9879, 'rarity': 'Common'},
  2973: {'rank': 808, 'rarity': 'Epic'},
  2974: {'rank': 1064, 'rarity': 'Rare'},
  2975: {'rank': 5043, 'rarity': 'Common'},
  2976: {'rank': 2436, 'rarity': 'Rare'},
  2977: {'rank': 6898, 'rarity': 'Common'},
  2978: {'rank': 9824, 'rarity': 'Common'},
  2979: {'rank': 9568, 'rarity': 'Common'},
  2980: {'rank': 9302, 'rarity': 'Common'},
  2981: {'rank': 8916, 'rarity': 'Common'},
  2982: {'rank': 5415, 'rarity': 'Common'},
  2983: {'rank': 6287, 'rarity': 'Common'},
  2984: {'rank': 6124, 'rarity': 'Common'},
  2985: {'rank': 5396, 'rarity': 'Common'},
  2986: {'rank': 8462, 'rarity': 'Common'},
  2987: {'rank': 9188, 'rarity': 'Common'},
  2988: {'rank': 1232, 'rarity': 'Rare'},
  2989: {'rank': 1330, 'rarity': 'Rare'},
  2990: {'rank': 5657, 'rarity': 'Common'},
  2991: {'rank': 9271, 'rarity': 'Common'},
  2992: {'rank': 4370, 'rarity': 'Uncommon'},
  2993: {'rank': 588, 'rarity': 'Epic'},
  2994: {'rank': 2479, 'rarity': 'Rare'},
  2995: {'rank': 1769, 'rarity': 'Rare'},
  2996: {'rank': 557, 'rarity': 'Epic'},
  2997: {'rank': 8052, 'rarity': 'Common'},
  2998: {'rank': 7522, 'rarity': 'Common'},
  2999: {'rank': 5212, 'rarity': 'Common'},
  3000: {'rank': 895, 'rarity': 'Epic'},
  3001: {'rank': 5993, 'rarity': 'Common'},
  3002: {'rank': 659, 'rarity': 'Epic'},
  3003: {'rank': 239, 'rarity': 'Epic'},
  3004: {'rank': 8586, 'rarity': 'Common'},
  3005: {'rank': 5686, 'rarity': 'Common'},
  3006: {'rank': 220, 'rarity': 'Epic'},
  3007: {'rank': 3239, 'rarity': 'Uncommon'},
  3008: {'rank': 2781, 'rarity': 'Uncommon'},
  3009: {'rank': 112, 'rarity': 'Epic'},
  3010: {'rank': 98, 'rarity': 'Legendary'},
  3011: {'rank': 4290, 'rarity': 'Uncommon'},
  3012: {'rank': 9178, 'rarity': 'Common'},
  3013: {'rank': 5671, 'rarity': 'Common'},
  3014: {'rank': 6472, 'rarity': 'Common'},
  3015: {'rank': 9283, 'rarity': 'Common'},
  3016: {'rank': 4371, 'rarity': 'Uncommon'},
  3017: {'rank': 4097, 'rarity': 'Uncommon'},
  3018: {'rank': 8379, 'rarity': 'Common'},
  3019: {'rank': 6242, 'rarity': 'Common'},
  3020: {'rank': 949, 'rarity': 'Epic'},
  3021: {'rank': 1743, 'rarity': 'Rare'},
  3022: {'rank': 4901, 'rarity': 'Uncommon'},
  3023: {'rank': 8129, 'rarity': 'Common'},
  3024: {'rank': 8074, 'rarity': 'Common'},
  3025: {'rank': 5078, 'rarity': 'Common'},
  3026: {'rank': 4988, 'rarity': 'Uncommon'},
  3027: {'rank': 4821, 'rarity': 'Uncommon'},
  3028: {'rank': 7219, 'rarity': 'Common'},
  3029: {'rank': 8039, 'rarity': 'Common'},
  3030: {'rank': 948, 'rarity': 'Epic'},
  3031: {'rank': 8148, 'rarity': 'Common'},
  3032: {'rank': 6686, 'rarity': 'Common'},
  3033: {'rank': 3252, 'rarity': 'Uncommon'},
  3034: {'rank': 2328, 'rarity': 'Rare'},
  3035: {'rank': 7386, 'rarity': 'Common'},
  3036: {'rank': 4917, 'rarity': 'Uncommon'},
  3037: {'rank': 7295, 'rarity': 'Common'},
  3038: {'rank': 5689, 'rarity': 'Common'},
  3039: {'rank': 4836, 'rarity': 'Uncommon'},
  3040: {'rank': 2487, 'rarity': 'Rare'},
  3041: {'rank': 2991, 'rarity': 'Uncommon'},
  3042: {'rank': 4061, 'rarity': 'Uncommon'},
  3043: {'rank': 3236, 'rarity': 'Uncommon'},
  3044: {'rank': 9441, 'rarity': 'Common'},
  3045: {'rank': 2624, 'rarity': 'Uncommon'},
  3046: {'rank': 1842, 'rarity': 'Rare'},
  3047: {'rank': 4758, 'rarity': 'Uncommon'},
  3048: {'rank': 3609, 'rarity': 'Uncommon'},
  3049: {'rank': 976, 'rarity': 'Epic'},
  3050: {'rank': 9169, 'rarity': 'Common'},
  3051: {'rank': 6794, 'rarity': 'Common'},
  3052: {'rank': 7749, 'rarity': 'Common'},
  3053: {'rank': 593, 'rarity': 'Epic'},
  3054: {'rank': 9802, 'rarity': 'Common'},
  3055: {'rank': 2486, 'rarity': 'Rare'},
  3056: {'rank': 8261, 'rarity': 'Common'},
  3057: {'rank': 6712, 'rarity': 'Common'},
  3058: {'rank': 5843, 'rarity': 'Common'},
  3059: {'rank': 4628, 'rarity': 'Uncommon'},
  3060: {'rank': 3672, 'rarity': 'Uncommon'},
  3061: {'rank': 6557, 'rarity': 'Common'},
  3062: {'rank': 1377, 'rarity': 'Rare'},
  3063: {'rank': 8760, 'rarity': 'Common'},
  3064: {'rank': 2214, 'rarity': 'Rare'},
  3065: {'rank': 8240, 'rarity': 'Common'},
  3066: {'rank': 1068, 'rarity': 'Rare'},
  3067: {'rank': 140, 'rarity': 'Epic'},
  3068: {'rank': 1932, 'rarity': 'Rare'},
  3069: {'rank': 7227, 'rarity': 'Common'},
  3070: {'rank': 5589, 'rarity': 'Common'},
  3071: {'rank': 9378, 'rarity': 'Common'},
  3072: {'rank': 4170, 'rarity': 'Uncommon'},
  3073: {'rank': 4004, 'rarity': 'Uncommon'},
  3074: {'rank': 5420, 'rarity': 'Common'},
  3075: {'rank': 3466, 'rarity': 'Uncommon'},
  3076: {'rank': 2075, 'rarity': 'Rare'},
  3077: {'rank': 912, 'rarity': 'Epic'},
  3078: {'rank': 2518, 'rarity': 'Uncommon'},
  3079: {'rank': 3649, 'rarity': 'Uncommon'},
  3080: {'rank': 3756, 'rarity': 'Uncommon'},
  3081: {'rank': 4282, 'rarity': 'Uncommon'},
  3082: {'rank': 2635, 'rarity': 'Uncommon'},
  3083: {'rank': 4360, 'rarity': 'Uncommon'},
  3084: {'rank': 1245, 'rarity': 'Rare'},
  3085: {'rank': 8600, 'rarity': 'Common'},
  3086: {'rank': 4875, 'rarity': 'Uncommon'},
  3087: {'rank': 3911, 'rarity': 'Uncommon'},
  3088: {'rank': 5832, 'rarity': 'Common'},
  3089: {'rank': 5003, 'rarity': 'Common'},
  3090: {'rank': 2334, 'rarity': 'Rare'},
  3091: {'rank': 1420, 'rarity': 'Rare'},
  3092: {'rank': 2948, 'rarity': 'Uncommon'},
  3093: {'rank': 3625, 'rarity': 'Uncommon'},
  3094: {'rank': 7939, 'rarity': 'Common'},
  3095: {'rank': 7989, 'rarity': 'Common'},
  3096: {'rank': 1484, 'rarity': 'Rare'},
  3097: {'rank': 8860, 'rarity': 'Common'},
  3098: {'rank': 8697, 'rarity': 'Common'},
  3099: {'rank': 2289, 'rarity': 'Rare'},
  3100: {'rank': 7140, 'rarity': 'Common'},
  3101: {'rank': 399, 'rarity': 'Epic'},
  3102: {'rank': 5270, 'rarity': 'Common'},
  3103: {'rank': 9015, 'rarity': 'Common'},
  3104: {'rank': 9138, 'rarity': 'Common'},
  3105: {'rank': 9962, 'rarity': 'Common'},
  3106: {'rank': 748, 'rarity': 'Epic'},
  3107: {'rank': 3428, 'rarity': 'Uncommon'},
  3108: {'rank': 3226, 'rarity': 'Uncommon'},
  3109: {'rank': 803, 'rarity': 'Epic'},
  3110: {'rank': 7675, 'rarity': 'Common'},
  3111: {'rank': 2917, 'rarity': 'Uncommon'},
  3112: {'rank': 6956, 'rarity': 'Common'},
  3113: {'rank': 1054, 'rarity': 'Rare'},
  3114: {'rank': 8875, 'rarity': 'Common'},
  3115: {'rank': 1751, 'rarity': 'Rare'},
  3116: {'rank': 103, 'rarity': 'Epic'},
  3117: {'rank': 4930, 'rarity': 'Uncommon'},
  3118: {'rank': 3621, 'rarity': 'Uncommon'},
  3119: {'rank': 8551, 'rarity': 'Common'},
  3120: {'rank': 7084, 'rarity': 'Common'},
  3121: {'rank': 3069, 'rarity': 'Uncommon'},
  3122: {'rank': 402, 'rarity': 'Epic'},
  3123: {'rank': 8957, 'rarity': 'Common'},
  3124: {'rank': 1237, 'rarity': 'Rare'},
  3125: {'rank': 2104, 'rarity': 'Rare'},
  3126: {'rank': 5105, 'rarity': 'Common'},
  3127: {'rank': 3084, 'rarity': 'Uncommon'},
  3128: {'rank': 8484, 'rarity': 'Common'},
  3129: {'rank': 3220, 'rarity': 'Uncommon'},
  3130: {'rank': 8201, 'rarity': 'Common'},
  3131: {'rank': 4164, 'rarity': 'Uncommon'},
  3132: {'rank': 4365, 'rarity': 'Uncommon'},
  3133: {'rank': 8164, 'rarity': 'Common'},
  3134: {'rank': 1580, 'rarity': 'Rare'},
  3135: {'rank': 2637, 'rarity': 'Uncommon'},
  3136: {'rank': 1945, 'rarity': 'Rare'},
  3137: {'rank': 5360, 'rarity': 'Common'},
  3138: {'rank': 7876, 'rarity': 'Common'},
  3139: {'rank': 6830, 'rarity': 'Common'},
  3140: {'rank': 2199, 'rarity': 'Rare'},
  3141: {'rank': 8637, 'rarity': 'Common'},
  3142: {'rank': 8709, 'rarity': 'Common'},
  3143: {'rank': 1104, 'rarity': 'Rare'},
  3144: {'rank': 4786, 'rarity': 'Uncommon'},
  3145: {'rank': 6045, 'rarity': 'Common'},
  3146: {'rank': 5383, 'rarity': 'Common'},
  3147: {'rank': 4042, 'rarity': 'Uncommon'},
  3148: {'rank': 4483, 'rarity': 'Uncommon'},
  3149: {'rank': 6590, 'rarity': 'Common'},
  3150: {'rank': 2216, 'rarity': 'Rare'},
  3151: {'rank': 8426, 'rarity': 'Common'},
  3152: {'rank': 7518, 'rarity': 'Common'},
  3153: {'rank': 8445, 'rarity': 'Common'},
  3154: {'rank': 6524, 'rarity': 'Common'},
  3155: {'rank': 5668, 'rarity': 'Common'},
  3156: {'rank': 3159, 'rarity': 'Uncommon'},
  3157: {'rank': 4326, 'rarity': 'Uncommon'},
  3158: {'rank': 7949, 'rarity': 'Common'},
  3159: {'rank': 8845, 'rarity': 'Common'},
  3160: {'rank': 5059, 'rarity': 'Common'},
  3161: {'rank': 9757, 'rarity': 'Common'},
  3162: {'rank': 5690, 'rarity': 'Common'},
  3163: {'rank': 5132, 'rarity': 'Common'},
  3164: {'rank': 4089, 'rarity': 'Uncommon'},
  3165: {'rank': 7444, 'rarity': 'Common'},
  3166: {'rank': 1649, 'rarity': 'Rare'},
  3167: {'rank': 2135, 'rarity': 'Rare'},
  3168: {'rank': 6594, 'rarity': 'Common'},
  3169: {'rank': 3423, 'rarity': 'Uncommon'},
  3170: {'rank': 8167, 'rarity': 'Common'},
  3171: {'rank': 6088, 'rarity': 'Common'},
  3172: {'rank': 5366, 'rarity': 'Common'},
  3173: {'rank': 1386, 'rarity': 'Rare'},
  3174: {'rank': 4284, 'rarity': 'Uncommon'},
  3175: {'rank': 1228, 'rarity': 'Rare'},
  3176: {'rank': 2699, 'rarity': 'Uncommon'},
  3177: {'rank': 7211, 'rarity': 'Common'},
  3178: {'rank': 8700, 'rarity': 'Common'},
  3179: {'rank': 6193, 'rarity': 'Common'},
  3180: {'rank': 9694, 'rarity': 'Common'},
  3181: {'rank': 2875, 'rarity': 'Uncommon'},
  3182: {'rank': 4962, 'rarity': 'Uncommon'},
  3183: {'rank': 8598, 'rarity': 'Common'},
  3184: {'rank': 8812, 'rarity': 'Common'},
  3185: {'rank': 4287, 'rarity': 'Uncommon'},
  3186: {'rank': 8196, 'rarity': 'Common'},
  3187: {'rank': 2573, 'rarity': 'Uncommon'},
  3188: {'rank': 3346, 'rarity': 'Uncommon'},
  3189: {'rank': 4746, 'rarity': 'Uncommon'},
  3190: {'rank': 2087, 'rarity': 'Rare'},
  3191: {'rank': 2726, 'rarity': 'Uncommon'},
  3192: {'rank': 6308, 'rarity': 'Common'},
  3193: {'rank': 5687, 'rarity': 'Common'},
  3194: {'rank': 9920, 'rarity': 'Common'},
  3195: {'rank': 3762, 'rarity': 'Uncommon'},
  3196: {'rank': 2625, 'rarity': 'Uncommon'},
  3197: {'rank': 1772, 'rarity': 'Rare'},
  3198: {'rank': 1886, 'rarity': 'Rare'},
  3199: {'rank': 9504, 'rarity': 'Common'},
  3200: {'rank': 8381, 'rarity': 'Common'},
  3201: {'rank': 9782, 'rarity': 'Common'},
  3202: {'rank': 1626, 'rarity': 'Rare'},
  3203: {'rank': 8755, 'rarity': 'Common'},
  3204: {'rank': 4408, 'rarity': 'Uncommon'},
  3205: {'rank': 3010, 'rarity': 'Uncommon'},
  3206: {'rank': 468, 'rarity': 'Epic'},
  3207: {'rank': 491, 'rarity': 'Epic'},
  3208: {'rank': 4279, 'rarity': 'Uncommon'},
  3209: {'rank': 7917, 'rarity': 'Common'},
  3210: {'rank': 8317, 'rarity': 'Common'},
  3211: {'rank': 2736, 'rarity': 'Uncommon'},
  3212: {'rank': 6354, 'rarity': 'Common'},
  3213: {'rank': 8543, 'rarity': 'Common'},
  3214: {'rank': 8109, 'rarity': 'Common'},
  3215: {'rank': 5036, 'rarity': 'Common'},
  3216: {'rank': 2201, 'rarity': 'Rare'},
  3217: {'rank': 3177, 'rarity': 'Uncommon'},
  3218: {'rank': 6776, 'rarity': 'Common'},
  3219: {'rank': 8773, 'rarity': 'Common'},
  3220: {'rank': 3055, 'rarity': 'Uncommon'},
  3221: {'rank': 5753, 'rarity': 'Common'},
  3222: {'rank': 9946, 'rarity': 'Common'},
  3223: {'rank': 8490, 'rarity': 'Common'},
  3224: {'rank': 5403, 'rarity': 'Common'},
  3225: {'rank': 5029, 'rarity': 'Common'},
  3226: {'rank': 7688, 'rarity': 'Common'},
  3227: {'rank': 2081, 'rarity': 'Rare'},
  3228: {'rank': 448, 'rarity': 'Epic'},
  3229: {'rank': 6617, 'rarity': 'Common'},
  3230: {'rank': 6564, 'rarity': 'Common'},
  3231: {'rank': 2437, 'rarity': 'Rare'},
  3232: {'rank': 4953, 'rarity': 'Uncommon'},
  3233: {'rank': 9275, 'rarity': 'Common'},
  3234: {'rank': 7264, 'rarity': 'Common'},
  3235: {'rank': 9884, 'rarity': 'Common'},
  3236: {'rank': 5831, 'rarity': 'Common'},
  3237: {'rank': 815, 'rarity': 'Epic'},
  3238: {'rank': 8310, 'rarity': 'Common'},
  3239: {'rank': 9863, 'rarity': 'Common'},
  3240: {'rank': 4041, 'rarity': 'Uncommon'},
  3241: {'rank': 2016, 'rarity': 'Rare'},
  3242: {'rank': 5202, 'rarity': 'Common'},
  3243: {'rank': 1044, 'rarity': 'Rare'},
  3244: {'rank': 6965, 'rarity': 'Common'},
  3245: {'rank': 9070, 'rarity': 'Common'},
  3246: {'rank': 9135, 'rarity': 'Common'},
  3247: {'rank': 3442, 'rarity': 'Uncommon'},
  3248: {'rank': 9815, 'rarity': 'Common'},
  3249: {'rank': 1802, 'rarity': 'Rare'},
  3250: {'rank': 3325, 'rarity': 'Uncommon'},
  3251: {'rank': 5250, 'rarity': 'Common'},
  3252: {'rank': 9193, 'rarity': 'Common'},
  3253: {'rank': 5375, 'rarity': 'Common'},
  3254: {'rank': 998, 'rarity': 'Epic'},
  3255: {'rank': 1538, 'rarity': 'Rare'},
  3256: {'rank': 2955, 'rarity': 'Uncommon'},
  3257: {'rank': 4516, 'rarity': 'Uncommon'},
  3258: {'rank': 2539, 'rarity': 'Uncommon'},
  3259: {'rank': 1944, 'rarity': 'Rare'},
  3260: {'rank': 9509, 'rarity': 'Common'},
  3261: {'rank': 3052, 'rarity': 'Uncommon'},
  3262: {'rank': 5335, 'rarity': 'Common'},
  3263: {'rank': 7775, 'rarity': 'Common'},
  3264: {'rank': 1804, 'rarity': 'Rare'},
  3265: {'rank': 5153, 'rarity': 'Common'},
  3266: {'rank': 4868, 'rarity': 'Uncommon'},
  3267: {'rank': 3750, 'rarity': 'Uncommon'},
  3268: {'rank': 7122, 'rarity': 'Common'},
  3269: {'rank': 356, 'rarity': 'Epic'},
  3270: {'rank': 8377, 'rarity': 'Common'},
  3271: {'rank': 3778, 'rarity': 'Uncommon'},
  3272: {'rank': 6787, 'rarity': 'Common'},
  3273: {'rank': 5565, 'rarity': 'Common'},
  3274: {'rank': 9645, 'rarity': 'Common'},
  3275: {'rank': 1252, 'rarity': 'Rare'},
  3276: {'rank': 5489, 'rarity': 'Common'},
  3277: {'rank': 7991, 'rarity': 'Common'},
  3278: {'rank': 6324, 'rarity': 'Common'},
  3279: {'rank': 9172, 'rarity': 'Common'},
  3280: {'rank': 6143, 'rarity': 'Common'},
  3281: {'rank': 6112, 'rarity': 'Common'},
  3282: {'rank': 7342, 'rarity': 'Common'},
  3283: {'rank': 9629, 'rarity': 'Common'},
  3284: {'rank': 1092, 'rarity': 'Rare'},
  3285: {'rank': 8192, 'rarity': 'Common'},
  3286: {'rank': 3670, 'rarity': 'Uncommon'},
  3287: {'rank': 2911, 'rarity': 'Uncommon'},
  3288: {'rank': 9048, 'rarity': 'Common'},
  3289: {'rank': 6402, 'rarity': 'Common'},
  3290: {'rank': 8921, 'rarity': 'Common'},
  3291: {'rank': 8772, 'rarity': 'Common'},
  3292: {'rank': 4013, 'rarity': 'Uncommon'},
  3293: {'rank': 4646, 'rarity': 'Uncommon'},
  3294: {'rank': 351, 'rarity': 'Epic'},
  3295: {'rank': 6654, 'rarity': 'Common'},
  3296: {'rank': 9622, 'rarity': 'Common'},
  3297: {'rank': 1666, 'rarity': 'Rare'},
  3298: {'rank': 8742, 'rarity': 'Common'},
  3299: {'rank': 8099, 'rarity': 'Common'},
  3300: {'rank': 105, 'rarity': 'Epic'},
  3301: {'rank': 4093, 'rarity': 'Uncommon'},
  3302: {'rank': 9466, 'rarity': 'Common'},
  3303: {'rank': 5405, 'rarity': 'Common'},
  3304: {'rank': 6141, 'rarity': 'Common'},
  3305: {'rank': 1938, 'rarity': 'Rare'},
  3306: {'rank': 8338, 'rarity': 'Common'},
  3307: {'rank': 5404, 'rarity': 'Common'},
  3308: {'rank': 5941, 'rarity': 'Common'},
  3309: {'rank': 4032, 'rarity': 'Uncommon'},
  3310: {'rank': 3250, 'rarity': 'Uncommon'},
  3311: {'rank': 450, 'rarity': 'Epic'},
  3312: {'rank': 8655, 'rarity': 'Common'},
  3313: {'rank': 4374, 'rarity': 'Uncommon'},
  3314: {'rank': 5883, 'rarity': 'Common'},
  3315: {'rank': 1399, 'rarity': 'Rare'},
  3316: {'rank': 6185, 'rarity': 'Common'},
  3317: {'rank': 6691, 'rarity': 'Common'},
  3318: {'rank': 217, 'rarity': 'Epic'},
  3319: {'rank': 2089, 'rarity': 'Rare'},
  3320: {'rank': 5541, 'rarity': 'Common'},
  3321: {'rank': 7157, 'rarity': 'Common'},
  3322: {'rank': 7493, 'rarity': 'Common'},
  3323: {'rank': 5162, 'rarity': 'Common'},
  3324: {'rank': 3754, 'rarity': 'Uncommon'},
  3325: {'rank': 2273, 'rarity': 'Rare'},
  3326: {'rank': 4716, 'rarity': 'Uncommon'},
  3327: {'rank': 867, 'rarity': 'Epic'},
  3328: {'rank': 7776, 'rarity': 'Common'},
  3329: {'rank': 8889, 'rarity': 'Common'},
  3330: {'rank': 7883, 'rarity': 'Common'},
  3331: {'rank': 9618, 'rarity': 'Common'},
  3332: {'rank': 3695, 'rarity': 'Uncommon'},
  3333: {'rank': 2580, 'rarity': 'Uncommon'},
  3334: {'rank': 9539, 'rarity': 'Common'},
  3335: {'rank': 1167, 'rarity': 'Rare'},
  3336: {'rank': 619, 'rarity': 'Epic'},
  3337: {'rank': 6364, 'rarity': 'Common'},
  3338: {'rank': 1766, 'rarity': 'Rare'},
  3339: {'rank': 1014, 'rarity': 'Rare'},
  3340: {'rank': 873, 'rarity': 'Epic'},
  3341: {'rank': 2795, 'rarity': 'Uncommon'},
  3342: {'rank': 7117, 'rarity': 'Common'},
  3343: {'rank': 2345, 'rarity': 'Rare'},
  3344: {'rank': 2804, 'rarity': 'Uncommon'},
  3345: {'rank': 4056, 'rarity': 'Uncommon'},
  3346: {'rank': 8308, 'rarity': 'Common'},
  3347: {'rank': 3130, 'rarity': 'Uncommon'},
  3348: {'rank': 3443, 'rarity': 'Uncommon'},
  3349: {'rank': 5538, 'rarity': 'Common'},
  3350: {'rank': 3790, 'rarity': 'Uncommon'},
  3351: {'rank': 5606, 'rarity': 'Common'},
  3352: {'rank': 3811, 'rarity': 'Uncommon'},
  3353: {'rank': 9213, 'rarity': 'Common'},
  3354: {'rank': 4000, 'rarity': 'Uncommon'},
  3355: {'rank': 4742, 'rarity': 'Uncommon'},
  3356: {'rank': 3724, 'rarity': 'Uncommon'},
  3357: {'rank': 1058, 'rarity': 'Rare'},
  3358: {'rank': 680, 'rarity': 'Epic'},
  3359: {'rank': 5453, 'rarity': 'Common'},
  3360: {'rank': 4494, 'rarity': 'Uncommon'},
  3361: {'rank': 8263, 'rarity': 'Common'},
  3362: {'rank': 8847, 'rarity': 'Common'},
  3363: {'rank': 3549, 'rarity': 'Uncommon'},
  3364: {'rank': 4292, 'rarity': 'Uncommon'},
  3365: {'rank': 4595, 'rarity': 'Uncommon'},
  3366: {'rank': 7660, 'rarity': 'Common'},
  3367: {'rank': 451, 'rarity': 'Epic'},
  3368: {'rank': 6726, 'rarity': 'Common'},
  3369: {'rank': 3309, 'rarity': 'Uncommon'},
  3370: {'rank': 6626, 'rarity': 'Common'},
  3371: {'rank': 9643, 'rarity': 'Common'},
  3372: {'rank': 3153, 'rarity': 'Uncommon'},
  3373: {'rank': 3365, 'rarity': 'Uncommon'},
  3374: {'rank': 3328, 'rarity': 'Uncommon'},
  3375: {'rank': 3527, 'rarity': 'Uncommon'},
  3376: {'rank': 1516, 'rarity': 'Rare'},
  3377: {'rank': 4229, 'rarity': 'Uncommon'},
  3378: {'rank': 1822, 'rarity': 'Rare'},
  3379: {'rank': 2080, 'rarity': 'Rare'},
  3380: {'rank': 1652, 'rarity': 'Rare'},
  3381: {'rank': 5656, 'rarity': 'Common'},
  3382: {'rank': 5223, 'rarity': 'Common'},
  3383: {'rank': 8992, 'rarity': 'Common'},
  3384: {'rank': 3986, 'rarity': 'Uncommon'},
  3385: {'rank': 3333, 'rarity': 'Uncommon'},
  3386: {'rank': 8712, 'rarity': 'Common'},
  3387: {'rank': 1506, 'rarity': 'Rare'},
  3388: {'rank': 6933, 'rarity': 'Common'},
  3389: {'rank': 6175, 'rarity': 'Common'},
  3390: {'rank': 5305, 'rarity': 'Common'},
  3391: {'rank': 4934, 'rarity': 'Uncommon'},
  3392: {'rank': 2174, 'rarity': 'Rare'},
  3393: {'rank': 6722, 'rarity': 'Common'},
  3394: {'rank': 7677, 'rarity': 'Common'},
  3395: {'rank': 1973, 'rarity': 'Rare'},
  3396: {'rank': 9101, 'rarity': 'Common'},
  3397: {'rank': 2292, 'rarity': 'Rare'},
  3398: {'rank': 985, 'rarity': 'Epic'},
  3399: {'rank': 8578, 'rarity': 'Common'},
  3400: {'rank': 9990, 'rarity': 'Common'},
  3401: {'rank': 3194, 'rarity': 'Uncommon'},
  3402: {'rank': 2582, 'rarity': 'Uncommon'},
  3403: {'rank': 8066, 'rarity': 'Common'},
  3404: {'rank': 8186, 'rarity': 'Common'},
  3405: {'rank': 5480, 'rarity': 'Common'},
  3406: {'rank': 4383, 'rarity': 'Uncommon'},
  3407: {'rank': 2798, 'rarity': 'Uncommon'},
  3408: {'rank': 7616, 'rarity': 'Common'},
  3409: {'rank': 6226, 'rarity': 'Common'},
  3410: {'rank': 3228, 'rarity': 'Uncommon'},
  3411: {'rank': 1057, 'rarity': 'Rare'},
  3412: {'rank': 6240, 'rarity': 'Common'},
  3413: {'rank': 7702, 'rarity': 'Common'},
  3414: {'rank': 69, 'rarity': 'Legendary'},
  3415: {'rank': 5973, 'rarity': 'Common'},
  3416: {'rank': 2937, 'rarity': 'Uncommon'},
  3417: {'rank': 6886, 'rarity': 'Common'},
  3418: {'rank': 8222, 'rarity': 'Common'},
  3419: {'rank': 6107, 'rarity': 'Common'},
  3420: {'rank': 1198, 'rarity': 'Rare'},
  3421: {'rank': 5262, 'rarity': 'Common'},
  3422: {'rank': 5597, 'rarity': 'Common'},
  3423: {'rank': 2620, 'rarity': 'Uncommon'},
  3424: {'rank': 5186, 'rarity': 'Common'},
  3425: {'rank': 1746, 'rarity': 'Rare'},
  3426: {'rank': 1189, 'rarity': 'Rare'},
  3427: {'rank': 6067, 'rarity': 'Common'},
  3428: {'rank': 2194, 'rarity': 'Rare'},
  3429: {'rank': 4706, 'rarity': 'Uncommon'},
  3430: {'rank': 3632, 'rarity': 'Uncommon'},
  3431: {'rank': 2943, 'rarity': 'Uncommon'},
  3432: {'rank': 4351, 'rarity': 'Uncommon'},
  3433: {'rank': 2692, 'rarity': 'Uncommon'},
  3434: {'rank': 919, 'rarity': 'Epic'},
  3435: {'rank': 9304, 'rarity': 'Common'},
  3436: {'rank': 8561, 'rarity': 'Common'},
  3437: {'rank': 9429, 'rarity': 'Common'},
  3438: {'rank': 5712, 'rarity': 'Common'},
  3439: {'rank': 5815, 'rarity': 'Common'},
  3440: {'rank': 7604, 'rarity': 'Common'},
  3441: {'rank': 1114, 'rarity': 'Rare'},
  3442: {'rank': 4458, 'rarity': 'Uncommon'},
  3443: {'rank': 755, 'rarity': 'Epic'},
  3444: {'rank': 1777, 'rarity': 'Rare'},
  3445: {'rank': 2583, 'rarity': 'Uncommon'},
  3446: {'rank': 5800, 'rarity': 'Common'},
  3447: {'rank': 3189, 'rarity': 'Uncommon'},
  3448: {'rank': 2951, 'rarity': 'Uncommon'},
  3449: {'rank': 469, 'rarity': 'Epic'},
  3450: {'rank': 375, 'rarity': 'Epic'},
  3451: {'rank': 4529, 'rarity': 'Uncommon'},
  3452: {'rank': 8634, 'rarity': 'Common'},
  3453: {'rank': 5958, 'rarity': 'Common'},
  3454: {'rank': 4129, 'rarity': 'Uncommon'},
  3455: {'rank': 2900, 'rarity': 'Uncommon'},
  3456: {'rank': 3716, 'rarity': 'Uncommon'},
  3457: {'rank': 1909, 'rarity': 'Rare'},
  3458: {'rank': 2646, 'rarity': 'Uncommon'},
  3459: {'rank': 9988, 'rarity': 'Common'},
  3460: {'rank': 2490, 'rarity': 'Rare'},
  3461: {'rank': 5569, 'rarity': 'Common'},
  3462: {'rank': 9839, 'rarity': 'Common'},
  3463: {'rank': 911, 'rarity': 'Epic'},
  3464: {'rank': 2566, 'rarity': 'Uncommon'},
  3465: {'rank': 2452, 'rarity': 'Rare'},
  3466: {'rank': 8244, 'rarity': 'Common'},
  3467: {'rank': 1837, 'rarity': 'Rare'},
  3468: {'rank': 8643, 'rarity': 'Common'},
  3469: {'rank': 3823, 'rarity': 'Uncommon'},
  3470: {'rank': 8020, 'rarity': 'Common'},
  3471: {'rank': 1863, 'rarity': 'Rare'},
  3472: {'rank': 7272, 'rarity': 'Common'},
  3473: {'rank': 9820, 'rarity': 'Common'},
  3474: {'rank': 9317, 'rarity': 'Common'},
  3475: {'rank': 2777, 'rarity': 'Uncommon'},
  3476: {'rank': 5786, 'rarity': 'Common'},
  3477: {'rank': 6797, 'rarity': 'Common'},
  3478: {'rank': 4895, 'rarity': 'Uncommon'},
  3479: {'rank': 8683, 'rarity': 'Common'},
  3480: {'rank': 1787, 'rarity': 'Rare'},
  3481: {'rank': 8219, 'rarity': 'Common'},
  3482: {'rank': 9267, 'rarity': 'Common'},
  3483: {'rank': 1798, 'rarity': 'Rare'},
  3484: {'rank': 4998, 'rarity': 'Uncommon'},
  3485: {'rank': 4006, 'rarity': 'Uncommon'},
  3486: {'rank': 6525, 'rarity': 'Common'},
  3487: {'rank': 9224, 'rarity': 'Common'},
  3488: {'rank': 9830, 'rarity': 'Common'},
  3489: {'rank': 646, 'rarity': 'Epic'},
  3490: {'rank': 3964, 'rarity': 'Uncommon'},
  3491: {'rank': 4008, 'rarity': 'Uncommon'},
  3492: {'rank': 114, 'rarity': 'Epic'},
  3493: {'rank': 7132, 'rarity': 'Common'},
  3494: {'rank': 8210, 'rarity': 'Common'},
  3495: {'rank': 9567, 'rarity': 'Common'},
  3496: {'rank': 7452, 'rarity': 'Common'},
  3497: {'rank': 7720, 'rarity': 'Common'},
  3498: {'rank': 7458, 'rarity': 'Common'},
  3499: {'rank': 1999, 'rarity': 'Rare'},
  3500: {'rank': 9637, 'rarity': 'Common'},
  3501: {'rank': 547, 'rarity': 'Epic'},
  3502: {'rank': 6881, 'rarity': 'Common'},
  3503: {'rank': 8565, 'rarity': 'Common'},
  3504: {'rank': 9954, 'rarity': 'Common'},
  3505: {'rank': 4985, 'rarity': 'Uncommon'},
  3506: {'rank': 4514, 'rarity': 'Uncommon'},
  3507: {'rank': 4845, 'rarity': 'Uncommon'},
  3508: {'rank': 6238, 'rarity': 'Common'},
  3509: {'rank': 8533, 'rarity': 'Common'},
  3510: {'rank': 8684, 'rarity': 'Common'},
  3511: {'rank': 7492, 'rarity': 'Common'},
  3512: {'rank': 9328, 'rarity': 'Common'},
  3513: {'rank': 8852, 'rarity': 'Common'},
  3514: {'rank': 4622, 'rarity': 'Uncommon'},
  3515: {'rank': 2877, 'rarity': 'Uncommon'},
  3516: {'rank': 3914, 'rarity': 'Uncommon'},
  3517: {'rank': 2071, 'rarity': 'Rare'},
  3518: {'rank': 1503, 'rarity': 'Rare'},
  3519: {'rank': 276, 'rarity': 'Epic'},
  3520: {'rank': 1106, 'rarity': 'Rare'},
  3521: {'rank': 6618, 'rarity': 'Common'},
  3522: {'rank': 7874, 'rarity': 'Common'},
  3523: {'rank': 3432, 'rarity': 'Uncommon'},
  3524: {'rank': 421, 'rarity': 'Epic'},
  3525: {'rank': 5684, 'rarity': 'Common'},
  3526: {'rank': 55, 'rarity': 'Legendary'},
  3527: {'rank': 1866, 'rarity': 'Rare'},
  3528: {'rank': 2440, 'rarity': 'Rare'},
  3529: {'rank': 7032, 'rarity': 'Common'},
  3530: {'rank': 2743, 'rarity': 'Uncommon'},
  3531: {'rank': 9661, 'rarity': 'Common'},
  3532: {'rank': 5208, 'rarity': 'Common'},
  3533: {'rank': 8008, 'rarity': 'Common'},
  3534: {'rank': 7863, 'rarity': 'Common'},
  3535: {'rank': 7057, 'rarity': 'Common'},
  3536: {'rank': 7148, 'rarity': 'Common'},
  3537: {'rank': 4177, 'rarity': 'Uncommon'},
  3538: {'rank': 1497, 'rarity': 'Rare'},
  3539: {'rank': 5461, 'rarity': 'Common'},
  3540: {'rank': 1912, 'rarity': 'Rare'},
  3541: {'rank': 2916, 'rarity': 'Uncommon'},
  3542: {'rank': 5213, 'rarity': 'Common'},
  3543: {'rank': 4775, 'rarity': 'Uncommon'},
  3544: {'rank': 7195, 'rarity': 'Common'},
  3545: {'rank': 6800, 'rarity': 'Common'},
  3546: {'rank': 1950, 'rarity': 'Rare'},
  3547: {'rank': 2085, 'rarity': 'Rare'},
  3548: {'rank': 672, 'rarity': 'Epic'},
  3549: {'rank': 3624, 'rarity': 'Uncommon'},
  3550: {'rank': 5806, 'rarity': 'Common'},
  3551: {'rank': 9347, 'rarity': 'Common'},
  3552: {'rank': 9727, 'rarity': 'Common'},
  3553: {'rank': 101, 'rarity': 'Epic'},
  3554: {'rank': 4385, 'rarity': 'Uncommon'},
  3555: {'rank': 8203, 'rarity': 'Common'},
  3556: {'rank': 6734, 'rarity': 'Common'},
  3557: {'rank': 3199, 'rarity': 'Uncommon'},
  3558: {'rank': 3836, 'rarity': 'Uncommon'},
  3559: {'rank': 6918, 'rarity': 'Common'},
  3560: {'rank': 5891, 'rarity': 'Common'},
  3561: {'rank': 542, 'rarity': 'Epic'},
  3562: {'rank': 4080, 'rarity': 'Uncommon'},
  3563: {'rank': 2466, 'rarity': 'Rare'},
  3564: {'rank': 6948, 'rarity': 'Common'},
  3565: {'rank': 1819, 'rarity': 'Rare'},
  3566: {'rank': 4111, 'rarity': 'Uncommon'},
  3567: {'rank': 2203, 'rarity': 'Rare'},
  3568: {'rank': 4818, 'rarity': 'Uncommon'},
  3569: {'rank': 7442, 'rarity': 'Common'},
  3570: {'rank': 6279, 'rarity': 'Common'},
  3571: {'rank': 8212, 'rarity': 'Common'},
  3572: {'rank': 8743, 'rarity': 'Common'},
  3573: {'rank': 6036, 'rarity': 'Common'},
  3574: {'rank': 4157, 'rarity': 'Uncommon'},
  3575: {'rank': 6135, 'rarity': 'Common'},
  3576: {'rank': 2589, 'rarity': 'Uncommon'},
  3577: {'rank': 4763, 'rarity': 'Uncommon'},
  3578: {'rank': 6113, 'rarity': 'Common'},
  3579: {'rank': 9157, 'rarity': 'Common'},
  3580: {'rank': 247, 'rarity': 'Epic'},
  3581: {'rank': 1406, 'rarity': 'Rare'},
  3582: {'rank': 8620, 'rarity': 'Common'},
  3583: {'rank': 4993, 'rarity': 'Uncommon'},
  3584: {'rank': 8944, 'rarity': 'Common'},
  3585: {'rank': 235, 'rarity': 'Epic'},
  3586: {'rank': 3020, 'rarity': 'Uncommon'},
  3587: {'rank': 9470, 'rarity': 'Common'},
  3588: {'rank': 5476, 'rarity': 'Common'},
  3589: {'rank': 2484, 'rarity': 'Rare'},
  3590: {'rank': 1619, 'rarity': 'Rare'},
  3591: {'rank': 8307, 'rarity': 'Common'},
  3592: {'rank': 6321, 'rarity': 'Common'},
  3593: {'rank': 3072, 'rarity': 'Uncommon'},
  3594: {'rank': 9876, 'rarity': 'Common'},
  3595: {'rank': 9415, 'rarity': 'Common'},
  3596: {'rank': 7233, 'rarity': 'Common'},
  3597: {'rank': 7395, 'rarity': 'Common'},
  3598: {'rank': 42, 'rarity': 'Legendary'},
  3599: {'rank': 9045, 'rarity': 'Common'},
  3600: {'rank': 1485, 'rarity': 'Rare'},
  3601: {'rank': 4242, 'rarity': 'Uncommon'},
  3602: {'rank': 6440, 'rarity': 'Common'},
  3603: {'rank': 7291, 'rarity': 'Common'},
  3604: {'rank': 3383, 'rarity': 'Uncommon'},
  3605: {'rank': 2691, 'rarity': 'Uncommon'},
  3606: {'rank': 4635, 'rarity': 'Uncommon'},
  3607: {'rank': 1254, 'rarity': 'Rare'},
  3608: {'rank': 5572, 'rarity': 'Common'},
  3609: {'rank': 616, 'rarity': 'Epic'},
  3610: {'rank': 8146, 'rarity': 'Common'},
  3611: {'rank': 3219, 'rarity': 'Uncommon'},
  3612: {'rank': 2664, 'rarity': 'Uncommon'},
  3613: {'rank': 7285, 'rarity': 'Common'},
  3614: {'rank': 1390, 'rarity': 'Rare'},
  3615: {'rank': 1939, 'rarity': 'Rare'},
  3616: {'rank': 4358, 'rarity': 'Uncommon'},
  3617: {'rank': 2564, 'rarity': 'Uncommon'},
  3618: {'rank': 4552, 'rarity': 'Uncommon'},
  3619: {'rank': 4894, 'rarity': 'Uncommon'},
  3620: {'rank': 5218, 'rarity': 'Common'},
  3621: {'rank': 1097, 'rarity': 'Rare'},
  3622: {'rank': 2949, 'rarity': 'Uncommon'},
  3623: {'rank': 8318, 'rarity': 'Common'},
  3624: {'rank': 1270, 'rarity': 'Rare'},
  3625: {'rank': 9272, 'rarity': 'Common'},
  3626: {'rank': 2650, 'rarity': 'Uncommon'},
  3627: {'rank': 9744, 'rarity': 'Common'},
  3628: {'rank': 1514, 'rarity': 'Rare'},
  3629: {'rank': 1900, 'rarity': 'Rare'},
  3630: {'rank': 8165, 'rarity': 'Common'},
  3631: {'rank': 3619, 'rarity': 'Uncommon'},
  3632: {'rank': 7413, 'rarity': 'Common'},
  3633: {'rank': 9711, 'rarity': 'Common'},
  3634: {'rank': 6889, 'rarity': 'Common'},
  3635: {'rank': 6223, 'rarity': 'Common'},
  3636: {'rank': 3281, 'rarity': 'Uncommon'},
  3637: {'rank': 8279, 'rarity': 'Common'},
  3638: {'rank': 9801, 'rarity': 'Common'},
  3639: {'rank': 9550, 'rarity': 'Common'},
  3640: {'rank': 9648, 'rarity': 'Common'},
  3641: {'rank': 5772, 'rarity': 'Common'},
  3642: {'rank': 7254, 'rarity': 'Common'},
  3643: {'rank': 8017, 'rarity': 'Common'},
  3644: {'rank': 8003, 'rarity': 'Common'},
  3645: {'rank': 7622, 'rarity': 'Common'},
  3646: {'rank': 7438, 'rarity': 'Common'},
  3647: {'rank': 4843, 'rarity': 'Uncommon'},
  3648: {'rank': 6116, 'rarity': 'Common'},
  3649: {'rank': 9689, 'rarity': 'Common'},
  3650: {'rank': 4734, 'rarity': 'Uncommon'},
  3651: {'rank': 7724, 'rarity': 'Common'},
  3652: {'rank': 3651, 'rarity': 'Uncommon'},
  3653: {'rank': 9170, 'rarity': 'Common'},
  3654: {'rank': 773, 'rarity': 'Epic'},
  3655: {'rank': 5976, 'rarity': 'Common'},
  3656: {'rank': 9266, 'rarity': 'Common'},
  3657: {'rank': 4083, 'rarity': 'Uncommon'},
  3658: {'rank': 523, 'rarity': 'Epic'},
  3659: {'rank': 584, 'rarity': 'Epic'},
  3660: {'rank': 1348, 'rarity': 'Rare'},
  3661: {'rank': 6907, 'rarity': 'Common'},
  3662: {'rank': 3671, 'rarity': 'Uncommon'},
  3663: {'rank': 7995, 'rarity': 'Common'},
  3664: {'rank': 9343, 'rarity': 'Common'},
  3665: {'rank': 369, 'rarity': 'Epic'},
  3666: {'rank': 2342, 'rarity': 'Rare'},
  3667: {'rank': 7933, 'rarity': 'Common'},
  3668: {'rank': 3182, 'rarity': 'Uncommon'},
  3669: {'rank': 7848, 'rarity': 'Common'},
  3670: {'rank': 4038, 'rarity': 'Uncommon'},
  3671: {'rank': 270, 'rarity': 'Epic'},
  3672: {'rank': 9780, 'rarity': 'Common'},
  3673: {'rank': 9098, 'rarity': 'Common'},
  3674: {'rank': 9586, 'rarity': 'Common'},
  3675: {'rank': 7020, 'rarity': 'Common'},
  3676: {'rank': 248, 'rarity': 'Epic'},
  3677: {'rank': 344, 'rarity': 'Epic'},
  3678: {'rank': 3644, 'rarity': 'Uncommon'},
  3679: {'rank': 2036, 'rarity': 'Rare'},
  3680: {'rank': 5286, 'rarity': 'Common'},
  3681: {'rank': 9004, 'rarity': 'Common'},
  3682: {'rank': 4897, 'rarity': 'Uncommon'},
  3683: {'rank': 8382, 'rarity': 'Common'},
  3684: {'rank': 4586, 'rarity': 'Uncommon'},
  3685: {'rank': 8215, 'rarity': 'Common'},
  3686: {'rank': 961, 'rarity': 'Epic'},
  3687: {'rank': 3704, 'rarity': 'Uncommon'},
  3688: {'rank': 4641, 'rarity': 'Uncommon'},
  3689: {'rank': 8073, 'rarity': 'Common'},
  3690: {'rank': 5338, 'rarity': 'Common'},
  3691: {'rank': 472, 'rarity': 'Epic'},
  3692: {'rank': 8990, 'rarity': 'Common'},
  3693: {'rank': 1500, 'rarity': 'Rare'},
  3694: {'rank': 5380, 'rarity': 'Common'},
  3695: {'rank': 7419, 'rarity': 'Common'},
  3696: {'rank': 3089, 'rarity': 'Uncommon'},
  3697: {'rank': 6282, 'rarity': 'Common'},
  3698: {'rank': 2445, 'rarity': 'Rare'},
  3699: {'rank': 5580, 'rarity': 'Common'},
  3700: {'rank': 7358, 'rarity': 'Common'},
  3701: {'rank': 6627, 'rarity': 'Common'},
  3702: {'rank': 9505, 'rarity': 'Common'},
  3703: {'rank': 8723, 'rarity': 'Common'},
  3704: {'rank': 6731, 'rarity': 'Common'},
  3705: {'rank': 8935, 'rarity': 'Common'},
  3706: {'rank': 3809, 'rarity': 'Uncommon'},
  3707: {'rank': 2694, 'rarity': 'Uncommon'},
  3708: {'rank': 7128, 'rarity': 'Common'},
  3709: {'rank': 4070, 'rarity': 'Uncommon'},
  3710: {'rank': 1847, 'rarity': 'Rare'},
  3711: {'rank': 2348, 'rarity': 'Rare'},
  3712: {'rank': 3864, 'rarity': 'Uncommon'},
  3713: {'rank': 7592, 'rarity': 'Common'},
  3714: {'rank': 4432, 'rarity': 'Uncommon'},
  3715: {'rank': 2658, 'rarity': 'Uncommon'},
  3716: {'rank': 3062, 'rarity': 'Uncommon'},
  3717: {'rank': 869, 'rarity': 'Epic'},
  3718: {'rank': 4911, 'rarity': 'Uncommon'},
  3719: {'rank': 7289, 'rarity': 'Common'},
  3720: {'rank': 3203, 'rarity': 'Uncommon'},
  3721: {'rank': 8271, 'rarity': 'Common'},
  3722: {'rank': 2396, 'rarity': 'Rare'},
  3723: {'rank': 233, 'rarity': 'Epic'},
  3724: {'rank': 3100, 'rarity': 'Uncommon'},
  3725: {'rank': 9678, 'rarity': 'Common'},
  3726: {'rank': 7175, 'rarity': 'Common'},
  3727: {'rank': 8705, 'rarity': 'Common'},
  3728: {'rank': 5247, 'rarity': 'Common'},
  3729: {'rank': 7093, 'rarity': 'Common'},
  3730: {'rank': 3314, 'rarity': 'Uncommon'},
  3731: {'rank': 522, 'rarity': 'Epic'},
  3732: {'rank': 1494, 'rarity': 'Rare'},
  3733: {'rank': 5020, 'rarity': 'Common'},
  3734: {'rank': 3403, 'rarity': 'Uncommon'},
  3735: {'rank': 3896, 'rarity': 'Uncommon'},
  3736: {'rank': 6022, 'rarity': 'Common'},
  3737: {'rank': 423, 'rarity': 'Epic'},
  3738: {'rank': 2169, 'rarity': 'Rare'},
  3739: {'rank': 4131, 'rarity': 'Uncommon'},
  3740: {'rank': 8437, 'rarity': 'Common'},
  3741: {'rank': 589, 'rarity': 'Epic'},
  3742: {'rank': 7782, 'rarity': 'Common'},
  3743: {'rank': 3148, 'rarity': 'Uncommon'},
  3744: {'rank': 8865, 'rarity': 'Common'},
  3745: {'rank': 9674, 'rarity': 'Common'},
  3746: {'rank': 2533, 'rarity': 'Uncommon'},
  3747: {'rank': 1835, 'rarity': 'Rare'},
  3748: {'rank': 4664, 'rarity': 'Uncommon'},
  3749: {'rank': 1302, 'rarity': 'Rare'},
  3750: {'rank': 8159, 'rarity': 'Common'},
  3751: {'rank': 3061, 'rarity': 'Uncommon'},
  3752: {'rank': 6910, 'rarity': 'Common'},
  3753: {'rank': 7738, 'rarity': 'Common'},
  3754: {'rank': 4539, 'rarity': 'Uncommon'},
  3755: {'rank': 8297, 'rarity': 'Common'},
  3756: {'rank': 8154, 'rarity': 'Common'},
  3757: {'rank': 4452, 'rarity': 'Uncommon'},
  3758: {'rank': 8141, 'rarity': 'Common'},
  3759: {'rank': 9616, 'rarity': 'Common'},
  3760: {'rank': 2593, 'rarity': 'Uncommon'},
  3761: {'rank': 6991, 'rarity': 'Common'},
  3762: {'rank': 2422, 'rarity': 'Rare'},
  3763: {'rank': 396, 'rarity': 'Epic'},
  3764: {'rank': 1612, 'rarity': 'Rare'},
  3765: {'rank': 5452, 'rarity': 'Common'},
  3766: {'rank': 3636, 'rarity': 'Uncommon'},
  3767: {'rank': 6005, 'rarity': 'Common'},
  3768: {'rank': 497, 'rarity': 'Epic'},
  3769: {'rank': 7153, 'rarity': 'Common'},
  3770: {'rank': 5509, 'rarity': 'Common'},
  3771: {'rank': 704, 'rarity': 'Epic'},
  3772: {'rank': 5942, 'rarity': 'Common'},
  3773: {'rank': 5000, 'rarity': 'Uncommon'},
  3774: {'rank': 4890, 'rarity': 'Uncommon'},
  3775: {'rank': 795, 'rarity': 'Epic'},
  3776: {'rank': 9827, 'rarity': 'Common'},
  3777: {'rank': 8721, 'rarity': 'Common'},
  3778: {'rank': 5191, 'rarity': 'Common'},
  3779: {'rank': 958, 'rarity': 'Epic'},
  3780: {'rank': 4303, 'rarity': 'Uncommon'},
  3781: {'rank': 6192, 'rarity': 'Common'},
  3782: {'rank': 5122, 'rarity': 'Common'},
  3783: {'rank': 7527, 'rarity': 'Common'},
  3784: {'rank': 3369, 'rarity': 'Uncommon'},
  3785: {'rank': 7600, 'rarity': 'Common'},
  3786: {'rank': 746, 'rarity': 'Epic'},
  3787: {'rank': 9030, 'rarity': 'Common'},
  3788: {'rank': 793, 'rarity': 'Epic'},
  3789: {'rank': 4922, 'rarity': 'Uncommon'},
  3790: {'rank': 4333, 'rarity': 'Uncommon'},
  3791: {'rank': 2417, 'rarity': 'Rare'},
  3792: {'rank': 7496, 'rarity': 'Common'},
  3793: {'rank': 5905, 'rarity': 'Common'},
  3794: {'rank': 4193, 'rarity': 'Uncommon'},
  3795: {'rank': 4958, 'rarity': 'Uncommon'},
  3796: {'rank': 2895, 'rarity': 'Uncommon'},
  3797: {'rank': 7964, 'rarity': 'Common'},
  3798: {'rank': 7534, 'rarity': 'Common'},
  3799: {'rank': 2742, 'rarity': 'Uncommon'},
  3800: {'rank': 7833, 'rarity': 'Common'},
  3801: {'rank': 1962, 'rarity': 'Rare'},
  3802: {'rank': 6370, 'rarity': 'Common'},
  3803: {'rank': 389, 'rarity': 'Epic'},
  3804: {'rank': 8227, 'rarity': 'Common'},
  3805: {'rank': 8830, 'rarity': 'Common'},
  3806: {'rank': 2213, 'rarity': 'Rare'},
  3807: {'rank': 1788, 'rarity': 'Rare'},
  3808: {'rank': 1438, 'rarity': 'Rare'},
  3809: {'rank': 232, 'rarity': 'Epic'},
  3810: {'rank': 1821, 'rarity': 'Rare'},
  3811: {'rank': 6912, 'rarity': 'Common'},
  3812: {'rank': 7774, 'rarity': 'Common'},
  3813: {'rank': 494, 'rarity': 'Epic'},
  3814: {'rank': 470, 'rarity': 'Epic'},
  3815: {'rank': 3501, 'rarity': 'Uncommon'},
  3816: {'rank': 3461, 'rarity': 'Uncommon'},
  3817: {'rank': 975, 'rarity': 'Epic'},
  3818: {'rank': 8711, 'rarity': 'Common'},
  3819: {'rank': 692, 'rarity': 'Epic'},
  3820: {'rank': 4555, 'rarity': 'Uncommon'},
  3821: {'rank': 6577, 'rarity': 'Common'},
  3822: {'rank': 3684, 'rarity': 'Uncommon'},
  3823: {'rank': 1293, 'rarity': 'Rare'},
  3824: {'rank': 585, 'rarity': 'Epic'},
  3825: {'rank': 4556, 'rarity': 'Uncommon'},
  3826: {'rank': 4932, 'rarity': 'Uncommon'},
  3827: {'rank': 8936, 'rarity': 'Common'},
  3828: {'rank': 923, 'rarity': 'Epic'},
  3829: {'rank': 1930, 'rarity': 'Rare'},
  3830: {'rank': 6929, 'rarity': 'Common'},
  3831: {'rank': 5263, 'rarity': 'Common'},
  3832: {'rank': 4713, 'rarity': 'Uncommon'},
  3833: {'rank': 9925, 'rarity': 'Common'},
  3834: {'rank': 592, 'rarity': 'Epic'},
  3835: {'rank': 6416, 'rarity': 'Common'},
  3836: {'rank': 290, 'rarity': 'Epic'},
  3837: {'rank': 8282, 'rarity': 'Common'},
  3838: {'rank': 6081, 'rarity': 'Common'},
  3839: {'rank': 5757, 'rarity': 'Common'},
  3840: {'rank': 1564, 'rarity': 'Rare'},
  3841: {'rank': 8736, 'rarity': 'Common'},
  3842: {'rank': 1579, 'rarity': 'Rare'},
  3843: {'rank': 3133, 'rarity': 'Uncommon'},
  3844: {'rank': 2426, 'rarity': 'Rare'},
  3845: {'rank': 27, 'rarity': 'Legendary'},
  3846: {'rank': 8794, 'rarity': 'Common'},
  3847: {'rank': 6196, 'rarity': 'Common'},
  3848: {'rank': 2427, 'rarity': 'Rare'},
  3849: {'rank': 5931, 'rarity': 'Common'},
  3850: {'rank': 6836, 'rarity': 'Common'},
  3851: {'rank': 5254, 'rarity': 'Common'},
  3852: {'rank': 5562, 'rarity': 'Common'},
  3853: {'rank': 9321, 'rarity': 'Common'},
  3854: {'rank': 340, 'rarity': 'Epic'},
  3855: {'rank': 607, 'rarity': 'Epic'},
  3856: {'rank': 7120, 'rarity': 'Common'},
  3857: {'rank': 465, 'rarity': 'Epic'},
  3858: {'rank': 1280, 'rarity': 'Rare'},
  3859: {'rank': 3397, 'rarity': 'Uncommon'},
  3860: {'rank': 5621, 'rarity': 'Common'},
  3861: {'rank': 3441, 'rarity': 'Uncommon'},
  3862: {'rank': 158, 'rarity': 'Epic'},
  3863: {'rank': 372, 'rarity': 'Epic'},
  3864: {'rank': 1096, 'rarity': 'Rare'},
  3865: {'rank': 3573, 'rarity': 'Uncommon'},
  3866: {'rank': 9883, 'rarity': 'Common'},
  3867: {'rank': 1389, 'rarity': 'Rare'},
  3868: {'rank': 2718, 'rarity': 'Uncommon'},
  3869: {'rank': 8963, 'rarity': 'Common'},
  3870: {'rank': 9353, 'rarity': 'Common'},
  3871: {'rank': 2526, 'rarity': 'Uncommon'},
  3872: {'rank': 8234, 'rarity': 'Common'},
  3873: {'rank': 5381, 'rarity': 'Common'},
  3874: {'rank': 5943, 'rarity': 'Common'},
  3875: {'rank': 9406, 'rarity': 'Common'},
  3876: {'rank': 3630, 'rarity': 'Uncommon'},
  3877: {'rank': 6229, 'rarity': 'Common'},
  3878: {'rank': 8973, 'rarity': 'Common'},
  3879: {'rank': 9777, 'rarity': 'Common'},
  3880: {'rank': 9798, 'rarity': 'Common'},
  3881: {'rank': 9700, 'rarity': 'Common'},
  3882: {'rank': 4039, 'rarity': 'Uncommon'},
  3883: {'rank': 2952, 'rarity': 'Uncommon'},
  3884: {'rank': 3916, 'rarity': 'Uncommon'},
  3885: {'rank': 7109, 'rarity': 'Common'},
  3886: {'rank': 5193, 'rarity': 'Common'},
  3887: {'rank': 7, 'rarity': 'Legendary'},
  3888: {'rank': 4695, 'rarity': 'Uncommon'},
  3889: {'rank': 8283, 'rarity': 'Common'},
  3890: {'rank': 2308, 'rarity': 'Rare'},
  3891: {'rank': 6522, 'rarity': 'Common'},
  3892: {'rank': 6804, 'rarity': 'Common'},
  3893: {'rank': 7065, 'rarity': 'Common'},
  3894: {'rank': 3315, 'rarity': 'Uncommon'},
  3895: {'rank': 3343, 'rarity': 'Uncommon'},
  3896: {'rank': 5633, 'rarity': 'Common'},
  3897: {'rank': 7405, 'rarity': 'Common'},
  3898: {'rank': 6510, 'rarity': 'Common'},
  3899: {'rank': 6288, 'rarity': 'Common'},
  3900: {'rank': 1021, 'rarity': 'Rare'},
  3901: {'rank': 6723, 'rarity': 'Common'},
  3902: {'rank': 5884, 'rarity': 'Common'},
  3903: {'rank': 2485, 'rarity': 'Rare'},
  3904: {'rank': 5450, 'rarity': 'Common'},
  3905: {'rank': 9396, 'rarity': 'Common'},
  3906: {'rank': 3195, 'rarity': 'Uncommon'},
  3907: {'rank': 2801, 'rarity': 'Uncommon'},
  3908: {'rank': 3073, 'rarity': 'Uncommon'},
  3909: {'rank': 1428, 'rarity': 'Rare'},
  3910: {'rank': 8288, 'rarity': 'Common'},
  3911: {'rank': 532, 'rarity': 'Epic'},
  3912: {'rank': 3396, 'rarity': 'Uncommon'},
  3913: {'rank': 6, 'rarity': 'Legendary'},
  3914: {'rank': 6659, 'rarity': 'Common'},
  3915: {'rank': 5596, 'rarity': 'Common'},
  3916: {'rank': 5716, 'rarity': 'Common'},
  3917: {'rank': 2783, 'rarity': 'Uncommon'},
  3918: {'rank': 669, 'rarity': 'Epic'},
  3919: {'rank': 8056, 'rarity': 'Common'},
  3920: {'rank': 606, 'rarity': 'Epic'},
  3921: {'rank': 9125, 'rarity': 'Common'},
  3922: {'rank': 9754, 'rarity': 'Common'},
  3923: {'rank': 1103, 'rarity': 'Rare'},
  3924: {'rank': 9163, 'rarity': 'Common'},
  3925: {'rank': 4698, 'rarity': 'Uncommon'},
  3926: {'rank': 9834, 'rarity': 'Common'},
  3927: {'rank': 1024, 'rarity': 'Rare'},
  3928: {'rank': 970, 'rarity': 'Epic'},
  3929: {'rank': 7787, 'rarity': 'Common'},
  3930: {'rank': 8036, 'rarity': 'Common'},
  3931: {'rank': 8104, 'rarity': 'Common'},
  3932: {'rank': 2833, 'rarity': 'Uncommon'},
  3933: {'rank': 7328, 'rarity': 'Common'},
  3934: {'rank': 1418, 'rarity': 'Rare'},
  3935: {'rank': 2880, 'rarity': 'Uncommon'},
  3936: {'rank': 2652, 'rarity': 'Uncommon'},
  3937: {'rank': 6742, 'rarity': 'Common'},
  3938: {'rank': 4113, 'rarity': 'Uncommon'},
  3939: {'rank': 5545, 'rarity': 'Common'},
  3940: {'rank': 4776, 'rarity': 'Uncommon'},
  3941: {'rank': 9844, 'rarity': 'Common'},
  3942: {'rank': 1870, 'rarity': 'Rare'},
  3943: {'rank': 4481, 'rarity': 'Uncommon'},
  3944: {'rank': 4951, 'rarity': 'Uncommon'},
  3945: {'rank': 5600, 'rarity': 'Common'},
  3946: {'rank': 9089, 'rarity': 'Common'},
  3947: {'rank': 5448, 'rarity': 'Common'},
  3948: {'rank': 5911, 'rarity': 'Common'},
  3949: {'rank': 7889, 'rarity': 'Common'},
  3950: {'rank': 5063, 'rarity': 'Common'},
  3951: {'rank': 7483, 'rarity': 'Common'},
  3952: {'rank': 9022, 'rarity': 'Common'},
  3953: {'rank': 9710, 'rarity': 'Common'},
  3954: {'rank': 5, 'rarity': 'Legendary'},
  3955: {'rank': 7614, 'rarity': 'Common'},
  3956: {'rank': 7352, 'rarity': 'Common'},
  3957: {'rank': 4185, 'rarity': 'Uncommon'},
  3958: {'rank': 3993, 'rarity': 'Uncommon'},
  3959: {'rank': 4451, 'rarity': 'Uncommon'},
  3960: {'rank': 6696, 'rarity': 'Common'},
  3961: {'rank': 4811, 'rarity': 'Uncommon'},
  3962: {'rank': 9462, 'rarity': 'Common'},
  3963: {'rank': 687, 'rarity': 'Epic'},
  3964: {'rank': 5491, 'rarity': 'Common'},
  3965: {'rank': 7895, 'rarity': 'Common'},
  3966: {'rank': 1246, 'rarity': 'Rare'},
  3967: {'rank': 8312, 'rarity': 'Common'},
  3968: {'rank': 2249, 'rarity': 'Rare'},
  3969: {'rank': 8470, 'rarity': 'Common'},
  3970: {'rank': 1685, 'rarity': 'Rare'},
  3971: {'rank': 1942, 'rarity': 'Rare'},
  3972: {'rank': 7971, 'rarity': 'Common'},
  3973: {'rank': 6730, 'rarity': 'Common'},
  3974: {'rank': 594, 'rarity': 'Epic'},
  3975: {'rank': 1109, 'rarity': 'Rare'},
  3976: {'rank': 6859, 'rarity': 'Common'},
  3977: {'rank': 5308, 'rarity': 'Common'},
  3978: {'rank': 7762, 'rarity': 'Common'},
  3979: {'rank': 2996, 'rarity': 'Uncommon'},
  3980: {'rank': 8171, 'rarity': 'Common'},
  3981: {'rank': 2817, 'rarity': 'Uncommon'},
  3982: {'rank': 5240, 'rarity': 'Common'},
  3983: {'rank': 7108, 'rarity': 'Common'},
  3984: {'rank': 6071, 'rarity': 'Common'},
  3985: {'rank': 6255, 'rarity': 'Common'},
  3986: {'rank': 8891, 'rarity': 'Common'},
  3987: {'rank': 6120, 'rarity': 'Common'},
  3988: {'rank': 9817, 'rarity': 'Common'},
  3989: {'rank': 4108, 'rarity': 'Uncommon'},
  3990: {'rank': 5410, 'rarity': 'Common'},
  3991: {'rank': 7532, 'rarity': 'Common'},
  3992: {'rank': 6888, 'rarity': 'Common'},
  3993: {'rank': 168, 'rarity': 'Epic'},
  3994: {'rank': 4460, 'rarity': 'Uncommon'},
  3995: {'rank': 5743, 'rarity': 'Common'},
  3996: {'rank': 6301, 'rarity': 'Common'},
  3997: {'rank': 6414, 'rarity': 'Common'},
  3998: {'rank': 891, 'rarity': 'Epic'},
  3999: {'rank': 7253, 'rarity': 'Common'},
  4000: {'rank': 8120, 'rarity': 'Common'},
  4001: {'rank': 1422, 'rarity': 'Rare'},
  4002: {'rank': 7222, 'rarity': 'Common'},
  4003: {'rank': 4330, 'rarity': 'Uncommon'},
  4004: {'rank': 214, 'rarity': 'Epic'},
  4005: {'rank': 8292, 'rarity': 'Common'},
  4006: {'rank': 9024, 'rarity': 'Common'},
  4007: {'rank': 2158, 'rarity': 'Rare'},
  4008: {'rank': 6123, 'rarity': 'Common'},
  4009: {'rank': 2536, 'rarity': 'Uncommon'},
  4010: {'rank': 9456, 'rarity': 'Common'},
  4011: {'rank': 2297, 'rarity': 'Rare'},
  4012: {'rank': 3966, 'rarity': 'Uncommon'},
  4013: {'rank': 1221, 'rarity': 'Rare'},
  4014: {'rank': 7755, 'rarity': 'Common'},
  4015: {'rank': 9949, 'rarity': 'Common'},
  4016: {'rank': 7924, 'rarity': 'Common'},
  4017: {'rank': 1914, 'rarity': 'Rare'},
  4018: {'rank': 2067, 'rarity': 'Rare'},
  4019: {'rank': 4194, 'rarity': 'Uncommon'},
  4020: {'rank': 779, 'rarity': 'Epic'},
  4021: {'rank': 9803, 'rarity': 'Common'},
  4022: {'rank': 2332, 'rarity': 'Rare'},
  4023: {'rank': 3099, 'rarity': 'Uncommon'},
  4024: {'rank': 2881, 'rarity': 'Uncommon'},
  4025: {'rank': 5334, 'rarity': 'Common'},
  4026: {'rank': 6745, 'rarity': 'Common'},
  4027: {'rank': 7645, 'rarity': 'Common'},
  4028: {'rank': 3191, 'rarity': 'Uncommon'},
  4029: {'rank': 163, 'rarity': 'Epic'},
  4030: {'rank': 811, 'rarity': 'Epic'},
  4031: {'rank': 6215, 'rarity': 'Common'},
  4032: {'rank': 1504, 'rarity': 'Rare'},
  4033: {'rank': 130, 'rarity': 'Epic'},
  4034: {'rank': 9995, 'rarity': 'Common'},
  4035: {'rank': 317, 'rarity': 'Epic'},
  4036: {'rank': 7236, 'rarity': 'Common'},
  4037: {'rank': 877, 'rarity': 'Epic'},
  4038: {'rank': 1439, 'rarity': 'Rare'},
  4039: {'rank': 9313, 'rarity': 'Common'},
  4040: {'rank': 5127, 'rarity': 'Common'},
  4041: {'rank': 2023, 'rarity': 'Rare'},
  4042: {'rank': 5827, 'rarity': 'Common'},
  4043: {'rank': 284, 'rarity': 'Epic'},
  4044: {'rank': 8962, 'rarity': 'Common'},
  4045: {'rank': 9244, 'rarity': 'Common'},
  4046: {'rank': 5751, 'rarity': 'Common'},
  4047: {'rank': 1098, 'rarity': 'Rare'},
  4048: {'rank': 714, 'rarity': 'Epic'},
  4049: {'rank': 6980, 'rarity': 'Common'},
  4050: {'rank': 587, 'rarity': 'Epic'},
  4051: {'rank': 1343, 'rarity': 'Rare'},
  4052: {'rank': 1381, 'rarity': 'Rare'},
  4053: {'rank': 9873, 'rarity': 'Common'},
  4054: {'rank': 2651, 'rarity': 'Uncommon'},
  4055: {'rank': 8209, 'rarity': 'Common'},
  4056: {'rank': 7984, 'rarity': 'Common'},
  4057: {'rank': 5442, 'rarity': 'Common'},
  4058: {'rank': 2992, 'rarity': 'Uncommon'},
  4059: {'rank': 6767, 'rarity': 'Common'},
  4060: {'rank': 21, 'rarity': 'Legendary'},
  4061: {'rank': 1009, 'rarity': 'Rare'},
  4062: {'rank': 7982, 'rarity': 'Common'},
  4063: {'rank': 4954, 'rarity': 'Uncommon'},
  4064: {'rank': 4790, 'rarity': 'Uncommon'},
  4065: {'rank': 9650, 'rarity': 'Common'},
  4066: {'rank': 2608, 'rarity': 'Uncommon'},
  4067: {'rank': 4386, 'rarity': 'Uncommon'},
  4068: {'rank': 1825, 'rarity': 'Rare'},
  4069: {'rank': 339, 'rarity': 'Epic'},
  4070: {'rank': 6814, 'rarity': 'Common'},
  4071: {'rank': 6957, 'rarity': 'Common'},
  4072: {'rank': 6361, 'rarity': 'Common'},
  4073: {'rank': 5866, 'rarity': 'Common'},
  4074: {'rank': 4350, 'rarity': 'Uncommon'},
  4075: {'rank': 2155, 'rarity': 'Rare'},
  4076: {'rank': 2894, 'rarity': 'Uncommon'},
  4077: {'rank': 3707, 'rarity': 'Uncommon'},
  4078: {'rank': 5028, 'rarity': 'Common'},
  4079: {'rank': 9761, 'rarity': 'Common'},
  4080: {'rank': 4569, 'rarity': 'Uncommon'},
  4081: {'rank': 1018, 'rarity': 'Rare'},
  4082: {'rank': 9257, 'rarity': 'Common'},
  4083: {'rank': 7952, 'rarity': 'Common'},
  4084: {'rank': 4611, 'rarity': 'Uncommon'},
  4085: {'rank': 1670, 'rarity': 'Rare'},
  4086: {'rank': 5155, 'rarity': 'Common'},
  4087: {'rank': 7460, 'rarity': 'Common'},
  4088: {'rank': 1706, 'rarity': 'Rare'},
  4089: {'rank': 9912, 'rarity': 'Common'},
  4090: {'rank': 8002, 'rarity': 'Common'},
  4091: {'rank': 956, 'rarity': 'Epic'},
  4092: {'rank': 1067, 'rarity': 'Rare'},
  4093: {'rank': 3595, 'rarity': 'Uncommon'},
  4094: {'rank': 7478, 'rarity': 'Common'},
  4095: {'rank': 8305, 'rarity': 'Common'},
  4096: {'rank': 5789, 'rarity': 'Common'},
  4097: {'rank': 2897, 'rarity': 'Uncommon'},
  4098: {'rank': 3289, 'rarity': 'Uncommon'},
  4099: {'rank': 9448, 'rarity': 'Common'},
  4100: {'rank': 383, 'rarity': 'Epic'},
  4101: {'rank': 8964, 'rarity': 'Common'},
  4102: {'rank': 3185, 'rarity': 'Uncommon'},
  4103: {'rank': 4356, 'rarity': 'Uncommon'},
  4104: {'rank': 4798, 'rarity': 'Uncommon'},
  4105: {'rank': 9636, 'rarity': 'Common'},
  4106: {'rank': 8744, 'rarity': 'Common'},
  4107: {'rank': 2957, 'rarity': 'Uncommon'},
  4108: {'rank': 1615, 'rarity': 'Rare'},
  4109: {'rank': 338, 'rarity': 'Epic'},
  4110: {'rank': 558, 'rarity': 'Epic'},
  4111: {'rank': 5496, 'rarity': 'Common'},
  4112: {'rank': 3829, 'rarity': 'Uncommon'},
  4113: {'rank': 1673, 'rarity': 'Rare'},
  4114: {'rank': 1829, 'rarity': 'Rare'},
  4115: {'rank': 8257, 'rarity': 'Common'},
  4116: {'rank': 4146, 'rarity': 'Uncommon'},
  4117: {'rank': 8810, 'rarity': 'Common'},
  4118: {'rank': 7044, 'rarity': 'Common'},
  4119: {'rank': 5899, 'rarity': 'Common'},
  4120: {'rank': 3142, 'rarity': 'Uncommon'},
  4121: {'rank': 352, 'rarity': 'Epic'},
  4122: {'rank': 8151, 'rarity': 'Common'},
  4123: {'rank': 3717, 'rarity': 'Uncommon'},
  4124: {'rank': 7316, 'rarity': 'Common'},
  4125: {'rank': 9858, 'rarity': 'Common'},
  4126: {'rank': 8353, 'rarity': 'Common'},
  4127: {'rank': 6385, 'rarity': 'Common'},
  4128: {'rank': 1174, 'rarity': 'Rare'},
  4129: {'rank': 1611, 'rarity': 'Rare'},
  4130: {'rank': 2112, 'rarity': 'Rare'},
  4131: {'rank': 7257, 'rarity': 'Common'},
  4132: {'rank': 2829, 'rarity': 'Uncommon'},
  4133: {'rank': 6296, 'rarity': 'Common'},
  4134: {'rank': 1583, 'rarity': 'Rare'},
  4135: {'rank': 706, 'rarity': 'Epic'},
  4136: {'rank': 288, 'rarity': 'Epic'},
  4137: {'rank': 3024, 'rarity': 'Uncommon'},
  4138: {'rank': 2552, 'rarity': 'Uncommon'},
  4139: {'rank': 6821, 'rarity': 'Common'},
  4140: {'rank': 3871, 'rarity': 'Uncommon'},
  4141: {'rank': 3577, 'rarity': 'Uncommon'},
  4142: {'rank': 3802, 'rarity': 'Uncommon'},
  4143: {'rank': 1540, 'rarity': 'Rare'},
  4144: {'rank': 2908, 'rarity': 'Uncommon'},
  4145: {'rank': 3067, 'rarity': 'Uncommon'},
  4146: {'rank': 8571, 'rarity': 'Common'},
  4147: {'rank': 5719, 'rarity': 'Common'},
  4148: {'rank': 5103, 'rarity': 'Common'},
  4149: {'rank': 8679, 'rarity': 'Common'},
  4150: {'rank': 546, 'rarity': 'Epic'},
  4151: {'rank': 8915, 'rarity': 'Common'},
  4152: {'rank': 9285, 'rarity': 'Common'},
  4153: {'rank': 5574, 'rarity': 'Common'},
  4154: {'rank': 7687, 'rarity': 'Common'},
  4155: {'rank': 192, 'rarity': 'Epic'},
  4156: {'rank': 909, 'rarity': 'Epic'},
  4157: {'rank': 6366, 'rarity': 'Common'},
  4158: {'rank': 647, 'rarity': 'Epic'},
  4159: {'rank': 8071, 'rarity': 'Common'},
  4160: {'rank': 6234, 'rarity': 'Common'},
  4161: {'rank': 981, 'rarity': 'Epic'},
  4162: {'rank': 3936, 'rarity': 'Uncommon'},
  4163: {'rank': 148, 'rarity': 'Epic'},
  4164: {'rank': 4296, 'rarity': 'Uncommon'},
  4165: {'rank': 3601, 'rarity': 'Uncommon'},
  4166: {'rank': 9734, 'rarity': 'Common'},
  4167: {'rank': 3444, 'rarity': 'Uncommon'},
  4168: {'rank': 8037, 'rarity': 'Common'},
  4169: {'rank': 576, 'rarity': 'Epic'},
  4170: {'rank': 2415, 'rarity': 'Rare'},
  4171: {'rank': 7306, 'rarity': 'Common'},
  4172: {'rank': 6812, 'rarity': 'Common'},
  4173: {'rank': 1854, 'rarity': 'Rare'},
  4174: {'rank': 419, 'rarity': 'Epic'},
  4175: {'rank': 6935, 'rarity': 'Common'},
  4176: {'rank': 8985, 'rarity': 'Common'},
  4177: {'rank': 1457, 'rarity': 'Rare'},
  4178: {'rank': 9154, 'rarity': 'Common'},
  4179: {'rank': 8612, 'rarity': 'Common'},
  4180: {'rank': 9968, 'rarity': 'Common'},
  4181: {'rank': 1151, 'rarity': 'Rare'},
  4182: {'rank': 6474, 'rarity': 'Common'},
  4183: {'rank': 6477, 'rarity': 'Common'},
  4184: {'rank': 8932, 'rarity': 'Common'},
  4185: {'rank': 9513, 'rarity': 'Common'},
  4186: {'rank': 8829, 'rarity': 'Common'},
  4187: {'rank': 6172, 'rarity': 'Common'},
  4188: {'rank': 4191, 'rarity': 'Uncommon'},
  4189: {'rank': 7379, 'rarity': 'Common'},
  4190: {'rank': 2828, 'rarity': 'Uncommon'},
  4191: {'rank': 8914, 'rarity': 'Common'},
  4192: {'rank': 6340, 'rarity': 'Common'},
  4193: {'rank': 2455, 'rarity': 'Rare'},
  4194: {'rank': 4806, 'rarity': 'Uncommon'},
  4195: {'rank': 4155, 'rarity': 'Uncommon'},
  4196: {'rank': 9588, 'rarity': 'Common'},
  4197: {'rank': 3582, 'rarity': 'Uncommon'},
  4198: {'rank': 3370, 'rarity': 'Uncommon'},
  4199: {'rank': 6270, 'rarity': 'Common'},
  4200: {'rank': 7432, 'rarity': 'Common'},
  4201: {'rank': 6568, 'rarity': 'Common'},
  4202: {'rank': 133, 'rarity': 'Epic'},
  4203: {'rank': 3371, 'rarity': 'Uncommon'},
  4204: {'rank': 2173, 'rarity': 'Rare'},
  4205: {'rank': 7882, 'rarity': 'Common'},
  4206: {'rank': 6681, 'rarity': 'Common'},
  4207: {'rank': 6316, 'rarity': 'Common'},
  4208: {'rank': 3305, 'rarity': 'Uncommon'},
  4209: {'rank': 2962, 'rarity': 'Uncommon'},
  4210: {'rank': 5833, 'rarity': 'Common'},
  4211: {'rank': 8184, 'rarity': 'Common'},
  4212: {'rank': 9543, 'rarity': 'Common'},
  4213: {'rank': 8464, 'rarity': 'Common'},
  4214: {'rank': 6834, 'rarity': 'Common'},
  4215: {'rank': 3469, 'rarity': 'Uncommon'},
  4216: {'rank': 4688, 'rarity': 'Uncommon'},
  4217: {'rank': 1800, 'rarity': 'Rare'},
  4218: {'rank': 2192, 'rarity': 'Rare'},
  4219: {'rank': 6326, 'rarity': 'Common'},
  4220: {'rank': 8665, 'rarity': 'Common'},
  4221: {'rank': 4736, 'rarity': 'Uncommon'},
  4222: {'rank': 6894, 'rarity': 'Common'},
  4223: {'rank': 7713, 'rarity': 'Common'},
  4224: {'rank': 8883, 'rarity': 'Common'},
  4225: {'rank': 9339, 'rarity': 'Common'},
  4226: {'rank': 7203, 'rarity': 'Common'},
  4227: {'rank': 6000, 'rarity': 'Common'},
  4228: {'rank': 4130, 'rarity': 'Uncommon'},
  4229: {'rank': 1959, 'rarity': 'Rare'},
  4230: {'rank': 7872, 'rarity': 'Common'},
  4231: {'rank': 9982, 'rarity': 'Common'},
  4232: {'rank': 541, 'rarity': 'Epic'},
  4233: {'rank': 3453, 'rarity': 'Uncommon'},
  4234: {'rank': 1834, 'rarity': 'Rare'},
  4235: {'rank': 2151, 'rarity': 'Rare'},
  4236: {'rank': 8404, 'rarity': 'Common'},
  4237: {'rank': 9458, 'rarity': 'Common'},
  4238: {'rank': 7753, 'rarity': 'Common'},
  4239: {'rank': 2548, 'rarity': 'Uncommon'},
  4240: {'rank': 482, 'rarity': 'Epic'},
  4241: {'rank': 5419, 'rarity': 'Common'},
  4242: {'rank': 4764, 'rarity': 'Uncommon'},
  4243: {'rank': 2478, 'rarity': 'Rare'},
  4244: {'rank': 3981, 'rarity': 'Uncommon'},
  4245: {'rank': 578, 'rarity': 'Epic'},
  4246: {'rank': 7795, 'rarity': 'Common'},
  4247: {'rank': 5124, 'rarity': 'Common'},
  4248: {'rank': 6208, 'rarity': 'Common'},
  4249: {'rank': 3824, 'rarity': 'Uncommon'},
  4250: {'rank': 1163, 'rarity': 'Rare'},
  4251: {'rank': 8603, 'rarity': 'Common'},
  4252: {'rank': 8352, 'rarity': 'Common'},
  4253: {'rank': 4339, 'rarity': 'Uncommon'},
  4254: {'rank': 428, 'rarity': 'Epic'},
  4255: {'rank': 3747, 'rarity': 'Uncommon'},
  4256: {'rank': 9626, 'rarity': 'Common'},
  4257: {'rank': 8082, 'rarity': 'Common'},
  4258: {'rank': 9526, 'rarity': 'Common'},
  4259: {'rank': 3494, 'rarity': 'Uncommon'},
  4260: {'rank': 3460, 'rarity': 'Uncommon'},
  4261: {'rank': 4532, 'rarity': 'Uncommon'},
  4262: {'rank': 5361, 'rarity': 'Common'},
  4263: {'rank': 7623, 'rarity': 'Common'},
  4264: {'rank': 1675, 'rarity': 'Rare'},
  4265: {'rank': 4682, 'rarity': 'Uncommon'},
  4266: {'rank': 7014, 'rarity': 'Common'},
  4267: {'rank': 8701, 'rarity': 'Common'},
  4268: {'rank': 3288, 'rarity': 'Uncommon'},
  4269: {'rank': 2049, 'rarity': 'Rare'},
  4270: {'rank': 2815, 'rarity': 'Uncommon'},
  4271: {'rank': 1730, 'rarity': 'Rare'},
  4272: {'rank': 2816, 'rarity': 'Uncommon'},
  4273: {'rank': 1989, 'rarity': 'Rare'},
  4274: {'rank': 6960, 'rarity': 'Common'},
  4275: {'rank': 4402, 'rarity': 'Uncommon'},
  4276: {'rank': 411, 'rarity': 'Epic'},
  4277: {'rank': 5344, 'rarity': 'Common'},
  4278: {'rank': 8241, 'rarity': 'Common'},
  4279: {'rank': 8566, 'rarity': 'Common'},
  4280: {'rank': 8182, 'rarity': 'Common'},
  4281: {'rank': 4213, 'rarity': 'Uncommon'},
  4282: {'rank': 8645, 'rarity': 'Common'},
  4283: {'rank': 7626, 'rarity': 'Common'},
  4284: {'rank': 5040, 'rarity': 'Common'},
  4285: {'rank': 3769, 'rarity': 'Uncommon'},
  4286: {'rank': 3980, 'rarity': 'Uncommon'},
  4287: {'rank': 5053, 'rarity': 'Common'},
  4288: {'rank': 1062, 'rarity': 'Rare'},
  4289: {'rank': 359, 'rarity': 'Epic'},
  4290: {'rank': 3940, 'rarity': 'Uncommon'},
  4291: {'rank': 4609, 'rarity': 'Uncommon'},
  4292: {'rank': 9595, 'rarity': 'Common'},
  4293: {'rank': 7520, 'rarity': 'Common'},
  4294: {'rank': 2257, 'rarity': 'Rare'},
  4295: {'rank': 365, 'rarity': 'Epic'},
  4296: {'rank': 2963, 'rarity': 'Uncommon'},
  4297: {'rank': 9392, 'rarity': 'Common'},
  4298: {'rank': 5632, 'rarity': 'Common'},
  4299: {'rank': 2759, 'rarity': 'Uncommon'},
  4300: {'rank': 5345, 'rarity': 'Common'},
  4301: {'rank': 3600, 'rarity': 'Uncommon'},
  4302: {'rank': 6746, 'rarity': 'Common'},
  4303: {'rank': 8451, 'rarity': 'Common'},
  4304: {'rank': 5506, 'rarity': 'Common'},
  4305: {'rank': 3665, 'rarity': 'Uncommon'},
  4306: {'rank': 1435, 'rarity': 'Rare'},
  4307: {'rank': 4221, 'rarity': 'Uncommon'},
  4308: {'rank': 767, 'rarity': 'Epic'},
  4309: {'rank': 3552, 'rarity': 'Uncommon'},
  4310: {'rank': 7916, 'rarity': 'Common'},
  4311: {'rank': 2022, 'rarity': 'Rare'},
  4312: {'rank': 3602, 'rarity': 'Uncommon'},
  4313: {'rank': 4394, 'rarity': 'Uncommon'},
  4314: {'rank': 8833, 'rarity': 'Common'},
  4315: {'rank': 2094, 'rarity': 'Rare'},
  4316: {'rank': 1338, 'rarity': 'Rare'},
  4317: {'rank': 6592, 'rarity': 'Common'},
  4318: {'rank': 6184, 'rarity': 'Common'},
  4319: {'rank': 7798, 'rarity': 'Common'},
  4320: {'rank': 7130, 'rarity': 'Common'},
  4321: {'rank': 7204, 'rarity': 'Common'},
  4322: {'rank': 8493, 'rarity': 'Common'},
  4323: {'rank': 7701, 'rarity': 'Common'},
  4324: {'rank': 4355, 'rarity': 'Uncommon'},
  4325: {'rank': 1663, 'rarity': 'Rare'},
  4326: {'rank': 6150, 'rarity': 'Common'},
  4327: {'rank': 2400, 'rarity': 'Rare'},
  4328: {'rank': 2412, 'rarity': 'Rare'},
  4329: {'rank': 9219, 'rarity': 'Common'},
  4330: {'rank': 3987, 'rarity': 'Uncommon'},
  4331: {'rank': 1055, 'rarity': 'Rare'},
  4332: {'rank': 1392, 'rarity': 'Rare'},
  4333: {'rank': 6718, 'rarity': 'Common'},
  4334: {'rank': 4832, 'rarity': 'Uncommon'},
  4335: {'rank': 9200, 'rarity': 'Common'},
  4336: {'rank': 2654, 'rarity': 'Uncommon'},
  4337: {'rank': 967, 'rarity': 'Epic'},
  4338: {'rank': 5925, 'rarity': 'Common'},
  4339: {'rank': 8605, 'rarity': 'Common'},
  4340: {'rank': 303, 'rarity': 'Epic'},
  4341: {'rank': 6867, 'rarity': 'Common'},
  4342: {'rank': 9231, 'rarity': 'Common'},
  4343: {'rank': 6040, 'rarity': 'Common'},
  4344: {'rank': 3796, 'rarity': 'Uncommon'},
  4345: {'rank': 2871, 'rarity': 'Uncommon'},
  4346: {'rank': 5775, 'rarity': 'Common'},
  4347: {'rank': 7150, 'rarity': 'Common'},
  4348: {'rank': 8623, 'rarity': 'Common'},
  4349: {'rank': 7879, 'rarity': 'Common'},
  4350: {'rank': 7725, 'rarity': 'Common'},
  4351: {'rank': 2234, 'rarity': 'Rare'},
  4352: {'rank': 6521, 'rarity': 'Common'},
  4353: {'rank': 1087, 'rarity': 'Rare'},
  4354: {'rank': 1349, 'rarity': 'Rare'},
  4355: {'rank': 6523, 'rarity': 'Common'},
  4356: {'rank': 1364, 'rarity': 'Rare'},
  4357: {'rank': 1893, 'rarity': 'Rare'},
  4358: {'rank': 1574, 'rarity': 'Rare'},
  4359: {'rank': 8155, 'rarity': 'Common'},
  4360: {'rank': 2527, 'rarity': 'Uncommon'},
  4361: {'rank': 5393, 'rarity': 'Common'},
  4362: {'rank': 2978, 'rarity': 'Uncommon'},
  4363: {'rank': 9097, 'rarity': 'Common'},
  4364: {'rank': 3952, 'rarity': 'Uncommon'},
  4365: {'rank': 4100, 'rarity': 'Uncommon'},
  4366: {'rank': 632, 'rarity': 'Epic'},
  4367: {'rank': 7670, 'rarity': 'Common'},
  4368: {'rank': 861, 'rarity': 'Epic'},
  4369: {'rank': 4637, 'rarity': 'Uncommon'},
  4370: {'rank': 5368, 'rarity': 'Common'},
  4371: {'rank': 4067, 'rarity': 'Uncommon'},
  4372: {'rank': 4322, 'rarity': 'Uncommon'},
  4373: {'rank': 2606, 'rarity': 'Uncommon'},
  4374: {'rank': 7374, 'rarity': 'Common'},
  4375: {'rank': 4217, 'rarity': 'Uncommon'},
  4376: {'rank': 2720, 'rarity': 'Uncommon'},
  4377: {'rank': 5307, 'rarity': 'Common'},
  4378: {'rank': 6952, 'rarity': 'Common'},
  4379: {'rank': 9546, 'rarity': 'Common'},
  4380: {'rank': 7201, 'rarity': 'Common'},
  4381: {'rank': 2265, 'rarity': 'Rare'},
  4382: {'rank': 7083, 'rarity': 'Common'},
  4383: {'rank': 6211, 'rarity': 'Common'},
  4384: {'rank': 4412, 'rarity': 'Uncommon'},
  4385: {'rank': 8513, 'rarity': 'Common'},
  4386: {'rank': 5495, 'rarity': 'Common'},
  4387: {'rank': 9805, 'rarity': 'Common'},
  4388: {'rank': 8230, 'rarity': 'Common'},
  4389: {'rank': 8681, 'rarity': 'Common'},
  4390: {'rank': 1865, 'rarity': 'Rare'},
  4391: {'rank': 3643, 'rarity': 'Uncommon'},
  4392: {'rank': 5165, 'rarity': 'Common'},
  4393: {'rank': 6822, 'rarity': 'Common'},
  4394: {'rank': 6235, 'rarity': 'Common'},
  4395: {'rank': 5184, 'rarity': 'Common'},
  4396: {'rank': 6059, 'rarity': 'Common'},
  4397: {'rank': 9872, 'rarity': 'Common'},
  4398: {'rank': 4850, 'rarity': 'Uncommon'},
  4399: {'rank': 2584, 'rarity': 'Uncommon'},
  4400: {'rank': 2387, 'rarity': 'Rare'},
  4401: {'rank': 9599, 'rarity': 'Common'},
  4402: {'rank': 8289, 'rarity': 'Common'},
  4403: {'rank': 463, 'rarity': 'Epic'},
  4404: {'rank': 5525, 'rarity': 'Common'},
  4405: {'rank': 3599, 'rarity': 'Uncommon'},
  4406: {'rank': 9144, 'rarity': 'Common'},
  4407: {'rank': 7311, 'rarity': 'Common'},
  4408: {'rank': 7420, 'rarity': 'Common'},
  4409: {'rank': 9071, 'rarity': 'Common'},
  4410: {'rank': 4597, 'rarity': 'Uncommon'},
  4411: {'rank': 8121, 'rarity': 'Common'},
  4412: {'rank': 4549, 'rarity': 'Uncommon'},
  4413: {'rank': 6981, 'rarity': 'Common'},
  4414: {'rank': 8480, 'rarity': 'Common'},
  4415: {'rank': 5484, 'rarity': 'Common'},
  4416: {'rank': 8034, 'rarity': 'Common'},
  4417: {'rank': 2560, 'rarity': 'Uncommon'},
  4418: {'rank': 1990, 'rarity': 'Rare'},
  4419: {'rank': 3946, 'rarity': 'Uncommon'},
  4420: {'rank': 151, 'rarity': 'Epic'},
  4421: {'rank': 2123, 'rarity': 'Rare'},
  4422: {'rank': 8001, 'rarity': 'Common'},
  4423: {'rank': 6962, 'rarity': 'Common'},
  4424: {'rank': 8675, 'rarity': 'Common'},
  4425: {'rank': 4503, 'rarity': 'Uncommon'},
  4426: {'rank': 9481, 'rarity': 'Common'},
  4427: {'rank': 5628, 'rarity': 'Common'},
  4428: {'rank': 1774, 'rarity': 'Rare'},
  4429: {'rank': 7363, 'rarity': 'Common'},
  4430: {'rank': 1968, 'rarity': 'Rare'},
  4431: {'rank': 8256, 'rarity': 'Common'},
  4432: {'rank': 260, 'rarity': 'Epic'},
  4433: {'rank': 5664, 'rarity': 'Common'},
  4434: {'rank': 9664, 'rarity': 'Common'},
  4435: {'rank': 4438, 'rarity': 'Uncommon'},
  4436: {'rank': 843, 'rarity': 'Epic'},
  4437: {'rank': 4942, 'rarity': 'Uncommon'},
  4438: {'rank': 6091, 'rarity': 'Common'},
  4439: {'rank': 1699, 'rarity': 'Rare'},
  4440: {'rank': 1617, 'rarity': 'Rare'},
  4441: {'rank': 4896, 'rarity': 'Uncommon'},
  4442: {'rank': 7009, 'rarity': 'Common'},
  4443: {'rank': 3251, 'rarity': 'Uncommon'},
  4444: {'rank': 3922, 'rarity': 'Uncommon'},
  4445: {'rank': 7449, 'rarity': 'Common'},
  4446: {'rank': 5855, 'rarity': 'Common'},
  4447: {'rank': 259, 'rarity': 'Epic'},
  4448: {'rank': 2822, 'rarity': 'Uncommon'},
  4449: {'rank': 8867, 'rarity': 'Common'},
  4450: {'rank': 5409, 'rarity': 'Common'},
  4451: {'rank': 1826, 'rarity': 'Rare'},
  4452: {'rank': 3540, 'rarity': 'Uncommon'},
  4453: {'rank': 2590, 'rarity': 'Uncommon'},
  4454: {'rank': 6661, 'rarity': 'Common'},
  4455: {'rank': 5156, 'rarity': 'Common'},
  4456: {'rank': 1913, 'rarity': 'Rare'},
  4457: {'rank': 9039, 'rarity': 'Common'},
  4458: {'rank': 4222, 'rarity': 'Uncommon'},
  4459: {'rank': 291, 'rarity': 'Epic'},
  4460: {'rank': 2175, 'rarity': 'Rare'},
  4461: {'rank': 2190, 'rarity': 'Rare'},
  4462: {'rank': 7818, 'rarity': 'Common'},
  4463: {'rank': 8025, 'rarity': 'Common'},
  4464: {'rank': 8088, 'rarity': 'Common'},
  4465: {'rank': 6349, 'rarity': 'Common'},
  4466: {'rank': 9956, 'rarity': 'Common'},
  4467: {'rank': 2521, 'rarity': 'Uncommon'},
  4468: {'rank': 1724, 'rarity': 'Rare'},
  4469: {'rank': 8687, 'rarity': 'Common'},
  4470: {'rank': 1573, 'rarity': 'Rare'},
  4471: {'rank': 2235, 'rarity': 'Rare'},
  4472: {'rank': 6762, 'rarity': 'Common'},
  4473: {'rank': 5215, 'rarity': 'Common'},
  4474: {'rank': 9646, 'rarity': 'Common'},
  4475: {'rank': 6619, 'rarity': 'Common'},
  4476: {'rank': 4699, 'rarity': 'Uncommon'},
  4477: {'rank': 1742, 'rarity': 'Rare'},
  4478: {'rank': 832, 'rarity': 'Epic'},
  4479: {'rank': 3026, 'rarity': 'Uncommon'},
  4480: {'rank': 225, 'rarity': 'Epic'},
  4481: {'rank': 8633, 'rarity': 'Common'},
  4482: {'rank': 8504, 'rarity': 'Common'},
  4483: {'rank': 6209, 'rarity': 'Common'},
  4484: {'rank': 5226, 'rarity': 'Common'},
  4485: {'rank': 2615, 'rarity': 'Uncommon'},
  4486: {'rank': 2274, 'rarity': 'Rare'},
  4487: {'rank': 2940, 'rarity': 'Uncommon'},
  4488: {'rank': 599, 'rarity': 'Epic'},
  4489: {'rank': 5817, 'rarity': 'Common'},
  4490: {'rank': 2301, 'rarity': 'Rare'},
  4491: {'rank': 4150, 'rarity': 'Uncommon'},
  4492: {'rank': 6711, 'rarity': 'Common'},
  4493: {'rank': 781, 'rarity': 'Epic'},
  4494: {'rank': 2084, 'rarity': 'Rare'},
  4495: {'rank': 3163, 'rarity': 'Uncommon'},
  4496: {'rank': 5234, 'rarity': 'Common'},
  4497: {'rank': 1292, 'rarity': 'Rare'},
  4498: {'rank': 5206, 'rarity': 'Common'},
  4499: {'rank': 9578, 'rarity': 'Common'},
  4500: {'rank': 329, 'rarity': 'Epic'},
  4501: {'rank': 1515, 'rarity': 'Rare'},
  4502: {'rank': 4697, 'rarity': 'Uncommon'},
  4503: {'rank': 8947, 'rarity': 'Common'},
  4504: {'rank': 2438, 'rarity': 'Rare'},
  4505: {'rank': 8779, 'rarity': 'Common'},
  4506: {'rank': 561, 'rarity': 'Epic'},
  4507: {'rank': 269, 'rarity': 'Epic'},
  4508: {'rank': 9825, 'rarity': 'Common'},
  4509: {'rank': 925, 'rarity': 'Epic'},
  4510: {'rank': 5479, 'rarity': 'Common'},
  4511: {'rank': 896, 'rarity': 'Epic'},
  4512: {'rank': 4092, 'rarity': 'Uncommon'},
  4513: {'rank': 2069, 'rarity': 'Rare'},
  4514: {'rank': 7217, 'rarity': 'Common'},
  4515: {'rank': 5177, 'rarity': 'Common'},
  4516: {'rank': 1850, 'rarity': 'Rare'},
  4517: {'rank': 9866, 'rarity': 'Common'},
  4518: {'rank': 5329, 'rarity': 'Common'},
  4519: {'rank': 1935, 'rarity': 'Rare'},
  4520: {'rank': 2785, 'rarity': 'Uncommon'},
  4521: {'rank': 7189, 'rarity': 'Common'},
  4522: {'rank': 9656, 'rarity': 'Common'},
  4523: {'rank': 8270, 'rarity': 'Common'},
  4524: {'rank': 2688, 'rarity': 'Uncommon'},
  4525: {'rank': 4802, 'rarity': 'Uncommon'},
  4526: {'rank': 2986, 'rarity': 'Uncommon'},
  4527: {'rank': 3114, 'rarity': 'Uncommon'},
  4528: {'rank': 4226, 'rarity': 'Uncommon'},
  4529: {'rank': 9417, 'rarity': 'Common'},
  4530: {'rank': 9021, 'rarity': 'Common'},
  4531: {'rank': 3961, 'rarity': 'Uncommon'},
  4532: {'rank': 8328, 'rarity': 'Common'},
  4533: {'rank': 8537, 'rarity': 'Common'},
  4534: {'rank': 9916, 'rarity': 'Common'},
  4535: {'rank': 4327, 'rarity': 'Uncommon'},
  4536: {'rank': 6694, 'rarity': 'Common'},
  4537: {'rank': 4860, 'rarity': 'Uncommon'},
  4538: {'rank': 993, 'rarity': 'Epic'},
  4539: {'rank': 1502, 'rarity': 'Rare'},
  4540: {'rank': 6387, 'rarity': 'Common'},
  4541: {'rank': 5346, 'rarity': 'Common'},
  4542: {'rank': 2931, 'rarity': 'Uncommon'},
  4543: {'rank': 6076, 'rarity': 'Common'},
  4544: {'rank': 1046, 'rarity': 'Rare'},
  4545: {'rank': 5922, 'rarity': 'Common'},
  4546: {'rank': 3241, 'rarity': 'Uncommon'},
  4547: {'rank': 1597, 'rarity': 'Rare'},
  4548: {'rank': 3143, 'rarity': 'Uncommon'},
  4549: {'rank': 7147, 'rarity': 'Common'},
  4550: {'rank': 477, 'rarity': 'Epic'},
  4551: {'rank': 5434, 'rarity': 'Common'},
  4552: {'rank': 3150, 'rarity': 'Uncommon'},
  4553: {'rank': 9397, 'rarity': 'Common'},
  4554: {'rank': 2488, 'rarity': 'Rare'},
  4555: {'rank': 625, 'rarity': 'Epic'},
  4556: {'rank': 8235, 'rarity': 'Common'},
  4557: {'rank': 6920, 'rarity': 'Common'},
  4558: {'rank': 4705, 'rarity': 'Uncommon'},
  4559: {'rank': 1599, 'rarity': 'Rare'},
  4560: {'rank': 7928, 'rarity': 'Common'},
  4561: {'rank': 2008, 'rarity': 'Rare'},
  4562: {'rank': 4190, 'rarity': 'Uncommon'},
  4563: {'rank': 8138, 'rarity': 'Common'},
  4564: {'rank': 3300, 'rarity': 'Uncommon'},
  4565: {'rank': 5373, 'rarity': 'Common'},
  4566: {'rank': 2695, 'rarity': 'Uncommon'},
  4567: {'rank': 8421, 'rarity': 'Common'},
  4568: {'rank': 8638, 'rarity': 'Common'},
  4569: {'rank': 1833, 'rarity': 'Rare'},
  4570: {'rank': 6346, 'rarity': 'Common'},
  4571: {'rank': 3405, 'rarity': 'Uncommon'},
  4572: {'rank': 9692, 'rarity': 'Common'},
  4573: {'rank': 2958, 'rarity': 'Uncommon'},
  4574: {'rank': 2164, 'rarity': 'Rare'},
  4575: {'rank': 720, 'rarity': 'Epic'},
  4576: {'rank': 9921, 'rarity': 'Common'},
  4577: {'rank': 1628, 'rarity': 'Rare'},
  4578: {'rank': 4250, 'rarity': 'Uncommon'},
  4579: {'rank': 883, 'rarity': 'Epic'},
  4580: {'rank': 3028, 'rarity': 'Uncommon'},
  4581: {'rank': 9693, 'rarity': 'Common'},
  4582: {'rank': 8905, 'rarity': 'Common'},
  4583: {'rank': 4496, 'rarity': 'Uncommon'},
  4584: {'rank': 3711, 'rarity': 'Uncommon'},
  4585: {'rank': 7960, 'rarity': 'Common'},
  4586: {'rank': 5579, 'rarity': 'Common'},
  4587: {'rank': 2840, 'rarity': 'Uncommon'},
  4588: {'rank': 1505, 'rarity': 'Rare'},
  4589: {'rank': 7344, 'rarity': 'Common'},
  4590: {'rank': 6133, 'rarity': 'Common'},
  4591: {'rank': 1195, 'rarity': 'Rare'},
  4592: {'rank': 2810, 'rarity': 'Uncommon'},
  4593: {'rank': 9085, 'rarity': 'Common'},
  4594: {'rank': 1578, 'rarity': 'Rare'},
  4595: {'rank': 8145, 'rarity': 'Common'},
  4596: {'rank': 996, 'rarity': 'Epic'},
  4597: {'rank': 8274, 'rarity': 'Common'},
  4598: {'rank': 7415, 'rarity': 'Common'},
  4599: {'rank': 8016, 'rarity': 'Common'},
  4600: {'rank': 5933, 'rarity': 'Common'},
  4601: {'rank': 571, 'rarity': 'Epic'},
  4602: {'rank': 1733, 'rarity': 'Rare'},
  4603: {'rank': 885, 'rarity': 'Epic'},
  4604: {'rank': 3037, 'rarity': 'Uncommon'},
  4605: {'rank': 9384, 'rarity': 'Common'},
  4606: {'rank': 9440, 'rarity': 'Common'},
  4607: {'rank': 1370, 'rarity': 'Rare'},
  4608: {'rank': 3154, 'rarity': 'Uncommon'},
  4609: {'rank': 5658, 'rarity': 'Common'},
  4610: {'rank': 6080, 'rarity': 'Common'},
  4611: {'rank': 293, 'rarity': 'Epic'},
  4612: {'rank': 7265, 'rarity': 'Common'},
  4613: {'rank': 5940, 'rarity': 'Common'},
  4614: {'rank': 8677, 'rarity': 'Common'},
  4615: {'rank': 2918, 'rarity': 'Uncommon'},
  4616: {'rank': 4880, 'rarity': 'Uncommon'},
  4617: {'rank': 9708, 'rarity': 'Common'},
  4618: {'rank': 1568, 'rarity': 'Rare'},
  4619: {'rank': 315, 'rarity': 'Epic'},
  4620: {'rank': 2208, 'rarity': 'Rare'},
  4621: {'rank': 7521, 'rarity': 'Common'},
  4622: {'rank': 4043, 'rarity': 'Uncommon'},
  4623: {'rank': 1037, 'rarity': 'Rare'},
  4624: {'rank': 3784, 'rarity': 'Uncommon'},
  4625: {'rank': 7543, 'rarity': 'Common'},
  4626: {'rank': 2534, 'rarity': 'Uncommon'},
  4627: {'rank': 874, 'rarity': 'Epic'},
  4628: {'rank': 8851, 'rarity': 'Common'},
  4629: {'rank': 7018, 'rarity': 'Common'},
  4630: {'rank': 3739, 'rarity': 'Uncommon'},
  4631: {'rank': 2599, 'rarity': 'Uncommon'},
  4632: {'rank': 3005, 'rarity': 'Uncommon'},
  4633: {'rank': 3843, 'rarity': 'Uncommon'},
  4634: {'rank': 1931, 'rarity': 'Rare'},
  4635: {'rank': 3953, 'rarity': 'Uncommon'},
  4636: {'rank': 7618, 'rarity': 'Common'},
  4637: {'rank': 8750, 'rarity': 'Common'},
  4638: {'rank': 6046, 'rarity': 'Common'},
  4639: {'rank': 8608, 'rarity': 'Common'},
  4640: {'rank': 6743, 'rarity': 'Common'},
  4641: {'rank': 8961, 'rarity': 'Common'},
  4642: {'rank': 2325, 'rarity': 'Rare'},
  4643: {'rank': 3188, 'rarity': 'Uncommon'},
  4644: {'rank': 4161, 'rarity': 'Uncommon'},
  4645: {'rank': 6556, 'rarity': 'Common'},
  4646: {'rank': 3438, 'rarity': 'Uncommon'},
  4647: {'rank': 9725, 'rarity': 'Common'},
  4648: {'rank': 8481, 'rarity': 'Common'},
  4649: {'rank': 3262, 'rarity': 'Uncommon'},
  4650: {'rank': 3487, 'rarity': 'Uncommon'},
  4651: {'rank': 7659, 'rarity': 'Common'},
  4652: {'rank': 9899, 'rarity': 'Common'},
  4653: {'rank': 8784, 'rarity': 'Common'},
  4654: {'rank': 3394, 'rarity': 'Uncommon'},
  4655: {'rank': 7751, 'rarity': 'Common'},
  4656: {'rank': 6373, 'rarity': 'Common'},
  4657: {'rank': 1561, 'rarity': 'Rare'},
  4658: {'rank': 9240, 'rarity': 'Common'},
  4659: {'rank': 146, 'rarity': 'Epic'},
  4660: {'rank': 9845, 'rarity': 'Common'},
  4661: {'rank': 5967, 'rarity': 'Common'},
  4662: {'rank': 9904, 'rarity': 'Common'},
  4663: {'rank': 7754, 'rarity': 'Common'},
  4664: {'rank': 4784, 'rarity': 'Uncommon'},
  4665: {'rank': 4575, 'rarity': 'Uncommon'},
  4666: {'rank': 8535, 'rarity': 'Common'},
  4667: {'rank': 3480, 'rarity': 'Uncommon'},
  4668: {'rank': 4558, 'rarity': 'Uncommon'},
  4669: {'rank': 7308, 'rarity': 'Common'},
  4670: {'rank': 5903, 'rarity': 'Common'},
  4671: {'rank': 6658, 'rarity': 'Common'},
  4672: {'rank': 9279, 'rarity': 'Common'},
  4673: {'rank': 3807, 'rarity': 'Uncommon'},
  4674: {'rank': 6535, 'rarity': 'Common'},
  4675: {'rank': 5466, 'rarity': 'Common'},
  4676: {'rank': 7794, 'rarity': 'Common'},
  4677: {'rank': 84, 'rarity': 'Legendary'},
  4678: {'rank': 9676, 'rarity': 'Common'},
  4679: {'rank': 8923, 'rarity': 'Common'},
  4680: {'rank': 6595, 'rarity': 'Common'},
  4681: {'rank': 8114, 'rarity': 'Common'},
  4682: {'rank': 1839, 'rarity': 'Rare'},
  4683: {'rank': 6561, 'rarity': 'Common'},
  4684: {'rank': 2020, 'rarity': 'Rare'},
  4685: {'rank': 1442, 'rarity': 'Rare'},
  4686: {'rank': 8617, 'rarity': 'Common'},
  4687: {'rank': 701, 'rarity': 'Epic'},
  4688: {'rank': 2823, 'rarity': 'Uncommon'},
  4689: {'rank': 9418, 'rarity': 'Common'},
  4690: {'rank': 7825, 'rarity': 'Common'},
  4691: {'rank': 1454, 'rarity': 'Rare'},
  4692: {'rank': 7326, 'rarity': 'Common'},
  4693: {'rank': 9852, 'rarity': 'Common'},
  4694: {'rank': 4912, 'rarity': 'Uncommon'},
  4695: {'rank': 8614, 'rarity': 'Common'},
  4696: {'rank': 3536, 'rarity': 'Uncommon'},
  4697: {'rank': 3660, 'rarity': 'Uncommon'},
  4698: {'rank': 6055, 'rarity': 'Common'},
  4699: {'rank': 5475, 'rarity': 'Common'},
  4700: {'rank': 3335, 'rarity': 'Uncommon'},
  4701: {'rank': 1440, 'rarity': 'Rare'},
  4702: {'rank': 1411, 'rarity': 'Rare'},
  4703: {'rank': 3240, 'rarity': 'Uncommon'},
  4704: {'rank': 3452, 'rarity': 'Uncommon'},
  4705: {'rank': 9800, 'rarity': 'Common'},
  4706: {'rank': 8199, 'rarity': 'Common'},
  4707: {'rank': 4472, 'rarity': 'Uncommon'},
  4708: {'rank': 1315, 'rarity': 'Rare'},
  4709: {'rank': 7292, 'rarity': 'Common'},
  4710: {'rank': 989, 'rarity': 'Epic'},
  4711: {'rank': 4362, 'rarity': 'Uncommon'},
  4712: {'rank': 7221, 'rarity': 'Common'},
  4713: {'rank': 9985, 'rarity': 'Common'},
  4714: {'rank': 2119, 'rarity': 'Rare'},
  4715: {'rank': 868, 'rarity': 'Epic'},
  4716: {'rank': 1269, 'rarity': 'Rare'},
  4717: {'rank': 9983, 'rarity': 'Common'},
  4718: {'rank': 6462, 'rarity': 'Common'},
  4719: {'rank': 741, 'rarity': 'Epic'},
  4720: {'rank': 8640, 'rarity': 'Common'},
  4721: {'rank': 1876, 'rarity': 'Rare'},
  4722: {'rank': 8078, 'rarity': 'Common'},
  4723: {'rank': 318, 'rarity': 'Epic'},
  4724: {'rank': 2276, 'rarity': 'Rare'},
  4725: {'rank': 277, 'rarity': 'Epic'},
  4726: {'rank': 4502, 'rarity': 'Uncommon'},
  4727: {'rank': 1636, 'rarity': 'Rare'},
  4728: {'rank': 3304, 'rarity': 'Uncommon'},
  4729: {'rank': 2211, 'rarity': 'Rare'},
  4730: {'rank': 184, 'rarity': 'Epic'},
  4731: {'rank': 9765, 'rarity': 'Common'},
  4732: {'rank': 5616, 'rarity': 'Common'},
  4733: {'rank': 6978, 'rarity': 'Common'},
  4734: {'rank': 289, 'rarity': 'Epic'},
  4735: {'rank': 4627, 'rarity': 'Uncommon'},
  4736: {'rank': 7288, 'rarity': 'Common'},
  4737: {'rank': 311, 'rarity': 'Epic'},
  4738: {'rank': 2827, 'rarity': 'Uncommon'},
  4739: {'rank': 2349, 'rarity': 'Rare'},
  4740: {'rank': 2483, 'rarity': 'Rare'},
  4741: {'rank': 9602, 'rarity': 'Common'},
  4742: {'rank': 4739, 'rarity': 'Uncommon'},
  4743: {'rank': 5848, 'rarity': 'Common'},
  4744: {'rank': 7840, 'rarity': 'Common'},
  4745: {'rank': 8546, 'rarity': 'Common'},
  4746: {'rank': 5460, 'rarity': 'Common'},
  4747: {'rank': 9585, 'rarity': 'Common'},
  4748: {'rank': 7954, 'rarity': 'Common'},
  4749: {'rank': 3937, 'rarity': 'Uncommon'},
  4750: {'rank': 4166, 'rarity': 'Uncommon'},
  4751: {'rank': 6271, 'rarity': 'Common'},
  4752: {'rank': 1351, 'rarity': 'Rare'},
  4753: {'rank': 76, 'rarity': 'Legendary'},
  4754: {'rank': 3417, 'rarity': 'Uncommon'},
  4755: {'rank': 9133, 'rarity': 'Common'},
  4756: {'rank': 5121, 'rarity': 'Common'},
  4757: {'rank': 1755, 'rarity': 'Rare'},
  4758: {'rank': 9344, 'rarity': 'Common'},
  4759: {'rank': 4459, 'rarity': 'Uncommon'},
  4760: {'rank': 4686, 'rarity': 'Uncommon'},
  4761: {'rank': 8351, 'rarity': 'Common'},
  4762: {'rank': 6655, 'rarity': 'Common'},
  4763: {'rank': 2821, 'rarity': 'Uncommon'},
  4764: {'rank': 6459, 'rarity': 'Common'},
  4765: {'rank': 28, 'rarity': 'Legendary'},
  4766: {'rank': 6500, 'rarity': 'Common'},
  4767: {'rank': 9014, 'rarity': 'Common'},
  4768: {'rank': 3216, 'rarity': 'Uncommon'},
  4769: {'rank': 5591, 'rarity': 'Common'},
  4770: {'rank': 6610, 'rarity': 'Common'},
  4771: {'rank': 9354, 'rarity': 'Common'},
  4772: {'rank': 1861, 'rarity': 'Rare'},
  4773: {'rank': 5733, 'rarity': 'Common'},
  4774: {'rank': 7366, 'rarity': 'Common'},
  4775: {'rank': 3213, 'rarity': 'Uncommon'},
  4776: {'rank': 3285, 'rarity': 'Uncommon'},
  4777: {'rank': 675, 'rarity': 'Epic'},
  4778: {'rank': 4079, 'rarity': 'Uncommon'},
  4779: {'rank': 1459, 'rarity': 'Rare'},
  4780: {'rank': 6802, 'rarity': 'Common'},
  4781: {'rank': 5964, 'rarity': 'Common'},
  4782: {'rank': 4135, 'rarity': 'Uncommon'},
  4783: {'rank': 8882, 'rarity': 'Common'},
  4784: {'rank': 959, 'rarity': 'Epic'},
  4785: {'rank': 2212, 'rarity': 'Rare'},
  4786: {'rank': 8861, 'rarity': 'Common'},
  4787: {'rank': 6671, 'rarity': 'Common'},
  4788: {'rank': 203, 'rarity': 'Epic'},
  4789: {'rank': 7742, 'rarity': 'Common'},
  4790: {'rank': 5283, 'rarity': 'Common'},
  4791: {'rank': 4337, 'rarity': 'Uncommon'},
  4792: {'rank': 5761, 'rarity': 'Common'},
  4793: {'rank': 8952, 'rarity': 'Common'},
  4794: {'rank': 4486, 'rarity': 'Uncommon'},
  4795: {'rank': 3311, 'rarity': 'Uncommon'},
  4796: {'rank': 7963, 'rarity': 'Common'},
  4797: {'rank': 1996, 'rarity': 'Rare'},
  4798: {'rank': 6853, 'rarity': 'Common'},
  4799: {'rank': 9424, 'rarity': 'Common'},
  4800: {'rank': 5788, 'rarity': 'Common'},
  4801: {'rank': 6289, 'rarity': 'Common'},
  4802: {'rank': 7994, 'rarity': 'Common'},
  4803: {'rank': 7684, 'rarity': 'Common'},
  4804: {'rank': 1857, 'rarity': 'Rare'},
  4805: {'rank': 1213, 'rarity': 'Rare'},
  4806: {'rank': 3076, 'rarity': 'Uncommon'},
  4807: {'rank': 6608, 'rarity': 'Common'},
  4808: {'rank': 9197, 'rarity': 'Common'},
  4809: {'rank': 5724, 'rarity': 'Common'},
  4810: {'rank': 1806, 'rarity': 'Rare'},
  4811: {'rank': 6615, 'rarity': 'Common'},
  4812: {'rank': 1166, 'rarity': 'Rare'},
  4813: {'rank': 8414, 'rarity': 'Common'},
  4814: {'rank': 495, 'rarity': 'Epic'},
  4815: {'rank': 8004, 'rarity': 'Common'},
  4816: {'rank': 5625, 'rarity': 'Common'},
  4817: {'rank': 3499, 'rarity': 'Uncommon'},
  4818: {'rank': 1177, 'rarity': 'Rare'},
  4819: {'rank': 8142, 'rarity': 'Common'},
  4820: {'rank': 5006, 'rarity': 'Common'},
  4821: {'rank': 2354, 'rarity': 'Rare'},
  4822: {'rank': 1075, 'rarity': 'Rare'},
  4823: {'rank': 8366, 'rarity': 'Common'},
  4824: {'rank': 1710, 'rarity': 'Rare'},
  4825: {'rank': 7631, 'rarity': 'Common'},
  4826: {'rank': 8631, 'rarity': 'Common'},
  4827: {'rank': 166, 'rarity': 'Epic'},
  4828: {'rank': 6119, 'rarity': 'Common'},
  4829: {'rank': 4872, 'rarity': 'Uncommon'},
  4830: {'rank': 381, 'rarity': 'Epic'},
  4831: {'rank': 4528, 'rarity': 'Uncommon'},
  4832: {'rank': 3749, 'rarity': 'Uncommon'},
  4833: {'rank': 3433, 'rarity': 'Uncommon'},
  4834: {'rank': 902, 'rarity': 'Epic'},
  4835: {'rank': 6256, 'rarity': 'Common'},
  4836: {'rank': 6634, 'rarity': 'Common'},
  4837: {'rank': 4368, 'rarity': 'Uncommon'},
  4838: {'rank': 4482, 'rarity': 'Uncommon'},
  4839: {'rank': 5474, 'rarity': 'Common'},
  4840: {'rank': 2772, 'rarity': 'Uncommon'},
  4841: {'rank': 3025, 'rarity': 'Uncommon'},
  4842: {'rank': 6230, 'rarity': 'Common'},
  4843: {'rank': 3139, 'rarity': 'Uncommon'},
  4844: {'rank': 8816, 'rarity': 'Common'},
  4845: {'rank': 8662, 'rarity': 'Common'},
  4846: {'rank': 1698, 'rarity': 'Rare'},
  4847: {'rank': 545, 'rarity': 'Epic'},
  4848: {'rank': 9134, 'rarity': 'Common'},
  4849: {'rank': 6827, 'rarity': 'Common'},
  4850: {'rank': 2889, 'rarity': 'Uncommon'},
  4851: {'rank': 3111, 'rarity': 'Uncommon'},
  4852: {'rank': 5585, 'rarity': 'Common'},
  4853: {'rank': 3840, 'rarity': 'Uncommon'},
  4854: {'rank': 8252, 'rarity': 'Common'},
  4855: {'rank': 6811, 'rarity': 'Common'},
  4856: {'rank': 2266, 'rarity': 'Rare'},
  4857: {'rank': 712, 'rarity': 'Epic'},
  4858: {'rank': 7760, 'rarity': 'Common'},
  4859: {'rank': 3839, 'rarity': 'Uncommon'},
  4860: {'rank': 2735, 'rarity': 'Uncommon'},
  4861: {'rank': 6871, 'rarity': 'Common'},
  4862: {'rank': 1567, 'rarity': 'Rare'},
  4863: {'rank': 5073, 'rarity': 'Common'},
  4864: {'rank': 1248, 'rarity': 'Rare'},
  4865: {'rank': 7948, 'rarity': 'Common'},
  4866: {'rank': 19, 'rarity': 'Legendary'},
  4867: {'rank': 9461, 'rarity': 'Common'},
  4868: {'rank': 3736, 'rarity': 'Uncommon'},
  4869: {'rank': 4976, 'rarity': 'Uncommon'},
  4870: {'rank': 6695, 'rarity': 'Common'},
  4871: {'rank': 5770, 'rarity': 'Common'},
  4872: {'rank': 4598, 'rarity': 'Uncommon'},
  4873: {'rank': 3170, 'rarity': 'Uncommon'},
  4874: {'rank': 5857, 'rarity': 'Common'},
  4875: {'rank': 1081, 'rarity': 'Rare'},
  4876: {'rank': 5602, 'rarity': 'Common'},
  4877: {'rank': 2241, 'rarity': 'Rare'},
  4878: {'rank': 7803, 'rarity': 'Common'},
  4879: {'rank': 9677, 'rarity': 'Common'},
  4880: {'rank': 4996, 'rarity': 'Uncommon'},
  4881: {'rank': 2843, 'rarity': 'Uncommon'},
  4882: {'rank': 864, 'rarity': 'Epic'},
  4883: {'rank': 5107, 'rarity': 'Common'},
  4884: {'rank': 780, 'rarity': 'Epic'},
  4885: {'rank': 9959, 'rarity': 'Common'},
  4886: {'rank': 4513, 'rarity': 'Uncommon'},
  4887: {'rank': 7407, 'rarity': 'Common'},
  4888: {'rank': 6528, 'rarity': 'Common'},
  4889: {'rank': 5139, 'rarity': 'Common'},
  4890: {'rank': 5198, 'rarity': 'Common'},
  4891: {'rank': 7071, 'rarity': 'Common'},
  4892: {'rank': 75, 'rarity': 'Legendary'},
  4893: {'rank': 2898, 'rarity': 'Uncommon'},
  4894: {'rank': 1963, 'rarity': 'Rare'},
  4895: {'rank': 9371, 'rarity': 'Common'},
  4896: {'rank': 2711, 'rarity': 'Uncommon'},
  4897: {'rank': 5950, 'rarity': 'Common'},
  4898: {'rank': 9360, 'rarity': 'Common'},
  4899: {'rank': 9604, 'rarity': 'Common'},
  4900: {'rank': 6717, 'rarity': 'Common'},
  4901: {'rank': 4232, 'rarity': 'Uncommon'},
  4902: {'rank': 8062, 'rarity': 'Common'},
  4903: {'rank': 5426, 'rarity': 'Common'},
  4904: {'rank': 6281, 'rarity': 'Common'},
  4905: {'rank': 2315, 'rarity': 'Rare'},
  4906: {'rank': 3298, 'rarity': 'Uncommon'},
  4907: {'rank': 6708, 'rarity': 'Common'},
  4908: {'rank': 9031, 'rarity': 'Common'},
  4909: {'rank': 8232, 'rarity': 'Common'},
  4910: {'rank': 654, 'rarity': 'Epic'},
  4911: {'rank': 6835, 'rarity': 'Common'},
  4912: {'rank': 927, 'rarity': 'Epic'},
  4913: {'rank': 7591, 'rarity': 'Common'},
  4914: {'rank': 3849, 'rarity': 'Uncommon'},
  4915: {'rank': 1400, 'rarity': 'Rare'},
  4916: {'rank': 346, 'rarity': 'Epic'},
  4917: {'rank': 4047, 'rarity': 'Uncommon'},
  4918: {'rank': 6198, 'rarity': 'Common'},
  4919: {'rank': 5050, 'rarity': 'Common'},
  4920: {'rank': 2730, 'rarity': 'Uncommon'},
  4921: {'rank': 5220, 'rarity': 'Common'},
  4922: {'rank': 8225, 'rarity': 'Common'},
  4923: {'rank': 3696, 'rarity': 'Uncommon'},
  4924: {'rank': 3870, 'rarity': 'Uncommon'},
  4925: {'rank': 1356, 'rarity': 'Rare'},
  4926: {'rank': 7113, 'rarity': 'Common'},
  4927: {'rank': 7338, 'rarity': 'Common'},
  4928: {'rank': 9475, 'rarity': 'Common'},
  4929: {'rank': 9301, 'rarity': 'Common'},
  4930: {'rank': 9776, 'rarity': 'Common'},
  4931: {'rank': 4398, 'rarity': 'Uncommon'},
  4932: {'rank': 3594, 'rarity': 'Uncommon'},
  4933: {'rank': 208, 'rarity': 'Epic'},
  4934: {'rank': 3158, 'rarity': 'Uncommon'},
  4935: {'rank': 5644, 'rarity': 'Common'},
  4936: {'rank': 7077, 'rarity': 'Common'},
  4937: {'rank': 6458, 'rarity': 'Common'},
  4938: {'rank': 859, 'rarity': 'Epic'},
  4939: {'rank': 3899, 'rarity': 'Uncommon'},
  4940: {'rank': 1322, 'rarity': 'Rare'},
  4941: {'rank': 807, 'rarity': 'Epic'},
  4942: {'rank': 2005, 'rarity': 'Rare'},
  4943: {'rank': 9965, 'rarity': 'Common'},
  4944: {'rank': 8594, 'rarity': 'Common'},
  4945: {'rank': 1917, 'rarity': 'Rare'},
  4946: {'rank': 4085, 'rarity': 'Uncommon'},
  4947: {'rank': 4534, 'rarity': 'Uncommon'},
  4948: {'rank': 6065, 'rarity': 'Common'},
  4949: {'rank': 1532, 'rarity': 'Rare'},
  4950: {'rank': 9443, 'rarity': 'Common'},
  4951: {'rank': 5217, 'rarity': 'Common'},
  4952: {'rank': 8607, 'rarity': 'Common'},
  4953: {'rank': 596, 'rarity': 'Epic'},
  4954: {'rank': 9095, 'rarity': 'Common'},
  4955: {'rank': 566, 'rarity': 'Epic'},
  4956: {'rank': 5878, 'rarity': 'Common'},
  4957: {'rank': 1022, 'rarity': 'Rare'},
  4958: {'rank': 9759, 'rarity': 'Common'},
  4959: {'rank': 3813, 'rarity': 'Uncommon'},
  4960: {'rank': 5877, 'rarity': 'Common'},
  4961: {'rank': 4743, 'rarity': 'Uncommon'},
  4962: {'rank': 7456, 'rarity': 'Common'},
  4963: {'rank': 7095, 'rarity': 'Common'},
  4964: {'rank': 1645, 'rarity': 'Rare'},
  4965: {'rank': 4040, 'rarity': 'Uncommon'},
  4966: {'rank': 8648, 'rarity': 'Common'},
  4967: {'rank': 9087, 'rarity': 'Common'},
  4968: {'rank': 4899, 'rarity': 'Uncommon'},
  4969: {'rank': 2113, 'rarity': 'Rare'},
  4970: {'rank': 8458, 'rarity': 'Common'},
  4971: {'rank': 4856, 'rarity': 'Uncommon'},
  4972: {'rank': 5117, 'rarity': 'Common'},
  4973: {'rank': 882, 'rarity': 'Epic'},
  4974: {'rank': 9349, 'rarity': 'Common'},
  4975: {'rank': 6179, 'rarity': 'Common'},
  4976: {'rank': 492, 'rarity': 'Epic'},
  4977: {'rank': 5629, 'rarity': 'Common'},
  4978: {'rank': 2408, 'rarity': 'Rare'},
  4979: {'rank': 9146, 'rarity': 'Common'},
  4980: {'rank': 8971, 'rarity': 'Common'},
  4981: {'rank': 3074, 'rarity': 'Uncommon'},
  4982: {'rank': 9196, 'rarity': 'Common'},
  4983: {'rank': 6251, 'rarity': 'Common'},
  4984: {'rank': 9059, 'rarity': 'Common'},
  4985: {'rank': 2869, 'rarity': 'Uncommon'},
  4986: {'rank': 7771, 'rarity': 'Common'},
  4987: {'rank': 5693, 'rarity': 'Common'},
  4988: {'rank': 676, 'rarity': 'Epic'},
  4989: {'rank': 7990, 'rarity': 'Common'},
  4990: {'rank': 3834, 'rarity': 'Uncommon'},
  4991: {'rank': 3998, 'rarity': 'Uncommon'},
  4992: {'rank': 6489, 'rarity': 'Common'},
  4993: {'rank': 6739, 'rarity': 'Common'},
  4994: {'rank': 9494, 'rarity': 'Common'},
  4995: {'rank': 10000, 'rarity': 'Common'},
  4996: {'rank': 6733, 'rarity': 'Common'},
  4997: {'rank': 3805, 'rarity': 'Uncommon'},
  4998: {'rank': 3202, 'rarity': 'Uncommon'},
  4999: {'rank': 478, 'rarity': 'Epic'},
  5000: {'rank': 3795, 'rarity': 'Uncommon'},
  5001: {'rank': 4158, 'rarity': 'Uncommon'},
  5002: {'rank': 5955, 'rarity': 'Common'},
  5003: {'rank': 1212, 'rarity': 'Rare'},
  5004: {'rank': 4253, 'rarity': 'Uncommon'},
  5005: {'rank': 8824, 'rarity': 'Common'},
  5006: {'rank': 6058, 'rarity': 'Common'},
  5007: {'rank': 9473, 'rarity': 'Common'},
  5008: {'rank': 3514, 'rarity': 'Uncommon'},
  5009: {'rank': 7741, 'rarity': 'Common'},
  5010: {'rank': 7897, 'rarity': 'Common'},
  5011: {'rank': 5638, 'rarity': 'Common'},
  5012: {'rank': 837, 'rarity': 'Epic'},
  5013: {'rank': 1862, 'rarity': 'Rare'},
  5014: {'rank': 8557, 'rarity': 'Common'},
  5015: {'rank': 4735, 'rarity': 'Uncommon'},
  5016: {'rank': 2517, 'rarity': 'Uncommon'},
  5017: {'rank': 7567, 'rarity': 'Common'},
  5018: {'rank': 3006, 'rarity': 'Uncommon'},
  5019: {'rank': 2398, 'rarity': 'Rare'},
  5020: {'rank': 1149, 'rarity': 'Rare'},
  5021: {'rank': 812, 'rarity': 'Epic'},
  5022: {'rank': 2972, 'rarity': 'Uncommon'},
  5023: {'rank': 4027, 'rarity': 'Uncommon'},
  5024: {'rank': 6513, 'rarity': 'Common'},
  5025: {'rank': 1495, 'rarity': 'Rare'},
  5026: {'rank': 8519, 'rarity': 'Common'},
  5027: {'rank': 7898, 'rarity': 'Common'},
  5028: {'rank': 4703, 'rarity': 'Uncommon'},
  5029: {'rank': 3376, 'rarity': 'Uncommon'},
  5030: {'rank': 8169, 'rarity': 'Common'},
  5031: {'rank': 1537, 'rarity': 'Rare'},
  5032: {'rank': 2671, 'rarity': 'Uncommon'},
  5033: {'rank': 5423, 'rarity': 'Common'},
  5034: {'rank': 4565, 'rarity': 'Uncommon'},
  5035: {'rank': 9579, 'rarity': 'Common'},
  5036: {'rank': 8639, 'rarity': 'Common'},
  5037: {'rank': 4246, 'rarity': 'Uncommon'},
  5038: {'rank': 1853, 'rarity': 'Rare'},
  5039: {'rank': 9020, 'rarity': 'Common'},
  5040: {'rank': 5709, 'rarity': 'Common'},
  5041: {'rank': 9062, 'rarity': 'Common'},
  5042: {'rank': 5093, 'rarity': 'Common'},
  5043: {'rank': 509, 'rarity': 'Epic'},
  5044: {'rank': 4642, 'rarity': 'Uncommon'},
  5045: {'rank': 3106, 'rarity': 'Uncommon'},
  5046: {'rank': 9363, 'rarity': 'Common'},
  5047: {'rank': 1605, 'rarity': 'Rare'},
  5048: {'rank': 4652, 'rarity': 'Uncommon'},
  5049: {'rank': 5872, 'rarity': 'Common'},
  5050: {'rank': 8727, 'rarity': 'Common'},
  5051: {'rank': 2927, 'rarity': 'Uncommon'},
  5052: {'rank': 8516, 'rarity': 'Common'},
  5053: {'rank': 5626, 'rarity': 'Common'},
  5054: {'rank': 1556, 'rarity': 'Rare'},
  5055: {'rank': 298, 'rarity': 'Epic'},
  5056: {'rank': 6950, 'rarity': 'Common'},
  5057: {'rank': 2708, 'rarity': 'Uncommon'},
  5058: {'rank': 3728, 'rarity': 'Uncommon'},
  5059: {'rank': 6408, 'rarity': 'Common'},
  5060: {'rank': 7553, 'rarity': 'Common'},
  5061: {'rank': 6645, 'rarity': 'Common'},
  5062: {'rank': 4799, 'rarity': 'Uncommon'},
  5063: {'rank': 4307, 'rarity': 'Uncommon'},
  5064: {'rank': 8447, 'rarity': 'Common'},
  5065: {'rank': 1740, 'rarity': 'Rare'},
  5066: {'rank': 5400, 'rarity': 'Common'},
  5067: {'rank': 415, 'rarity': 'Epic'},
  5068: {'rank': 6997, 'rarity': 'Common'},
  5069: {'rank': 9003, 'rarity': 'Common'},
  5070: {'rank': 1102, 'rarity': 'Rare'},
  5071: {'rank': 9609, 'rarity': 'Common'},
  5072: {'rank': 1344, 'rarity': 'Rare'},
  5073: {'rank': 6328, 'rarity': 'Common'},
  5074: {'rank': 1211, 'rarity': 'Rare'},
  5075: {'rank': 3212, 'rarity': 'Uncommon'},
  5076: {'rank': 7788, 'rarity': 'Common'},
  5077: {'rank': 1530, 'rarity': 'Rare'},
  5078: {'rank': 3223, 'rarity': 'Uncommon'},
  5079: {'rank': 8767, 'rarity': 'Common'},
  5080: {'rank': 7003, 'rarity': 'Common'},
  5081: {'rank': 2807, 'rarity': 'Uncommon'},
  5082: {'rank': 9090, 'rarity': 'Common'},
  5083: {'rank': 9918, 'rarity': 'Common'},
  5084: {'rank': 4426, 'rarity': 'Uncommon'},
  5085: {'rank': 5892, 'rarity': 'Common'},
  5086: {'rank': 3474, 'rarity': 'Uncommon'},
  5087: {'rank': 2865, 'rarity': 'Uncommon'},
  5088: {'rank': 5066, 'rarity': 'Common'},
  5089: {'rank': 1828, 'rarity': 'Rare'},
  5090: {'rank': 59, 'rarity': 'Legendary'},
  5091: {'rank': 3248, 'rarity': 'Uncommon'},
  5092: {'rank': 8392, 'rarity': 'Common'},
  5093: {'rank': 6336, 'rarity': 'Common'},
  5094: {'rank': 1181, 'rarity': 'Rare'},
  5095: {'rank': 9601, 'rarity': 'Common'},
  5096: {'rank': 2296, 'rarity': 'Rare'},
  5097: {'rank': 3694, 'rarity': 'Uncommon'},
  5098: {'rank': 8676, 'rarity': 'Common'},
  5099: {'rank': 9411, 'rarity': 'Common'},
  5100: {'rank': 5867, 'rarity': 'Common'},
  5101: {'rank': 1701, 'rarity': 'Rare'},
  5102: {'rank': 4219, 'rarity': 'Uncommon'},
  5103: {'rank': 3511, 'rarity': 'Uncommon'},
  5104: {'rank': 7184, 'rarity': 'Common'},
  5105: {'rank': 8029, 'rarity': 'Common'},
  5106: {'rank': 7139, 'rarity': 'Common'},
  5107: {'rank': 8390, 'rarity': 'Common'},
  5108: {'rank': 3921, 'rarity': 'Uncommon'},
  5109: {'rank': 4810, 'rarity': 'Uncommon'},
  5110: {'rank': 4165, 'rarity': 'Uncommon'},
  5111: {'rank': 489, 'rarity': 'Epic'},
  5112: {'rank': 5754, 'rarity': 'Common'},
  5113: {'rank': 1940, 'rarity': 'Rare'},
  5114: {'rank': 7327, 'rarity': 'Common'},
  5115: {'rank': 8993, 'rarity': 'Common'},
  5116: {'rank': 4180, 'rarity': 'Uncommon'},
  5117: {'rank': 8022, 'rarity': 'Common'},
  5118: {'rank': 456, 'rarity': 'Epic'},
  5119: {'rank': 6832, 'rarity': 'Common'},
  5120: {'rank': 2376, 'rarity': 'Rare'},
  5121: {'rank': 8393, 'rarity': 'Common'},
  5122: {'rank': 251, 'rarity': 'Epic'},
  5123: {'rank': 7193, 'rarity': 'Common'},
  5124: {'rank': 9812, 'rarity': 'Common'},
  5125: {'rank': 737, 'rarity': 'Epic'},
  5126: {'rank': 7149, 'rarity': 'Common'},
  5127: {'rank': 3375, 'rarity': 'Uncommon'},
  5128: {'rank': 487, 'rarity': 'Epic'},
  5129: {'rank': 388, 'rarity': 'Epic'},
  5130: {'rank': 9837, 'rarity': 'Common'},
  5131: {'rank': 2048, 'rarity': 'Rare'},
  5132: {'rank': 9981, 'rarity': 'Common'},
  5133: {'rank': 302, 'rarity': 'Epic'},
  5134: {'rank': 1234, 'rarity': 'Rare'},
  5135: {'rank': 108, 'rarity': 'Epic'},
  5136: {'rank': 3637, 'rarity': 'Uncommon'},
  5137: {'rank': 3120, 'rarity': 'Uncommon'},
  5138: {'rank': 443, 'rarity': 'Epic'},
  5139: {'rank': 6604, 'rarity': 'Common'},
  5140: {'rank': 3059, 'rarity': 'Uncommon'},
  5141: {'rank': 3042, 'rarity': 'Uncommon'},
  5142: {'rank': 2035, 'rarity': 'Rare'},
  5143: {'rank': 335, 'rarity': 'Epic'},
  5144: {'rank': 1226, 'rarity': 'Rare'},
  5145: {'rank': 6148, 'rarity': 'Common'},
  5146: {'rank': 8293, 'rarity': 'Common'},
  5147: {'rank': 5320, 'rarity': 'Common'},
  5148: {'rank': 1193, 'rarity': 'Rare'},
  5149: {'rank': 8248, 'rarity': 'Common'},
  5150: {'rank': 2969, 'rarity': 'Uncommon'},
  5151: {'rank': 1178, 'rarity': 'Rare'},
  5152: {'rank': 3990, 'rarity': 'Uncommon'},
  5153: {'rank': 5175, 'rarity': 'Common'},
  5154: {'rank': 2403, 'rarity': 'Rare'},
  5155: {'rank': 8344, 'rarity': 'Common'},
  5156: {'rank': 3777, 'rarity': 'Uncommon'},
  5157: {'rank': 3056, 'rarity': 'Uncommon'},
  5158: {'rank': 3606, 'rarity': 'Uncommon'},
  5159: {'rank': 8084, 'rarity': 'Common'},
  5160: {'rank': 8966, 'rarity': 'Common'},
  5161: {'rank': 7381, 'rarity': 'Common'},
  5162: {'rank': 6565, 'rarity': 'Common'},
  5163: {'rank': 9432, 'rarity': 'Common'},
  5164: {'rank': 5584, 'rarity': 'Common'},
  5165: {'rank': 894, 'rarity': 'Epic'},
  5166: {'rank': 1061, 'rarity': 'Rare'},
  5167: {'rank': 5141, 'rarity': 'Common'},
  5168: {'rank': 6295, 'rarity': 'Common'},
  5169: {'rank': 3757, 'rarity': 'Uncommon'},
  5170: {'rank': 7761, 'rarity': 'Common'},
  5171: {'rank': 4525, 'rarity': 'Uncommon'},
  5172: {'rank': 3877, 'rarity': 'Uncommon'},
  5173: {'rank': 6315, 'rarity': 'Common'},
  5174: {'rank': 7826, 'rarity': 'Common'},
  5175: {'rank': 4230, 'rarity': 'Uncommon'},
  5176: {'rank': 2477, 'rarity': 'Rare'},
  5177: {'rank': 3299, 'rarity': 'Uncommon'},
  5178: {'rank': 6709, 'rarity': 'Common'},
  5179: {'rank': 1856, 'rarity': 'Rare'},
  5180: {'rank': 7349, 'rarity': 'Common'},
  5181: {'rank': 4254, 'rarity': 'Uncommon'},
  5182: {'rank': 6669, 'rarity': 'Common'},
  5183: {'rank': 7584, 'rarity': 'Common'},
  5184: {'rank': 7851, 'rarity': 'Common'},
  5185: {'rank': 991, 'rarity': 'Epic'},
  5186: {'rank': 926, 'rarity': 'Epic'},
  5187: {'rank': 4021, 'rarity': 'Uncommon'},
  5188: {'rank': 7629, 'rarity': 'Common'},
  5189: {'rank': 8815, 'rarity': 'Common'},
  5190: {'rank': 4347, 'rarity': 'Uncommon'},
  5191: {'rank': 9767, 'rarity': 'Common'},
  5192: {'rank': 2053, 'rarity': 'Rare'},
  5193: {'rank': 6310, 'rarity': 'Common'},
  5194: {'rank': 9756, 'rarity': 'Common'},
  5195: {'rank': 6372, 'rarity': 'Common'},
  5196: {'rank': 2709, 'rarity': 'Uncommon'},
  5197: {'rank': 1689, 'rarity': 'Rare'},
  5198: {'rank': 8409, 'rarity': 'Common'},
  5199: {'rank': 6930, 'rarity': 'Common'},
  5200: {'rank': 2934, 'rarity': 'Uncommon'},
  5201: {'rank': 4181, 'rarity': 'Uncommon'},
  5202: {'rank': 6899, 'rarity': 'Common'},
  5203: {'rank': 7031, 'rarity': 'Common'},
  5204: {'rank': 1711, 'rarity': 'Rare'},
  5205: {'rank': 8360, 'rarity': 'Common'},
  5206: {'rank': 4016, 'rarity': 'Uncommon'},
  5207: {'rank': 6662, 'rarity': 'Common'},
  5208: {'rank': 2499, 'rarity': 'Rare'},
  5209: {'rank': 1176, 'rarity': 'Rare'},
  5210: {'rank': 2609, 'rarity': 'Uncommon'},
  5211: {'rank': 1405, 'rarity': 'Rare'},
  5212: {'rank': 2076, 'rarity': 'Rare'},
  5213: {'rank': 2111, 'rarity': 'Rare'},
  5214: {'rank': 4521, 'rarity': 'Uncommon'},
  5215: {'rank': 6342, 'rarity': 'Common'},
  5216: {'rank': 3222, 'rarity': 'Uncommon'},
  5217: {'rank': 4662, 'rarity': 'Uncommon'},
  5218: {'rank': 6589, 'rarity': 'Common'},
  5219: {'rank': 5646, 'rarity': 'Common'},
  5220: {'rank': 905, 'rarity': 'Epic'},
  5221: {'rank': 5695, 'rarity': 'Common'},
  5222: {'rank': 1313, 'rarity': 'Rare'},
  5223: {'rank': 7224, 'rarity': 'Common'},
  5224: {'rank': 1118, 'rarity': 'Rare'},
  5225: {'rank': 1960, 'rarity': 'Rare'},
  5226: {'rank': 6029, 'rarity': 'Common'},
  5227: {'rank': 791, 'rarity': 'Epic'},
  5228: {'rank': 695, 'rarity': 'Epic'},
  5229: {'rank': 4693, 'rarity': 'Uncommon'},
  5230: {'rank': 7322, 'rarity': 'Common'},
  5231: {'rank': 5790, 'rarity': 'Common'},
  5232: {'rank': 6319, 'rarity': 'Common'},
  5233: {'rank': 7958, 'rarity': 'Common'},
  5234: {'rank': 7206, 'rarity': 'Common'},
  5235: {'rank': 1632, 'rarity': 'Rare'},
  5236: {'rank': 590, 'rarity': 'Epic'},
  5237: {'rank': 7011, 'rarity': 'Common'},
  5238: {'rank': 6404, 'rarity': 'Common'},
  5239: {'rank': 9649, 'rarity': 'Common'},
  5240: {'rank': 8801, 'rarity': 'Common'},
  5241: {'rank': 9249, 'rarity': 'Common'},
  5242: {'rank': 6922, 'rarity': 'Common'},
  5243: {'rank': 7652, 'rarity': 'Common'},
  5244: {'rank': 1368, 'rarity': 'Rare'},
  5245: {'rank': 9227, 'rarity': 'Common'},
  5246: {'rank': 5482, 'rarity': 'Common'},
  5247: {'rank': 6007, 'rarity': 'Common'},
  5248: {'rank': 10, 'rarity': 'Legendary'},
  5249: {'rank': 8610, 'rarity': 'Common'},
  5250: {'rank': 3261, 'rarity': 'Uncommon'},
  5251: {'rank': 685, 'rarity': 'Epic'},
  5252: {'rank': 4684, 'rarity': 'Uncommon'},
  5253: {'rank': 5612, 'rarity': 'Common'},
  5254: {'rank': 1372, 'rarity': 'Rare'},
  5255: {'rank': 1281, 'rarity': 'Rare'},
  5256: {'rank': 3393, 'rarity': 'Uncommon'},
  5257: {'rank': 778, 'rarity': 'Epic'},
  5258: {'rank': 5916, 'rarity': 'Common'},
  5259: {'rank': 8800, 'rarity': 'Common'},
  5260: {'rank': 6798, 'rarity': 'Common'},
  5261: {'rank': 7799, 'rarity': 'Common'},
  5262: {'rank': 6921, 'rarity': 'Common'},
  5263: {'rank': 4366, 'rarity': 'Uncommon'},
  5264: {'rank': 4389, 'rarity': 'Uncommon'},
  5265: {'rank': 8694, 'rarity': 'Common'},
  5266: {'rank': 9414, 'rarity': 'Common'},
  5267: {'rank': 5953, 'rarity': 'Common'},
  5268: {'rank': 4861, 'rarity': 'Uncommon'},
  5269: {'rank': 4045, 'rarity': 'Uncommon'},
  5270: {'rank': 8591, 'rarity': 'Common'},
  5271: {'rank': 9358, 'rarity': 'Common'},
  5272: {'rank': 8068, 'rarity': 'Common'},
  5273: {'rank': 4467, 'rarity': 'Uncommon'},
  5274: {'rank': 170, 'rarity': 'Epic'},
  5275: {'rank': 5094, 'rarity': 'Common'},
  5276: {'rank': 8465, 'rarity': 'Common'},
  5277: {'rank': 9958, 'rarity': 'Common'},
  5278: {'rank': 8799, 'rarity': 'Common'},
  5279: {'rank': 8413, 'rarity': 'Common'},
  5280: {'rank': 4583, 'rarity': 'Uncommon'},
  5281: {'rank': 8502, 'rarity': 'Common'},
  5282: {'rank': 9563, 'rarity': 'Common'},
  5283: {'rank': 8871, 'rarity': 'Common'},
  5284: {'rank': 527, 'rarity': 'Epic'},
  5285: {'rank': 5926, 'rarity': 'Common'},
  5286: {'rank': 1901, 'rarity': 'Rare'},
  5287: {'rank': 2814, 'rarity': 'Uncommon'},
  5288: {'rank': 614, 'rarity': 'Epic'},
  5289: {'rank': 3181, 'rarity': 'Uncommon'},
  5290: {'rank': 5880, 'rarity': 'Common'},
  5291: {'rank': 5205, 'rarity': 'Common'},
  5292: {'rank': 8038, 'rarity': 'Common'},
  5293: {'rank': 2103, 'rarity': 'Rare'},
  5294: {'rank': 1923, 'rarity': 'Rare'},
  5295: {'rank': 7607, 'rarity': 'Common'},
  5296: {'rank': 1395, 'rarity': 'Rare'},
  5297: {'rank': 922, 'rarity': 'Epic'},
  5298: {'rank': 2538, 'rarity': 'Uncommon'},
  5299: {'rank': 7977, 'rarity': 'Common'},
  5300: {'rank': 8834, 'rarity': 'Common'},
  5301: {'rank': 1784, 'rarity': 'Rare'},
  5302: {'rank': 9019, 'rarity': 'Common'},
  5303: {'rank': 6507, 'rarity': 'Common'},
  5304: {'rank': 8576, 'rarity': 'Common'},
  5305: {'rank': 7069, 'rarity': 'Common'},
  5306: {'rank': 5637, 'rarity': 'Common'},
  5307: {'rank': 1753, 'rarity': 'Rare'},
  5308: {'rank': 3798, 'rarity': 'Uncommon'},
  5309: {'rank': 1695, 'rarity': 'Rare'},
  5310: {'rank': 4679, 'rarity': 'Uncommon'},
  5311: {'rank': 2861, 'rarity': 'Uncommon'},
  5312: {'rank': 7235, 'rarity': 'Common'},
  5313: {'rank': 9139, 'rarity': 'Common'},
  5314: {'rank': 8160, 'rarity': 'Common'},
  5315: {'rank': 621, 'rarity': 'Epic'},
  5316: {'rank': 5734, 'rarity': 'Common'},
  5317: {'rank': 3860, 'rarity': 'Uncommon'},
  5318: {'rank': 8290, 'rarity': 'Common'},
  5319: {'rank': 9969, 'rarity': 'Common'},
  5320: {'rank': 2968, 'rarity': 'Uncommon'},
  5321: {'rank': 7114, 'rarity': 'Common'},
  5322: {'rank': 766, 'rarity': 'Epic'},
  5323: {'rank': 6651, 'rarity': 'Common'},
  5324: {'rank': 8260, 'rarity': 'Common'},
  5325: {'rank': 4745, 'rarity': 'Uncommon'},
  5326: {'rank': 434, 'rarity': 'Epic'},
  5327: {'rank': 2200, 'rarity': 'Rare'},
  5328: {'rank': 9814, 'rarity': 'Common'},
  5329: {'rank': 3421, 'rarity': 'Uncommon'},
  5330: {'rank': 7477, 'rarity': 'Common'},
  5331: {'rank': 7499, 'rarity': 'Common'},
  5332: {'rank': 1542, 'rarity': 'Rare'},
  5333: {'rank': 3123, 'rarity': 'Uncommon'},
  5334: {'rank': 2983, 'rarity': 'Uncommon'},
  5335: {'rank': 3837, 'rarity': 'Uncommon'},
  5336: {'rank': 731, 'rarity': 'Epic'},
  5337: {'rank': 8724, 'rarity': 'Common'},
  5338: {'rank': 3449, 'rarity': 'Uncommon'},
  5339: {'rank': 2905, 'rarity': 'Uncommon'},
  5340: {'rank': 2128, 'rarity': 'Rare'},
  5341: {'rank': 2563, 'rarity': 'Uncommon'},
  5342: {'rank': 501, 'rarity': 'Epic'},
  5343: {'rank': 3049, 'rarity': 'Uncommon'},
  5344: {'rank': 2467, 'rarity': 'Rare'},
  5345: {'rank': 1465, 'rarity': 'Rare'},
  5346: {'rank': 6603, 'rarity': 'Common'},
  5347: {'rank': 3435, 'rarity': 'Uncommon'},
  5348: {'rank': 4218, 'rarity': 'Uncommon'},
  5349: {'rank': 2346, 'rarity': 'Rare'},
  5350: {'rank': 5677, 'rarity': 'Common'},
  5351: {'rank': 9488, 'rarity': 'Common'},
  5352: {'rank': 9060, 'rarity': 'Common'},
  5353: {'rank': 9575, 'rarity': 'Common'},
  5354: {'rank': 7225, 'rarity': 'Common'},
  5355: {'rank': 2876, 'rarity': 'Uncommon'},
  5356: {'rank': 8998, 'rarity': 'Common'},
  5357: {'rank': 1241, 'rarity': 'Rare'},
  5358: {'rank': 5045, 'rarity': 'Common'},
  5359: {'rank': 5099, 'rarity': 'Common'},
  5360: {'rank': 1631, 'rarity': 'Rare'},
  5361: {'rank': 8415, 'rarity': 'Common'},
  5362: {'rank': 4388, 'rarity': 'Uncommon'},
  5363: {'rank': 4424, 'rarity': 'Uncommon'},
  5364: {'rank': 7181, 'rarity': 'Common'},
  5365: {'rank': 8006, 'rarity': 'Common'},
  5366: {'rank': 5836, 'rarity': 'Common'},
  5367: {'rank': 2344, 'rarity': 'Rare'},
  5368: {'rank': 4447, 'rarity': 'Uncommon'},
  5369: {'rank': 4286, 'rarity': 'Uncommon'},
  5370: {'rank': 4505, 'rarity': 'Uncommon'},
  5371: {'rank': 3969, 'rarity': 'Uncommon'},
  5372: {'rank': 1566, 'rarity': 'Rare'},
  5373: {'rank': 3330, 'rarity': 'Uncommon'},
  5374: {'rank': 7337, 'rarity': 'Common'},
  5375: {'rank': 3044, 'rarity': 'Uncommon'},
  5376: {'rank': 6050, 'rarity': 'Common'},
  5377: {'rank': 3661, 'rarity': 'Uncommon'},
  5378: {'rank': 3616, 'rarity': 'Uncommon'},
  5379: {'rank': 6191, 'rarity': 'Common'},
  5380: {'rank': 7159, 'rarity': 'Common'},
  5381: {'rank': 8368, 'rarity': 'Common'},
  5382: {'rank': 2068, 'rarity': 'Rare'},
  5383: {'rank': 6989, 'rarity': 'Common'},
  5384: {'rank': 531, 'rarity': 'Epic'},
  5385: {'rank': 9561, 'rarity': 'Common'},
  5386: {'rank': 2693, 'rarity': 'Uncommon'},
  5387: {'rank': 6754, 'rarity': 'Common'},
  5388: {'rank': 7857, 'rarity': 'Common'},
  5389: {'rank': 334, 'rarity': 'Epic'},
  5390: {'rank': 3171, 'rarity': 'Uncommon'},
  5391: {'rank': 7767, 'rarity': 'Common'},
  5392: {'rank': 1985, 'rarity': 'Rare'},
  5393: {'rank': 1770, 'rarity': 'Rare'},
  5394: {'rank': 4838, 'rarity': 'Uncommon'},
  5395: {'rank': 3988, 'rarity': 'Uncommon'},
  5396: {'rank': 5760, 'rarity': 'Common'},
  5397: {'rank': 9445, 'rarity': 'Common'},
  5398: {'rank': 2107, 'rarity': 'Rare'},
  5399: {'rank': 190, 'rarity': 'Epic'},
  5400: {'rank': 3363, 'rarity': 'Uncommon'},
  5401: {'rank': 3855, 'rarity': 'Uncommon'},
  5402: {'rank': 5726, 'rarity': 'Common'},
  5403: {'rank': 5458, 'rarity': 'Common'},
  5404: {'rank': 5024, 'rarity': 'Common'},
  5405: {'rank': 2561, 'rarity': 'Uncommon'},
  5406: {'rank': 5032, 'rarity': 'Common'},
  5407: {'rank': 7560, 'rarity': 'Common'},
  5408: {'rank': 9191, 'rarity': 'Common'},
  5409: {'rank': 5841, 'rarity': 'Common'},
  5410: {'rank': 5535, 'rarity': 'Common'},
  5411: {'rank': 5752, 'rarity': 'Common'},
  5412: {'rank': 3535, 'rarity': 'Uncommon'},
  5413: {'rank': 1764, 'rarity': 'Rare'},
  5414: {'rank': 6200, 'rarity': 'Common'},
  5415: {'rank': 3888, 'rarity': 'Uncommon'},
  5416: {'rank': 2441, 'rarity': 'Rare'},
  5417: {'rank': 2493, 'rarity': 'Rare'},
  5418: {'rank': 6760, 'rarity': 'Common'},
  5419: {'rank': 1535, 'rarity': 'Rare'},
  5420: {'rank': 513, 'rarity': 'Epic'},
  5421: {'rank': 9074, 'rarity': 'Common'},
  5422: {'rank': 7992, 'rarity': 'Common'},
  5423: {'rank': 8205, 'rarity': 'Common'},
  5424: {'rank': 4125, 'rarity': 'Uncommon'},
  5425: {'rank': 3653, 'rarity': 'Uncommon'},
  5426: {'rank': 2449, 'rarity': 'Rare'},
  5427: {'rank': 8156, 'rarity': 'Common'},
  5428: {'rank': 6033, 'rarity': 'Common'},
  5429: {'rank': 8198, 'rarity': 'Common'},
  5430: {'rank': 4255, 'rarity': 'Uncommon'},
  5431: {'rank': 5140, 'rarity': 'Common'},
  5432: {'rank': 4420, 'rarity': 'Uncommon'},
  5433: {'rank': 2100, 'rarity': 'Rare'},
  5434: {'rank': 2032, 'rarity': 'Rare'},
  5435: {'rank': 6252, 'rarity': 'Common'},
  5436: {'rank': 4747, 'rarity': 'Uncommon'},
  5437: {'rank': 9994, 'rarity': 'Common'},
  5438: {'rank': 8375, 'rarity': 'Common'},
  5439: {'rank': 4715, 'rarity': 'Uncommon'},
  5440: {'rank': 7294, 'rarity': 'Common'},
  5441: {'rank': 7630, 'rarity': 'Common'},
  5442: {'rank': 2350, 'rarity': 'Rare'},
  5443: {'rank': 1358, 'rarity': 'Rare'},
  5444: {'rank': 319, 'rarity': 'Epic'},
  5445: {'rank': 9853, 'rarity': 'Common'},
  5446: {'rank': 3659, 'rarity': 'Uncommon'},
  5447: {'rank': 8055, 'rarity': 'Common'},
  5448: {'rank': 1915, 'rarity': 'Rare'},
  5449: {'rank': 3767, 'rarity': 'Uncommon'},
  5450: {'rank': 2545, 'rarity': 'Uncommon'},
  5451: {'rank': 1278, 'rarity': 'Rare'},
  5452: {'rank': 4606, 'rarity': 'Uncommon'},
  5453: {'rank': 45, 'rarity': 'Legendary'},
  5454: {'rank': 2570, 'rarity': 'Uncommon'},
  5455: {'rank': 3900, 'rarity': 'Uncommon'},
  5456: {'rank': 2327, 'rarity': 'Rare'},
  5457: {'rank': 3956, 'rarity': 'Uncommon'},
  5458: {'rank': 5092, 'rarity': 'Common'},
  5459: {'rank': 2253, 'rarity': 'Rare'},
  5460: {'rank': 5297, 'rarity': 'Common'},
  5461: {'rank': 394, 'rarity': 'Epic'},
  5462: {'rank': 2226, 'rarity': 'Rare'},
  5463: {'rank': 1184, 'rarity': 'Rare'},
  5464: {'rank': 644, 'rarity': 'Epic'},
  5465: {'rank': 1407, 'rarity': 'Rare'},
  5466: {'rank': 1162, 'rarity': 'Rare'},
  5467: {'rank': 3880, 'rarity': 'Uncommon'},
  5468: {'rank': 3793, 'rarity': 'Uncommon'},
  5469: {'rank': 3697, 'rarity': 'Uncommon'},
  5470: {'rank': 605, 'rarity': 'Epic'},
  5471: {'rank': 921, 'rarity': 'Epic'},
  5472: {'rank': 8873, 'rarity': 'Common'},
  5473: {'rank': 6869, 'rarity': 'Common'},
  5474: {'rank': 5861, 'rarity': 'Common'},
  5475: {'rank': 7320, 'rarity': 'Common'},
  5476: {'rank': 7166, 'rarity': 'Common'},
  5477: {'rank': 6330, 'rarity': 'Common'},
  5478: {'rank': 9351, 'rarity': 'Common'},
  5479: {'rank': 4319, 'rarity': 'Uncommon'},
  5480: {'rank': 6305, 'rarity': 'Common'},
  5481: {'rank': 5424, 'rarity': 'Common'},
  5482: {'rank': 4059, 'rarity': 'Uncommon'},
  5483: {'rank': 2617, 'rarity': 'Uncommon'},
  5484: {'rank': 3232, 'rarity': 'Uncommon'},
  5485: {'rank': 6405, 'rarity': 'Common'},
  5486: {'rank': 1425, 'rarity': 'Rare'},
  5487: {'rank': 5150, 'rarity': 'Common'},
  5488: {'rank': 3989, 'rarity': 'Uncommon'},
  5489: {'rank': 7372, 'rarity': 'Common'},
  5490: {'rank': 3312, 'rarity': 'Uncommon'},
  5491: {'rank': 110, 'rarity': 'Epic'},
  5492: {'rank': 7074, 'rarity': 'Common'},
  5493: {'rank': 7199, 'rarity': 'Common'},
  5494: {'rank': 4892, 'rarity': 'Uncommon'},
  5495: {'rank': 6214, 'rarity': 'Common'},
  5496: {'rank': 4621, 'rarity': 'Uncommon'},
  5497: {'rank': 4395, 'rarity': 'Uncommon'},
  5498: {'rank': 5977, 'rarity': 'Common'},
  5499: {'rank': 9747, 'rarity': 'Common'},
  5500: {'rank': 2140, 'rarity': 'Rare'},
  5501: {'rank': 2473, 'rarity': 'Rare'},
  5502: {'rank': 8473, 'rarity': 'Common'},
  5503: {'rank': 337, 'rarity': 'Epic'},
  5504: {'rank': 7731, 'rarity': 'Common'},
  5505: {'rank': 4830, 'rarity': 'Uncommon'},
  5506: {'rank': 8716, 'rarity': 'Common'},
  5507: {'rank': 2116, 'rarity': 'Rare'},
  5508: {'rank': 5322, 'rarity': 'Common'},
  5509: {'rank': 2152, 'rarity': 'Rare'},
  5510: {'rank': 165, 'rarity': 'Epic'},
  5511: {'rank': 4077, 'rarity': 'Uncommon'},
  5512: {'rank': 3011, 'rarity': 'Uncommon'},
  5513: {'rank': 2925, 'rarity': 'Uncommon'},
  5514: {'rank': 4137, 'rarity': 'Uncommon'},
  5515: {'rank': 31, 'rarity': 'Legendary'},
  5516: {'rank': 36, 'rarity': 'Legendary'},
  5517: {'rank': 3471, 'rarity': 'Uncommon'},
  5518: {'rank': 9529, 'rarity': 'Common'},
  5519: {'rank': 7810, 'rarity': 'Common'},
  5520: {'rank': 2460, 'rarity': 'Rare'},
  5521: {'rank': 2205, 'rarity': 'Rare'},
  5522: {'rank': 2456, 'rarity': 'Rare'},
  5523: {'rank': 4931, 'rarity': 'Uncommon'},
  5524: {'rank': 4834, 'rarity': 'Uncommon'},
  5525: {'rank': 9072, 'rarity': 'Common'},
  5526: {'rank': 6218, 'rarity': 'Common'},
  5527: {'rank': 9764, 'rarity': 'Common'},
  5528: {'rank': 3825, 'rarity': 'Uncommon'},
  5529: {'rank': 230, 'rarity': 'Epic'},
  5530: {'rank': 5151, 'rarity': 'Common'},
  5531: {'rank': 2154, 'rarity': 'Rare'},
  5532: {'rank': 9141, 'rarity': 'Common'},
  5533: {'rank': 9292, 'rarity': 'Common'},
  5534: {'rank': 2409, 'rarity': 'Rare'},
  5535: {'rank': 7667, 'rarity': 'Common'},
  5536: {'rank': 1823, 'rarity': 'Rare'},
  5537: {'rank': 4592, 'rarity': 'Uncommon'},
  5538: {'rank': 3700, 'rarity': 'Uncommon'},
  5539: {'rank': 4987, 'rarity': 'Uncommon'},
  5540: {'rank': 3116, 'rarity': 'Uncommon'},
  5541: {'rank': 2604, 'rarity': 'Uncommon'},
  5542: {'rank': 2448, 'rarity': 'Rare'},
  5543: {'rank': 3827, 'rarity': 'Uncommon'},
  5544: {'rank': 9465, 'rarity': 'Common'},
  5545: {'rank': 3409, 'rarity': 'Uncommon'},
  5546: {'rank': 34, 'rarity': 'Legendary'},
  5547: {'rank': 9698, 'rarity': 'Common'},
  5548: {'rank': 1130, 'rarity': 'Rare'},
  5549: {'rank': 2788, 'rarity': 'Uncommon'},
  5550: {'rank': 3677, 'rarity': 'Uncommon'},
  5551: {'rank': 6729, 'rarity': 'Common'},
  5552: {'rank': 2945, 'rarity': 'Uncommon'},
  5553: {'rank': 4936, 'rarity': 'Uncommon'},
  5554: {'rank': 6996, 'rarity': 'Common'},
  5555: {'rank': 1859, 'rarity': 'Rare'},
  5556: {'rank': 5550, 'rarity': 'Common'},
  5557: {'rank': 2286, 'rarity': 'Rare'},
  5558: {'rank': 2892, 'rarity': 'Uncommon'},
  5559: {'rank': 5986, 'rarity': 'Common'},
  5560: {'rank': 1065, 'rarity': 'Rare'},
  5561: {'rank': 3857, 'rarity': 'Uncommon'},
  5562: {'rank': 1190, 'rarity': 'Rare'},
  5563: {'rank': 4946, 'rarity': 'Uncommon'},
  5564: {'rank': 2221, 'rarity': 'Rare'},
  5565: {'rank': 9158, 'rarity': 'Common'},
  5566: {'rank': 3502, 'rarity': 'Uncommon'},
  5567: {'rank': 2645, 'rarity': 'Uncommon'},
  5568: {'rank': 4277, 'rarity': 'Uncommon'},
  5569: {'rank': 3893, 'rarity': 'Uncommon'},
  5570: {'rank': 7255, 'rarity': 'Common'},
  5571: {'rank': 4195, 'rarity': 'Uncommon'},
  5572: {'rank': 6675, 'rarity': 'Common'},
  5573: {'rank': 6222, 'rarity': 'Common'},
  5574: {'rank': 9311, 'rarity': 'Common'},
  5575: {'rank': 7097, 'rarity': 'Common'},
  5576: {'rank': 287, 'rarity': 'Epic'},
  5577: {'rank': 1830, 'rarity': 'Rare'},
  5578: {'rank': 5149, 'rarity': 'Common'},
  5579: {'rank': 8872, 'rarity': 'Common'},
  5580: {'rank': 1507, 'rarity': 'Rare'},
  5581: {'rank': 2747, 'rarity': 'Uncommon'},
  5582: {'rank': 1810, 'rarity': 'Rare'},
  5583: {'rank': 2605, 'rarity': 'Uncommon'},
  5584: {'rank': 1294, 'rarity': 'Rare'},
  5585: {'rank': 3065, 'rarity': 'Uncommon'},
  5586: {'rank': 3876, 'rarity': 'Uncommon'},
  5587: {'rank': 9746, 'rarity': 'Common'},
  5588: {'rank': 876, 'rarity': 'Epic'},
  5589: {'rank': 4390, 'rarity': 'Uncommon'},
  5590: {'rank': 2834, 'rarity': 'Uncommon'},
  5591: {'rank': 2579, 'rarity': 'Uncommon'},
  5592: {'rank': 3317, 'rarity': 'Uncommon'},
  5593: {'rank': 2145, 'rarity': 'Rare'},
  5594: {'rank': 3082, 'rarity': 'Uncommon'},
  5595: {'rank': 3561, 'rarity': 'Uncommon'},
  5596: {'rank': 5027, 'rarity': 'Common'},
  5597: {'rank': 54, 'rarity': 'Legendary'},
  5598: {'rank': 1146, 'rarity': 'Rare'},
  5599: {'rank': 2922, 'rarity': 'Uncommon'},
  5600: {'rank': 1681, 'rarity': 'Rare'},
  5601: {'rank': 7430, 'rarity': 'Common'},
  5602: {'rank': 5490, 'rarity': 'Common'},
  5603: {'rank': 5875, 'rarity': 'Common'},
  5604: {'rank': 1625, 'rarity': 'Rare'},
  5605: {'rank': 5224, 'rarity': 'Common'},
  5606: {'rank': 1076, 'rarity': 'Rare'},
  5607: {'rank': 6968, 'rarity': 'Common'},
  5608: {'rank': 1145, 'rarity': 'Rare'},
  5609: {'rank': 5703, 'rarity': 'Common'},
  5610: {'rank': 852, 'rarity': 'Epic'},
  5611: {'rank': 3477, 'rarity': 'Uncommon'},
  5612: {'rank': 422, 'rarity': 'Epic'},
  5613: {'rank': 9909, 'rarity': 'Common'},
  5614: {'rank': 4916, 'rarity': 'Uncommon'},
  5615: {'rank': 4530, 'rarity': 'Uncommon'},
  5616: {'rank': 1361, 'rarity': 'Rare'},
  5617: {'rank': 5508, 'rarity': 'Common'},
  5618: {'rank': 6736, 'rarity': 'Common'},
  5619: {'rank': 1312, 'rarity': 'Rare'},
  5620: {'rank': 5114, 'rarity': 'Common'},
  5621: {'rank': 1518, 'rarity': 'Rare'},
  5622: {'rank': 1148, 'rarity': 'Rare'},
  5623: {'rank': 5969, 'rarity': 'Common'},
  5624: {'rank': 9781, 'rarity': 'Common'},
  5625: {'rank': 7509, 'rarity': 'Common'},
  5626: {'rank': 2682, 'rarity': 'Uncommon'},
  5627: {'rank': 5349, 'rarity': 'Common'},
  5628: {'rank': 9263, 'rarity': 'Common'},
  5629: {'rank': 7473, 'rarity': 'Common'},
  5630: {'rank': 7610, 'rarity': 'Common'},
  5631: {'rank': 9268, 'rarity': 'Common'},
  5632: {'rank': 3550, 'rarity': 'Uncommon'},
  5633: {'rank': 725, 'rarity': 'Epic'},
  5634: {'rank': 5300, 'rarity': 'Common'},
  5635: {'rank': 3214, 'rarity': 'Uncommon'},
  5636: {'rank': 3608, 'rarity': 'Uncommon'},
  5637: {'rank': 4477, 'rarity': 'Uncommon'},
  5638: {'rank': 5675, 'rarity': 'Common'},
  5639: {'rank': 9654, 'rarity': 'Common'},
  5640: {'rank': 2237, 'rarity': 'Rare'},
  5641: {'rank': 1304, 'rarity': 'Rare'},
  5642: {'rank': 8524, 'rarity': 'Common'},
  5643: {'rank': 1607, 'rarity': 'Rare'},
  5644: {'rank': 5324, 'rarity': 'Common'},
  5645: {'rank': 3161, 'rarity': 'Uncommon'},
  5646: {'rank': 8220, 'rarity': 'Common'},
  5647: {'rank': 7260, 'rarity': 'Common'},
  5648: {'rank': 3450, 'rarity': 'Uncommon'},
  5649: {'rank': 2031, 'rarity': 'Rare'},
  5650: {'rank': 1860, 'rarity': 'Rare'},
  5651: {'rank': 6940, 'rarity': 'Common'},
  5652: {'rank': 5497, 'rarity': 'Common'},
  5653: {'rank': 4927, 'rarity': 'Uncommon'},
  5654: {'rank': 2984, 'rarity': 'Uncommon'},
  5655: {'rank': 7161, 'rarity': 'Common'},
  5656: {'rank': 8105, 'rarity': 'Common'},
  5657: {'rank': 8574, 'rarity': 'Common'},
  5658: {'rank': 4639, 'rarity': 'Uncommon'},
  5659: {'rank': 990, 'rarity': 'Epic'},
  5660: {'rank': 6267, 'rarity': 'Common'},
  5661: {'rank': 8075, 'rarity': 'Common'},
  5662: {'rank': 1220, 'rarity': 'Rare'},
  5663: {'rank': 7321, 'rarity': 'Common'},
  5664: {'rank': 2391, 'rarity': 'Rare'},
  5665: {'rank': 9484, 'rarity': 'Common'},
  5666: {'rank': 6652, 'rarity': 'Common'},
  5667: {'rank': 3745, 'rarity': 'Uncommon'},
  5668: {'rank': 1204, 'rarity': 'Rare'},
  5669: {'rank': 4522, 'rarity': 'Uncommon'},
  5670: {'rank': 3639, 'rarity': 'Uncommon'},
  5671: {'rank': 636, 'rarity': 'Epic'},
  5672: {'rank': 3256, 'rarity': 'Uncommon'},
  5673: {'rank': 1656, 'rarity': 'Rare'},
  5674: {'rank': 8040, 'rarity': 'Common'},
  5675: {'rank': 3868, 'rarity': 'Uncommon'},
  5676: {'rank': 7431, 'rarity': 'Common'},
  5677: {'rank': 2222, 'rarity': 'Rare'},
  5678: {'rank': 7641, 'rarity': 'Common'},
  5679: {'rank': 1209, 'rarity': 'Rare'},
  5680: {'rank': 5528, 'rarity': 'Common'},
  5681: {'rank': 3945, 'rarity': 'Uncommon'},
  5682: {'rank': 7131, 'rarity': 'Common'},
  5683: {'rank': 9639, 'rarity': 'Common'},
  5684: {'rank': 4913, 'rarity': 'Uncommon'},
  5685: {'rank': 7726, 'rarity': 'Common'},
  5686: {'rank': 1609, 'rarity': 'Rare'},
  5687: {'rank': 9281, 'rarity': 'Common'},
  5688: {'rank': 9404, 'rarity': 'Common'},
  5689: {'rank': 9270, 'rarity': 'Common'},
  5690: {'rank': 3476, 'rarity': 'Uncommon'},
  5691: {'rank': 5388, 'rarity': 'Common'},
  5692: {'rank': 2727, 'rarity': 'Uncommon'},
  5693: {'rank': 123, 'rarity': 'Epic'},
  5694: {'rank': 5718, 'rarity': 'Common'},
  5695: {'rank': 790, 'rarity': 'Epic'},
  5696: {'rank': 6207, 'rarity': 'Common'},
  5697: {'rank': 4428, 'rarity': 'Uncommon'},
  5698: {'rank': 5135, 'rarity': 'Common'},
  5699: {'rank': 264, 'rarity': 'Epic'},
  5700: {'rank': 6444, 'rarity': 'Common'},
  5701: {'rank': 6710, 'rarity': 'Common'},
  5702: {'rank': 2083, 'rarity': 'Rare'},
  5703: {'rank': 1789, 'rarity': 'Rare'},
  5704: {'rank': 7804, 'rarity': 'Common'},
  5705: {'rank': 433, 'rarity': 'Epic'},
  5706: {'rank': 4422, 'rarity': 'Uncommon'},
  5707: {'rank': 4673, 'rarity': 'Uncommon'},
  5708: {'rank': 4462, 'rarity': 'Uncommon'},
  5709: {'rank': 5408, 'rarity': 'Common'},
  5710: {'rank': 3974, 'rarity': 'Uncommon'},
  5711: {'rank': 9739, 'rarity': 'Common'},
  5712: {'rank': 5755, 'rarity': 'Common'},
  5713: {'rank': 6914, 'rarity': 'Common'},
  5714: {'rank': 7913, 'rarity': 'Common'},
  5715: {'rank': 2811, 'rarity': 'Uncommon'},
  5716: {'rank': 6566, 'rarity': 'Common'},
  5717: {'rank': 3454, 'rarity': 'Uncommon'},
  5718: {'rank': 2381, 'rarity': 'Rare'},
  5719: {'rank': 6536, 'rarity': 'Common'},
  5720: {'rank': 1881, 'rarity': 'Rare'},
  5721: {'rank': 7179, 'rarity': 'Common'},
  5722: {'rank': 7743, 'rarity': 'Common'},
  5723: {'rank': 6278, 'rarity': 'Common'},
  5724: {'rank': 3310, 'rarity': 'Uncommon'},
  5725: {'rank': 7033, 'rarity': 'Common'},
  5726: {'rank': 2177, 'rarity': 'Rare'},
  5727: {'rank': 7712, 'rarity': 'Common'},
  5728: {'rank': 4198, 'rarity': 'Uncommon'},
  5729: {'rank': 5249, 'rarity': 'Common'},
  5730: {'rank': 8087, 'rarity': 'Common'},
  5731: {'rank': 8492, 'rarity': 'Common'},
  5732: {'rank': 3113, 'rarity': 'Uncommon'},
  5733: {'rank': 7426, 'rarity': 'Common'},
  5734: {'rank': 8471, 'rarity': 'Common'},
  5735: {'rank': 7387, 'rarity': 'Common'},
  5736: {'rank': 5700, 'rarity': 'Common'},
  5737: {'rank': 9841, 'rarity': 'Common'},
  5738: {'rank': 8262, 'rarity': 'Common'},
  5739: {'rank': 4602, 'rarity': 'Uncommon'},
  5740: {'rank': 2314, 'rarity': 'Rare'},
  5741: {'rank': 9176, 'rarity': 'Common'},
  5742: {'rank': 633, 'rarity': 'Epic'},
  5743: {'rank': 9067, 'rarity': 'Common'},
  5744: {'rank': 9584, 'rarity': 'Common'},
  5745: {'rank': 13, 'rarity': 'Legendary'},
  5746: {'rank': 8930, 'rarity': 'Common'},
  5747: {'rank': 2790, 'rarity': 'Uncommon'},
  5748: {'rank': 4361, 'rarity': 'Uncommon'},
  5749: {'rank': 7835, 'rarity': 'Common'},
  5750: {'rank': 618, 'rarity': 'Epic'},
  5751: {'rank': 6977, 'rarity': 'Common'},
  5752: {'rank': 8365, 'rarity': 'Common'},
  5753: {'rank': 1577, 'rarity': 'Rare'},
  5754: {'rank': 2318, 'rarity': 'Rare'},
  5755: {'rank': 6487, 'rarity': 'Common'},
  5756: {'rank': 2268, 'rarity': 'Rare'},
  5757: {'rank': 9017, 'rarity': 'Common'},
  5758: {'rank': 7813, 'rarity': 'Common'},
  5759: {'rank': 9893, 'rarity': 'Common'},
  5760: {'rank': 6531, 'rarity': 'Common'},
  5761: {'rank': 6144, 'rarity': 'Common'},
  5762: {'rank': 4270, 'rarity': 'Uncommon'},
  5763: {'rank': 2454, 'rarity': 'Rare'},
  5764: {'rank': 8085, 'rarity': 'Common'},
  5765: {'rank': 2611, 'rarity': 'Uncommon'},
  5766: {'rank': 404, 'rarity': 'Epic'},
  5767: {'rank': 113, 'rarity': 'Epic'},
  5768: {'rank': 9110, 'rarity': 'Common'},
  5769: {'rank': 1797, 'rarity': 'Rare'},
  5770: {'rank': 8540, 'rarity': 'Common'},
  5771: {'rank': 5422, 'rarity': 'Common'},
  5772: {'rank': 2672, 'rarity': 'Uncommon'},
  5773: {'rank': 4814, 'rarity': 'Uncommon'},
  5774: {'rank': 2421, 'rarity': 'Rare'},
  5775: {'rank': 821, 'rarity': 'Epic'},
  5776: {'rank': 4206, 'rarity': 'Uncommon'},
  5777: {'rank': 6638, 'rarity': 'Common'},
  5778: {'rank': 7696, 'rarity': 'Common'},
  5779: {'rank': 7503, 'rarity': 'Common'},
  5780: {'rank': 5110, 'rarity': 'Common'},
  5781: {'rank': 4052, 'rarity': 'Uncommon'},
  5782: {'rank': 1725, 'rarity': 'Rare'},
  5783: {'rank': 9337, 'rarity': 'Common'},
  5784: {'rank': 5306, 'rarity': 'Common'},
  5785: {'rank': 4291, 'rarity': 'Uncommon'},
  5786: {'rank': 5021, 'rarity': 'Common'},
  5787: {'rank': 9108, 'rarity': 'Common'},
  5788: {'rank': 3760, 'rarity': 'Uncommon'},
  5789: {'rank': 3307, 'rarity': 'Uncommon'},
  5790: {'rank': 4833, 'rarity': 'Uncommon'},
  5791: {'rank': 703, 'rarity': 'Epic'},
  5792: {'rank': 8680, 'rarity': 'Common'},
  5793: {'rank': 9972, 'rarity': 'Common'},
  5794: {'rank': 3381, 'rarity': 'Uncommon'},
  5795: {'rank': 936, 'rarity': 'Epic'},
  5796: {'rank': 8771, 'rarity': 'Common'},
  5797: {'rank': 9703, 'rarity': 'Common'},
  5798: {'rank': 9148, 'rarity': 'Common'},
  5799: {'rank': 2551, 'rarity': 'Uncommon'},
  5800: {'rank': 4956, 'rarity': 'Uncommon'},
  5801: {'rank': 8497, 'rarity': 'Common'},
  5802: {'rank': 8359, 'rarity': 'Common'},
  5803: {'rank': 8954, 'rarity': 'Common'},
  5804: {'rank': 5698, 'rarity': 'Common'},
  5805: {'rank': 2504, 'rarity': 'Uncommon'},
  5806: {'rank': 5895, 'rarity': 'Common'},
  5807: {'rank': 3341, 'rarity': 'Uncommon'},
  5808: {'rank': 6205, 'rarity': 'Common'},
  5809: {'rank': 992, 'rarity': 'Epic'},
  5810: {'rank': 5947, 'rarity': 'Common'},
  5811: {'rank': 8123, 'rarity': 'Common'},
  5812: {'rank': 6880, 'rarity': 'Common'},
  5813: {'rank': 4030, 'rarity': 'Uncommon'},
  5814: {'rank': 8753, 'rarity': 'Common'},
  5815: {'rank': 1808, 'rarity': 'Rare'},
  5816: {'rank': 673, 'rarity': 'Epic'},
  5817: {'rank': 4694, 'rarity': 'Uncommon'},
  5818: {'rank': 4147, 'rarity': 'Uncommon'},
  5819: {'rank': 6009, 'rarity': 'Common'},
  5820: {'rank': 5741, 'rarity': 'Common'},
  5821: {'rank': 9119, 'rarity': 'Common'},
  5822: {'rank': 3607, 'rarity': 'Uncommon'},
  5823: {'rank': 3645, 'rarity': 'Uncommon'},
  5824: {'rank': 6010, 'rarity': 'Common'},
  5825: {'rank': 4475, 'rarity': 'Uncommon'},
  5826: {'rank': 2402, 'rarity': 'Rare'},
  5827: {'rank': 978, 'rarity': 'Epic'},
  5828: {'rank': 5051, 'rarity': 'Common'},
  5829: {'rank': 9187, 'rarity': 'Common'},
  5830: {'rank': 7000, 'rarity': 'Common'},
  5831: {'rank': 1316, 'rarity': 'Rare'},
  5832: {'rank': 3490, 'rarity': 'Uncommon'},
  5833: {'rank': 6839, 'rarity': 'Common'},
  5834: {'rank': 6425, 'rarity': 'Common'},
  5835: {'rank': 5085, 'rarity': 'Common'},
  5836: {'rank': 7160, 'rarity': 'Common'},
  5837: {'rank': 2323, 'rarity': 'Rare'},
  5838: {'rank': 1238, 'rarity': 'Rare'},
  5839: {'rank': 8520, 'rarity': 'Common'},
  5840: {'rank': 3723, 'rarity': 'Uncommon'},
  5841: {'rank': 3889, 'rarity': 'Uncommon'},
  5842: {'rank': 8387, 'rarity': 'Common'},
  5843: {'rank': 1197, 'rarity': 'Rare'},
  5844: {'rank': 8995, 'rarity': 'Common'},
  5845: {'rank': 6228, 'rarity': 'Common'},
  5846: {'rank': 9217, 'rarity': 'Common'},
  5847: {'rank': 8014, 'rarity': 'Common'},
  5848: {'rank': 2262, 'rarity': 'Rare'},
  5849: {'rank': 8862, 'rarity': 'Common'},
  5850: {'rank': 6949, 'rarity': 'Common'},
  5851: {'rank': 7662, 'rarity': 'Common'},
  5852: {'rank': 2196, 'rarity': 'Rare'},
  5853: {'rank': 4262, 'rarity': 'Uncommon'},
  5854: {'rank': 8579, 'rarity': 'Common'},
  5855: {'rank': 5487, 'rarity': 'Common'},
  5856: {'rank': 2153, 'rarity': 'Rare'},
  5857: {'rank': 8079, 'rarity': 'Common'},
  5858: {'rank': 5795, 'rarity': 'Common'},
  5859: {'rank': 8418, 'rarity': 'Common'},
  5860: {'rank': 6862, 'rarity': 'Common'},
  5861: {'rank': 1890, 'rarity': 'Rare'},
  5862: {'rank': 9964, 'rarity': 'Common'},
  5863: {'rank': 2363, 'rarity': 'Rare'},
  5864: {'rank': 9889, 'rarity': 'Common'},
  5865: {'rank': 9823, 'rarity': 'Common'},
  5866: {'rank': 1208, 'rarity': 'Rare'},
  5867: {'rank': 8221, 'rarity': 'Common'},
  5868: {'rank': 5676, 'rarity': 'Common'},
  5869: {'rank': 9322, 'rarity': 'Common'},
  5870: {'rank': 8895, 'rarity': 'Common'},
  5871: {'rank': 8371, 'rarity': 'Common'},
  5872: {'rank': 3851, 'rarity': 'Uncommon'},
  5873: {'rank': 4629, 'rarity': 'Uncommon'},
  5874: {'rank': 7212, 'rarity': 'Common'},
  5875: {'rank': 6199, 'rarity': 'Common'},
  5876: {'rank': 4401, 'rarity': 'Uncommon'},
  5877: {'rank': 2379, 'rarity': 'Rare'},
  5878: {'rank': 9821, 'rarity': 'Common'},
  5879: {'rank': 6189, 'rarity': 'Common'},
  5880: {'rank': 5847, 'rarity': 'Common'},
  5881: {'rank': 8277, 'rarity': 'Common'},
  5882: {'rank': 7634, 'rarity': 'Common'},
  5883: {'rank': 1674, 'rarity': 'Rare'},
  5884: {'rank': 5532, 'rarity': 'Common'},
  5885: {'rank': 1792, 'rarity': 'Rare'},
  5886: {'rank': 7728, 'rarity': 'Common'},
  5887: {'rank': 9799, 'rarity': 'Common'},
  5888: {'rank': 3332, 'rarity': 'Uncommon'},
  5889: {'rank': 8041, 'rarity': 'Common'},
  5890: {'rank': 4280, 'rarity': 'Uncommon'},
  5891: {'rank': 9778, 'rarity': 'Common'},
  5892: {'rank': 3478, 'rarity': 'Uncommon'},
  5893: {'rank': 5389, 'rarity': 'Common'},
  5894: {'rank': 244, 'rarity': 'Epic'},
  5895: {'rank': 918, 'rarity': 'Epic'},
  5896: {'rank': 8294, 'rarity': 'Common'},
  5897: {'rank': 3429, 'rarity': 'Uncommon'},
  5898: {'rank': 1488, 'rarity': 'Rare'},
  5899: {'rank': 5011, 'rarity': 'Common'},
  5900: {'rank': 2144, 'rarity': 'Rare'},
  5901: {'rank': 7243, 'rarity': 'Common'},
  5902: {'rank': 9850, 'rarity': 'Common'},
  5903: {'rank': 9502, 'rarity': 'Common'},
  5904: {'rank': 7182, 'rarity': 'Common'},
  5905: {'rank': 9023, 'rarity': 'Common'},
  5906: {'rank': 8217, 'rarity': 'Common'},
  5907: {'rank': 8942, 'rarity': 'Common'},
  5908: {'rank': 3169, 'rarity': 'Uncommon'},
  5909: {'rank': 7401, 'rarity': 'Common'},
  5910: {'rank': 653, 'rarity': 'Epic'},
  5911: {'rank': 2428, 'rarity': 'Rare'},
  5912: {'rank': 2367, 'rarity': 'Rare'},
  5913: {'rank': 7275, 'rarity': 'Common'},
  5914: {'rank': 4750, 'rarity': 'Uncommon'},
  5915: {'rank': 4643, 'rarity': 'Uncommon'},
  5916: {'rank': 4127, 'rarity': 'Uncommon'},
  5917: {'rank': 191, 'rarity': 'Epic'},
  5918: {'rank': 3180, 'rarity': 'Uncommon'},
  5919: {'rank': 7871, 'rarity': 'Common'},
  5920: {'rank': 7266, 'rarity': 'Common'},
  5921: {'rank': 9870, 'rarity': 'Common'},
  5922: {'rank': 9203, 'rarity': 'Common'},
  5923: {'rank': 694, 'rarity': 'Epic'},
  5924: {'rank': 9256, 'rarity': 'Common'},
  5925: {'rank': 6108, 'rarity': 'Common'},
  5926: {'rank': 9913, 'rarity': 'Common'},
  5927: {'rank': 4770, 'rarity': 'Uncommon'},
  5928: {'rank': 6355, 'rarity': 'Common'},
  5929: {'rank': 5417, 'rarity': 'Common'},
  5930: {'rank': 8059, 'rarity': 'Common'},
  5931: {'rank': 5821, 'rarity': 'Common'},
  5932: {'rank': 2655, 'rarity': 'Uncommon'},
  5933: {'rank': 6755, 'rarity': 'Common'},
  5934: {'rank': 2078, 'rarity': 'Rare'},
  5935: {'rank': 6202, 'rarity': 'Common'},
  5936: {'rank': 4879, 'rarity': 'Uncommon'},
  5937: {'rank': 2621, 'rarity': 'Uncommon'},
  5938: {'rank': 1955, 'rarity': 'Rare'},
  5939: {'rank': 2616, 'rarity': 'Uncommon'},
  5940: {'rank': 1761, 'rarity': 'Rare'},
  5941: {'rank': 3841, 'rarity': 'Uncommon'},
  5942: {'rank': 9136, 'rarity': 'Common'},
  5943: {'rank': 2896, 'rarity': 'Uncommon'},
  5944: {'rank': 5412, 'rarity': 'Common'},
  5945: {'rank': 4265, 'rarity': 'Uncommon'},
  5946: {'rank': 3892, 'rarity': 'Uncommon'},
  5947: {'rank': 6394, 'rarity': 'Common'},
  5948: {'rank': 2279, 'rarity': 'Rare'},
  5949: {'rank': 6575, 'rarity': 'Common'},
  5950: {'rank': 1133, 'rarity': 'Rare'},
  5951: {'rank': 5169, 'rarity': 'Common'},
  5952: {'rank': 371, 'rarity': 'Epic'},
  5953: {'rank': 6453, 'rarity': 'Common'},
  5954: {'rank': 9532, 'rarity': 'Common'},
  5955: {'rank': 3254, 'rarity': 'Uncommon'},
  5956: {'rank': 5371, 'rarity': 'Common'},
  5957: {'rank': 6863, 'rarity': 'Common'},
  5958: {'rank': 7406, 'rarity': 'Common'},
  5959: {'rank': 7606, 'rarity': 'Common'},
  5960: {'rank': 4434, 'rarity': 'Uncommon'},
  5961: {'rank': 7537, 'rarity': 'Common'},
  5962: {'rank': 4610, 'rarity': 'Uncommon'},
  5963: {'rank': 9760, 'rarity': 'Common'},
  5964: {'rank': 9487, 'rarity': 'Common'},
  5965: {'rank': 7719, 'rarity': 'Common'},
  5966: {'rank': 3424, 'rarity': 'Uncommon'},
  5967: {'rank': 4542, 'rarity': 'Uncommon'},
  5968: {'rank': 5146, 'rarity': 'Common'},
  5969: {'rank': 9211, 'rarity': 'Common'},
  5970: {'rank': 3233, 'rarity': 'Uncommon'},
  5971: {'rank': 4524, 'rarity': 'Uncommon'},
  5972: {'rank': 9589, 'rarity': 'Common'},
  5973: {'rank': 6642, 'rarity': 'Common'},
  5974: {'rank': 1311, 'rarity': 'Rare'},
  5975: {'rank': 9472, 'rarity': 'Common'},
  5976: {'rank': 8922, 'rarity': 'Common'},
  5977: {'rank': 5264, 'rarity': 'Common'},
  5978: {'rank': 7710, 'rarity': 'Common'},
  5979: {'rank': 707, 'rarity': 'Epic'},
  5980: {'rank': 5678, 'rarity': 'Common'},
  5981: {'rank': 6095, 'rarity': 'Common'},
  5982: {'rank': 2451, 'rarity': 'Rare'},
  5983: {'rank': 8808, 'rarity': 'Common'},
  5984: {'rank': 3751, 'rarity': 'Uncommon'},
  5985: {'rank': 67, 'rarity': 'Legendary'},
  5986: {'rank': 9607, 'rarity': 'Common'},
  5987: {'rank': 8496, 'rarity': 'Common'},
  5988: {'rank': 4649, 'rarity': 'Uncommon'},
  5989: {'rank': 6130, 'rarity': 'Common'},
  5990: {'rank': 1694, 'rarity': 'Rare'},
  5991: {'rank': 9204, 'rarity': 'Common'},
  5992: {'rank': 6946, 'rarity': 'Common'},
  5993: {'rank': 1412, 'rarity': 'Rare'},
  5994: {'rank': 8646, 'rarity': 'Common'},
  5995: {'rank': 3358, 'rarity': 'Uncommon'},
  5996: {'rank': 2495, 'rarity': 'Rare'},
  5997: {'rank': 8534, 'rarity': 'Common'},
  5998: {'rank': 8202, 'rarity': 'Common'},
  5999: {'rank': 271, 'rarity': 'Epic'},
  6000: {'rank': 5808, 'rarity': 'Common'},
  6001: {'rank': 392, 'rarity': 'Epic'},
  6002: {'rank': 5820, 'rarity': 'Common'},
  6003: {'rank': 4772, 'rarity': 'Uncommon'},
  6004: {'rank': 4123, 'rarity': 'Uncommon'},
  6005: {'rank': 7156, 'rarity': 'Common'},
  6006: {'rank': 2600, 'rarity': 'Uncommon'},
  6007: {'rank': 8568, 'rarity': 'Common'},
  6008: {'rank': 5279, 'rarity': 'Common'},
  6009: {'rank': 4443, 'rarity': 'Uncommon'},
  6010: {'rank': 2857, 'rarity': 'Uncommon'},
  6011: {'rank': 4685, 'rarity': 'Uncommon'},
  6012: {'rank': 5075, 'rarity': 'Common'},
  6013: {'rank': 9724, 'rarity': 'Common'},
  6014: {'rank': 7658, 'rarity': 'Common'},
  6015: {'rank': 3184, 'rarity': 'Uncommon'},
  6016: {'rank': 6428, 'rarity': 'Common'},
  6017: {'rank': 4271, 'rarity': 'Uncommon'},
  6018: {'rank': 3613, 'rarity': 'Uncommon'},
  6019: {'rank': 9953, 'rarity': 'Common'},
  6020: {'rank': 9416, 'rarity': 'Common'},
  6021: {'rank': 1250, 'rarity': 'Rare'},
  6022: {'rank': 4841, 'rarity': 'Uncommon'},
  6023: {'rank': 8423, 'rarity': 'Common'},
  6024: {'rank': 3913, 'rarity': 'Uncommon'},
  6025: {'rank': 2930, 'rarity': 'Uncommon'},
  6026: {'rank': 2105, 'rarity': 'Rare'},
  6027: {'rank': 4816, 'rarity': 'Uncommon'},
  6028: {'rank': 6666, 'rarity': 'Common'},
  6029: {'rank': 3562, 'rarity': 'Uncommon'},
  6030: {'rank': 3831, 'rarity': 'Uncommon'},
  6031: {'rank': 1371, 'rarity': 'Rare'},
  6032: {'rank': 5353, 'rarity': 'Common'},
  6033: {'rank': 3617, 'rarity': 'Uncommon'},
  6034: {'rank': 9793, 'rarity': 'Common'},
  6035: {'rank': 7792, 'rarity': 'Common'},
  6036: {'rank': 5325, 'rarity': 'Common'},
  6037: {'rank': 4094, 'rarity': 'Uncommon'},
  6038: {'rank': 6072, 'rarity': 'Common'},
  6039: {'rank': 9314, 'rarity': 'Common'},
  6040: {'rank': 1965, 'rarity': 'Rare'},
  6041: {'rank': 947, 'rarity': 'Epic'},
  6042: {'rank': 6356, 'rarity': 'Common'},
  6043: {'rank': 1141, 'rarity': 'Rare'},
  6044: {'rank': 689, 'rarity': 'Epic'},
  6045: {'rank': 9348, 'rarity': 'Common'},
  6046: {'rank': 3997, 'rarity': 'Uncommon'},
  6047: {'rank': 5397, 'rarity': 'Common'},
  6048: {'rank': 2839, 'rarity': 'Uncommon'},
  6049: {'rank': 4189, 'rarity': 'Uncommon'},
  6050: {'rank': 696, 'rarity': 'Epic'},
  6051: {'rank': 9541, 'rarity': 'Common'},
  6052: {'rank': 1020, 'rarity': 'Rare'},
  6053: {'rank': 7923, 'rarity': 'Common'},
  6054: {'rank': 5257, 'rarity': 'Common'},
  6055: {'rank': 7993, 'rarity': 'Common'},
  6056: {'rank': 9341, 'rarity': 'Common'},
  6057: {'rank': 9901, 'rarity': 'Common'},
  6058: {'rank': 3000, 'rarity': 'Uncommon'},
  6059: {'rank': 3227, 'rarity': 'Uncommon'},
  6060: {'rank': 7467, 'rarity': 'Common'},
  6061: {'rank': 8559, 'rarity': 'Common'},
  6062: {'rank': 3268, 'rarity': 'Uncommon'},
  6063: {'rank': 1471, 'rarity': 'Rare'},
  6064: {'rank': 2666, 'rarity': 'Uncommon'},
  6065: {'rank': 1664, 'rarity': 'Rare'},
  6066: {'rank': 809, 'rarity': 'Epic'},
  6067: {'rank': 3475, 'rarity': 'Uncommon'},
  6068: {'rank': 7015, 'rarity': 'Common'},
  6069: {'rank': 774, 'rarity': 'Epic'},
  6070: {'rank': 9516, 'rarity': 'Common'},
  6071: {'rank': 8158, 'rarity': 'Common'},
  6072: {'rank': 963, 'rarity': 'Epic'},
  6073: {'rank': 6170, 'rarity': 'Common'},
  6074: {'rank': 1986, 'rarity': 'Rare'},
  6075: {'rank': 4308, 'rarity': 'Uncommon'},
  6076: {'rank': 4593, 'rarity': 'Uncommon'},
  6077: {'rank': 1353, 'rarity': 'Rare'},
  6078: {'rank': 9327, 'rarity': 'Common'},
  6079: {'rank': 3127, 'rarity': 'Uncommon'},
  6080: {'rank': 6422, 'rarity': 'Common'},
  6081: {'rank': 8928, 'rarity': 'Common'},
  6082: {'rank': 5293, 'rarity': 'Common'},
  6083: {'rank': 8858, 'rarity': 'Common'},
  6084: {'rank': 6983, 'rarity': 'Common'},
  6085: {'rank': 683, 'rarity': 'Epic'},
  6086: {'rank': 6555, 'rarity': 'Common'},
  6087: {'rank': 9482, 'rarity': 'Common'},
  6088: {'rank': 6488, 'rarity': 'Common'},
  6089: {'rank': 1310, 'rarity': 'Rare'},
  6090: {'rank': 1805, 'rarity': 'Rare'},
  6091: {'rank': 6483, 'rarity': 'Common'},
  6092: {'rank': 4311, 'rarity': 'Uncommon'},
  6093: {'rank': 243, 'rarity': 'Epic'},
  6094: {'rank': 8452, 'rarity': 'Common'},
  6095: {'rank': 8102, 'rarity': 'Common'},
  6096: {'rank': 9350, 'rarity': 'Common'},
  6097: {'rank': 8929, 'rarity': 'Common'},
  6098: {'rank': 8191, 'rarity': 'Common'},
  6099: {'rank': 9083, 'rarity': 'Common'},
  6100: {'rank': 8909, 'rarity': 'Common'},
  6101: {'rank': 12, 'rarity': 'Legendary'},
  6102: {'rank': 4275, 'rarity': 'Uncommon'},
  6103: {'rank': 6127, 'rarity': 'Common'},
  6104: {'rank': 6644, 'rarity': 'Common'},
  6105: {'rank': 2846, 'rarity': 'Uncommon'},
  6106: {'rank': 126, 'rarity': 'Epic'},
  6107: {'rank': 1409, 'rarity': 'Rare'},
  6108: {'rank': 4881, 'rarity': 'Uncommon'},
  6109: {'rank': 7651, 'rarity': 'Common'},
  6110: {'rank': 4057, 'rarity': 'Uncommon'},
  6111: {'rank': 6721, 'rarity': 'Common'},
  6112: {'rank': 6850, 'rarity': 'Common'},
  6113: {'rank': 387, 'rarity': 'Epic'},
  6114: {'rank': 2019, 'rarity': 'Rare'},
  6115: {'rank': 2794, 'rarity': 'Uncommon'},
  6116: {'rank': 3699, 'rarity': 'Uncommon'},
  6117: {'rank': 2717, 'rarity': 'Uncommon'},
  6118: {'rank': 6161, 'rarity': 'Common'},
  6119: {'rank': 9862, 'rarity': 'Common'},
  6120: {'rank': 301, 'rarity': 'Epic'},
  6121: {'rank': 2884, 'rarity': 'Uncommon'},
  6122: {'rank': 6917, 'rarity': 'Common'},
  6123: {'rank': 2750, 'rarity': 'Uncommon'},
  6124: {'rank': 3861, 'rarity': 'Uncommon'},
  6125: {'rank': 6410, 'rarity': 'Common'},
  6126: {'rank': 4512, 'rarity': 'Uncommon'},
  6127: {'rank': 1844, 'rarity': 'Rare'},
  6128: {'rank': 1156, 'rarity': 'Rare'},
  6129: {'rank': 5630, 'rarity': 'Common'},
  6130: {'rank': 2519, 'rarity': 'Uncommon'},
  6131: {'rank': 7172, 'rarity': 'Common'},
  6132: {'rank': 2941, 'rarity': 'Uncommon'},
  6133: {'rank': 3093, 'rarity': 'Uncommon'},
  6134: {'rank': 5369, 'rarity': 'Common'},
  6135: {'rank': 4625, 'rarity': 'Uncommon'},
  6136: {'rank': 216, 'rarity': 'Epic'},
  6137: {'rank': 5478, 'rarity': 'Common'},
  6138: {'rank': 307, 'rarity': 'Epic'},
  6139: {'rank': 5277, 'rarity': 'Common'},
  6140: {'rank': 8460, 'rarity': 'Common'},
  6141: {'rank': 6897, 'rarity': 'Common'},
  6142: {'rank': 994, 'rarity': 'Epic'},
  6143: {'rank': 9084, 'rarity': 'Common'},
  6144: {'rank': 78, 'rarity': 'Legendary'},
  6145: {'rank': 7942, 'rarity': 'Common'},
  6146: {'rank': 8826, 'rarity': 'Common'},
  6147: {'rank': 5995, 'rarity': 'Common'},
  6148: {'rank': 4281, 'rarity': 'Uncommon'},
  6149: {'rank': 8718, 'rarity': 'Common'},
  6150: {'rank': 8804, 'rarity': 'Common'},
  6151: {'rank': 4048, 'rarity': 'Uncommon'},
  6152: {'rank': 8112, 'rarity': 'Common'},
  6153: {'rank': 3382, 'rarity': 'Uncommon'},
  6154: {'rank': 6915, 'rarity': 'Common'},
  6155: {'rank': 5425, 'rarity': 'Common'},
  6156: {'rank': 5241, 'rarity': 'Common'},
  6157: {'rank': 7640, 'rarity': 'Common'},
  6158: {'rank': 1897, 'rarity': 'Rare'},
  6159: {'rank': 1845, 'rarity': 'Rare'},
  6160: {'rank': 7351, 'rarity': 'Common'},
  6161: {'rank': 6633, 'rarity': 'Common'},
  6162: {'rank': 6074, 'rarity': 'Common'},
  6163: {'rank': 4430, 'rarity': 'Uncommon'},
  6164: {'rank': 7639, 'rarity': 'Common'},
  6165: {'rank': 5106, 'rarity': 'Common'},
  6166: {'rank': 3068, 'rarity': 'Uncommon'},
  6167: {'rank': 9247, 'rarity': 'Common'},
  6168: {'rank': 5319, 'rarity': 'Common'},
  6169: {'rank': 3544, 'rarity': 'Uncommon'},
  6170: {'rank': 7050, 'rarity': 'Common'},
  6171: {'rank': 1818, 'rarity': 'Rare'},
  6172: {'rank': 7841, 'rarity': 'Common'},
  6173: {'rank': 5667, 'rarity': 'Common'},
  6174: {'rank': 2674, 'rarity': 'Uncommon'},
  6175: {'rank': 5982, 'rarity': 'Common'},
  6176: {'rank': 3497, 'rarity': 'Uncommon'},
  6177: {'rank': 6851, 'rarity': 'Common'},
  6178: {'rank': 6961, 'rarity': 'Common'},
  6179: {'rank': 2232, 'rarity': 'Rare'},
  6180: {'rank': 4725, 'rarity': 'Uncommon'},
  6181: {'rank': 9186, 'rarity': 'Common'},
  6182: {'rank': 8362, 'rarity': 'Common'},
  6183: {'rank': 1261, 'rarity': 'Rare'},
  6184: {'rank': 4857, 'rarity': 'Uncommon'},
  6185: {'rank': 3567, 'rarity': 'Uncommon'},
  6186: {'rank': 1606, 'rarity': 'Rare'},
  6187: {'rank': 4774, 'rarity': 'Uncommon'},
  6188: {'rank': 7765, 'rarity': 'Common'},
  6189: {'rank': 5511, 'rarity': 'Common'},
  6190: {'rank': 612, 'rarity': 'Epic'},
  6191: {'rank': 7551, 'rarity': 'Common'},
  6192: {'rank': 17, 'rarity': 'Legendary'},
  6193: {'rank': 9053, 'rarity': 'Common'},
  6194: {'rank': 6456, 'rarity': 'Common'},
  6195: {'rank': 8819, 'rarity': 'Common'},
  6196: {'rank': 2240, 'rarity': 'Rare'},
  6197: {'rank': 6778, 'rarity': 'Common'},
  6198: {'rank': 1477, 'rarity': 'Rare'},
  6199: {'rank': 7213, 'rarity': 'Common'},
  6200: {'rank': 8208, 'rarity': 'Common'},
  6201: {'rank': 9936, 'rarity': 'Common'},
  6202: {'rank': 645, 'rarity': 'Epic'},
  6203: {'rank': 9635, 'rarity': 'Common'},
  6204: {'rank': 7635, 'rarity': 'Common'},
  6205: {'rank': 5530, 'rarity': 'Common'},
  6206: {'rank': 4596, 'rarity': 'Uncommon'},
  6207: {'rank': 6048, 'rarity': 'Common'},
  6208: {'rank': 4537, 'rarity': 'Uncommon'},
  6209: {'rank': 6131, 'rarity': 'Common'},
  6210: {'rank': 4086, 'rarity': 'Uncommon'},
  6211: {'rank': 3887, 'rarity': 'Uncommon'},
  6212: {'rank': 9121, 'rarity': 'Common'},
  6213: {'rank': 6766, 'rarity': 'Common'},
  6214: {'rank': 2702, 'rarity': 'Uncommon'},
  6215: {'rank': 8269, 'rarity': 'Common'},
  6216: {'rank': 4623, 'rarity': 'Uncommon'},
  6217: {'rank': 3388, 'rarity': 'Uncommon'},
  6218: {'rank': 6738, 'rarity': 'Common'},
  6219: {'rank': 5352, 'rarity': 'Common'},
  6220: {'rank': 1480, 'rarity': 'Rare'},
  6221: {'rank': 6049, 'rarity': 'Common'},
  6222: {'rank': 4480, 'rarity': 'Uncommon'},
  6223: {'rank': 6903, 'rarity': 'Common'},
  6224: {'rank': 5188, 'rarity': 'Common'},
  6225: {'rank': 6221, 'rarity': 'Common'},
  6226: {'rank': 9743, 'rarity': 'Common'},
  6227: {'rank': 503, 'rarity': 'Epic'},
  6228: {'rank': 6395, 'rarity': 'Common'},
  6229: {'rank': 9295, 'rarity': 'Common'},
  6230: {'rank': 8730, 'rarity': 'Common'},
  6231: {'rank': 8070, 'rarity': 'Common'},
  6232: {'rank': 6247, 'rarity': 'Common'},
  6233: {'rank': 8682, 'rarity': 'Common'},
  6234: {'rank': 6306, 'rarity': 'Common'},
  6235: {'rank': 3075, 'rarity': 'Uncommon'},
  6236: {'rank': 6037, 'rarity': 'Common'},
  6237: {'rank': 5023, 'rarity': 'Common'},
  6238: {'rank': 6057, 'rarity': 'Common'},
  6239: {'rank': 2051, 'rarity': 'Rare'},
  6240: {'rank': 6204, 'rarity': 'Common'},
  6241: {'rank': 6763, 'rarity': 'Common'},
  6242: {'rank': 7663, 'rarity': 'Common'},
  6243: {'rank': 1297, 'rarity': 'Rare'},
  6244: {'rank': 7934, 'rarity': 'Common'},
  6245: {'rank': 8326, 'rarity': 'Common'},
  6246: {'rank': 1482, 'rarity': 'Rare'},
  6247: {'rank': 7925, 'rarity': 'Common'},
  6248: {'rank': 5510, 'rarity': 'Common'},
  6249: {'rank': 6494, 'rarity': 'Common'},
  6250: {'rank': 4968, 'rarity': 'Uncommon'},
  6251: {'rank': 4348, 'rarity': 'Uncommon'},
  6252: {'rank': 4794, 'rarity': 'Uncommon'},
  6253: {'rank': 273, 'rarity': 'Epic'},
  6254: {'rank': 5192, 'rarity': 'Common'},
  6255: {'rank': 1164, 'rarity': 'Rare'},
  6256: {'rank': 5959, 'rarity': 'Common'},
  6257: {'rank': 2137, 'rarity': 'Rare'},
  6258: {'rank': 4453, 'rarity': 'Uncommon'},
  6259: {'rank': 373, 'rarity': 'Epic'},
  6260: {'rank': 7823, 'rarity': 'Common'},
  6261: {'rank': 1286, 'rarity': 'Rare'},
  6262: {'rank': 5623, 'rarity': 'Common'},
  6263: {'rank': 3566, 'rarity': 'Uncommon'},
  6264: {'rank': 3977, 'rarity': 'Uncommon'},
  6265: {'rank': 4829, 'rarity': 'Uncommon'},
  6266: {'rank': 4046, 'rarity': 'Uncommon'},
  6267: {'rank': 2390, 'rarity': 'Rare'},
  6268: {'rank': 9507, 'rarity': 'Common'},
  6269: {'rank': 8988, 'rarity': 'Common'},
  6270: {'rank': 3350, 'rarity': 'Uncommon'},
  6271: {'rank': 9997, 'rarity': 'Common'},
  6272: {'rank': 1558, 'rarity': 'Rare'},
  6273: {'rank': 5974, 'rarity': 'Common'},
  6274: {'rank': 6406, 'rarity': 'Common'},
  6275: {'rank': 785, 'rarity': 'Epic'},
  6276: {'rank': 9398, 'rarity': 'Common'},
  6277: {'rank': 175, 'rarity': 'Epic'},
  6278: {'rank': 1275, 'rarity': 'Rare'},
  6279: {'rank': 2836, 'rarity': 'Uncommon'},
  6280: {'rank': 7666, 'rarity': 'Common'},
  6281: {'rank': 7912, 'rarity': 'Common'},
  6282: {'rank': 6764, 'rarity': 'Common'},
  6283: {'rank': 2108, 'rarity': 'Rare'},
  6284: {'rank': 2770, 'rarity': 'Uncommon'},
  6285: {'rank': 5034, 'rarity': 'Common'},
  6286: {'rank': 5765, 'rarity': 'Common'},
  6287: {'rank': 8345, 'rarity': 'Common'},
  6288: {'rank': 4540, 'rarity': 'Uncommon'},
  6289: {'rank': 2850, 'rarity': 'Uncommon'},
  6290: {'rank': 474, 'rarity': 'Epic'},
  6291: {'rank': 7972, 'rarity': 'Common'},
  6292: {'rank': 4031, 'rarity': 'Uncommon'},
  6293: {'rank': 1415, 'rarity': 'Rare'},
  6294: {'rank': 6139, 'rarity': 'Common'},
  6295: {'rank': 3094, 'rarity': 'Uncommon'},
  6296: {'rank': 195, 'rarity': 'Epic'},
  6297: {'rank': 3235, 'rarity': 'Uncommon'},
  6298: {'rank': 8822, 'rarity': 'Common'},
  6299: {'rank': 3779, 'rarity': 'Uncommon'},
  6300: {'rank': 3243, 'rarity': 'Uncommon'},
  6301: {'rank': 5615, 'rarity': 'Common'},
  6302: {'rank': 5913, 'rarity': 'Common'},
  6303: {'rank': 7608, 'rarity': 'Common'},
  6304: {'rank': 2594, 'rarity': 'Uncommon'},
  6305: {'rank': 6160, 'rarity': 'Common'},
  6306: {'rank': 2780, 'rarity': 'Uncommon'},
  6307: {'rank': 5774, 'rarity': 'Common'},
  6308: {'rank': 5653, 'rarity': 'Common'},
  6309: {'rank': 4372, 'rarity': 'Uncommon'},
  6310: {'rank': 2893, 'rarity': 'Uncommon'},
  6311: {'rank': 6465, 'rarity': 'Common'},
  6312: {'rank': 1757, 'rarity': 'Rare'},
  6313: {'rank': 9013, 'rarity': 'Common'},
  6314: {'rank': 4823, 'rarity': 'Uncommon'},
  6315: {'rank': 6765, 'rarity': 'Common'},
  6316: {'rank': 718, 'rarity': 'Epic'},
  6317: {'rank': 5655, 'rarity': 'Common'},
  6318: {'rank': 893, 'rarity': 'Epic'},
  6319: {'rank': 8821, 'rarity': 'Common'},
  6320: {'rank': 3801, 'rarity': 'Uncommon'},
  6321: {'rank': 2077, 'rarity': 'Rare'},
  6322: {'rank': 1318, 'rarity': 'Rare'},
  6323: {'rank': 1521, 'rarity': 'Rare'},
  6324: {'rank': 9054, 'rarity': 'Common'},
  6325: {'rank': 8469, 'rarity': 'Common'},
  6326: {'rank': 7027, 'rarity': 'Common'},
  6327: {'rank': 968, 'rarity': 'Epic'},
  6328: {'rank': 1171, 'rarity': 'Rare'},
  6329: {'rank': 4864, 'rarity': 'Uncommon'},
  6330: {'rank': 1180, 'rarity': 'Rare'},
  6331: {'rank': 2070, 'rarity': 'Rare'},
  6332: {'rank': 831, 'rarity': 'Epic'},
  6333: {'rank': 6808, 'rarity': 'Common'},
  6334: {'rank': 7042, 'rarity': 'Common'},
  6335: {'rank': 2066, 'rarity': 'Rare'},
  6336: {'rank': 7425, 'rarity': 'Common'},
  6337: {'rank': 2191, 'rarity': 'Rare'},
  6338: {'rank': 8094, 'rarity': 'Common'},
  6339: {'rank': 3088, 'rarity': 'Uncommon'},
  6340: {'rank': 5706, 'rarity': 'Common'},
  6341: {'rank': 9306, 'rarity': 'Common'},
  6342: {'rank': 9173, 'rarity': 'Common'},
  6343: {'rank': 1369, 'rarity': 'Rare'},
  6344: {'rank': 5429, 'rarity': 'Common'},
  6345: {'rank': 2532, 'rarity': 'Uncommon'},
  6346: {'rank': 6606, 'rarity': 'Common'},
  6347: {'rank': 8531, 'rarity': 'Common'},
  6348: {'rank': 8163, 'rarity': 'Common'},
  6349: {'rank': 3276, 'rarity': 'Uncommon'},
  6350: {'rank': 5736, 'rarity': 'Common'},
  6351: {'rank': 9937, 'rarity': 'Common'},
  6352: {'rank': 8302, 'rarity': 'Common'},
  6353: {'rank': 8439, 'rarity': 'Common'},
  6354: {'rank': 4969, 'rarity': 'Uncommon'},
  6355: {'rank': 5440, 'rarity': 'Common'},
  6356: {'rank': 9079, 'rarity': 'Common'},
  6357: {'rank': 3804, 'rarity': 'Uncommon'},
  6358: {'rank': 9423, 'rarity': 'Common'},
  6359: {'rank': 1306, 'rarity': 'Rare'},
  6360: {'rank': 7690, 'rarity': 'Common'},
  6361: {'rank': 2856, 'rarity': 'Uncommon'},
  6362: {'rank': 4740, 'rarity': 'Uncommon'},
  6363: {'rank': 9451, 'rarity': 'Common'},
  6364: {'rank': 8978, 'rarity': 'Common'},
  6365: {'rank': 4782, 'rarity': 'Uncommon'},
  6366: {'rank': 8380, 'rarity': 'Common'},
  6367: {'rank': 8780, 'rarity': 'Common'},
  6368: {'rank': 2677, 'rarity': 'Uncommon'},
  6369: {'rank': 1384, 'rarity': 'Rare'},
  6370: {'rank': 2528, 'rarity': 'Uncommon'},
  6371: {'rank': 8868, 'rarity': 'Common'},
  6372: {'rank': 4792, 'rarity': 'Uncommon'},
  6373: {'rank': 2864, 'rarity': 'Uncommon'},
  6374: {'rank': 5194, 'rarity': 'Common'},
  6375: {'rank': 2663, 'rarity': 'Uncommon'},
  6376: {'rank': 8350, 'rarity': 'Common'},
  6377: {'rank': 1004, 'rarity': 'Rare'},
  6378: {'rank': 8741, 'rarity': 'Common'},
  6379: {'rank': 9557, 'rarity': 'Common'},
  6380: {'rank': 7187, 'rarity': 'Common'},
  6381: {'rank': 9117, 'rarity': 'Common'},
  6382: {'rank': 8512, 'rarity': 'Common'},
  6383: {'rank': 2643, 'rarity': 'Uncommon'},
  6384: {'rank': 7410, 'rarity': 'Common'},
  6385: {'rank': 378, 'rarity': 'Epic'},
  6386: {'rank': 698, 'rarity': 'Epic'},
  6387: {'rank': 581, 'rarity': 'Epic'},
  6388: {'rank': 5762, 'rarity': 'Common'},
  6389: {'rank': 6418, 'rarity': 'Common'},
  6390: {'rank': 3083, 'rarity': 'Uncommon'},
  6391: {'rank': 3290, 'rarity': 'Uncommon'},
  6392: {'rank': 7546, 'rarity': 'Common'},
  6393: {'rank': 4176, 'rarity': 'Uncommon'},
  6394: {'rank': 2160, 'rarity': 'Rare'},
  6395: {'rank': 5672, 'rarity': 'Common'},
  6396: {'rank': 9183, 'rarity': 'Common'},
  6397: {'rank': 8768, 'rarity': 'Common'},
  6398: {'rank': 5620, 'rarity': 'Common'},
  6399: {'rank': 9829, 'rarity': 'Common'},
  6400: {'rank': 129, 'rarity': 'Epic'},
  6401: {'rank': 9303, 'rarity': 'Common'},
  6402: {'rank': 8525, 'rarity': 'Common'},
  6403: {'rank': 9628, 'rarity': 'Common'},
  6404: {'rank': 5929, 'rarity': 'Common'},
  6405: {'rank': 5756, 'rarity': 'Common'},
  6406: {'rank': 8187, 'rarity': 'Common'},
  6407: {'rank': 4980, 'rarity': 'Uncommon'},
  6408: {'rank': 11, 'rarity': 'Legendary'},
  6409: {'rank': 8632, 'rarity': 'Common'},
  6410: {'rank': 7888, 'rarity': 'Common'},
  6411: {'rank': 8939, 'rarity': 'Common'},
  6412: {'rank': 6413, 'rarity': 'Common'},
  6413: {'rank': 2845, 'rarity': 'Uncommon'},
  6414: {'rank': 1745, 'rarity': 'Rare'},
  6415: {'rank': 6438, 'rarity': 'Common'},
  6416: {'rank': 9732, 'rarity': 'Common'},
  6417: {'rank': 4102, 'rarity': 'Uncommon'},
  6418: {'rank': 4708, 'rarity': 'Uncommon'},
  6419: {'rank': 1267, 'rarity': 'Rare'},
  6420: {'rank': 2149, 'rarity': 'Rare'},
  6421: {'rank': 7304, 'rarity': 'Common'},
  6422: {'rank': 8644, 'rarity': 'Common'},
  6423: {'rank': 9671, 'rarity': 'Common'},
  6424: {'rank': 8918, 'rarity': 'Common'},
  6425: {'rank': 7566, 'rarity': 'Common'},
  6426: {'rank': 6842, 'rarity': 'Common'},
  6427: {'rank': 6297, 'rarity': 'Common'},
  6428: {'rank': 4839, 'rarity': 'Uncommon'},
  6429: {'rank': 7921, 'rarity': 'Common'},
  6430: {'rank': 7668, 'rarity': 'Common'},
  6431: {'rank': 4827, 'rarity': 'Uncommon'},
  6432: {'rank': 2915, 'rarity': 'Uncommon'},
  6433: {'rank': 2118, 'rarity': 'Rare'},
  6434: {'rank': 1186, 'rarity': 'Rare'},
  6435: {'rank': 7001, 'rarity': 'Common'},
  6436: {'rank': 327, 'rarity': 'Epic'},
  6437: {'rank': 6111, 'rarity': 'Common'},
  6438: {'rank': 9773, 'rarity': 'Common'},
  6439: {'rank': 2842, 'rarity': 'Uncommon'},
  6440: {'rank': 953, 'rarity': 'Epic'},
  6441: {'rank': 635, 'rarity': 'Epic'},
  6442: {'rank': 8793, 'rarity': 'Common'},
  6443: {'rank': 7388, 'rarity': 'Common'},
  6444: {'rank': 4474, 'rarity': 'Uncommon'},
  6445: {'rank': 854, 'rarity': 'Epic'},
  6446: {'rank': 995, 'rarity': 'Epic'},
  6447: {'rank': 1336, 'rarity': 'Rare'},
  6448: {'rank': 700, 'rarity': 'Epic'},
  6449: {'rank': 9975, 'rarity': 'Common'},
  6450: {'rank': 8152, 'rarity': 'Common'},
  6451: {'rank': 8828, 'rarity': 'Common'},
  6452: {'rank': 274, 'rarity': 'Epic'},
  6453: {'rank': 5517, 'rarity': 'Common'},
  6454: {'rank': 7301, 'rarity': 'Common'},
  6455: {'rank': 3817, 'rarity': 'Uncommon'},
  6456: {'rank': 1137, 'rarity': 'Rare'},
  6457: {'rank': 3821, 'rarity': 'Uncommon'},
  6458: {'rank': 9705, 'rarity': 'Common'},
  6459: {'rank': 53, 'rarity': 'Legendary'},
  6460: {'rank': 253, 'rarity': 'Epic'},
  6461: {'rank': 4295, 'rarity': 'Uncommon'},
  6462: {'rank': 8455, 'rarity': 'Common'},
  6463: {'rank': 5611, 'rarity': 'Common'},
  6464: {'rank': 6576, 'rarity': 'Common'},
  6465: {'rank': 3137, 'rarity': 'Uncommon'},
  6466: {'rank': 7959, 'rarity': 'Common'},
  6467: {'rank': 5492, 'rarity': 'Common'},
  6468: {'rank': 9255, 'rarity': 'Common'},
  6469: {'rank': 4800, 'rarity': 'Uncommon'},
  6470: {'rank': 729, 'rarity': 'Epic'},
  6471: {'rank': 9856, 'rarity': 'Common'},
  6472: {'rank': 586, 'rarity': 'Epic'},
  6473: {'rank': 7470, 'rarity': 'Common'},
  6474: {'rank': 6075, 'rarity': 'Common'},
  6475: {'rank': 9156, 'rarity': 'Common'},
  6476: {'rank': 1692, 'rarity': 'Rare'},
  6477: {'rank': 6692, 'rarity': 'Common'},
  6478: {'rank': 4938, 'rarity': 'Uncommon'},
  6479: {'rank': 4696, 'rarity': 'Uncommon'},
  6480: {'rank': 7174, 'rarity': 'Common'},
  6481: {'rank': 7330, 'rarity': 'Common'},
  6482: {'rank': 7232, 'rarity': 'Common'},
  6483: {'rank': 2891, 'rarity': 'Uncommon'},
  6484: {'rank': 5152, 'rarity': 'Common'},
  6485: {'rank': 9309, 'rarity': 'Common'},
  6486: {'rank': 9894, 'rarity': 'Common'},
  6487: {'rank': 3939, 'rarity': 'Uncommon'},
  6488: {'rank': 2739, 'rarity': 'Uncommon'},
  6489: {'rank': 7885, 'rarity': 'Common'},
  6490: {'rank': 8487, 'rarity': 'Common'},
  6491: {'rank': 440, 'rarity': 'Epic'},
  6492: {'rank': 600, 'rarity': 'Epic'},
  6493: {'rank': 8532, 'rarity': 'Common'},
  6494: {'rank': 9486, 'rarity': 'Common'},
  6495: {'rank': 6068, 'rarity': 'Common'},
  6496: {'rank': 9874, 'rarity': 'Common'},
  6497: {'rank': 9666, 'rarity': 'Common'},
  6498: {'rank': 664, 'rarity': 'Epic'},
  6499: {'rank': 9771, 'rarity': 'Common'},
  6500: {'rank': 8708, 'rarity': 'Common'},
  6501: {'rank': 6799, 'rarity': 'Common'},
  6502: {'rank': 5309, 'rarity': 'Common'},
  6503: {'rank': 1738, 'rarity': 'Rare'},
  6504: {'rank': 3638, 'rarity': 'Uncommon'},
  6505: {'rank': 577, 'rarity': 'Epic'},
  6506: {'rank': 9510, 'rarity': 'Common'},
  6507: {'rank': 4588, 'rarity': 'Uncommon'},
  6508: {'rank': 4908, 'rarity': 'Uncommon'},
  6509: {'rank': 257, 'rarity': 'Epic'},
  6510: {'rank': 8556, 'rarity': 'Common'},
  6511: {'rank': 2638, 'rarity': 'Uncommon'},
  6512: {'rank': 4515, 'rarity': 'Uncommon'},
  6513: {'rank': 2516, 'rarity': 'Uncommon'},
  6514: {'rank': 234, 'rarity': 'Epic'},
  6515: {'rank': 8239, 'rarity': 'Common'},
  6516: {'rank': 2574, 'rarity': 'Uncommon'},
  6517: {'rank': 4842, 'rarity': 'Uncommon'},
  6518: {'rank': 7625, 'rarity': 'Common'},
  6519: {'rank': 7911, 'rarity': 'Common'},
  6520: {'rank': 6587, 'rarity': 'Common'},
  6521: {'rank': 5799, 'rarity': 'Common'},
  6522: {'rank': 281, 'rarity': 'Epic'},
  6523: {'rank': 4050, 'rarity': 'Uncommon'},
  6524: {'rank': 4353, 'rarity': 'Uncommon'},
  6525: {'rank': 1758, 'rarity': 'Rare'},
  6526: {'rank': 6724, 'rarity': 'Common'},
  6527: {'rank': 1958, 'rarity': 'Rare'},
  6528: {'rank': 4574, 'rarity': 'Uncommon'},
  6529: {'rank': 3758, 'rarity': 'Uncommon'},
  6530: {'rank': 8589, 'rarity': 'Common'},
  6531: {'rank': 8361, 'rarity': 'Common'},
  6532: {'rank': 1562, 'rarity': 'Rare'},
  6533: {'rank': 8899, 'rarity': 'Common'},
  6534: {'rank': 7735, 'rarity': 'Common'},
  6535: {'rank': 1992, 'rarity': 'Rare'},
  6536: {'rank': 4707, 'rarity': 'Uncommon'},
  6537: {'rank': 5248, 'rarity': 'Common'},
  6538: {'rank': 7268, 'rarity': 'Common'},
  6539: {'rank': 9668, 'rarity': 'Common'},
  6540: {'rank': 5558, 'rarity': 'Common'},
  6541: {'rank': 847, 'rarity': 'Epic'},
  6542: {'rank': 6498, 'rarity': 'Common'},
  6543: {'rank': 9697, 'rarity': 'Common'},
  6544: {'rank': 5801, 'rarity': 'Common'},
  6545: {'rank': 6216, 'rarity': 'Common'},
  6546: {'rank': 2419, 'rarity': 'Rare'},
  6547: {'rank': 172, 'rarity': 'Epic'},
  6548: {'rank': 4381, 'rarity': 'Uncommon'},
  6549: {'rank': 9058, 'rarity': 'Common'},
  6550: {'rank': 5533, 'rarity': 'Common'},
  6551: {'rank': 5221, 'rarity': 'Common'},
  6552: {'rank': 3295, 'rarity': 'Uncommon'},
  6553: {'rank': 1651, 'rarity': 'Rare'},
  6554: {'rank': 4714, 'rarity': 'Uncommon'},
  6555: {'rank': 6700, 'rarity': 'Common'},
  6556: {'rank': 9493, 'rarity': 'Common'},
  6557: {'rank': 928, 'rarity': 'Epic'},
  6558: {'rank': 5601, 'rarity': 'Common'},
  6559: {'rank': 7556, 'rarity': 'Common'},
  6560: {'rank': 4926, 'rarity': 'Uncommon'},
  6561: {'rank': 1888, 'rarity': 'Rare'},
  6562: {'rank': 1716, 'rarity': 'Rare'},
  6563: {'rank': 8876, 'rarity': 'Common'},
  6564: {'rank': 9682, 'rarity': 'Common'},
  6565: {'rank': 7905, 'rarity': 'Common'},
  6566: {'rank': 1069, 'rarity': 'Rare'},
  6567: {'rank': 7881, 'rarity': 'Common'},
  6568: {'rank': 6082, 'rarity': 'Common'},
  6569: {'rank': 7931, 'rarity': 'Common'},
  6570: {'rank': 7838, 'rarity': 'Common'},
  6571: {'rank': 8258, 'rarity': 'Common'},
  6572: {'rank': 279, 'rarity': 'Epic'},
  6573: {'rank': 8276, 'rarity': 'Common'},
  6574: {'rank': 9633, 'rarity': 'Common'},
  6575: {'rank': 9665, 'rarity': 'Common'},
  6576: {'rank': 9517, 'rarity': 'Common'},
  6577: {'rank': 5560, 'rarity': 'Common'},
  6578: {'rank': 825, 'rarity': 'Epic'},
  6579: {'rank': 4202, 'rarity': 'Uncommon'},
  6580: {'rank': 9704, 'rarity': 'Common'},
  6581: {'rank': 6927, 'rarity': 'Common'},
  6582: {'rank': 6505, 'rarity': 'Common'},
  6583: {'rank': 5077, 'rarity': 'Common'},
  6584: {'rank': 8624, 'rarity': 'Common'},
  6585: {'rank': 6224, 'rarity': 'Common'},
  6586: {'rank': 5017, 'rarity': 'Common'},
  6587: {'rank': 336, 'rarity': 'Epic'},
  6588: {'rank': 9669, 'rarity': 'Common'},
  6589: {'rank': 9376, 'rarity': 'Common'},
  6590: {'rank': 9025, 'rarity': 'Common'},
  6591: {'rank': 8341, 'rarity': 'Common'},
  6592: {'rank': 5430, 'rarity': 'Common'},
  6593: {'rank': 9865, 'rarity': 'Common'},
  6594: {'rank': 9009, 'rarity': 'Common'},
  6595: {'rank': 4613, 'rarity': 'Uncommon'},
  6596: {'rank': 3473, 'rarity': 'Uncommon'},
  6597: {'rank': 5812, 'rarity': 'Common'},
  6598: {'rank': 9652, 'rarity': 'Common'},
  6599: {'rank': 9919, 'rarity': 'Common'},
  6600: {'rank': 2072, 'rarity': 'Rare'},
  6601: {'rank': 4701, 'rarity': 'Uncommon'},
  6602: {'rank': 4620, 'rarity': 'Uncommon'},
  6603: {'rank': 7110, 'rarity': 'Common'},
  6604: {'rank': 5041, 'rarity': 'Common'},
  6605: {'rank': 1655, 'rarity': 'Rare'},
  6606: {'rank': 1608, 'rarity': 'Rare'},
  6607: {'rank': 1720, 'rarity': 'Rare'},
  6608: {'rank': 5904, 'rarity': 'Common'},
  6609: {'rank': 573, 'rarity': 'Epic'},
  6610: {'rank': 5340, 'rarity': 'Common'},
  6611: {'rank': 1948, 'rarity': 'Rare'},
  6612: {'rank': 8343, 'rarity': 'Common'},
  6613: {'rank': 9885, 'rarity': 'Common'},
  6614: {'rank': 2792, 'rarity': 'Uncommon'},
  6615: {'rank': 4973, 'rarity': 'Uncommon'},
  6616: {'rank': 932, 'rarity': 'Epic'},
  6617: {'rank': 7081, 'rarity': 'Common'},
  6618: {'rank': 4710, 'rarity': 'Uncommon'},
  6619: {'rank': 4675, 'rarity': 'Uncommon'},
  6620: {'rank': 5267, 'rarity': 'Common'},
  6621: {'rank': 2476, 'rarity': 'Rare'},
  6622: {'rank': 6879, 'rarity': 'Common'},
  6623: {'rank': 379, 'rarity': 'Epic'},
  6624: {'rank': 702, 'rarity': 'Epic'},
  6625: {'rank': 2254, 'rarity': 'Rare'},
  6626: {'rank': 6078, 'rarity': 'Common'},
  6627: {'rank': 7056, 'rarity': 'Common'},
  6628: {'rank': 4779, 'rarity': 'Uncommon'},
  6629: {'rank': 2623, 'rarity': 'Uncommon'},
  6630: {'rank': 8236, 'rarity': 'Common'},
  6631: {'rank': 9503, 'rarity': 'Common'},
  6632: {'rank': 2090, 'rarity': 'Rare'},
  6633: {'rank': 2696, 'rarity': 'Uncommon'},
  6634: {'rank': 8485, 'rarity': 'Common'},
  6635: {'rank': 6647, 'rarity': 'Common'},
  6636: {'rank': 4970, 'rarity': 'Uncommon'},
  6637: {'rank': 6388, 'rarity': 'Common'},
  6638: {'rank': 5042, 'rarity': 'Common'},
  6639: {'rank': 5519, 'rarity': 'Common'},
  6640: {'rank': 8436, 'rarity': 'Common'},
  6641: {'rank': 8582, 'rarity': 'Common'},
  6642: {'rank': 9318, 'rarity': 'Common'},
  6643: {'rank': 6966, 'rarity': 'Common'},
  6644: {'rank': 6504, 'rarity': 'Common'},
  6645: {'rank': 1858, 'rarity': 'Rare'},
  6646: {'rank': 7158, 'rarity': 'Common'},
  6647: {'rank': 6285, 'rarity': 'Common'},
  6648: {'rank': 3978, 'rarity': 'Uncommon'},
  6649: {'rank': 3509, 'rarity': 'Uncommon'},
  6650: {'rank': 6945, 'rarity': 'Common'},
  6651: {'rank': 7947, 'rarity': 'Common'},
  6652: {'rank': 9762, 'rarity': 'Common'},
  6653: {'rank': 1884, 'rarity': 'Rare'},
  6654: {'rank': 1031, 'rarity': 'Rare'},
  6655: {'rank': 4439, 'rarity': 'Uncommon'},
  6656: {'rank': 6732, 'rarity': 'Common'},
  6657: {'rank': 6825, 'rarity': 'Common'},
  6658: {'rank': 7090, 'rarity': 'Common'},
  6659: {'rank': 4116, 'rarity': 'Uncommon'},
  6660: {'rank': 8334, 'rarity': 'Common'},
  6661: {'rank': 7579, 'rarity': 'Common'},
  6662: {'rank': 2432, 'rarity': 'Rare'},
  6663: {'rank': 5473, 'rarity': 'Common'},
  6664: {'rank': 6570, 'rarity': 'Common'},
  6665: {'rank': 5694, 'rarity': 'Common'},
  6666: {'rank': 3157, 'rarity': 'Uncommon'},
  6667: {'rank': 7906, 'rarity': 'Common'},
  6668: {'rank': 875, 'rarity': 'Epic'},
  6669: {'rank': 2126, 'rarity': 'Rare'},
  6670: {'rank': 4090, 'rarity': 'Uncommon'},
  6671: {'rank': 4314, 'rarity': 'Uncommon'},
  6672: {'rank': 8506, 'rarity': 'Common'},
  6673: {'rank': 3965, 'rarity': 'Uncommon'},
  6674: {'rank': 7756, 'rarity': 'Common'},
  6675: {'rank': 152, 'rarity': 'Epic'},
  6676: {'rank': 7070, 'rarity': 'Common'},
  6677: {'rank': 2797, 'rarity': 'Uncommon'},
  6678: {'rank': 3338, 'rarity': 'Uncommon'},
  6679: {'rank': 2964, 'rarity': 'Uncommon'},
  6680: {'rank': 9500, 'rarity': 'Common'},
  6681: {'rank': 7587, 'rarity': 'Common'},
  6682: {'rank': 6820, 'rarity': 'Common'},
  6683: {'rank': 420, 'rarity': 'Epic'},
  6684: {'rank': 96, 'rarity': 'Legendary'},
  6685: {'rank': 2091, 'rarity': 'Rare'},
  6686: {'rank': 4285, 'rarity': 'Uncommon'},
  6687: {'rank': 6403, 'rarity': 'Common'},
  6688: {'rank': 6855, 'rarity': 'Common'},
  6689: {'rank': 1585, 'rarity': 'Rare'},
  6690: {'rank': 6021, 'rarity': 'Common'},
  6691: {'rank': 2139, 'rarity': 'Rare'},
  6692: {'rank': 6034, 'rarity': 'Common'},
  6693: {'rank': 3510, 'rarity': 'Uncommon'},
  6694: {'rank': 3506, 'rarity': 'Uncommon'},
  6695: {'rank': 9880, 'rarity': 'Common'},
  6696: {'rank': 866, 'rarity': 'Epic'},
  6697: {'rank': 7086, 'rarity': 'Common'},
  6698: {'rank': 7207, 'rarity': 'Common'},
  6699: {'rank': 2341, 'rarity': 'Rare'},
  6700: {'rank': 4999, 'rarity': 'Uncommon'},
  6701: {'rank': 5158, 'rarity': 'Common'},
  6702: {'rank': 629, 'rarity': 'Epic'},
  6703: {'rank': 3614, 'rarity': 'Uncommon'},
  6704: {'rank': 4795, 'rarity': 'Uncommon'},
  6705: {'rank': 3748, 'rarity': 'Uncommon'},
  6706: {'rank': 3740, 'rarity': 'Uncommon'},
  6707: {'rank': 386, 'rarity': 'Epic'},
  6708: {'rank': 7937, 'rarity': 'Common'},
  6709: {'rank': 1513, 'rarity': 'Rare'},
  6710: {'rank': 8474, 'rarity': 'Common'},
  6711: {'rank': 7525, 'rarity': 'Common'},
  6712: {'rank': 9491, 'rarity': 'Common'},
  6713: {'rank': 20, 'rarity': 'Legendary'},
  6714: {'rank': 2255, 'rarity': 'Rare'},
  6715: {'rank': 4433, 'rarity': 'Uncommon'},
  6716: {'rank': 7075, 'rarity': 'Common'},
  6717: {'rank': 7829, 'rarity': 'Common'},
  6718: {'rank': 9942, 'rarity': 'Common'},
  6719: {'rank': 2242, 'rarity': 'Rare'},
  6720: {'rank': 7048, 'rarity': 'Common'},
  6721: {'rank': 1749, 'rarity': 'Rare'},
  6722: {'rank': 3146, 'rarity': 'Uncommon'},
  6723: {'rank': 8619, 'rarity': 'Common'},
  6724: {'rank': 3437, 'rarity': 'Uncommon'},
  6725: {'rank': 9149, 'rarity': 'Common'},
  6726: {'rank': 7856, 'rarity': 'Common'},
  6727: {'rank': 3942, 'rarity': 'Uncommon'},
  6728: {'rank': 8218, 'rarity': 'Common'},
  6729: {'rank': 6338, 'rarity': 'Common'},
  6730: {'rank': 16, 'rarity': 'Legendary'},
  6731: {'rank': 3878, 'rarity': 'Uncommon'},
  6732: {'rank': 4844, 'rarity': 'Uncommon'},
  6733: {'rank': 2626, 'rarity': 'Uncommon'},
  6734: {'rank': 3634, 'rarity': 'Uncommon'},
  6735: {'rank': 8100, 'rarity': 'Common'},
  6736: {'rank': 6307, 'rarity': 'Common'},
  6737: {'rank': 8748, 'rarity': 'Common'},
  6738: {'rank': 2407, 'rarity': 'Rare'},
  6739: {'rank': 8855, 'rarity': 'Common'},
  6740: {'rank': 5835, 'rarity': 'Common'},
  6741: {'rank': 2370, 'rarity': 'Rare'},
  6742: {'rank': 2858, 'rarity': 'Uncommon'},
  6743: {'rank': 4491, 'rarity': 'Uncommon'},
  6744: {'rank': 7417, 'rarity': 'Common'},
  6745: {'rank': 4874, 'rarity': 'Uncommon'},
  6746: {'rank': 1194, 'rarity': 'Rare'},
  6747: {'rank': 1453, 'rarity': 'Rare'},
  6748: {'rank': 1169, 'rarity': 'Rare'},
  6749: {'rank': 6707, 'rarity': 'Common'},
  6750: {'rank': 7155, 'rarity': 'Common'},
  6751: {'rank': 9847, 'rarity': 'Common'},
  6752: {'rank': 7809, 'rarity': 'Common'},
  6753: {'rank': 7544, 'rarity': 'Common'},
  6754: {'rank': 4977, 'rarity': 'Uncommon'},
  6755: {'rank': 1063, 'rarity': 'Rare'},
  6756: {'rank': 1671, 'rarity': 'Rare'},
  6757: {'rank': 3272, 'rarity': 'Uncommon'},
  6758: {'rank': 7486, 'rarity': 'Common'},
  6759: {'rank': 2138, 'rarity': 'Rare'},
  6760: {'rank': 1225, 'rarity': 'Rare'},
  6761: {'rank': 5975, 'rarity': 'Common'},
  6762: {'rank': 6768, 'rarity': 'Common'},
  6763: {'rank': 2942, 'rarity': 'Uncommon'},
  6764: {'rank': 5401, 'rarity': 'Common'},
  6765: {'rank': 3929, 'rarity': 'Uncommon'},
  6766: {'rank': 7142, 'rarity': 'Common'},
  6767: {'rank': 1647, 'rarity': 'Rare'},
  6768: {'rank': 2741, 'rarity': 'Uncommon'},
  6769: {'rank': 8067, 'rarity': 'Common'},
  6770: {'rank': 6083, 'rarity': 'Common'},
  6771: {'rank': 6259, 'rarity': 'Common'},
  6772: {'rank': 549, 'rarity': 'Epic'},
  6773: {'rank': 109, 'rarity': 'Epic'},
  6774: {'rank': 5196, 'rarity': 'Common'},
  6775: {'rank': 8168, 'rarity': 'Common'},
  6776: {'rank': 5588, 'rarity': 'Common'},
  6777: {'rank': 4925, 'rarity': 'Uncommon'},
  6778: {'rank': 2033, 'rarity': 'Rare'},
  6779: {'rank': 7094, 'rarity': 'Common'},
  6780: {'rank': 3906, 'rarity': 'Uncommon'},
  6781: {'rank': 4084, 'rarity': 'Uncommon'},
  6782: {'rank': 4109, 'rarity': 'Uncommon'},
  6783: {'rank': 6593, 'rarity': 'Common'},
  6784: {'rank': 6339, 'rarity': 'Common'},
  6785: {'rank': 9907, 'rarity': 'Common'},
  6786: {'rank': 65, 'rarity': 'Legendary'},
  6787: {'rank': 4900, 'rarity': 'Uncommon'},
  6788: {'rank': 7617, 'rarity': 'Common'},
  6789: {'rank': 2361, 'rarity': 'Rare'},
  6790: {'rank': 5582, 'rarity': 'Common'},
  6791: {'rank': 8242, 'rarity': 'Common'},
  6792: {'rank': 9189, 'rarity': 'Common'},
  6793: {'rank': 9789, 'rarity': 'Common'},
  6794: {'rank': 176, 'rarity': 'Epic'},
  6795: {'rank': 7028, 'rarity': 'Common'},
  6796: {'rank': 1038, 'rarity': 'Rare'},
  6797: {'rank': 8994, 'rarity': 'Common'},
  6798: {'rank': 3457, 'rarity': 'Uncommon'},
  6799: {'rank': 3530, 'rarity': 'Uncommon'},
  6800: {'rank': 888, 'rarity': 'Epic'},
  6801: {'rank': 9501, 'rarity': 'Common'},
  6802: {'rank': 2752, 'rarity': 'Uncommon'},
  6803: {'rank': 1147, 'rarity': 'Rare'},
  6804: {'rank': 3225, 'rarity': 'Uncommon'},
  6805: {'rank': 2320, 'rarity': 'Rare'},
  6806: {'rank': 9357, 'rarity': 'Common'},
  6807: {'rank': 2026, 'rarity': 'Rare'},
  6808: {'rank': 5702, 'rarity': 'Common'},
  6809: {'rank': 6840, 'rarity': 'Common'},
  6810: {'rank': 5047, 'rarity': 'Common'},
  6811: {'rank': 1843, 'rarity': 'Rare'},
  6812: {'rank': 4216, 'rarity': 'Uncommon'},
  6813: {'rank': 2661, 'rarity': 'Uncommon'},
  6814: {'rank': 530, 'rarity': 'Epic'},
  6815: {'rank': 6703, 'rarity': 'Common'},
  6816: {'rank': 940, 'rarity': 'Epic'},
  6817: {'rank': 502, 'rarity': 'Epic'},
  6818: {'rank': 8552, 'rarity': 'Common'},
  6819: {'rank': 5477, 'rarity': 'Common'},
  6820: {'rank': 5673, 'rarity': 'Common'},
  6821: {'rank': 5445, 'rarity': 'Common'},
  6822: {'rank': 1222, 'rarity': 'Rare'},
  6823: {'rank': 3729, 'rarity': 'Uncommon'},
  6824: {'rank': 7864, 'rarity': 'Common'},
  6825: {'rank': 2337, 'rarity': 'Rare'},
  6826: {'rank': 677, 'rarity': 'Epic'},
  6827: {'rank': 7037, 'rarity': 'Common'},
  6828: {'rank': 6025, 'rarity': 'Common'},
  6829: {'rank': 7570, 'rarity': 'Common'},
  6830: {'rank': 4541, 'rarity': 'Uncommon'},
  6831: {'rank': 5610, 'rarity': 'Common'},
  6832: {'rank': 3495, 'rarity': 'Uncommon'},
  6833: {'rank': 3274, 'rarity': 'Uncommon'},
  6834: {'rank': 9768, 'rarity': 'Common'},
  6835: {'rank': 7884, 'rarity': 'Common'},
  6836: {'rank': 4396, 'rarity': 'Uncommon'},
  6837: {'rank': 5185, 'rarity': 'Common'},
  6838: {'rank': 7007, 'rarity': 'Common'},
  6839: {'rank': 6625, 'rarity': 'Common'},
  6840: {'rank': 4630, 'rarity': 'Uncommon'},
  6841: {'rank': 9717, 'rarity': 'Common'},
  6842: {'rank': 8147, 'rarity': 'Common'},
  6843: {'rank': 2079, 'rarity': 'Rare'},
  6844: {'rank': 684, 'rarity': 'Epic'},
  6845: {'rank': 7274, 'rarity': 'Common'},
  6846: {'rank': 5685, 'rarity': 'Common'},
  6847: {'rank': 9558, 'rarity': 'Common'},
  6848: {'rank': 7642, 'rarity': 'Common'},
  6849: {'rank': 9408, 'rarity': 'Common'},
  6850: {'rank': 7536, 'rarity': 'Common'},
  6851: {'rank': 2612, 'rarity': 'Uncommon'},
  6852: {'rank': 3537, 'rarity': 'Uncommon'},
  6853: {'rank': 7816, 'rarity': 'Common'},
  6854: {'rank': 2172, 'rarity': 'Rare'},
  6855: {'rank': 3894, 'rarity': 'Uncommon'},
  6856: {'rank': 1873, 'rarity': 'Rare'},
  6857: {'rank': 3525, 'rarity': 'Uncommon'},
  6858: {'rank': 6624, 'rarity': 'Common'},
  6859: {'rank': 3192, 'rarity': 'Uncommon'},
  6860: {'rank': 738, 'rarity': 'Epic'},
  6861: {'rank': 4293, 'rarity': 'Uncommon'},
  6862: {'rank': 8658, 'rarity': 'Common'},
  6863: {'rank': 3401, 'rarity': 'Uncommon'},
  6864: {'rank': 9923, 'rarity': 'Common'},
  6865: {'rank': 2146, 'rarity': 'Rare'},
  6866: {'rank': 2725, 'rarity': 'Uncommon'},
  6867: {'rank': 3866, 'rarity': 'Uncommon'},
  6868: {'rank': 2667, 'rarity': 'Uncommon'},
  6869: {'rank': 8032, 'rarity': 'Common'},
  6870: {'rank': 5109, 'rarity': 'Common'},
  6871: {'rank': 1827, 'rarity': 'Rare'},
  6872: {'rank': 5731, 'rarity': 'Common'},
  6873: {'rank': 7745, 'rarity': 'Common'},
  6874: {'rank': 7839, 'rarity': 'Common'},
  6875: {'rank': 1824, 'rarity': 'Rare'},
  6876: {'rank': 5592, 'rarity': 'Common'},
  6877: {'rank': 1707, 'rarity': 'Rare'},
  6878: {'rank': 7191, 'rarity': 'Common'},
  6879: {'rank': 7681, 'rarity': 'Common'},
  6880: {'rank': 8548, 'rarity': 'Common'},
  6881: {'rank': 1956, 'rarity': 'Rare'},
  6882: {'rank': 7805, 'rarity': 'Common'},
  6883: {'rank': 1126, 'rarity': 'Rare'},
  6884: {'rank': 1217, 'rarity': 'Rare'},
  6885: {'rank': 637, 'rarity': 'Epic'},
  6886: {'rank': 5826, 'rarity': 'Common'},
  6887: {'rank': 6668, 'rarity': 'Common'},
  6888: {'rank': 7164, 'rarity': 'Common'},
  6889: {'rank': 323, 'rarity': 'Epic'},
  6890: {'rank': 4615, 'rarity': 'Uncommon'},
  6891: {'rank': 4231, 'rarity': 'Uncommon'},
  6892: {'rank': 2491, 'rarity': 'Rare'},
  6893: {'rank': 6176, 'rarity': 'Common'},
  6894: {'rank': 347, 'rarity': 'Epic'},
  6895: {'rank': 1337, 'rarity': 'Rare'},
  6896: {'rank': 5555, 'rarity': 'Common'},
  6897: {'rank': 7506, 'rarity': 'Common'},
  6898: {'rank': 9049, 'rarity': 'Common'},
  6899: {'rank': 829, 'rarity': 'Epic'},
  6900: {'rank': 6052, 'rarity': 'Common'},
  6901: {'rank': 9421, 'rarity': 'Common'},
  6902: {'rank': 8549, 'rarity': 'Common'},
  6903: {'rank': 7862, 'rarity': 'Common'},
  6904: {'rank': 7052, 'rarity': 'Common'},
  6905: {'rank': 2469, 'rarity': 'Rare'},
  6906: {'rank': 2261, 'rarity': 'Rare'},
  6907: {'rank': 9323, 'rarity': 'Common'},
  6908: {'rank': 6813, 'rarity': 'Common'},
  6909: {'rank': 1916, 'rarity': 'Rare'},
  6910: {'rank': 9498, 'rarity': 'Common'},
  6911: {'rank': 3029, 'rarity': 'Uncommon'},
  6912: {'rank': 5728, 'rarity': 'Common'},
  6913: {'rank': 2514, 'rarity': 'Uncommon'},
  6914: {'rank': 1693, 'rarity': 'Rare'},
  6915: {'rank': 5449, 'rarity': 'Common'},
  6916: {'rank': 3377, 'rarity': 'Uncommon'},
  6917: {'rank': 479, 'rarity': 'Epic'},
  6918: {'rank': 8349, 'rarity': 'Common'},
  6919: {'rank': 9010, 'rarity': 'Common'},
  6920: {'rank': 4923, 'rarity': 'Uncommon'},
  6921: {'rank': 3302, 'rarity': 'Uncommon'},
  6922: {'rank': 2260, 'rarity': 'Rare'},
  6923: {'rank': 461, 'rarity': 'Epic'},
  6924: {'rank': 2056, 'rarity': 'Rare'},
  6925: {'rank': 554, 'rarity': 'Epic'},
  6926: {'rank': 4425, 'rarity': 'Uncommon'},
  6927: {'rank': 8654, 'rarity': 'Common'},
  6928: {'rank': 5216, 'rarity': 'Common'},
  6929: {'rank': 9890, 'rarity': 'Common'},
  6930: {'rank': 2360, 'rarity': 'Rare'},
  6931: {'rank': 7758, 'rarity': 'Common'},
  6932: {'rank': 1285, 'rarity': 'Rare'},
  6933: {'rank': 4220, 'rarity': 'Uncommon'},
  6934: {'rank': 1775, 'rarity': 'Rare'},
  6935: {'rank': 6006, 'rarity': 'Common'},
  6936: {'rank': 2697, 'rarity': 'Uncommon'},
  6937: {'rank': 1205, 'rarity': 'Rare'},
  6938: {'rank': 5298, 'rarity': 'Common'},
  6939: {'rank': 3737, 'rarity': 'Uncommon'},
  6940: {'rank': 1469, 'rarity': 'Rare'},
  6941: {'rank': 5978, 'rarity': 'Common'},
  6942: {'rank': 1143, 'rarity': 'Rare'},
  6943: {'rank': 1108, 'rarity': 'Rare'},
  6944: {'rank': 1803, 'rarity': 'Rare'},
  6945: {'rank': 4342, 'rarity': 'Uncommon'},
  6946: {'rank': 5735, 'rarity': 'Common'},
  6947: {'rank': 8229, 'rarity': 'Common'},
  6948: {'rank': 3440, 'rarity': 'Uncommon'},
  6949: {'rank': 5858, 'rarity': 'Common'},
  6950: {'rank': 1401, 'rarity': 'Rare'},
  6951: {'rank': 9955, 'rarity': 'Common'},
  6952: {'rank': 5331, 'rarity': 'Common'},
  6953: {'rank': 1321, 'rarity': 'Rare'},
  6954: {'rank': 3079, 'rarity': 'Uncommon'},
  6955: {'rank': 476, 'rarity': 'Epic'},
  6956: {'rank': 3618, 'rarity': 'Uncommon'},
  6957: {'rank': 2142, 'rarity': 'Rare'},
  6958: {'rank': 5464, 'rarity': 'Common'},
  6959: {'rank': 7307, 'rarity': 'Common'},
  6960: {'rank': 7936, 'rarity': 'Common'},
  6961: {'rank': 4488, 'rarity': 'Uncommon'},
  6962: {'rank': 4288, 'rarity': 'Uncommon'},
  6963: {'rank': 8792, 'rarity': 'Common'},
  6964: {'rank': 9284, 'rarity': 'Common'},
  6965: {'rank': 5648, 'rarity': 'Common'},
  6966: {'rank': 4997, 'rarity': 'Uncommon'},
  6967: {'rank': 6347, 'rarity': 'Common'},
  6968: {'rank': 6268, 'rarity': 'Common'},
  6969: {'rank': 8629, 'rarity': 'Common'},
  6970: {'rank': 7915, 'rarity': 'Common'},
  6971: {'rank': 2854, 'rarity': 'Uncommon'},
  6972: {'rank': 1030, 'rarity': 'Rare'},
  6973: {'rank': 187, 'rarity': 'Epic'},
  6974: {'rank': 7821, 'rarity': 'Common'},
  6975: {'rank': 9657, 'rarity': 'Common'},
  6976: {'rank': 9898, 'rarity': 'Common'},
  6977: {'rank': 8319, 'rarity': 'Common'},
  6978: {'rank': 6663, 'rarity': 'Common'},
  6979: {'rank': 3337, 'rarity': 'Uncommon'},
  6980: {'rank': 5985, 'rarity': 'Common'},
  6981: {'rank': 4709, 'rarity': 'Uncommon'},
  6982: {'rank': 7746, 'rarity': 'Common'},
  6983: {'rank': 5370, 'rarity': 'Common'},
  6984: {'rank': 5468, 'rarity': 'Common'},
  6985: {'rank': 8733, 'rarity': 'Common'},
  6986: {'rank': 2073, 'rarity': 'Rare'},
  6987: {'rank': 2377, 'rarity': 'Rare'},
  6988: {'rank': 6622, 'rarity': 'Common'},
  6989: {'rank': 5058, 'rarity': 'Common'},
  6990: {'rank': 1527, 'rarity': 'Rare'},
  6991: {'rank': 1203, 'rarity': 'Rare'},
  6992: {'rank': 5666, 'rarity': 'Common'},
  6993: {'rank': 9208, 'rarity': 'Common'},
  6994: {'rank': 7730, 'rarity': 'Common'},
  6995: {'rank': 2724, 'rarity': 'Uncommon'},
  6996: {'rank': 9040, 'rarity': 'Common'},
  6997: {'rank': 6725, 'rarity': 'Common'},
  6998: {'rank': 4064, 'rarity': 'Uncommon'},
  6999: {'rank': 5183, 'rarity': 'Common'},
  7000: {'rank': 8642, 'rarity': 'Common'},
  7001: {'rank': 9867, 'rarity': 'Common'},
  7002: {'rank': 4722, 'rarity': 'Uncommon'},
  7003: {'rank': 3036, 'rarity': 'Uncommon'},
  7004: {'rank': 6345, 'rarity': 'Common'},
  7005: {'rank': 3557, 'rarity': 'Uncommon'},
  7006: {'rank': 5115, 'rarity': 'Common'},
  7007: {'rank': 3954, 'rarity': 'Uncommon'},
  7008: {'rank': 9795, 'rarity': 'Common'},
  7009: {'rank': 8267, 'rarity': 'Common'},
  7010: {'rank': 4647, 'rarity': 'Uncommon'},
  7011: {'rank': 3546, 'rarity': 'Uncommon'},
  7012: {'rank': 8980, 'rarity': 'Common'},
  7013: {'rank': 4139, 'rarity': 'Uncommon'},
  7014: {'rank': 4251, 'rarity': 'Uncommon'},
  7015: {'rank': 8602, 'rarity': 'Common'},
  7016: {'rank': 9374, 'rarity': 'Common'},
  7017: {'rank': 6631, 'rarity': 'Common'},
  7018: {'rank': 6293, 'rarity': 'Common'},
  7019: {'rank': 2860, 'rarity': 'Uncommon'},
  7020: {'rank': 332, 'rarity': 'Epic'},
  7021: {'rank': 4533, 'rarity': 'Uncommon'},
  7022: {'rank': 1875, 'rarity': 'Rare'},
  7023: {'rank': 8047, 'rarity': 'Common'},
  7024: {'rank': 6367, 'rarity': 'Common'},
  7025: {'rank': 7514, 'rarity': 'Common'},
  7026: {'rank': 145, 'rarity': 'Epic'},
  7027: {'rank': 7899, 'rarity': 'Common'},
  7028: {'rank': 8278, 'rarity': 'Common'},
  7029: {'rank': 5890, 'rarity': 'Common'},
  7030: {'rank': 904, 'rarity': 'Epic'},
  7031: {'rank': 9016, 'rarity': 'Common'},
  7032: {'rank': 136, 'rarity': 'Epic'},
  7033: {'rank': 9787, 'rarity': 'Common'},
  7034: {'rank': 7861, 'rarity': 'Common'},
  7035: {'rank': 9614, 'rarity': 'Common'},
  7036: {'rank': 4803, 'rarity': 'Uncommon'},
  7037: {'rank': 3048, 'rarity': 'Uncommon'},
  7038: {'rank': 7384, 'rarity': 'Common'},
  7039: {'rank': 5131, 'rarity': 'Common'},
  7040: {'rank': 4363, 'rarity': 'Uncommon'},
  7041: {'rank': 8200, 'rarity': 'Common'},
  7042: {'rank': 772, 'rarity': 'Epic'},
  7043: {'rank': 9707, 'rarity': 'Common'},
  7044: {'rank': 7609, 'rarity': 'Common'},
  7045: {'rank': 3009, 'rarity': 'Uncommon'},
  7046: {'rank': 5507, 'rarity': 'Common'},
  7047: {'rank': 1432, 'rarity': 'Rare'},
  7048: {'rank': 40, 'rarity': 'Legendary'},
  7049: {'rank': 1244, 'rarity': 'Rare'},
  7050: {'rank': 8398, 'rarity': 'Common'},
  7051: {'rank': 6605, 'rarity': 'Common'},
  7052: {'rank': 658, 'rarity': 'Epic'},
  7053: {'rank': 5844, 'rarity': 'Common'},
  7054: {'rank': 4723, 'rarity': 'Uncommon'},
  7055: {'rank': 397, 'rarity': 'Epic'},
  7056: {'rank': 1033, 'rarity': 'Rare'},
  7057: {'rank': 3399, 'rarity': 'Uncommon'},
  7058: {'rank': 6401, 'rarity': 'Common'},
  7059: {'rank': 4651, 'rarity': 'Uncommon'},
  7060: {'rank': 4114, 'rarity': 'Uncommon'},
  7061: {'rank': 3349, 'rarity': 'Uncommon'},
  7062: {'rank': 4007, 'rarity': 'Uncommon'},
  7063: {'rank': 2446, 'rarity': 'Rare'},
  7064: {'rank': 2598, 'rarity': 'Uncommon'},
  7065: {'rank': 3673, 'rarity': 'Uncommon'},
  7066: {'rank': 7495, 'rarity': 'Common'},
  7067: {'rank': 122, 'rarity': 'Epic'},
  7068: {'rank': 5957, 'rarity': 'Common'},
  7069: {'rank': 7433, 'rarity': 'Common'},
  7070: {'rank': 4450, 'rarity': 'Uncommon'},
  7071: {'rank': 3031, 'rarity': 'Uncommon'},
  7072: {'rank': 5364, 'rarity': 'Common'},
  7073: {'rank': 1175, 'rarity': 'Rare'},
  7074: {'rank': 1074, 'rarity': 'Rare'},
  7075: {'rank': 8925, 'rarity': 'Common'},
  7076: {'rank': 8796, 'rarity': 'Common'},
  7077: {'rank': 5104, 'rarity': 'Common'},
  7078: {'rank': 782, 'rarity': 'Epic'},
  7079: {'rank': 4103, 'rarity': 'Uncommon'},
  7080: {'rank': 4325, 'rarity': 'Uncommon'},
  7081: {'rank': 7220, 'rarity': 'Common'},
  7082: {'rank': 3041, 'rarity': 'Uncommon'},
  7083: {'rank': 6245, 'rarity': 'Common'},
  7084: {'rank': 1328, 'rarity': 'Rare'},
  7085: {'rank': 2405, 'rarity': 'Rare'},
  7086: {'rank': 802, 'rarity': 'Epic'},
  7087: {'rank': 4633, 'rarity': 'Uncommon'},
  7088: {'rank': 5870, 'rarity': 'Common'},
  7089: {'rank': 6047, 'rarity': 'Common'},
  7090: {'rank': 7068, 'rarity': 'Common'},
  7091: {'rank': 9464, 'rarity': 'Common'},
  7092: {'rank': 9369, 'rarity': 'Common'},
  7093: {'rank': 4305, 'rarity': 'Uncommon'},
  7094: {'rank': 8190, 'rarity': 'Common'},
  7095: {'rank': 9960, 'rarity': 'Common'},
  7096: {'rank': 107, 'rarity': 'Epic'},
  7097: {'rank': 5704, 'rarity': 'Common'},
  7098: {'rank': 9569, 'rarity': 'Common'},
  7099: {'rank': 3459, 'rarity': 'Uncommon'},
  7100: {'rank': 5578, 'rarity': 'Common'},
  7101: {'rank': 878, 'rarity': 'Epic'},
  7102: {'rank': 930, 'rarity': 'Epic'},
  7103: {'rank': 608, 'rarity': 'Epic'},
  7104: {'rank': 1972, 'rarity': 'Rare'},
  7105: {'rank': 2352, 'rarity': 'Rare'},
  7106: {'rank': 1657, 'rarity': 'Rare'},
  7107: {'rank': 8021, 'rarity': 'Common'},
  7108: {'rank': 3112, 'rarity': 'Uncommon'},
  7109: {'rank': 6180, 'rarity': 'Common'},
  7110: {'rank': 5015, 'rarity': 'Common'},
  7111: {'rank': 2813, 'rarity': 'Uncommon'},
  7112: {'rank': 9506, 'rarity': 'Common'},
  7113: {'rank': 4182, 'rarity': 'Uncommon'},
  7114: {'rank': 8555, 'rarity': 'Common'},
  7115: {'rank': 6926, 'rarity': 'Common'},
  7116: {'rank': 1817, 'rarity': 'Rare'},
  7117: {'rank': 3862, 'rarity': 'Uncommon'},
  7118: {'rank': 3271, 'rarity': 'Uncommon'},
  7119: {'rank': 7595, 'rarity': 'Common'},
  7120: {'rank': 5137, 'rarity': 'Common'},
  7121: {'rank': 1339, 'rarity': 'Rare'},
  7122: {'rank': 540, 'rarity': 'Epic'},
  7123: {'rank': 41, 'rarity': 'Legendary'},
  7124: {'rank': 6847, 'rarity': 'Common'},
  7125: {'rank': 8211, 'rarity': 'Common'},
  7126: {'rank': 9109, 'rarity': 'Common'},
  7127: {'rank': 2060, 'rarity': 'Rare'},
  7128: {'rank': 7276, 'rarity': 'Common'},
  7129: {'rank': 1661, 'rarity': 'Rare'},
  7130: {'rank': 3390, 'rarity': 'Uncommon'},
  7131: {'rank': 80, 'rarity': 'Legendary'},
  7132: {'rank': 5936, 'rarity': 'Common'},
  7133: {'rank': 5639, 'rarity': 'Common'},
  7134: {'rank': 7421, 'rarity': 'Common'},
  7135: {'rank': 957, 'rarity': 'Epic'},
  7136: {'rank': 1659, 'rarity': 'Rare'},
  7137: {'rank': 9228, 'rarity': 'Common'},
  7138: {'rank': 2102, 'rarity': 'Rare'},
  7139: {'rank': 4457, 'rarity': 'Uncommon'},
  7140: {'rank': 295, 'rarity': 'Epic'},
  7141: {'rank': 5285, 'rarity': 'Common'},
  7142: {'rank': 7896, 'rarity': 'Common'},
  7143: {'rank': 5930, 'rarity': 'Common'},
  7144: {'rank': 1731, 'rarity': 'Rare'},
  7145: {'rank': 6423, 'rarity': 'Common'},
  7146: {'rank': 3924, 'rarity': 'Uncommon'},
  7147: {'rank': 5608, 'rarity': 'Common'},
  7148: {'rank': 6581, 'rarity': 'Common'},
  7149: {'rank': 9680, 'rarity': 'Common'},
  7150: {'rank': 5463, 'rarity': 'Common'},
  7151: {'rank': 7935, 'rarity': 'Common'},
  7152: {'rank': 5026, 'rarity': 'Common'},
  7153: {'rank': 4121, 'rarity': 'Uncommon'},
  7154: {'rank': 7023, 'rarity': 'Common'},
  7155: {'rank': 4992, 'rarity': 'Uncommon'},
  7156: {'rank': 2734, 'rarity': 'Uncommon'},
  7157: {'rank': 3568, 'rarity': 'Uncommon'},
  7158: {'rank': 3741, 'rarity': 'Uncommon'},
  7159: {'rank': 8898, 'rarity': 'Common'},
  7160: {'rank': 3090, 'rarity': 'Uncommon'},
  7161: {'rank': 8136, 'rarity': 'Common'},
  7162: {'rank': 3979, 'rarity': 'Uncommon'},
  7163: {'rank': 3103, 'rarity': 'Uncommon'},
  7164: {'rank': 1387, 'rarity': 'Rare'},
  7165: {'rank': 8695, 'rarity': 'Common'},
  7166: {'rank': 9855, 'rarity': 'Common'},
  7167: {'rank': 8339, 'rarity': 'Common'},
  7168: {'rank': 634, 'rarity': 'Epic'},
  7169: {'rank': 2218, 'rarity': 'Rare'},
  7170: {'rank': 7777, 'rarity': 'Common'},
  7171: {'rank': 1910, 'rarity': 'Rare'},
  7172: {'rank': 9947, 'rarity': 'Common'},
  7173: {'rank': 2678, 'rarity': 'Uncommon'},
  7174: {'rank': 8604, 'rarity': 'Common'},
  7175: {'rank': 744, 'rarity': 'Epic'},
  7176: {'rank': 2771, 'rarity': 'Uncommon'},
  7177: {'rank': 1462, 'rarity': 'Rare'},
  7178: {'rank': 7127, 'rarity': 'Common'},
  7179: {'rank': 9264, 'rarity': 'Common'},
  7180: {'rank': 2127, 'rarity': 'Rare'},
  7181: {'rank': 5640, 'rarity': 'Common'},
  7182: {'rank': 447, 'rarity': 'Epic'},
  7183: {'rank': 2929, 'rarity': 'Uncommon'},
  7184: {'rank': 2182, 'rarity': 'Rare'},
  7185: {'rank': 539, 'rarity': 'Epic'},
  7186: {'rank': 3971, 'rarity': 'Uncommon'},
  7187: {'rank': 5852, 'rarity': 'Common'},
  7188: {'rank': 7827, 'rarity': 'Common'},
  7189: {'rank': 7783, 'rarity': 'Common'},
  7190: {'rank': 2093, 'rarity': 'Rare'},
  7191: {'rank': 7773, 'rarity': 'Common'},
  7192: {'rank': 4650, 'rarity': 'Uncommon'},
  7193: {'rank': 4205, 'rarity': 'Uncommon'},
  7194: {'rank': 7107, 'rarity': 'Common'},
  7195: {'rank': 8811, 'rarity': 'Common'},
  7196: {'rank': 9950, 'rarity': 'Common'},
  7197: {'rank': 240, 'rarity': 'Epic'},
  7198: {'rank': 7006, 'rarity': 'Common'},
  7199: {'rank': 4523, 'rarity': 'Uncommon'},
  7200: {'rank': 4587, 'rarity': 'Uncommon'},
  7201: {'rank': 3774, 'rarity': 'Uncommon'},
  7202: {'rank': 1548, 'rarity': 'Rare'},
  7203: {'rank': 3603, 'rarity': 'Uncommon'},
  7204: {'rank': 9160, 'rarity': 'Common'},
  7205: {'rank': 9878, 'rarity': 'Common'},
  7206: {'rank': 142, 'rarity': 'Epic'},
  7207: {'rank': 6560, 'rarity': 'Common'},
  7208: {'rank': 1654, 'rarity': 'Rare'},
  7209: {'rank': 6934, 'rarity': 'Common'},
  7210: {'rank': 3556, 'rarity': 'Uncommon'},
  7211: {'rank': 7686, 'rarity': 'Common'},
  7212: {'rank': 8622, 'rarity': 'Common'},
  7213: {'rank': 9297, 'rarity': 'Common'},
  7214: {'rank': 2923, 'rarity': 'Uncommon'},
  7215: {'rank': 2339, 'rarity': 'Rare'},
  7216: {'rank': 5968, 'rarity': 'Common'},
  7217: {'rank': 6990, 'rarity': 'Common'},
  7218: {'rank': 7152, 'rarity': 'Common'},
  7219: {'rank': 3404, 'rarity': 'Uncommon'},
  7220: {'rank': 7377, 'rarity': 'Common'},
  7221: {'rank': 215, 'rarity': 'Epic'},
  7222: {'rank': 439, 'rarity': 'Epic'},
  7223: {'rank': 6386, 'rarity': 'Common'},
  7224: {'rank': 4548, 'rarity': 'Uncommon'},
  7225: {'rank': 6219, 'rarity': 'Common'},
  7226: {'rank': 952, 'rarity': 'Epic'},
  7227: {'rank': 3803, 'rarity': 'Uncommon'},
  7228: {'rank': 7039, 'rarity': 'Common'},
  7229: {'rank': 2251, 'rarity': 'Rare'},
  7230: {'rank': 9733, 'rarity': 'Common'},
  7231: {'rank': 2245, 'rarity': 'Rare'},
  7232: {'rank': 6290, 'rarity': 'Common'},
  7233: {'rank': 3512, 'rarity': 'Uncommon'},
  7234: {'rank': 9770, 'rarity': 'Common'},
  7235: {'rank': 9655, 'rarity': 'Common'},
  7236: {'rank': 8786, 'rarity': 'Common'},
  7237: {'rank': 8043, 'rarity': 'Common'},
  7238: {'rank': 9380, 'rarity': 'Common'},
  7239: {'rank': 7017, 'rarity': 'Common'},
  7240: {'rank': 1630, 'rarity': 'Rare'},
  7241: {'rank': 8774, 'rarity': 'Common'},
  7242: {'rank': 4780, 'rarity': 'Uncommon'},
  7243: {'rank': 167, 'rarity': 'Epic'},
  7244: {'rank': 2244, 'rarity': 'Rare'},
  7245: {'rank': 8315, 'rarity': 'Common'},
  7246: {'rank': 3902, 'rarity': 'Uncommon'},
  7247: {'rank': 7516, 'rarity': 'Common'},
  7248: {'rank': 6332, 'rarity': 'Common'},
  7249: {'rank': 466, 'rarity': 'Epic'},
  7250: {'rank': 4473, 'rarity': 'Uncommon'},
  7251: {'rank': 7987, 'rarity': 'Common'},
  7252: {'rank': 5070, 'rarity': 'Common'},
  7253: {'rank': 2413, 'rarity': 'Rare'},
  7254: {'rank': 8863, 'rarity': 'Common'},
  7255: {'rank': 4266, 'rarity': 'Uncommon'},
  7256: {'rank': 7380, 'rarity': 'Common'},
  7257: {'rank': 8782, 'rarity': 'Common'},
  7258: {'rank': 7218, 'rarity': 'Common'},
  7259: {'rank': 752, 'rarity': 'Epic'},
  7260: {'rank': 9831, 'rarity': 'Common'},
  7261: {'rank': 7318, 'rarity': 'Common'},
  7262: {'rank': 8986, 'rarity': 'Common'},
  7263: {'rank': 3718, 'rarity': 'Uncommon'},
  7264: {'rank': 8255, 'rarity': 'Common'},
  7265: {'rank': 9296, 'rarity': 'Common'},
  7266: {'rank': 1672, 'rarity': 'Rare'},
  7267: {'rank': 732, 'rarity': 'Epic'},
  7268: {'rank': 611, 'rarity': 'Epic'},
  7269: {'rank': 115, 'rarity': 'Epic'},
  7270: {'rank': 2425, 'rarity': 'Rare'},
  7271: {'rank': 3785, 'rarity': 'Uncommon'},
  7272: {'rank': 4579, 'rarity': 'Uncommon'},
  7273: {'rank': 4947, 'rarity': 'Uncommon'},
  7274: {'rank': 2098, 'rarity': 'Rare'},
  7275: {'rank': 1490, 'rarity': 'Rare'},
  7276: {'rank': 5010, 'rarity': 'Common'},
  7277: {'rank': 6533, 'rarity': 'Common'},
  7278: {'rank': 7507, 'rarity': 'Common'},
  7279: {'rank': 4883, 'rarity': 'Uncommon'},
  7280: {'rank': 6941, 'rarity': 'Common'},
  7281: {'rank': 3539, 'rarity': 'Uncommon'},
  7282: {'rank': 3735, 'rarity': 'Uncommon'},
  7283: {'rank': 1872, 'rarity': 'Rare'},
  7284: {'rank': 1124, 'rarity': 'Rare'},
  7285: {'rank': 4261, 'rarity': 'Uncommon'},
  7286: {'rank': 7163, 'rarity': 'Common'},
  7287: {'rank': 514, 'rarity': 'Epic'},
  7288: {'rank': 5573, 'rarity': 'Common'},
  7289: {'rank': 5531, 'rarity': 'Common'},
  7290: {'rank': 8672, 'rarity': 'Common'},
  7291: {'rank': 7628, 'rarity': 'Common'},
  7292: {'rank': 4204, 'rarity': 'Uncommon'},
  7293: {'rank': 9944, 'rarity': 'Common'},
  7294: {'rank': 3152, 'rarity': 'Uncommon'},
  7295: {'rank': 7853, 'rarity': 'Common'},
  7296: {'rank': 5966, 'rarity': 'Common'},
  7297: {'rank': 94, 'rarity': 'Legendary'},
  7298: {'rank': 3193, 'rarity': 'Uncommon'},
  7299: {'rank': 3720, 'rarity': 'Uncommon'},
  7300: {'rank': 7055, 'rarity': 'Common'},
  7301: {'rank': 8732, 'rarity': 'Common'},
  7302: {'rank': 9147, 'rarity': 'Common'},
  7303: {'rank': 7778, 'rarity': 'Common'},
  7304: {'rank': 427, 'rarity': 'Epic'},
  7305: {'rank': 1053, 'rarity': 'Rare'},
  7306: {'rank': 1182, 'rarity': 'Rare'},
  7307: {'rank': 5587, 'rarity': 'Common'},
  7308: {'rank': 8137, 'rarity': 'Common'},
  7309: {'rank': 7886, 'rarity': 'Common'},
  7310: {'rank': 7711, 'rarity': 'Common'},
  7311: {'rank': 150, 'rarity': 'Epic'},
  7312: {'rank': 3973, 'rarity': 'Uncommon'},
  7313: {'rank': 2826, 'rarity': 'Uncommon'},
  7314: {'rank': 7545, 'rarity': 'Common'},
  7315: {'rank': 8384, 'rarity': 'Common'},
  7316: {'rank': 3574, 'rarity': 'Uncommon'},
  7317: {'rank': 4797, 'rarity': 'Uncommon'},
  7318: {'rank': 9518, 'rarity': 'Common'},
  7319: {'rank': 6893, 'rarity': 'Common'},
  7320: {'rank': 9056, 'rarity': 'Common'},
  7321: {'rank': 2619, 'rarity': 'Uncommon'},
  7322: {'rank': 7010, 'rarity': 'Common'},
  7323: {'rank': 2906, 'rarity': 'Uncommon'},
  7324: {'rank': 5886, 'rarity': 'Common'},
  7325: {'rank': 8051, 'rarity': 'Common'},
  7326: {'rank': 817, 'rarity': 'Epic'},
  7327: {'rank': 705, 'rarity': 'Epic'},
  7328: {'rank': 2309, 'rarity': 'Rare'},
  7329: {'rank': 3587, 'rarity': 'Uncommon'},
  7330: {'rank': 3336, 'rarity': 'Uncommon'},
  7331: {'rank': 3918, 'rarity': 'Uncommon'},
  7332: {'rank': 1419, 'rarity': 'Rare'},
  7333: {'rank': 7539, 'rarity': 'Common'},
  7334: {'rank': 682, 'rarity': 'Epic'},
  7335: {'rank': 2685, 'rarity': 'Uncommon'},
  7336: {'rank': 8931, 'rarity': 'Common'},
  7337: {'rank': 2782, 'rarity': 'Uncommon'},
  7338: {'rank': 6445, 'rarity': 'Common'},
  7339: {'rank': 1043, 'rarity': 'Rare'},
  7340: {'rank': 193, 'rarity': 'Epic'},
  7341: {'rank': 286, 'rarity': 'Epic'},
  7342: {'rank': 6621, 'rarity': 'Common'},
  7343: {'rank': 6542, 'rarity': 'Common'},
  7344: {'rank': 1634, 'rarity': 'Rare'},
  7345: {'rank': 3422, 'rarity': 'Uncommon'},
  7346: {'rank': 7822, 'rarity': 'Common'},
  7347: {'rank': 5238, 'rarity': 'Common'},
  7348: {'rank': 2188, 'rarity': 'Rare'},
  7349: {'rank': 9806, 'rarity': 'Common'},
  7350: {'rank': 1125, 'rarity': 'Rare'},
  7351: {'rank': 1136, 'rarity': 'Rare'},
  7352: {'rank': 528, 'rarity': 'Epic'},
  7353: {'rank': 4943, 'rarity': 'Uncommon'},
  7354: {'rank': 1735, 'rarity': 'Rare'},
  7355: {'rank': 8902, 'rarity': 'Common'},
  7356: {'rank': 5471, 'rarity': 'Common'},
  7357: {'rank': 6097, 'rarity': 'Common'},
  7358: {'rank': 1284, 'rarity': 'Rare'},
  7359: {'rank': 9063, 'rarity': 'Common'},
  7360: {'rank': 6217, 'rarity': 'Common'},
  7361: {'rank': 7029, 'rarity': 'Common'},
  7362: {'rank': 7790, 'rarity': 'Common'},
  7363: {'rank': 8698, 'rarity': 'Common'},
  7364: {'rank': 6275, 'rarity': 'Common'},
  7365: {'rank': 9118, 'rarity': 'Common'},
  7366: {'rank': 7394, 'rarity': 'Common'},
  7367: {'rank': 7550, 'rarity': 'Common'},
  7368: {'rank': 9230, 'rarity': 'Common'},
  7369: {'rank': 800, 'rarity': 'Epic'},
  7370: {'rank': 2021, 'rarity': 'Rare'},
  7371: {'rank': 7262, 'rarity': 'Common'},
  7372: {'rank': 8979, 'rarity': 'Common'},
  7373: {'rank': 3683, 'rarity': 'Uncommon'},
  7374: {'rank': 3134, 'rarity': 'Uncommon'},
  7375: {'rank': 5937, 'rarity': 'Common'},
  7376: {'rank': 1669, 'rarity': 'Rare'},
  7377: {'rank': 6682, 'rarity': 'Common'},
  7378: {'rank': 1836, 'rarity': 'Rare'},
  7379: {'rank': 717, 'rarity': 'Epic'},
  7380: {'rank': 6134, 'rarity': 'Common'},
  7381: {'rank': 8667, 'rarity': 'Common'},
  7382: {'rank': 7422, 'rarity': 'Common'},
  7383: {'rank': 8951, 'rarity': 'Common'},
  7384: {'rank': 8969, 'rarity': 'Common'},
  7385: {'rank': 412, 'rarity': 'Epic'},
  7386: {'rank': 8758, 'rarity': 'Common'},
  7387: {'rank': 6852, 'rarity': 'Common'},
  7388: {'rank': 1534, 'rarity': 'Rare'},
  7389: {'rank': 7247, 'rarity': 'Common'},
  7390: {'rank': 3713, 'rarity': 'Uncommon'},
  7391: {'rank': 8378, 'rarity': 'Common'},
  7392: {'rank': 9379, 'rarity': 'Common'},
  7393: {'rank': 8172, 'rarity': 'Common'},
  7394: {'rank': 2228, 'rarity': 'Rare'},
  7395: {'rank': 3201, 'rarity': 'Uncommon'},
  7396: {'rank': 1575, 'rarity': 'Rare'},
  7397: {'rank': 7485, 'rarity': 'Common'},
  7398: {'rank': 6121, 'rarity': 'Common'},
  7399: {'rank': 2868, 'rarity': 'Uncommon'},
  7400: {'rank': 2365, 'rarity': 'Rare'},
  7401: {'rank': 856, 'rarity': 'Epic'},
  7402: {'rank': 6769, 'rarity': 'Common'},
  7403: {'rank': 8042, 'rarity': 'Common'},
  7404: {'rank': 1066, 'rarity': 'Rare'},
  7405: {'rank': 7319, 'rarity': 'Common'},
  7406: {'rank': 5112, 'rarity': 'Common'},
  7407: {'rank': 2668, 'rarity': 'Uncommon'},
  7408: {'rank': 9209, 'rarity': 'Common'},
  7409: {'rank': 6003, 'rarity': 'Common'},
  7410: {'rank': 4567, 'rarity': 'Uncommon'},
  7411: {'rank': 2435, 'rarity': 'Rare'},
  7412: {'rank': 3948, 'rarity': 'Uncommon'},
  7413: {'rank': 4733, 'rarity': 'Uncommon'},
  7414: {'rank': 8564, 'rarity': 'Common'},
  7415: {'rank': 8587, 'rarity': 'Common'},
  7416: {'rank': 2159, 'rarity': 'Rare'},
  7417: {'rank': 7008, 'rarity': 'Common'},
  7418: {'rank': 7439, 'rarity': 'Common'},
  7419: {'rank': 1134, 'rarity': 'Rare'},
  7420: {'rank': 446, 'rarity': 'Epic'},
  7421: {'rank': 380, 'rarity': 'Epic'},
  7422: {'rank': 1974, 'rarity': 'Rare'},
  7423: {'rank': 2374, 'rarity': 'Rare'},
  7424: {'rank': 4414, 'rarity': 'Uncommon'},
  7425: {'rank': 6303, 'rarity': 'Common'},
  7426: {'rank': 1303, 'rarity': 'Rare'},
  7427: {'rank': 7459, 'rarity': 'Common'},
  7428: {'rank': 9064, 'rarity': 'Common'},
  7429: {'rank': 6149, 'rarity': 'Common'},
  7430: {'rank': 3662, 'rarity': 'Uncommon'},
  7431: {'rank': 915, 'rarity': 'Epic'},
  7432: {'rank': 5991, 'rarity': 'Common'},
  7433: {'rank': 9714, 'rarity': 'Common'},
  7434: {'rank': 7435, 'rarity': 'Common'},
  7435: {'rank': 4849, 'rarity': 'Uncommon'},
  7436: {'rank': 6015, 'rarity': 'Common'},
  7437: {'rank': 4019, 'rarity': 'Uncommon'},
  7438: {'rank': 615, 'rarity': 'Epic'},
  7439: {'rank': 2728, 'rarity': 'Uncommon'},
  7440: {'rank': 3641, 'rarity': 'Uncommon'},
  7441: {'rank': 7763, 'rarity': 'Common'},
  7442: {'rank': 7376, 'rarity': 'Common'},
  7443: {'rank': 5025, 'rarity': 'Common'},
  7444: {'rank': 4939, 'rarity': 'Uncommon'},
  7445: {'rank': 5549, 'rarity': 'Common'},
  7446: {'rank': 942, 'rarity': 'Epic'},
  7447: {'rank': 198, 'rarity': 'Epic'},
  7448: {'rank': 362, 'rarity': 'Epic'},
  7449: {'rank': 9736, 'rarity': 'Common'},
  7450: {'rank': 498, 'rarity': 'Epic'},
  7451: {'rank': 3108, 'rarity': 'Uncommon'},
  7452: {'rank': 550, 'rarity': 'Epic'},
  7453: {'rank': 8523, 'rarity': 'Common'},
  7454: {'rank': 485, 'rarity': 'Epic'},
  7455: {'rank': 4309, 'rarity': 'Uncommon'},
  7456: {'rank': 5261, 'rarity': 'Common'},
  7457: {'rank': 4119, 'rarity': 'Uncommon'},
  7458: {'rank': 8501, 'rarity': 'Common'},
  7459: {'rank': 7513, 'rarity': 'Common'},
  7460: {'rank': 7902, 'rarity': 'Common'},
  7461: {'rank': 4624, 'rarity': 'Uncommon'},
  7462: {'rank': 3395, 'rarity': 'Uncommon'},
  7463: {'rank': 7817, 'rarity': 'Common'},
  7464: {'rank': 4867, 'rarity': 'Uncommon'},
  7465: {'rank': 4891, 'rarity': 'Uncommon'},
  7466: {'rank': 4590, 'rarity': 'Uncommon'},
  7467: {'rank': 3034, 'rarity': 'Uncommon'},
  7468: {'rank': 7100, 'rarity': 'Common'},
  7469: {'rank': 2029, 'rarity': 'Rare'},
  7470: {'rank': 5811, 'rarity': 'Common'},
  7471: {'rank': 5229, 'rarity': 'Common'},
  7472: {'rank': 6115, 'rarity': 'Common'},
  7473: {'rank': 5539, 'rarity': 'Common'},
  7474: {'rank': 7464, 'rarity': 'Common'},
  7475: {'rank': 4933, 'rarity': 'Uncommon'},
  7476: {'rank': 2731, 'rarity': 'Uncommon'},
  7477: {'rank': 5932, 'rarity': 'Common'},
  7478: {'rank': 5559, 'rarity': 'Common'},
  7479: {'rank': 7450, 'rarity': 'Common'},
  7480: {'rank': 1, 'rarity': 'Legendary'},
  7481: {'rank': 1215, 'rarity': 'Rare'},
  7482: {'rank': 9808, 'rarity': 'Common'},
  7483: {'rank': 8653, 'rarity': 'Common'},
  7484: {'rank': 3604, 'rarity': 'Uncommon'},
  7485: {'rank': 6874, 'rarity': 'Common'},
  7486: {'rank': 8140, 'rarity': 'Common'},
  7487: {'rank': 7049, 'rarity': 'Common'},
  7488: {'rank': 4724, 'rarity': 'Uncommon'},
  7489: {'rank': 7875, 'rarity': 'Common'},
  7490: {'rank': 4236, 'rarity': 'Uncommon'},
  7491: {'rank': 1954, 'rarity': 'Rare'},
  7492: {'rank': 8178, 'rarity': 'Common'},
  7493: {'rank': 5439, 'rarity': 'Common'},
  7494: {'rank': 770, 'rarity': 'Epic'},
  7495: {'rank': 3007, 'rarity': 'Uncommon'},
  7496: {'rank': 2910, 'rarity': 'Uncommon'},
  7497: {'rank': 935, 'rarity': 'Epic'},
  7498: {'rank': 3206, 'rarity': 'Uncommon'},
  7499: {'rank': 409, 'rarity': 'Epic'},
  7500: {'rank': 7676, 'rarity': 'Common'},
  7501: {'rank': 5271, 'rarity': 'Common'},
  7502: {'rank': 408, 'rarity': 'Epic'},
  7503: {'rank': 355, 'rarity': 'Epic'},
  7504: {'rank': 4983, 'rarity': 'Uncommon'},
  7505: {'rank': 4463, 'rarity': 'Uncommon'},
  7506: {'rank': 4409, 'rarity': 'Uncommon'},
  7507: {'rank': 1052, 'rarity': 'Rare'},
  7508: {'rank': 4297, 'rarity': 'Uncommon'},
  7509: {'rank': 8894, 'rarity': 'Common'},
  7510: {'rank': 3755, 'rarity': 'Uncommon'},
  7511: {'rank': 5235, 'rarity': 'Common'},
  7512: {'rank': 9642, 'rarity': 'Common'},
  7513: {'rank': 6357, 'rarity': 'Common'},
  7514: {'rank': 4858, 'rarity': 'Uncommon'},
  7515: {'rank': 6861, 'rarity': 'Common'},
  7516: {'rank': 3368, 'rarity': 'Uncommon'},
  7517: {'rank': 4343, 'rarity': 'Uncommon'},
  7518: {'rank': 9370, 'rarity': 'Common'},
  7519: {'rank': 6146, 'rarity': 'Common'},
  7520: {'rank': 6660, 'rarity': 'Common'},
  7521: {'rank': 5674, 'rarity': 'Common'},
  7522: {'rank': 6399, 'rarity': 'Common'},
  7523: {'rank': 743, 'rarity': 'Epic'},
  7524: {'rank': 6546, 'rarity': 'Common'},
  7525: {'rank': 6183, 'rarity': 'Common'},
  7526: {'rank': 3179, 'rarity': 'Uncommon'},
  7527: {'rank': 7154, 'rarity': 'Common'},
  7528: {'rank': 8734, 'rarity': 'Common'},
  7529: {'rank': 1691, 'rarity': 'Rare'},
  7530: {'rank': 6503, 'rarity': 'Common'},
  7531: {'rank': 8785, 'rarity': 'Common'},
  7532: {'rank': 6382, 'rarity': 'Common'},
  7533: {'rank': 4918, 'rarity': 'Uncommon'},
  7534: {'rank': 5902, 'rarity': 'Common'},
  7535: {'rank': 5603, 'rarity': 'Common'},
  7536: {'rank': 5680, 'rarity': 'Common'},
  7537: {'rank': 2714, 'rarity': 'Uncommon'},
  7538: {'rank': 8095, 'rarity': 'Common'},
  7539: {'rank': 2546, 'rarity': 'Uncommon'},
  7540: {'rank': 6362, 'rarity': 'Common'},
  7541: {'rank': 2569, 'rarity': 'Uncommon'},
  7542: {'rank': 8703, 'rarity': 'Common'},
  7543: {'rank': 8974, 'rarity': 'Common'},
  7544: {'rank': 5407, 'rarity': 'Common'},
  7545: {'rank': 2043, 'rarity': 'Rare'},
  7546: {'rank': 1045, 'rarity': 'Rare'},
  7547: {'rank': 4387, 'rarity': 'Uncommon'},
  7548: {'rank': 8669, 'rarity': 'Common'},
  7549: {'rank': 7475, 'rarity': 'Common'},
  7550: {'rank': 3411, 'rarity': 'Uncommon'},
  7551: {'rank': 2330, 'rarity': 'Rare'},
  7552: {'rank': 9596, 'rarity': 'Common'},
  7553: {'rank': 648, 'rarity': 'Epic'},
  7554: {'rank': 3542, 'rarity': 'Uncommon'},
  7555: {'rank': 5111, 'rarity': 'Common'},
  7556: {'rank': 9573, 'rarity': 'Common'},
  7557: {'rank': 454, 'rarity': 'Epic'},
  7558: {'rank': 2399, 'rarity': 'Rare'},
  7559: {'rank': 9305, 'rarity': 'Common'},
  7560: {'rank': 4436, 'rarity': 'Uncommon'},
  7561: {'rank': 2838, 'rarity': 'Uncommon'},
  7562: {'rank': 9624, 'rarity': 'Common'},
  7563: {'rank': 7706, 'rarity': 'Common'},
  7564: {'rank': 6286, 'rarity': 'Common'},
  7565: {'rank': 6866, 'rarity': 'Common'},
  7566: {'rank': 2329, 'rarity': 'Rare'},
  7567: {'rank': 5312, 'rarity': 'Common'},
  7568: {'rank': 1308, 'rarity': 'Rare'},
  7569: {'rank': 2965, 'rarity': 'Uncommon'},
  7570: {'rank': 9667, 'rarity': 'Common'},
  7571: {'rank': 2343, 'rarity': 'Rare'},
  7572: {'rank': 5785, 'rarity': 'Common'},
  7573: {'rank': 3967, 'rarity': 'Uncommon'},
  7574: {'rank': 9846, 'rarity': 'Common'},
  7575: {'rank': 2990, 'rarity': 'Uncommon'},
  7576: {'rank': 759, 'rarity': 'Epic'},
  7577: {'rank': 5167, 'rarity': 'Common'},
  7578: {'rank': 7828, 'rarity': 'Common'},
  7579: {'rank': 1202, 'rarity': 'Rare'},
  7580: {'rank': 9463, 'rarity': 'Common'},
  7581: {'rank': 1648, 'rarity': 'Rare'},
  7582: {'rank': 6417, 'rarity': 'Common'},
  7583: {'rank': 2101, 'rarity': 'Rare'},
  7584: {'rank': 4211, 'rarity': 'Uncommon'},
  7585: {'rank': 4729, 'rarity': 'Uncommon'},
  7586: {'rank': 4215, 'rarity': 'Uncommon'},
  7587: {'rank': 9444, 'rarity': 'Common'},
  7588: {'rank': 2700, 'rarity': 'Uncommon'},
  7589: {'rank': 4636, 'rarity': 'Uncommon'},
  7590: {'rank': 5566, 'rarity': 'Common'},
  7591: {'rank': 7918, 'rarity': 'Common'},
  7592: {'rank': 9093, 'rarity': 'Common'},
  7593: {'rank': 2009, 'rarity': 'Rare'},
  7594: {'rank': 9891, 'rarity': 'Common'},
  7595: {'rank': 3814, 'rarity': 'Uncommon'},
  7596: {'rank': 1748, 'rarity': 'Rare'},
  7597: {'rank': 2758, 'rarity': 'Uncommon'},
  7598: {'rank': 1700, 'rarity': 'Rare'},
  7599: {'rank': 7836, 'rarity': 'Common'},
  7600: {'rank': 8207, 'rarity': 'Common'},
  7601: {'rank': 9715, 'rarity': 'Common'},
  7602: {'rank': 8110, 'rarity': 'Common'},
  7603: {'rank': 294, 'rarity': 'Epic'},
  7604: {'rank': 7005, 'rarity': 'Common'},
  7605: {'rank': 1291, 'rarity': 'Rare'},
  7606: {'rank': 4315, 'rarity': 'Uncommon'},
  7607: {'rank': 9644, 'rarity': 'Common'},
  7608: {'rank': 8495, 'rarity': 'Common'},
  7609: {'rank': 8098, 'rarity': 'Common'},
  7610: {'rank': 3835, 'rarity': 'Uncommon'},
  7611: {'rank': 8226, 'rarity': 'Common'},
  7612: {'rank': 224, 'rarity': 'Epic'},
  7613: {'rank': 2000, 'rarity': 'Rare'},
  7614: {'rank': 296, 'rarity': 'Epic'},
  7615: {'rank': 2382, 'rarity': 'Rare'},
  7616: {'rank': 4468, 'rarity': 'Uncommon'},
  7617: {'rank': 2841, 'rarity': 'Uncommon'},
  7618: {'rank': 90, 'rarity': 'Legendary'},
  7619: {'rank': 6077, 'rarity': 'Common'},
  7620: {'rank': 1883, 'rarity': 'Rare'},
  7621: {'rank': 5889, 'rarity': 'Common'},
  7622: {'rank': 5363, 'rarity': 'Common'},
  7623: {'rank': 5269, 'rarity': 'Common'},
  7624: {'rank': 6580, 'rarity': 'Common'},
  7625: {'rank': 6775, 'rarity': 'Common'},
  7626: {'rank': 3353, 'rarity': 'Uncommon'},
  7627: {'rank': 1001, 'rarity': 'Rare'},
  7628: {'rank': 8476, 'rarity': 'Common'},
  7629: {'rank': 2932, 'rarity': 'Uncommon'},
  7630: {'rank': 6427, 'rarity': 'Common'},
  7631: {'rank': 1786, 'rarity': 'Rare'},
  7632: {'rank': 3523, 'rarity': 'Uncommon'},
  7633: {'rank': 5938, 'rarity': 'Common'},
  7634: {'rank': 6548, 'rarity': 'Common'},
  7635: {'rank': 3678, 'rarity': 'Uncommon'},
  7636: {'rank': 7091, 'rarity': 'Common'},
  7637: {'rank': 3043, 'rarity': 'Uncommon'},
  7638: {'rank': 6864, 'rarity': 'Common'},
  7639: {'rank': 3270, 'rarity': 'Uncommon'},
  7640: {'rank': 48, 'rarity': 'Legendary'},
  7641: {'rank': 5564, 'rarity': 'Common'},
  7642: {'rank': 118, 'rarity': 'Epic'},
  7643: {'rank': 5290, 'rarity': 'Common'},
  7644: {'rank': 8363, 'rarity': 'Common'},
  7645: {'rank': 2698, 'rarity': 'Uncommon'},
  7646: {'rank': 7145, 'rarity': 'Common'},
  7647: {'rank': 8247, 'rarity': 'Common'},
  7648: {'rank': 2676, 'rarity': 'Uncommon'},
  7649: {'rank': 4672, 'rarity': 'Uncommon'},
  7650: {'rank': 7733, 'rarity': 'Common'},
  7651: {'rank': 4581, 'rarity': 'Uncommon'},
  7652: {'rank': 805, 'rarity': 'Epic'},
  7653: {'rank': 2809, 'rarity': 'Uncommon'},
  7654: {'rank': 5038, 'rarity': 'Common'},
  7655: {'rank': 9201, 'rarity': 'Common'},
  7656: {'rank': 4124, 'rarity': 'Uncommon'},
  7657: {'rank': 4357, 'rarity': 'Uncommon'},
  7658: {'rank': 4168, 'rarity': 'Uncommon'},
  7659: {'rank': 651, 'rarity': 'Epic'},
  7660: {'rank': 3355, 'rarity': 'Uncommon'},
  7661: {'rank': 7759, 'rarity': 'Common'},
  7662: {'rank': 6019, 'rarity': 'Common'},
  7663: {'rank': 6702, 'rarity': 'Common'},
  7664: {'rank': 5435, 'rarity': 'Common'},
  7665: {'rank': 8329, 'rarity': 'Common'},
  7666: {'rank': 1379, 'rarity': 'Rare'},
  7667: {'rank': 8945, 'rarity': 'Common'},
  7668: {'rank': 4754, 'rarity': 'Uncommon'},
  7669: {'rank': 3458, 'rarity': 'Uncommon'},
  7670: {'rank': 5268, 'rarity': 'Common'},
  7671: {'rank': 2820, 'rarity': 'Uncommon'},
  7672: {'rank': 8515, 'rarity': 'Common'},
  7673: {'rank': 1895, 'rarity': 'Rare'},
  7674: {'rank': 490, 'rarity': 'Epic'},
  7675: {'rank': 3374, 'rarity': 'Uncommon'},
  7676: {'rank': 8045, 'rarity': 'Common'},
  7677: {'rank': 7118, 'rarity': 'Common'},
  7678: {'rank': 6744, 'rarity': 'Common'},
  7679: {'rank': 2774, 'rarity': 'Uncommon'},
  7680: {'rank': 46, 'rarity': 'Legendary'},
  7681: {'rank': 6378, 'rarity': 'Common'},
  7682: {'rank': 9611, 'rarity': 'Common'},
  7683: {'rank': 1543, 'rarity': 'Rare'},
  7684: {'rank': 863, 'rarity': 'Epic'},
  7685: {'rank': 6104, 'rarity': 'Common'},
  7686: {'rank': 2793, 'rarity': 'Uncommon'},
  7687: {'rank': 4510, 'rarity': 'Uncommon'},
  7688: {'rank': 7986, 'rarity': 'Common'},
  7689: {'rank': 1852, 'rarity': 'Rare'},
  7690: {'rank': 4964, 'rarity': 'Uncommon'},
  7691: {'rank': 2326, 'rarity': 'Rare'},
  7692: {'rank': 3919, 'rarity': 'Uncommon'},
  7693: {'rank': 945, 'rarity': 'Epic'},
  7694: {'rank': 7482, 'rarity': 'Common'},
  7695: {'rank': 2515, 'rarity': 'Uncommon'},
  7696: {'rank': 3259, 'rarity': 'Uncommon'},
  7697: {'rank': 3033, 'rarity': 'Uncommon'},
  7698: {'rank': 9168, 'rarity': 'Common'},
  7699: {'rank': 9977, 'rarity': 'Common'},
  7700: {'rank': 354, 'rarity': 'Epic'},
  7701: {'rank': 1090, 'rarity': 'Rare'},
  7702: {'rank': 4465, 'rarity': 'Uncommon'},
  7703: {'rank': 2710, 'rarity': 'Uncommon'},
  7704: {'rank': 4848, 'rarity': 'Uncommon'},
  7705: {'rank': 5956, 'rarity': 'Common'},
  7706: {'rank': 2571, 'rarity': 'Uncommon'},
  7707: {'rank': 162, 'rarity': 'Epic'},
  7708: {'rank': 5590, 'rarity': 'Common'},
  7709: {'rank': 7812, 'rarity': 'Common'},
  7710: {'rank': 1032, 'rarity': 'Rare'},
  7711: {'rank': 6807, 'rarity': 'Common'},
  7712: {'rank': 6579, 'rarity': 'Common'},
  7713: {'rank': 6069, 'rarity': 'Common'},
  7714: {'rank': 8699, 'rarity': 'Common'},
  7715: {'rank': 1493, 'rarity': 'Rare'},
  7716: {'rank': 1744, 'rarity': 'Rare'},
  7717: {'rank': 5005, 'rarity': 'Common'},
  7718: {'rank': 7365, 'rarity': 'Common'},
  7719: {'rank': 2768, 'rarity': 'Uncommon'},
  7720: {'rank': 306, 'rarity': 'Epic'},
  7721: {'rank': 3727, 'rarity': 'Uncommon'},
  7722: {'rank': 2059, 'rarity': 'Rare'},
  7723: {'rank': 3019, 'rarity': 'Uncommon'},
  7724: {'rank': 2607, 'rarity': 'Uncommon'},
  7725: {'rank': 4149, 'rarity': 'Uncommon'},
  7726: {'rank': 7025, 'rarity': 'Common'},
  7727: {'rank': 2633, 'rarity': 'Uncommon'},
  7728: {'rank': 9774, 'rarity': 'Common'},
  7729: {'rank': 390, 'rarity': 'Epic'},
  7730: {'rank': 1122, 'rarity': 'Rare'},
  7731: {'rank': 7538, 'rarity': 'Common'},
  7732: {'rank': 8417, 'rarity': 'Common'},
  7733: {'rank': 7970, 'rarity': 'Common'},
  7734: {'rank': 3654, 'rarity': 'Uncommon'},
  7735: {'rank': 6443, 'rarity': 'Common'},
  7736: {'rank': 6995, 'rarity': 'Common'},
  7737: {'rank': 7324, 'rarity': 'Common'},
  7738: {'rank': 8081, 'rarity': 'Common'},
  7739: {'rank': 6171, 'rarity': 'Common'},
  7740: {'rank': 9331, 'rarity': 'Common'},
  7741: {'rank': 6166, 'rarity': 'Common'},
  7742: {'rank': 9476, 'rarity': 'Common'},
  7743: {'rank': 8649, 'rarity': 'Common'},
  7744: {'rank': 8410, 'rarity': 'Common'},
  7745: {'rank': 5824, 'rarity': 'Common'},
  7746: {'rank': 6344, 'rarity': 'Common'},
  7747: {'rank': 7192, 'rarity': 'Common'},
  7748: {'rank': 8093, 'rarity': 'Common'},
  7749: {'rank': 9755, 'rarity': 'Common'},
  7750: {'rank': 924, 'rarity': 'Epic'},
  7751: {'rank': 5080, 'rarity': 'Common'},
  7752: {'rank': 305, 'rarity': 'Epic'},
  7753: {'rank': 2587, 'rarity': 'Uncommon'},
  7754: {'rank': 7382, 'rarity': 'Common'},
  7755: {'rank': 353, 'rarity': 'Epic'},
  7756: {'rank': 8542, 'rarity': 'Common'},
  7757: {'rank': 6129, 'rarity': 'Common'},
  7758: {'rank': 9836, 'rarity': 'Common'},
  7759: {'rank': 1300, 'rarity': 'Rare'},
  7760: {'rank': 1239, 'rarity': 'Rare'},
  7761: {'rank': 7779, 'rarity': 'Common'},
  7762: {'rank': 8394, 'rarity': 'Common'},
  7763: {'rank': 9388, 'rarity': 'Common'},
  7764: {'rank': 7361, 'rarity': 'Common'},
  7765: {'rank': 4619, 'rarity': 'Uncommon'},
  7766: {'rank': 5828, 'rarity': 'Common'},
  7767: {'rank': 9833, 'rarity': 'Common'},
  7768: {'rank': 7500, 'rarity': 'Common'},
  7769: {'rank': 5869, 'rarity': 'Common'},
  7770: {'rank': 5394, 'rarity': 'Common'},
  7771: {'rank': 3963, 'rarity': 'Uncommon'},
  7772: {'rank': 3543, 'rarity': 'Uncommon'},
  7773: {'rank': 9478, 'rarity': 'Common'},
  7774: {'rank': 5342, 'rarity': 'Common'},
  7775: {'rank': 441, 'rarity': 'Epic'},
  7776: {'rank': 304, 'rarity': 'Epic'},
  7777: {'rank': 6942, 'rarity': 'Common'},
  7778: {'rank': 6873, 'rarity': 'Common'},
  7779: {'rank': 8953, 'rarity': 'Common'},
  7780: {'rank': 8976, 'rarity': 'Common'},
  7781: {'rank': 5888, 'rarity': 'Common'},
  7782: {'rank': 6974, 'rarity': 'Common'},
  7783: {'rank': 7705, 'rarity': 'Common'},
  7784: {'rank': 8975, 'rarity': 'Common'},
  7785: {'rank': 6454, 'rarity': 'Common'},
  7786: {'rank': 9340, 'rarity': 'Common'},
  7787: {'rank': 1642, 'rarity': 'Rare'},
  7788: {'rank': 3532, 'rarity': 'Uncommon'},
  7789: {'rank': 679, 'rarity': 'Epic'},
  7790: {'rank': 6493, 'rarity': 'Common'},
  7791: {'rank': 2389, 'rarity': 'Rare'},
  7792: {'rank': 9182, 'rarity': 'Common'},
  7793: {'rank': 8103, 'rarity': 'Common'},
  7794: {'rank': 4331, 'rarity': 'Uncommon'},
  7795: {'rank': 4200, 'rarity': 'Uncommon'},
  7796: {'rank': 4563, 'rarity': 'Uncommon'},
  7797: {'rank': 7177, 'rarity': 'Common'},
  7798: {'rank': 6212, 'rarity': 'Common'},
  7799: {'rank': 5586, 'rarity': 'Common'},
  7800: {'rank': 4886, 'rarity': 'Uncommon'},
  7801: {'rank': 400, 'rarity': 'Epic'},
  7802: {'rank': 7801, 'rarity': 'Common'},
  7803: {'rank': 6612, 'rarity': 'Common'},
  7804: {'rank': 1179, 'rarity': 'Rare'},
  7805: {'rank': 5651, 'rarity': 'Common'},
  7806: {'rank': 9688, 'rarity': 'Common'},
  7807: {'rank': 851, 'rarity': 'Epic'},
  7808: {'rank': 6828, 'rarity': 'Common'},
  7809: {'rank': 1529, 'rarity': 'Rare'},
  7810: {'rank': 9992, 'rarity': 'Common'},
  7811: {'rank': 5057, 'rarity': 'Common'},
  7812: {'rank': 7605, 'rarity': 'Common'},
  7813: {'rank': 7769, 'rarity': 'Common'},
  7814: {'rank': 1949, 'rarity': 'Rare'},
  7815: {'rank': 7693, 'rarity': 'Common'},
  7816: {'rank': 3743, 'rarity': 'Uncommon'},
  7817: {'rank': 960, 'rarity': 'Epic'},
  7818: {'rank': 2855, 'rarity': 'Uncommon'},
  7819: {'rank': 9274, 'rarity': 'Common'},
  7820: {'rank': 7698, 'rarity': 'Common'},
  7821: {'rank': 127, 'rarity': 'Epic'},
  7822: {'rank': 4391, 'rarity': 'Uncommon'},
  7823: {'rank': 5663, 'rarity': 'Common'},
  7824: {'rank': 5536, 'rarity': 'Common'},
  7825: {'rank': 9468, 'rarity': 'Common'},
  7826: {'rank': 508, 'rarity': 'Epic'},
  7827: {'rank': 7973, 'rarity': 'Common'},
  7828: {'rank': 1394, 'rarity': 'Rare'},
  7829: {'rank': 7704, 'rarity': 'Common'},
  7830: {'rank': 7012, 'rarity': 'Common'},
  7831: {'rank': 4578, 'rarity': 'Uncommon'},
  7832: {'rank': 9447, 'rarity': 'Common'},
  7833: {'rank': 9911, 'rarity': 'Common'},
  7834: {'rank': 7785, 'rarity': 'Common'},
  7835: {'rank': 457, 'rarity': 'Epic'},
  7836: {'rank': 9564, 'rarity': 'Common'},
  7837: {'rank': 6412, 'rarity': 'Common'},
  7838: {'rank': 1867, 'rarity': 'Rare'},
  7839: {'rank': 4564, 'rarity': 'Uncommon'},
  7840: {'rank': 5134, 'rarity': 'Common'},
  7841: {'rank': 2960, 'rarity': 'Uncommon'},
  7842: {'rank': 3722, 'rarity': 'Uncommon'},
  7843: {'rank': 1443, 'rarity': 'Rare'},
  7844: {'rank': 4225, 'rarity': 'Uncommon'},
  7845: {'rank': 7078, 'rarity': 'Common'},
  7846: {'rank': 5846, 'rarity': 'Common'},
  7847: {'rank': 6872, 'rarity': 'Common'},
  7848: {'rank': 4122, 'rarity': 'Uncommon'},
  7849: {'rank': 5927, 'rarity': 'Common'},
  7850: {'rank': 6325, 'rarity': 'Common'},
  7851: {'rank': 6210, 'rarity': 'Common'},
  7852: {'rank': 8237, 'rarity': 'Common'},
  7853: {'rank': 5924, 'rarity': 'Common'},
  7854: {'rank': 9775, 'rarity': 'Common'},
  7855: {'rank': 3586, 'rarity': 'Uncommon'},
  7856: {'rank': 4234, 'rarity': 'Uncommon'},
  7857: {'rank': 1013, 'rarity': 'Rare'},
  7858: {'rank': 5865, 'rarity': 'Common'},
  7859: {'rank': 4173, 'rarity': 'Uncommon'},
  7860: {'rank': 9028, 'rarity': 'Common'},
  7861: {'rank': 6292, 'rarity': 'Common'},
  7862: {'rank': 822, 'rarity': 'Epic'},
  7863: {'rank': 2687, 'rarity': 'Uncommon'},
  7864: {'rank': 5897, 'rarity': 'Common'},
  7865: {'rank': 6099, 'rarity': 'Common'},
  7866: {'rank': 9538, 'rarity': 'Common'},
  7867: {'rank': 8131, 'rarity': 'Common'},
  7868: {'rank': 5818, 'rarity': 'Common'},
  7869: {'rank': 9788, 'rarity': 'Common'},
  7870: {'rank': 5575, 'rarity': 'Common'},
  7871: {'rank': 7241, 'rarity': 'Common'},
  7872: {'rank': 9952, 'rarity': 'Common'},
  7873: {'rank': 1116, 'rarity': 'Rare'},
  7874: {'rank': 7397, 'rarity': 'Common'},
  7875: {'rank': 3597, 'rarity': 'Uncommon'},
  7876: {'rank': 4632, 'rarity': 'Uncommon'},
  7877: {'rank': 7332, 'rarity': 'Common'},
  7878: {'rank': 6838, 'rarity': 'Common'},
  7879: {'rank': 5979, 'rarity': 'Common'},
  7880: {'rank': 4441, 'rarity': 'Uncommon'},
  7881: {'rank': 328, 'rarity': 'Epic'},
  7882: {'rank': 3938, 'rarity': 'Uncommon'},
  7883: {'rank': 7887, 'rarity': 'Common'},
  7884: {'rank': 1541, 'rarity': 'Rare'},
  7885: {'rank': 4801, 'rarity': 'Uncommon'},
  7886: {'rank': 7144, 'rarity': 'Common'},
  7887: {'rank': 7323, 'rarity': 'Common'},
  7888: {'rank': 8080, 'rarity': 'Common'},
  7889: {'rank': 2225, 'rarity': 'Rare'},
  7890: {'rank': 8849, 'rarity': 'Common'},
  7891: {'rank': 3592, 'rarity': 'Uncommon'},
  7892: {'rank': 2531, 'rarity': 'Uncommon'},
  7893: {'rank': 5641, 'rarity': 'Common'},
  7894: {'rank': 7877, 'rarity': 'Common'},
  7895: {'rank': 2167, 'rarity': 'Rare'},
  7896: {'rank': 5465, 'rarity': 'Common'},
  7897: {'rank': 3367, 'rarity': 'Uncommon'},
  7898: {'rank': 1688, 'rarity': 'Rare'},
  7899: {'rank': 435, 'rarity': 'Epic'},
  7900: {'rank': 4671, 'rarity': 'Uncommon'},
  7901: {'rank': 2195, 'rarity': 'Rare'},
  7902: {'rank': 100, 'rarity': 'Legendary'},
  7903: {'rank': 6643, 'rarity': 'Common'},
  7904: {'rank': 9483, 'rarity': 'Common'},
  7905: {'rank': 7429, 'rarity': 'Common'},
  7906: {'rank': 9591, 'rarity': 'Common'},
  7907: {'rank': 5072, 'rarity': 'Common'},
  7908: {'rank': 164, 'rarity': 'Epic'},
  7909: {'rank': 668, 'rarity': 'Epic'},
  7910: {'rank': 2729, 'rarity': 'Uncommon'},
  7911: {'rank': 2057, 'rarity': 'Rare'},
  7912: {'rank': 2052, 'rarity': 'Rare'},
  7913: {'rank': 7309, 'rarity': 'Common'},
  7914: {'rank': 8118, 'rarity': 'Common'},
  7915: {'rank': 3773, 'rarity': 'Uncommon'},
  7916: {'rank': 2133, 'rarity': 'Rare'},
  7917: {'rank': 4914, 'rarity': 'Uncommon'},
  7918: {'rank': 9185, 'rarity': 'Common'},
  7919: {'rank': 3479, 'rarity': 'Uncommon'},
  7920: {'rank': 7297, 'rarity': 'Common'},
  7921: {'rank': 5272, 'rarity': 'Common'},
  7922: {'rank': 4399, 'rarity': 'Uncommon'},
  7923: {'rank': 5784, 'rarity': 'Common'},
  7924: {'rank': 8179, 'rarity': 'Common'},
  7925: {'rank': 3316, 'rarity': 'Uncommon'},
  7926: {'rank': 272, 'rarity': 'Epic'},
  7927: {'rank': 4645, 'rarity': 'Uncommon'},
  7928: {'rank': 8406, 'rarity': 'Common'},
  7929: {'rank': 8115, 'rarity': 'Common'},
  7930: {'rank': 3022, 'rarity': 'Uncommon'},
  7931: {'rank': 9278, 'rarity': 'Common'},
  7932: {'rank': 4341, 'rarity': 'Uncommon'},
  7933: {'rank': 6943, 'rarity': 'Common'},
  7934: {'rank': 3384, 'rarity': 'Uncommon'},
  7935: {'rank': 7197, 'rarity': 'Common'},
  7936: {'rank': 3156, 'rarity': 'Uncommon'},
  7937: {'rank': 8982, 'rarity': 'Common'},
  7938: {'rank': 6109, 'rarity': 'Common'},
  7939: {'rank': 5328, 'rarity': 'Common'},
  7940: {'rank': 6932, 'rarity': 'Common'},
  7941: {'rank': 2631, 'rarity': 'Uncommon'},
  7942: {'rank': 7999, 'rarity': 'Common'},
  7943: {'rank': 3702, 'rarity': 'Uncommon'},
  7944: {'rank': 506, 'rarity': 'Epic'},
  7945: {'rank': 212, 'rarity': 'Epic'},
  7946: {'rank': 9195, 'rarity': 'Common'},
  7947: {'rank': 178, 'rarity': 'Epic'},
  7948: {'rank': 8728, 'rarity': 'Common'},
  7949: {'rank': 1526, 'rarity': 'Rare'},
  7950: {'rank': 986, 'rarity': 'Epic'},
  7951: {'rank': 8223, 'rarity': 'Common'},
  7952: {'rank': 393, 'rarity': 'Epic'},
  7953: {'rank': 1183, 'rarity': 'Rare'},
  7954: {'rank': 6558, 'rarity': 'Common'},
  7955: {'rank': 5797, 'rarity': 'Common'},
  7956: {'rank': 693, 'rarity': 'Epic'},
  7957: {'rank': 4001, 'rarity': 'Uncommon'},
  7958: {'rank': 2803, 'rarity': 'Uncommon'},
  7959: {'rank': 6823, 'rarity': 'Common'},
  7960: {'rank': 1417, 'rarity': 'Rare'},
  7961: {'rank': 997, 'rarity': 'Epic'},
  7962: {'rank': 1933, 'rarity': 'Rare'},
  7963: {'rank': 1622, 'rarity': 'Rare'},
  7964: {'rank': 2410, 'rarity': 'Rare'},
  7965: {'rank': 7284, 'rarity': 'Common'},
  7966: {'rank': 1737, 'rarity': 'Rare'},
  7967: {'rank': 5863, 'rarity': 'Common'},
  7968: {'rank': 763, 'rarity': 'Epic'},
  7969: {'rank': 1188, 'rarity': 'Rare'},
  7970: {'rank': 7880, 'rarity': 'Common'},
  7971: {'rank': 9069, 'rarity': 'Common'},
  7972: {'rank': 7878, 'rarity': 'Common'},
  7973: {'rank': 3578, 'rarity': 'Uncommon'},
  7974: {'rank': 6818, 'rarity': 'Common'},
  7975: {'rank': 4626, 'rarity': 'Uncommon'},
  7976: {'rank': 5060, 'rarity': 'Common'},
  7977: {'rank': 2669, 'rarity': 'Uncommon'},
  7978: {'rank': 9220, 'rarity': 'Common'},
  7979: {'rank': 7305, 'rarity': 'Common'},
  7980: {'rank': 3176, 'rarity': 'Uncommon'},
  7981: {'rank': 5280, 'rarity': 'Common'},
  7982: {'rank': 7974, 'rarity': 'Common'},
  7983: {'rank': 9769, 'rarity': 'Common'},
  7984: {'rank': 4789, 'rarity': 'Uncommon'},
  7985: {'rank': 5652, 'rarity': 'Common'},
  7986: {'rank': 8848, 'rarity': 'Common'},
  7987: {'rank': 5701, 'rarity': 'Common'},
  7988: {'rank': 204, 'rarity': 'Epic'},
  7989: {'rank': 5853, 'rarity': 'Common'},
  7990: {'rank': 7465, 'rarity': 'Common'},
  7991: {'rank': 4720, 'rarity': 'Uncommon'},
  7992: {'rank': 3770, 'rarity': 'Uncommon'},
  7993: {'rank': 9523, 'rarity': 'Common'},
  7994: {'rank': 9512, 'rarity': 'Common'},
  7995: {'rank': 916, 'rarity': 'Epic'},
  7996: {'rank': 1759, 'rarity': 'Rare'},
  7997: {'rank': 6699, 'rarity': 'Common'},
  7998: {'rank': 3046, 'rarity': 'Uncommon'},
  7999: {'rank': 7870, 'rarity': 'Common'},
  8000: {'rank': 9239, 'rarity': 'Common'},
  8001: {'rank': 988, 'rarity': 'Epic'},
  8002: {'rank': 4076, 'rarity': 'Uncommon'},
  8003: {'rank': 7076, 'rarity': 'Common'},
  8004: {'rank': 794, 'rarity': 'Epic'},
  8005: {'rank': 226, 'rarity': 'Epic'},
  8006: {'rank': 4175, 'rarity': 'Uncommon'},
  8007: {'rank': 29, 'rarity': 'Legendary'},
  8008: {'rank': 3121, 'rarity': 'Uncommon'},
  8009: {'rank': 4965, 'rarity': 'Uncommon'},
  8010: {'rank': 9006, 'rarity': 'Common'},
  8011: {'rank': 6002, 'rarity': 'Common'},
  8012: {'rank': 3845, 'rarity': 'Uncommon'},
  8013: {'rank': 210, 'rarity': 'Epic'},
  8014: {'rank': 2689, 'rarity': 'Uncommon'},
  8015: {'rank': 8092, 'rarity': 'Common'},
  8016: {'rank': 1976, 'rarity': 'Rare'},
  8017: {'rank': 3416, 'rarity': 'Uncommon'},
  8018: {'rank': 3674, 'rarity': 'Uncommon'},
  8019: {'rank': 5016, 'rarity': 'Common'},
  8020: {'rank': 6785, 'rarity': 'Common'},
  8021: {'rank': 5740, 'rarity': 'Common'},
  8022: {'rank': 8803, 'rarity': 'Common'},
  8023: {'rank': 7335, 'rarity': 'Common'},
  8024: {'rank': 6323, 'rarity': 'Common'},
  8025: {'rank': 9966, 'rarity': 'Common'},
  8026: {'rank': 1641, 'rarity': 'Rare'},
  8027: {'rank': 1421, 'rarity': 'Rare'},
  8028: {'rank': 8901, 'rarity': 'Common'},
  8029: {'rank': 6753, 'rarity': 'Common'},
  8030: {'rank': 3462, 'rarity': 'Uncommon'},
  8031: {'rank': 4003, 'rarity': 'Uncommon'},
  8032: {'rank': 406, 'rarity': 'Epic'},
  8033: {'rank': 6244, 'rarity': 'Common'},
  8034: {'rank': 5379, 'rarity': 'Common'},
  8035: {'rank': 7411, 'rarity': 'Common'},
  8036: {'rank': 5810, 'rarity': 'Common'},
  8037: {'rank': 2706, 'rarity': 'Uncommon'},
  8038: {'rank': 2556, 'rarity': 'Uncommon'},
  8039: {'rank': 171, 'rarity': 'Epic'},
  8040: {'rank': 6312, 'rarity': 'Common'},
  8041: {'rank': 2236, 'rarity': 'Rare'},
  8042: {'rank': 4411, 'rarity': 'Uncommon'},
  8043: {'rank': 1359, 'rarity': 'Rare'},
  8044: {'rank': 9566, 'rarity': 'Common'},
  8045: {'rank': 1677, 'rarity': 'Rare'},
  8046: {'rank': 9047, 'rarity': 'Common'},
  8047: {'rank': 8802, 'rarity': 'Common'},
  8048: {'rank': 9940, 'rarity': 'Common'},
  8049: {'rank': 8170, 'rarity': 'Common'},
  8050: {'rank': 2659, 'rarity': 'Uncommon'},
  8051: {'rank': 801, 'rarity': 'Epic'},
  8052: {'rank': 6016, 'rarity': 'Common'},
  8053: {'rank': 7169, 'rarity': 'Common'},
  8054: {'rank': 690, 'rarity': 'Epic'},
  8055: {'rank': 786, 'rarity': 'Epic'},
  8056: {'rank': 2966, 'rarity': 'Uncommon'},
  8057: {'rank': 5069, 'rarity': 'Common'},
  8058: {'rank': 6906, 'rarity': 'Common'},
  8059: {'rank': 4469, 'rarity': 'Uncommon'},
  8060: {'rank': 4442, 'rarity': 'Uncommon'},
  8061: {'rank': 4683, 'rarity': 'Uncommon'},
  8062: {'rank': 3551, 'rarity': 'Uncommon'},
  8063: {'rank': 5039, 'rarity': 'Common'},
  8064: {'rank': 777, 'rarity': 'Epic'},
  8065: {'rank': 6343, 'rarity': 'Common'},
  8066: {'rank': 5288, 'rarity': 'Common'},
  8067: {'rank': 1367, 'rarity': 'Rare'},
  8068: {'rank': 5742, 'rarity': 'Common'},
  8069: {'rank': 8795, 'rarity': 'Common'},
  8070: {'rank': 4904, 'rarity': 'Uncommon'},
  8071: {'rank': 1889, 'rarity': 'Rare'},
  8072: {'rank': 2690, 'rarity': 'Uncommon'},
  8073: {'rank': 2824, 'rarity': 'Uncommon'},
  8074: {'rank': 8910, 'rarity': 'Common'},
  8075: {'rank': 1434, 'rarity': 'Rare'},
  8076: {'rank': 6728, 'rarity': 'Common'},
  8077: {'rank': 7922, 'rarity': 'Common'},
  8078: {'rank': 5696, 'rarity': 'Common'},
  8079: {'rank': 349, 'rarity': 'Epic'},
  8080: {'rank': 2494, 'rarity': 'Rare'},
  8081: {'rank': 7256, 'rarity': 'Common'},
  8082: {'rank': 4506, 'rarity': 'Uncommon'},
  8083: {'rank': 1050, 'rarity': 'Rare'},
  8084: {'rank': 4493, 'rarity': 'Uncommon'},
  8085: {'rank': 1105, 'rarity': 'Rare'},
  8086: {'rank': 3508, 'rarity': 'Uncommon'},
  8087: {'rank': 3386, 'rarity': 'Uncommon'},
  8088: {'rank': 5766, 'rarity': 'Common'},
  8089: {'rank': 7504, 'rarity': 'Common'},
  8090: {'rank': 5433, 'rarity': 'Common'},
  8091: {'rank': 8185, 'rarity': 'Common'},
  8092: {'rank': 1721, 'rarity': 'Rare'},
  8093: {'rank': 8077, 'rarity': 'Common'},
  8094: {'rank': 8912, 'rarity': 'Common'},
  8095: {'rank': 3753, 'rarity': 'Uncommon'},
  8096: {'rank': 9111, 'rarity': 'Common'},
  8097: {'rank': 2919, 'rarity': 'Uncommon'},
  8098: {'rank': 43, 'rarity': 'Legendary'},
  8099: {'rank': 1135, 'rarity': 'Rare'},
  8100: {'rank': 5563, 'rarity': 'Common'},
  8101: {'rank': 9450, 'rarity': 'Common'},
  8102: {'rank': 4081, 'rarity': 'Uncommon'},
  8103: {'rank': 1638, 'rarity': 'Rare'},
  8104: {'rank': 9075, 'rarity': 'Common'},
  8105: {'rank': 9843, 'rarity': 'Common'},
  8106: {'rank': 8321, 'rarity': 'Common'},
  8107: {'rank': 2458, 'rarity': 'Rare'},
  8108: {'rank': 9835, 'rarity': 'Common'},
  8109: {'rank': 1427, 'rarity': 'Rare'},
  8110: {'rank': 1920, 'rarity': 'Rare'},
  8111: {'rank': 2732, 'rarity': 'Uncommon'},
  8112: {'rank': 2302, 'rarity': 'Rare'},
  8113: {'rank': 7481, 'rarity': 'Common'},
  8114: {'rank': 7396, 'rarity': 'Common'},
  8115: {'rank': 4617, 'rarity': 'Uncommon'},
  8116: {'rank': 242, 'rarity': 'Epic'},
  8117: {'rank': 5136, 'rarity': 'Common'},
  8118: {'rank': 613, 'rarity': 'Epic'},
  8119: {'rank': 6467, 'rarity': 'Common'},
  8120: {'rank': 4783, 'rarity': 'Uncommon'},
  8121: {'rank': 7067, 'rarity': 'Common'},
  8122: {'rank': 4851, 'rarity': 'Uncommon'},
  8123: {'rank': 280, 'rarity': 'Epic'},
  8124: {'rank': 3058, 'rarity': 'Uncommon'},
  8125: {'rank': 2179, 'rarity': 'Rare'},
  8126: {'rank': 4971, 'rarity': 'Uncommon'},
  8127: {'rank': 6701, 'rarity': 'Common'},
  8128: {'rank': 7102, 'rarity': 'Common'},
  8129: {'rank': 5885, 'rarity': 'Common'},
  8130: {'rank': 1260, 'rarity': 'Rare'},
  8131: {'rank': 2755, 'rarity': 'Uncommon'},
  8132: {'rank': 7408, 'rarity': 'Common'},
  8133: {'rank': 7868, 'rarity': 'Common'},
  8134: {'rank': 6519, 'rarity': 'Common'},
  8135: {'rank': 3269, 'rarity': 'Uncommon'},
  8136: {'rank': 7866, 'rarity': 'Common'},
  8137: {'rank': 6688, 'rarity': 'Common'},
  8138: {'rank': 5543, 'rarity': 'Common'},
  8139: {'rank': 872, 'rarity': 'Epic'},
  8140: {'rank': 330, 'rarity': 'Epic'},
  8141: {'rank': 3782, 'rarity': 'Uncommon'},
  8142: {'rank': 9210, 'rarity': 'Common'},
  8143: {'rank': 2250, 'rarity': 'Rare'},
  8144: {'rank': 824, 'rarity': 'Epic'},
  8145: {'rank': 7409, 'rarity': 'Common'},
  8146: {'rank': 33, 'rarity': 'Legendary'},
  8147: {'rank': 7596, 'rarity': 'Common'},
  8148: {'rank': 7691, 'rarity': 'Common'},
  8149: {'rank': 3847, 'rarity': 'Uncommon'},
  8150: {'rank': 9941, 'rarity': 'Common'},
  8151: {'rank': 9580, 'rarity': 'Common'},
  8152: {'rank': 1363, 'rarity': 'Rare'},
  8153: {'rank': 2178, 'rarity': 'Rare'},
  8154: {'rank': 2117, 'rarity': 'Rare'},
  8155: {'rank': 2106, 'rarity': 'Rare'},
  8156: {'rank': 7531, 'rarity': 'Common'},
  8157: {'rank': 2040, 'rarity': 'Rare'},
  8158: {'rank': 5179, 'rarity': 'Common'},
  8159: {'rank': 7697, 'rarity': 'Common'},
  8160: {'rank': 4238, 'rarity': 'Uncommon'},
  8161: {'rank': 4169, 'rarity': 'Uncommon'},
  8162: {'rank': 2496, 'rarity': 'Rare'},
  8163: {'rank': 6909, 'rarity': 'Common'},
  8164: {'rank': 2852, 'rarity': 'Uncommon'},
  8165: {'rank': 1025, 'rarity': 'Rare'},
  8166: {'rank': 2749, 'rarity': 'Uncommon'},
  8167: {'rank': 7414, 'rarity': 'Common'},
  8168: {'rank': 9252, 'rarity': 'Common'},
  8169: {'rank': 9107, 'rarity': 'Common'},
  8170: {'rank': 186, 'rarity': 'Epic'},
  8171: {'rank': 3102, 'rarity': 'Uncommon'},
  8172: {'rank': 6597, 'rarity': 'Common'},
  8173: {'rank': 3715, 'rarity': 'Uncommon'},
  8174: {'rank': 3553, 'rarity': 'Uncommon'},
  8175: {'rank': 5119, 'rarity': 'Common'},
  8176: {'rank': 182, 'rarity': 'Epic'},
  8177: {'rank': 7170, 'rarity': 'Common'},
  8178: {'rank': 9929, 'rarity': 'Common'},
  8179: {'rank': 7293, 'rarity': 'Common'},
  8180: {'rank': 1780, 'rarity': 'Rare'},
  8181: {'rank': 6611, 'rarity': 'Common'},
  8182: {'rank': 3819, 'rarity': 'Uncommon'},
  8183: {'rank': 7355, 'rarity': 'Common'},
  8184: {'rank': 3960, 'rarity': 'Uncommon'},
  8185: {'rank': 670, 'rarity': 'Epic'},
  8186: {'rank': 4245, 'rarity': 'Uncommon'},
  8187: {'rank': 7389, 'rarity': 'Common'},
  8188: {'rank': 5204, 'rarity': 'Common'},
  8189: {'rank': 1082, 'rarity': 'Rare'},
  8190: {'rank': 436, 'rarity': 'Epic'},
  8191: {'rank': 6657, 'rarity': 'Common'},
  8192: {'rank': 8204, 'rarity': 'Common'},
  8193: {'rank': 6502, 'rarity': 'Common'},
  8194: {'rank': 2603, 'rarity': 'Uncommon'},
  8195: {'rank': 9192, 'rarity': 'Common'},
  8196: {'rank': 7781, 'rarity': 'Common'},
  8197: {'rank': 8402, 'rarity': 'Common'},
  8198: {'rank': 4566, 'rarity': 'Uncommon'},
  8199: {'rank': 1779, 'rarity': 'Rare'},
  8200: {'rank': 5296, 'rarity': 'Common'},
  8201: {'rank': 5294, 'rarity': 'Common'},
  8202: {'rank': 9115, 'rarity': 'Common'},
  8203: {'rank': 4377, 'rarity': 'Uncommon'},
  8204: {'rank': 1007, 'rarity': 'Rare'},
  8205: {'rank': 2434, 'rarity': 'Rare'},
  8206: {'rank': 4654, 'rarity': 'Uncommon'},
  8207: {'rank': 9427, 'rarity': 'Common'},
  8208: {'rank': 211, 'rarity': 'Epic'},
  8209: {'rank': 3488, 'rarity': 'Uncommon'},
  8210: {'rank': 8354, 'rarity': 'Common'},
  8211: {'rank': 3293, 'rarity': 'Uncommon'},
  8212: {'rank': 5061, 'rarity': 'Common'},
  8213: {'rank': 6549, 'rarity': 'Common'},
  8214: {'rank': 2294, 'rarity': 'Rare'},
  8215: {'rank': 3872, 'rarity': 'Uncommon'},
  8216: {'rank': 9534, 'rarity': 'Common'},
  8217: {'rank': 8320, 'rarity': 'Common'},
  8218: {'rank': 903, 'rarity': 'Epic'},
  8219: {'rank': 7489, 'rarity': 'Common'},
  8220: {'rank': 3920, 'rarity': 'Uncommon'},
  8221: {'rank': 6195, 'rarity': 'Common'},
  8222: {'rank': 4644, 'rarity': 'Uncommon'},
  8223: {'rank': 1253, 'rarity': 'Rare'},
  8224: {'rank': 267, 'rarity': 'Epic'},
  8225: {'rank': 3463, 'rarity': 'Uncommon'},
  8226: {'rank': 4184, 'rarity': 'Uncommon'},
  8227: {'rank': 9439, 'rarity': 'Common'},
  8228: {'rank': 6947, 'rarity': 'Common'},
  8229: {'rank': 7059, 'rarity': 'Common'},
  8230: {'rank': 9258, 'rarity': 'Common'},
  8231: {'rank': 3560, 'rarity': 'Uncommon'},
  8232: {'rank': 6352, 'rarity': 'Common'},
  8233: {'rank': 44, 'rarity': 'Legendary'},
  8234: {'rank': 2553, 'rarity': 'Uncommon'},
  8235: {'rank': 5090, 'rarity': 'Common'},
  8236: {'rank': 4196, 'rarity': 'Uncommon'},
  8237: {'rank': 196, 'rarity': 'Epic'},
  8238: {'rank': 3554, 'rarity': 'Uncommon'},
  8239: {'rank': 2715, 'rarity': 'Uncommon'},
  8240: {'rank': 2256, 'rarity': 'Rare'},
  8241: {'rank': 4423, 'rarity': 'Uncommon'},
  8242: {'rank': 7612, 'rarity': 'Common'},
  8243: {'rank': 9166, 'rarity': 'Common'},
  8244: {'rank': 4359, 'rarity': 'Uncommon'},
  8245: {'rank': 5013, 'rarity': 'Common'},
  8246: {'rank': 6464, 'rarity': 'Common'},
  8247: {'rank': 8870, 'rarity': 'Common'},
  8248: {'rank': 481, 'rarity': 'Epic'},
  8249: {'rank': 6896, 'rarity': 'Common'},
  8250: {'rank': 9683, 'rarity': 'Common'},
  8251: {'rank': 1966, 'rarity': 'Rare'},
  8252: {'rank': 8299, 'rarity': 'Common'},
  8253: {'rank': 6562, 'rarity': 'Common'},
  8254: {'rank': 5483, 'rarity': 'Common'},
  8255: {'rank': 3008, 'rarity': 'Uncommon'},
  8256: {'rank': 5544, 'rarity': 'Common'},
  8257: {'rank': 9536, 'rarity': 'Common'},
  8258: {'rank': 7325, 'rarity': 'Common'},
  8259: {'rank': 2095, 'rarity': 'Rare'},
  8260: {'rank': 1334, 'rarity': 'Rare'},
  8261: {'rank': 9367, 'rarity': 'Common'},
  8262: {'rank': 3791, 'rarity': 'Uncommon'},
  8263: {'rank': 7072, 'rarity': 'Common'},
  8264: {'rank': 6331, 'rarity': 'Common'},
  8265: {'rank': 3655, 'rarity': 'Uncommon'},
  8266: {'rank': 8547, 'rarity': 'Common'},
  8267: {'rank': 2629, 'rarity': 'Uncommon'},
  8268: {'rank': 8007, 'rarity': 'Common'},
  8269: {'rank': 157, 'rarity': 'Epic'},
  8270: {'rank': 1450, 'rarity': 'Rare'},
  8271: {'rank': 4826, 'rarity': 'Uncommon'},
  8272: {'rank': 7597, 'rarity': 'Common'},
  8273: {'rank': 7955, 'rarity': 'Common'},
  8274: {'rank': 1998, 'rarity': 'Rare'},
  8275: {'rank': 7466, 'rarity': 'Common'},
  8276: {'rank': 2233, 'rarity': 'Rare'},
  8277: {'rank': 7104, 'rarity': 'Common'},
  8278: {'rank': 9100, 'rarity': 'Common'},
  8279: {'rank': 7119, 'rarity': 'Common'},
  8280: {'rank': 1928, 'rarity': 'Rare'},
  8281: {'rank': 4134, 'rarity': 'Uncommon'},
  8282: {'rank': 9251, 'rarity': 'Common'},
  8283: {'rank': 8585, 'rarity': 'Common'},
  8284: {'rank': 7967, 'rarity': 'Common'},
  8285: {'rank': 3402, 'rarity': 'Uncommon'},
  8286: {'rank': 7263, 'rarity': 'Common'},
  8287: {'rank': 6341, 'rarity': 'Common'},
  8288: {'rank': 8483, 'rarity': 'Common'},
  8289: {'rank': 1929, 'rarity': 'Rare'},
  8290: {'rank': 1767, 'rarity': 'Rare'},
  8291: {'rank': 9142, 'rarity': 'Common'},
  8292: {'rank': 624, 'rarity': 'Epic'},
  8293: {'rank': 1717, 'rarity': 'Rare'},
  8294: {'rank': 2299, 'rarity': 'Rare'},
  8295: {'rank': 4822, 'rarity': 'Uncommon'},
  8296: {'rank': 8807, 'rarity': 'Common'},
  8297: {'rank': 2459, 'rarity': 'Rare'},
  8298: {'rank': 2439, 'rarity': 'Rare'},
  8299: {'rank': 1388, 'rarity': 'Rare'},
  8300: {'rank': 7747, 'rarity': 'Common'},
  8301: {'rank': 7768, 'rarity': 'Common'},
  8302: {'rank': 6264, 'rarity': 'Common'},
  8303: {'rank': 9162, 'rarity': 'Common'},
  8304: {'rank': 1644, 'rarity': 'Rare'},
  8305: {'rank': 4702, 'rarity': 'Uncommon'},
  8306: {'rank': 3434, 'rarity': 'Uncommon'},
  8307: {'rank': 1762, 'rarity': 'Rare'},
  8308: {'rank': 9961, 'rarity': 'Common'},
  8309: {'rank': 7038, 'rarity': 'Common'},
  8310: {'rank': 9310, 'rarity': 'Common'},
  8311: {'rank': 4576, 'rarity': 'Uncommon'},
  8312: {'rank': 3057, 'rarity': 'Uncommon'},
  8313: {'rank': 6959, 'rarity': 'Common'},
  8314: {'rank': 4071, 'rarity': 'Uncommon'},
  8315: {'rank': 9681, 'rarity': 'Common'},
  8316: {'rank': 8453, 'rarity': 'Common'},
  8317: {'rank': 5467, 'rarity': 'Common'},
  8318: {'rank': 6573, 'rarity': 'Common'},
  8319: {'rank': 8893, 'rarity': 'Common'},
  8320: {'rank': 1523, 'rarity': 'Rare'},
  8321: {'rank': 5537, 'rarity': 'Common'},
  8322: {'rank': 7909, 'rarity': 'Common'},
  8323: {'rank': 881, 'rarity': 'Epic'},
  8324: {'rank': 9927, 'rarity': 'Common'},
  8325: {'rank': 6508, 'rarity': 'Common'},
  8326: {'rank': 2722, 'rarity': 'Uncommon'},
  8327: {'rank': 9355, 'rarity': 'Common'},
  8328: {'rank': 2369, 'rarity': 'Rare'},
  8329: {'rank': 9018, 'rarity': 'Common'},
  8330: {'rank': 8967, 'rarity': 'Common'},
  8331: {'rank': 1984, 'rarity': 'Rare'},
  8332: {'rank': 2291, 'rarity': 'Rare'},
  8333: {'rank': 2928, 'rarity': 'Uncommon'},
  8334: {'rank': 2303, 'rarity': 'Rare'},
  8335: {'rank': 816, 'rarity': 'Epic'},
  8336: {'rank': 3808, 'rarity': 'Uncommon'},
  8337: {'rank': 1006, 'rarity': 'Rare'},
  8338: {'rank': 6274, 'rarity': 'Common'},
  8339: {'rank': 5614, 'rarity': 'Common'},
  8340: {'rank': 7849, 'rarity': 'Common'},
  8341: {'rank': 8459, 'rarity': 'Common'},
  8342: {'rank': 7956, 'rarity': 'Common'},
  8343: {'rank': 4479, 'rarity': 'Uncommon'},
  8344: {'rank': 8206, 'rarity': 'Common'},
  8345: {'rank': 350, 'rarity': 'Epic'},
  8346: {'rank': 7501, 'rarity': 'Common'},
  8347: {'rank': 1099, 'rarity': 'Rare'},
  8348: {'rank': 4068, 'rarity': 'Uncommon'},
  8349: {'rank': 3018, 'rarity': 'Uncommon'},
  8350: {'rank': 5645, 'rarity': 'Common'},
  8351: {'rank': 3173, 'rarity': 'Uncommon'},
  8352: {'rank': 5850, 'rarity': 'Common'},
  8353: {'rank': 7333, 'rarity': 'Common'},
  8354: {'rank': 6181, 'rarity': 'Common'},
  8355: {'rank': 2394, 'rarity': 'Rare'},
  8356: {'rank': 6632, 'rarity': 'Common'},
  8357: {'rank': 1983, 'rarity': 'Rare'},
  8358: {'rank': 1868, 'rarity': 'Rare'},
  8359: {'rank': 8111, 'rarity': 'Common'},
  8360: {'rank': 9381, 'rarity': 'Common'},
  8361: {'rank': 3282, 'rarity': 'Uncommon'},
  8362: {'rank': 4128, 'rarity': 'Uncommon'},
  8363: {'rank': 3648, 'rarity': 'Uncommon'},
  8364: {'rank': 7594, 'rarity': 'Common'},
  8365: {'rank': 3669, 'rarity': 'Uncommon'},
  8366: {'rank': 3605, 'rarity': 'Uncommon'},
  8367: {'rank': 2764, 'rarity': 'Uncommon'},
  8368: {'rank': 3610, 'rarity': 'Uncommon'},
  8369: {'rank': 4418, 'rarity': 'Uncommon'},
  8370: {'rank': 8336, 'rarity': 'Common'},
  8371: {'rank': 2641, 'rarity': 'Uncommon'},
  8372: {'rank': 2015, 'rarity': 'Rare'},
  8373: {'rank': 9259, 'rarity': 'Common'},
  8374: {'rank': 7669, 'rarity': 'Common'},
  8375: {'rank': 7673, 'rarity': 'Common'},
  8376: {'rank': 799, 'rarity': 'Epic'},
  8377: {'rank': 5661, 'rarity': 'Common'},
  8378: {'rank': 5113, 'rarity': 'Common'},
  8379: {'rank': 7647, 'rarity': 'Common'},
  8380: {'rank': 6609, 'rarity': 'Common'},
  8381: {'rank': 6958, 'rarity': 'Common'},
  8382: {'rank': 630, 'rarity': 'Epic'},
  8383: {'rank': 7855, 'rarity': 'Common'},
  8384: {'rank': 6596, 'rarity': 'Common'},
  8385: {'rank': 4687, 'rarity': 'Uncommon'},
  8386: {'rank': 8927, 'rarity': 'Common'},
  8387: {'rank': 6629, 'rarity': 'Common'},
  8388: {'rank': 7392, 'rarity': 'Common'},
  8389: {'rank': 201, 'rarity': 'Epic'},
  8390: {'rank': 9612, 'rarity': 'Common'},
  8391: {'rank': 3816, 'rarity': 'Uncommon'},
  8392: {'rank': 8331, 'rarity': 'Common'},
  8393: {'rank': 5444, 'rarity': 'Common'},
  8394: {'rank': 6433, 'rarity': 'Common'},
  8395: {'rank': 9114, 'rarity': 'Common'},
  8396: {'rank': 4148, 'rarity': 'Uncommon'},
  8397: {'rank': 2429, 'rarity': 'Rare'},
  8398: {'rank': 2141, 'rarity': 'Rare'},
  8399: {'rank': 3016, 'rarity': 'Uncommon'},
  8400: {'rank': 4805, 'rarity': 'Uncommon'},
  8401: {'rank': 8636, 'rarity': 'Common'},
  8402: {'rank': 299, 'rarity': 'Epic'},
  8403: {'rank': 5920, 'rarity': 'Common'},
  8404: {'rank': 5071, 'rarity': 'Common'},
  8405: {'rank': 5660, 'rarity': 'Common'},
  8406: {'rank': 5030, 'rarity': 'Common'},
  8407: {'rank': 4354, 'rarity': 'Uncommon'},
  8408: {'rank': 9623, 'rarity': 'Common'},
  8409: {'rank': 6435, 'rarity': 'Common'},
  8410: {'rank': 4813, 'rarity': 'Uncommon'},
  8411: {'rank': 1520, 'rarity': 'Rare'},
  8412: {'rank': 8005, 'rarity': 'Common'},
  8413: {'rank': 74, 'rarity': 'Legendary'},
  8414: {'rank': 6061, 'rarity': 'Common'},
  8415: {'rank': 898, 'rarity': 'Epic'},
  8416: {'rank': 7717, 'rarity': 'Common'},
  8417: {'rank': 9232, 'rarity': 'Common'},
  8418: {'rank': 8322, 'rarity': 'Common'},
  8419: {'rank': 8246, 'rarity': 'Common'},
  8420: {'rank': 1908, 'rarity': 'Rare'},
  8421: {'rank': 3279, 'rarity': 'Uncommon'},
  8422: {'rank': 1464, 'rarity': 'Rare'},
  8423: {'rank': 7234, 'rarity': 'Common'},
  8424: {'rank': 1325, 'rarity': 'Rare'},
  8425: {'rank': 7251, 'rarity': 'Common'},
  8426: {'rank': 5605, 'rarity': 'Common'},
  8427: {'rank': 649, 'rarity': 'Epic'},
  8428: {'rank': 4062, 'rarity': 'Uncommon'},
  8429: {'rank': 8335, 'rarity': 'Common'},
  8430: {'rank': 179, 'rarity': 'Epic'},
  8431: {'rank': 7453, 'rarity': 'Common'},
  8432: {'rank': 8122, 'rarity': 'Common'},
  8433: {'rank': 969, 'rarity': 'Epic'},
  8434: {'rank': 6486, 'rarity': 'Common'},
  8435: {'rank': 3541, 'rarity': 'Uncommon'},
  8436: {'rank': 4224, 'rarity': 'Uncommon'},
  8437: {'rank': 8463, 'rarity': 'Common'},
  8438: {'rank': 3886, 'rarity': 'Uncommon'},
  8439: {'rank': 345, 'rarity': 'Epic'},
  8440: {'rank': 9571, 'rarity': 'Common'},
  8441: {'rank': 2849, 'rarity': 'Uncommon'},
  8442: {'rank': 2744, 'rarity': 'Uncommon'},
  8443: {'rank': 62, 'rarity': 'Legendary'},
  8444: {'rank': 1846, 'rarity': 'Rare'},
  8445: {'rank': 2443, 'rarity': 'Rare'},
  8446: {'rank': 6837, 'rarity': 'Common'},
  8447: {'rank': 2506, 'rarity': 'Uncommon'},
  8448: {'rank': 382, 'rarity': 'Epic'},
  8449: {'rank': 1455, 'rarity': 'Rare'},
  8450: {'rank': 199, 'rarity': 'Epic'},
  8451: {'rank': 6447, 'rarity': 'Common'},
  8452: {'rank': 4608, 'rarity': 'Uncommon'},
  8453: {'rank': 7244, 'rarity': 'Common'},
  8454: {'rank': 2544, 'rarity': 'Uncommon'},
  8455: {'rank': 6841, 'rarity': 'Common'},
  8456: {'rank': 1380, 'rarity': 'Rare'},
  8457: {'rank': 4214, 'rarity': 'Uncommon'},
  8458: {'rank': 555, 'rarity': 'Epic'},
  8459: {'rank': 7085, 'rarity': 'Common'},
  8460: {'rank': 3165, 'rarity': 'Uncommon'},
  8461: {'rank': 1783, 'rarity': 'Rare'},
  8462: {'rank': 459, 'rarity': 'Epic'},
  8463: {'rank': 6201, 'rarity': 'Common'},
  8464: {'rank': 9218, 'rarity': 'Common'},
  8465: {'rank': 6309, 'rarity': 'Common'},
  8466: {'rank': 4239, 'rarity': 'Uncommon'},
  8467: {'rank': 5341, 'rarity': 'Common'},
  8468: {'rank': 8715, 'rarity': 'Common'},
  8469: {'rank': 8776, 'rarity': 'Common'},
  8470: {'rank': 3863, 'rarity': 'Uncommon'},
  8471: {'rank': 2472, 'rarity': 'Rare'},
  8472: {'rank': 9810, 'rarity': 'Common'},
  8473: {'rank': 3518, 'rarity': 'Uncommon'},
  8474: {'rank': 9029, 'rarity': 'Common'},
  8475: {'rank': 3792, 'rarity': 'Uncommon'},
  8476: {'rank': 7367, 'rarity': 'Common'},
  8477: {'rank': 6272, 'rarity': 'Common'},
  8478: {'rank': 9312, 'rarity': 'Common'},
  8479: {'rank': 2041, 'rarity': 'Rare'},
  8480: {'rank': 3482, 'rarity': 'Uncommon'},
  8481: {'rank': 4961, 'rarity': 'Uncommon'},
  8482: {'rank': 8690, 'rarity': 'Common'},
  8483: {'rank': 9143, 'rarity': 'Common'},
  8484: {'rank': 1117, 'rarity': 'Rare'},
  8485: {'rank': 4267, 'rarity': 'Uncommon'},
  8486: {'rank': 9485, 'rarity': 'Common'},
  8487: {'rank': 4869, 'rarity': 'Uncommon'},
  8488: {'rank': 3124, 'rarity': 'Uncommon'},
  8489: {'rank': 5627, 'rarity': 'Common'},
  8490: {'rank': 3496, 'rarity': 'Uncommon'},
  8491: {'rank': 102, 'rarity': 'Epic'},
  8492: {'rank': 7402, 'rarity': 'Common'},
  8493: {'rank': 8061, 'rarity': 'Common'},
  8494: {'rank': 5284, 'rarity': 'Common'},
  8495: {'rank': 4476, 'rarity': 'Uncommon'},
  8496: {'rank': 8938, 'rarity': 'Common'},
  8497: {'rank': 9857, 'rarity': 'Common'},
  8498: {'rank': 6480, 'rarity': 'Common'},
  8499: {'rank': 5199, 'rarity': 'Common'},
  8500: {'rank': 4349, 'rarity': 'Uncommon'},
  8501: {'rank': 154, 'rarity': 'Epic'},
  8502: {'rank': 4, 'rarity': 'Legendary'},
  8503: {'rank': 8686, 'rarity': 'Common'},
  8504: {'rank': 9631, 'rarity': 'Common'},
  8505: {'rank': 8425, 'rarity': 'Common'},
  8506: {'rank': 2271, 'rarity': 'Rare'},
  8507: {'rank': 5171, 'rarity': 'Common'},
  8508: {'rank': 1646, 'rarity': 'Rare'},
  8509: {'rank': 4663, 'rarity': 'Uncommon'},
  8510: {'rank': 7054, 'rarity': 'Common'},
  8511: {'rank': 3580, 'rarity': 'Uncommon'},
  8512: {'rank': 6512, 'rarity': 'Common'},
  8513: {'rank': 3565, 'rarity': 'Uncommon'},
  8514: {'rank': 3867, 'rarity': 'Uncommon'},
  8515: {'rank': 71, 'rarity': 'Legendary'},
  8516: {'rank': 8106, 'rarity': 'Common'},
  8517: {'rank': 6676, 'rarity': 'Common'},
  8518: {'rank': 9164, 'rarity': 'Common'},
  8519: {'rank': 5384, 'rarity': 'Common'},
  8520: {'rank': 39, 'rarity': 'Legendary'},
  8521: {'rank': 3531, 'rarity': 'Uncommon'},
  8522: {'rank': 30, 'rarity': 'Legendary'},
  8523: {'rank': 8584, 'rarity': 'Common'},
  8524: {'rank': 6186, 'rarity': 'Common'},
  8525: {'rank': 8575, 'rarity': 'Common'},
  8526: {'rank': 5881, 'rarity': 'Common'},
  8527: {'rank': 4638, 'rarity': 'Uncommon'},
  8528: {'rank': 884, 'rarity': 'Epic'},
  8529: {'rank': 6540, 'rarity': 'Common'},
  8530: {'rank': 5431, 'rarity': 'Common'},
  8531: {'rank': 3389, 'rarity': 'Uncommon'},
  8532: {'rank': 4777, 'rarity': 'Uncommon'},
  8533: {'rank': 9202, 'rarity': 'Common'},
  8534: {'rank': 4274, 'rarity': 'Uncommon'},
  8535: {'rank': 3334, 'rarity': 'Uncommon'},
  8536: {'rank': 8668, 'rarity': 'Common'},
  8537: {'rank': 376, 'rarity': 'Epic'},
  8538: {'rank': 5750, 'rarity': 'Common'},
  8539: {'rank': 8440, 'rarity': 'Common'},
  8540: {'rank': 1152, 'rarity': 'Rare'},
  8541: {'rank': 5372, 'rarity': 'Common'},
  8542: {'rank': 567, 'rarity': 'Epic'},
  8543: {'rank': 2591, 'rarity': 'Uncommon'},
  8544: {'rank': 128, 'rarity': 'Epic'},
  8545: {'rank': 7258, 'rarity': 'Common'},
  8546: {'rank': 9092, 'rarity': 'Common'},
  8547: {'rank': 1813, 'rarity': 'Rare'},
  8548: {'rank': 8888, 'rarity': 'Common'},
  8549: {'rank': 7969, 'rarity': 'Common'},
  8550: {'rank': 3826, 'rarity': 'Uncommon'},
  8551: {'rank': 188, 'rarity': 'Epic'},
  8552: {'rank': 8427, 'rarity': 'Common'},
  8553: {'rank': 221, 'rarity': 'Epic'},
  8554: {'rank': 3077, 'rarity': 'Uncommon'},
  8555: {'rank': 8696, 'rarity': 'Common'},
  8556: {'rank': 3742, 'rarity': 'Uncommon'},
  8557: {'rank': 6740, 'rarity': 'Common'},
  8558: {'rank': 7215, 'rarity': 'Common'},
  8559: {'rank': 9177, 'rarity': 'Common'},
  8560: {'rank': 7314, 'rarity': 'Common'},
  8561: {'rank': 8717, 'rarity': 'Common'},
  8562: {'rank': 2555, 'rarity': 'Uncommon'},
  8563: {'rank': 900, 'rarity': 'Epic'},
  8564: {'rank': 7296, 'rarity': 'Common'},
  8565: {'rank': 3856, 'rarity': 'Uncommon'},
  8566: {'rank': 1533, 'rarity': 'Rare'},
  8567: {'rank': 1472, 'rarity': 'Rare'},
  8568: {'rank': 1071, 'rarity': 'Rare'},
  8569: {'rank': 9779, 'rarity': 'Common'},
  8570: {'rank': 119, 'rarity': 'Epic'},
  8571: {'rank': 4259, 'rarity': 'Uncommon'},
  8572: {'rank': 6035, 'rarity': 'Common'},
  8573: {'rank': 4440, 'rarity': 'Uncommon'},
  8574: {'rank': 4668, 'rarity': 'Uncommon'},
  8575: {'rank': 9934, 'rarity': 'Common'},
  8576: {'rank': 8401, 'rarity': 'Common'},
  8577: {'rank': 9932, 'rarity': 'Common'},
  8578: {'rank': 8714, 'rarity': 'Common'},
  8579: {'rank': 2247, 'rarity': 'Rare'},
  8580: {'rank': 2863, 'rarity': 'Uncommon'},
  8581: {'rank': 1466, 'rarity': 'Rare'},
  8582: {'rank': 9979, 'rarity': 'Common'},
  8583: {'rank': 6400, 'rarity': 'Common'},
  8584: {'rank': 3744, 'rarity': 'Uncommon'},
  8585: {'rank': 5862, 'rarity': 'Common'},
  8586: {'rank': 7455, 'rarity': 'Common'},
  8587: {'rank': 2074, 'rarity': 'Rare'},
  8588: {'rank': 2748, 'rarity': 'Uncommon'},
  8589: {'rank': 131, 'rarity': 'Epic'},
  8590: {'rank': 917, 'rarity': 'Epic'},
  8591: {'rank': 2248, 'rarity': 'Rare'},
  8592: {'rank': 1347, 'rarity': 'Rare'},
  8593: {'rank': 3464, 'rarity': 'Uncommon'},
  8594: {'rank': 9931, 'rarity': 'Common'},
  8595: {'rank': 5619, 'rarity': 'Common'},
  8596: {'rank': 9895, 'rarity': 'Common'},
  8597: {'rank': 2851, 'rarity': 'Uncommon'},
  8598: {'rank': 484, 'rarity': 'Epic'},
  8599: {'rank': 9368, 'rarity': 'Common'},
  8600: {'rank': 2872, 'rarity': 'Uncommon'},
  8601: {'rank': 2373, 'rarity': 'Rare'},
  8602: {'rank': 3162, 'rarity': 'Uncommon'},
  8603: {'rank': 8224, 'rarity': 'Common'},
  8604: {'rank': 610, 'rarity': 'Epic'},
  8605: {'rank': 4536, 'rarity': 'Uncommon'},
  8606: {'rank': 4655, 'rarity': 'Uncommon'},
  8607: {'rank': 901, 'rarity': 'Epic'},
  8608: {'rank': 1643, 'rarity': 'Rare'},
  8609: {'rank': 4759, 'rarity': 'Uncommon'},
  8610: {'rank': 5837, 'rarity': 'Common'},
  8611: {'rank': 7512, 'rarity': 'Common'},
  8612: {'rank': 2540, 'rarity': 'Uncommon'},
  8613: {'rank': 7672, 'rarity': 'Common'},
  8614: {'rank': 7655, 'rarity': 'Common'},
  8615: {'rank': 1040, 'rarity': 'Rare'},
  8616: {'rank': 2559, 'rarity': 'Uncommon'},
  8617: {'rank': 3080, 'rarity': 'Uncommon'},
  8618: {'rank': 2002, 'rarity': 'Rare'},
  8619: {'rank': 5523, 'rarity': 'Common'},
  8620: {'rank': 3591, 'rarity': 'Uncommon'},
  8621: {'rank': 1196, 'rarity': 'Rare'},
  8622: {'rank': 2170, 'rarity': 'Rare'},
  8623: {'rank': 5180, 'rarity': 'Common'},
  8624: {'rank': 3327, 'rarity': 'Uncommon'},
  8625: {'rank': 8253, 'rarity': 'Common'},
  8626: {'rank': 6684, 'rarity': 'Common'},
  8627: {'rank': 1396, 'rarity': 'Rare'},
  8628: {'rank': 1474, 'rarity': 'Rare'},
  8629: {'rank': 3972, 'rarity': 'Uncommon'},
  8630: {'rank': 144, 'rarity': 'Epic'},
  8631: {'rank': 6110, 'rarity': 'Common'},
  8632: {'rank': 1207, 'rarity': 'Rare'},
  8633: {'rank': 3204, 'rarity': 'Uncommon'},
  8634: {'rank': 7063, 'rarity': 'Common'},
  8635: {'rank': 7479, 'rarity': 'Common'},
  8636: {'rank': 3023, 'rarity': 'Uncommon'},
  8637: {'rank': 721, 'rarity': 'Epic'},
  8638: {'rank': 521, 'rarity': 'Epic'},
  8639: {'rank': 5459, 'rarity': 'Common'},
  8640: {'rank': 7892, 'rarity': 'Common'},
  8641: {'rank': 9065, 'rarity': 'Common'},
  8642: {'rank': 1969, 'rarity': 'Rare'},
  8643: {'rank': 8389, 'rarity': 'Common'},
  8644: {'rank': 1271, 'rarity': 'Rare'},
  8645: {'rank': 2047, 'rarity': 'Rare'},
  8646: {'rank': 4847, 'rarity': 'Uncommon'},
  8647: {'rank': 3882, 'rarity': 'Uncommon'},
  8648: {'rank': 4323, 'rarity': 'Uncommon'},
  8649: {'rank': 7004, 'rarity': 'Common'},
  8650: {'rank': 4501, 'rarity': 'Uncommon'},
  8651: {'rank': 6878, 'rarity': 'Common'},
  8652: {'rank': 275, 'rarity': 'Epic'},
  8653: {'rank': 3364, 'rarity': 'Uncommon'},
  8654: {'rank': 6381, 'rarity': 'Common'},
  8655: {'rank': 6630, 'rarity': 'Common'},
  8656: {'rank': 1640, 'rarity': 'Rare'},
  8657: {'rank': 4304, 'rarity': 'Uncommon'},
  8658: {'rank': 1957, 'rarity': 'Rare'},
  8659: {'rank': 3513, 'rarity': 'Uncommon'},
  8660: {'rank': 2416, 'rarity': 'Rare'},
  8661: {'rank': 1658, 'rarity': 'Rare'},
  8662: {'rank': 7793, 'rarity': 'Common'},
  8663: {'rank': 4022, 'rarity': 'Uncommon'},
  8664: {'rank': 2503, 'rarity': 'Uncommon'},
  8665: {'rank': 7784, 'rarity': 'Common'},
  8666: {'rank': 1305, 'rarity': 'Rare'},
  8667: {'rank': 9145, 'rarity': 'Common'},
  8668: {'rank': 7981, 'rarity': 'Common'},
  8669: {'rank': 9511, 'rarity': 'Common'},
  8670: {'rank': 8651, 'rarity': 'Common'},
  8671: {'rank': 4762, 'rarity': 'Uncommon'},
  8672: {'rank': 2890, 'rarity': 'Uncommon'},
  8673: {'rank': 7930, 'rarity': 'Common'},
  8674: {'rank': 7371, 'rarity': 'Common'},
  8675: {'rank': 3692, 'rarity': 'Uncommon'},
  8676: {'rank': 5116, 'rarity': 'Common'},
  8677: {'rank': 8630, 'rarity': 'Common'},
  8678: {'rank': 3815, 'rarity': 'Uncommon'},
  8679: {'rank': 9790, 'rarity': 'Common'},
  8680: {'rank': 7404, 'rarity': 'Common'},
  8681: {'rank': 3943, 'rarity': 'Uncommon'},
  8682: {'rank': 6748, 'rarity': 'Common'},
  8683: {'rank': 8180, 'rarity': 'Common'},
  8684: {'rank': 6598, 'rarity': 'Common'},
  8685: {'rank': 3483, 'rarity': 'Uncommon'},
  8686: {'rank': 237, 'rarity': 'Epic'},
  8687: {'rank': 9587, 'rarity': 'Common'},
  8688: {'rank': 2209, 'rarity': 'Rare'},
  8689: {'rank': 8018, 'rarity': 'Common'},
  8690: {'rank': 5669, 'rarity': 'Common'},
  8691: {'rank': 5909, 'rarity': 'Common'},
  8692: {'rank': 5378, 'rarity': 'Common'},
  8693: {'rank': 9116, 'rarity': 'Common'},
  8694: {'rank': 1463, 'rarity': 'Rare'},
  8695: {'rank': 467, 'rarity': 'Epic'},
  8696: {'rank': 4544, 'rarity': 'Uncommon'},
  8697: {'rank': 973, 'rarity': 'Epic'},
  8698: {'rank': 8710, 'rarity': 'Common'},
  8699: {'rank': 9052, 'rarity': 'Common'},
  8700: {'rank': 735, 'rarity': 'Epic'},
  8701: {'rank': 4098, 'rarity': 'Uncommon'},
  8702: {'rank': 4429, 'rarity': 'Uncommon'},
  8703: {'rank': 9818, 'rarity': 'Common'},
  8704: {'rank': 1016, 'rarity': 'Rare'},
  8705: {'rank': 568, 'rarity': 'Epic'},
  8706: {'rank': 7334, 'rarity': 'Common'},
  8707: {'rank': 6774, 'rarity': 'Common'},
  8708: {'rank': 9971, 'rarity': 'Common'},
  8709: {'rank': 3078, 'rarity': 'Uncommon'},
  8710: {'rank': 3263, 'rarity': 'Uncommon'},
  8711: {'rank': 4020, 'rarity': 'Uncommon'},
  8712: {'rank': 4660, 'rarity': 'Uncommon'},
  8713: {'rank': 1224, 'rarity': 'Rare'},
  8714: {'rank': 8726, 'rarity': 'Common'},
  8715: {'rank': 357, 'rarity': 'Epic'},
  8716: {'rank': 1988, 'rarity': 'Rare'},
  8717: {'rank': 9651, 'rarity': 'Common'},
  8718: {'rank': 5289, 'rarity': 'Common'},
  8719: {'rank': 8521, 'rarity': 'Common'},
  8720: {'rank': 9033, 'rarity': 'Common'},
  8721: {'rank': 6698, 'rarity': 'Common'},
  8722: {'rank': 3286, 'rarity': 'Uncommon'},
  8723: {'rank': 4520, 'rarity': 'Uncommon'},
  8724: {'rank': 3231, 'rarity': 'Uncommon'},
  8725: {'rank': 5988, 'rarity': 'Common'},
  8726: {'rank': 9822, 'rarity': 'Common'},
  8727: {'rank': 1891, 'rarity': 'Rare'},
  8728: {'rank': 1855, 'rarity': 'Rare'},
  8729: {'rank': 70, 'rarity': 'Legendary'},
  8730: {'rank': 5377, 'rarity': 'Common'},
  8731: {'rank': 370, 'rarity': 'Epic'},
  8732: {'rank': 5900, 'rarity': 'Common'},
  8733: {'rank': 1447, 'rarity': 'Rare'},
  8734: {'rank': 4235, 'rarity': 'Uncommon'},
  8735: {'rank': 6865, 'rarity': 'Common'},
  8736: {'rank': 9590, 'rarity': 'Common'},
  8737: {'rank': 7598, 'rarity': 'Common'},
  8738: {'rank': 9838, 'rarity': 'Common'},
  8739: {'rank': 8878, 'rarity': 'Common'},
  8740: {'rank': 5160, 'rarity': 'Common'},
  8741: {'rank': 7919, 'rarity': 'Common'},
  8742: {'rank': 8097, 'rarity': 'Common'},
  8743: {'rank': 2411, 'rarity': 'Rare'},
  8744: {'rank': 4406, 'rarity': 'Uncommon'},
  8745: {'rank': 2006, 'rarity': 'Rare'},
  8746: {'rank': 141, 'rarity': 'Epic'},
  8747: {'rank': 2786, 'rarity': 'Uncommon'},
  8748: {'rank': 3764, 'rarity': 'Uncommon'},
  8749: {'rank': 6348, 'rarity': 'Common'},
  8750: {'rank': 6796, 'rarity': 'Common'},
  8751: {'rank': 3771, 'rarity': 'Uncommon'},
  8752: {'rank': 2380, 'rarity': 'Rare'},
  8753: {'rank': 6905, 'rarity': 'Common'},
  8754: {'rank': 5839, 'rarity': 'Common'},
  8755: {'rank': 4887, 'rarity': 'Uncommon'},
  8756: {'rank': 946, 'rarity': 'Epic'},
  8757: {'rank': 4854, 'rarity': 'Uncommon'},
  8758: {'rank': 3776, 'rarity': 'Uncommon'},
  8759: {'rank': 5721, 'rarity': 'Common'},
  8760: {'rank': 974, 'rarity': 'Epic'},
  8761: {'rank': 5082, 'rarity': 'Common'},
  8762: {'rank': 3253, 'rarity': 'Uncommon'},
  8763: {'rank': 8011, 'rarity': 'Common'},
  8764: {'rank': 6397, 'rarity': 'Common'},
  8765: {'rank': 5952, 'rarity': 'Common'},
  8766: {'rank': 6173, 'rarity': 'Common'},
  8767: {'rank': 2223, 'rarity': 'Rare'},
  8768: {'rank': 320, 'rarity': 'Epic'},
  8769: {'rank': 9469, 'rarity': 'Common'},
  8770: {'rank': 8432, 'rarity': 'Common'},
  8771: {'rank': 8113, 'rarity': 'Common'},
  8772: {'rank': 2388, 'rarity': 'Rare'},
  8773: {'rank': 9008, 'rarity': 'Common'},
  8774: {'rank': 4101, 'rarity': 'Uncommon'},
  8775: {'rank': 4631, 'rarity': 'Uncommon'},
  8776: {'rank': 6253, 'rarity': 'Common'},
  8777: {'rank': 4656, 'rarity': 'Uncommon'},
  8778: {'rank': 5944, 'rarity': 'Common'},
  8779: {'rank': 7510, 'rarity': 'Common'},
  8780: {'rank': 4921, 'rarity': 'Uncommon'},
  8781: {'rank': 9848, 'rarity': 'Common'},
  8782: {'rank': 6020, 'rarity': 'Common'},
  8783: {'rank': 5594, 'rarity': 'Common'},
  8784: {'rank': 2275, 'rarity': 'Rare'},
  8785: {'rank': 256, 'rarity': 'Epic'},
  8786: {'rank': 955, 'rarity': 'Epic'},
  8787: {'rank': 3012, 'rarity': 'Uncommon'},
  8788: {'rank': 6023, 'rarity': 'Common'},
  8789: {'rank': 5302, 'rarity': 'Common'},
  8790: {'rank': 2082, 'rarity': 'Rare'},
  8791: {'rank': 425, 'rarity': 'Epic'},
  8792: {'rank': 5123, 'rarity': 'Common'},
  8793: {'rank': 8965, 'rarity': 'Common'},
  8794: {'rank': 1994, 'rarity': 'Rare'},
  8795: {'rank': 7089, 'rarity': 'Common'},
  8796: {'rank': 3050, 'rarity': 'Uncommon'},
  8797: {'rank': 2920, 'rarity': 'Uncommon'},
  8798: {'rank': 7740, 'rarity': 'Common'},
  8799: {'rank': 5355, 'rarity': 'Common'},
  8800: {'rank': 5310, 'rarity': 'Common'},
  8801: {'rank': 7440, 'rarity': 'Common'},
  8802: {'rank': 5946, 'rarity': 'Common'},
  8803: {'rank': 3709, 'rarity': 'Uncommon'},
  8804: {'rank': 429, 'rarity': 'Epic'},
  8805: {'rank': 6620, 'rarity': 'Common'},
  8806: {'rank': 9869, 'rarity': 'Common'},
  8807: {'rank': 6266, 'rarity': 'Common'},
  8808: {'rank': 61, 'rarity': 'Legendary'},
  8809: {'rank': 8933, 'rarity': 'Common'},
  8810: {'rank': 6187, 'rarity': 'Common'},
  8811: {'rank': 6792, 'rarity': 'Common'},
  8812: {'rank': 2357, 'rarity': 'Rare'},
  8813: {'rank': 3854, 'rarity': 'Uncommon'},
  8814: {'rank': 5534, 'rarity': 'Common'},
  8815: {'rank': 4015, 'rarity': 'Uncommon'},
  8816: {'rank': 3283, 'rarity': 'Uncommon'},
  8817: {'rank': 5547, 'rarity': 'Common'},
  8818: {'rank': 4793, 'rarity': 'Uncommon'},
  8819: {'rank': 4865, 'rarity': 'Uncommon'},
  8820: {'rank': 4055, 'rarity': 'Uncommon'},
  8821: {'rank': 2001, 'rarity': 'Rare'},
  8822: {'rank': 7650, 'rarity': 'Common'},
  8823: {'rank': 2372, 'rarity': 'Rare'},
  8824: {'rank': 1598, 'rarity': 'Rare'},
  8825: {'rank': 3949, 'rarity': 'Uncommon'},
  8826: {'rank': 2187, 'rarity': 'Rare'},
  8827: {'rank': 183, 'rarity': 'Epic'},
  8828: {'rank': 2383, 'rarity': 'Rare'},
  8829: {'rank': 899, 'rarity': 'Epic'},
  8830: {'rank': 3292, 'rarity': 'Uncommon'},
  8831: {'rank': 1264, 'rarity': 'Rare'},
  8832: {'rank': 9892, 'rarity': 'Common'},
  8833: {'rank': 2129, 'rarity': 'Rare'},
  8834: {'rank': 742, 'rarity': 'Epic'},
  8835: {'rank': 6884, 'rarity': 'Common'},
  8836: {'rank': 4658, 'rarity': 'Uncommon'},
  8837: {'rank': 8376, 'rarity': 'Common'},
  8838: {'rank': 7766, 'rarity': 'Common'},
  8839: {'rank': 8117, 'rarity': 'Common'},
  8840: {'rank': 7832, 'rarity': 'Common'},
  8841: {'rank': 7299, 'rarity': 'Common'},
  8842: {'rank': 9299, 'rarity': 'Common'},
  8843: {'rank': 4817, 'rarity': 'Uncommon'},
  8844: {'rank': 8837, 'rarity': 'Common'},
  8845: {'rank': 413, 'rarity': 'Epic'},
  8846: {'rank': 4344, 'rarity': 'Uncommon'},
  8847: {'rank': 8295, 'rarity': 'Common'},
  8848: {'rank': 3265, 'rarity': 'Uncommon'},
  8849: {'rank': 7558, 'rarity': 'Common'},
  8850: {'rank': 3559, 'rarity': 'Uncommon'},
  8851: {'rank': 5838, 'rarity': 'Common'},
  8852: {'rank': 5524, 'rarity': 'Common'},
  8853: {'rank': 4815, 'rarity': 'Uncommon'},
  8854: {'rank': 9078, 'rarity': 'Common'},
  8855: {'rank': 7996, 'rarity': 'Common'},
  8856: {'rank': 3833, 'rarity': 'Uncommon'},
  8857: {'rank': 2336, 'rarity': 'Rare'},
  8858: {'rank': 3491, 'rarity': 'Uncommon'},
  8859: {'rank': 2088, 'rarity': 'Rare'},
  8860: {'rank': 66, 'rarity': 'Legendary'},
  8861: {'rank': 7226, 'rarity': 'Common'},
  8862: {'rank': 8797, 'rarity': 'Common'},
  8863: {'rank': 9598, 'rarity': 'Common'},
  8864: {'rank': 1481, 'rarity': 'Rare'},
  8865: {'rank': 6964, 'rarity': 'Common'},
  8866: {'rank': 9521, 'rarity': 'Common'},
  8867: {'rank': 9495, 'rarity': 'Common'},
  8868: {'rank': 6117, 'rarity': 'Common'},
  8869: {'rank': 9908, 'rarity': 'Common'},
  8870: {'rank': 4876, 'rarity': 'Uncommon'},
  8871: {'rank': 2120, 'rarity': 'Rare'},
  8872: {'rank': 8752, 'rarity': 'Common'},
  8873: {'rank': 6265, 'rarity': 'Common'},
  8874: {'rank': 1115, 'rarity': 'Rare'},
  8875: {'rank': 9905, 'rarity': 'Common'},
  8876: {'rank': 3874, 'rarity': 'Uncommon'},
  8877: {'rank': 7601, 'rarity': 'Common'},
  8878: {'rank': 4014, 'rarity': 'Uncommon'},
  8879: {'rank': 5031, 'rarity': 'Common'},
  8880: {'rank': 7123, 'rarity': 'Common'},
  8881: {'rank': 8526, 'rarity': 'Common'},
  8882: {'rank': 8616, 'rarity': 'Common'},
  8883: {'rank': 5301, 'rarity': 'Common'},
  8884: {'rank': 3215, 'rarity': 'Uncommon'},
  8885: {'rank': 4924, 'rarity': 'Uncommon'},
  8886: {'rank': 265, 'rarity': 'Epic'},
  8887: {'rank': 6294, 'rarity': 'Common'},
  8888: {'rank': 6857, 'rarity': 'Common'},
  8889: {'rank': 6174, 'rarity': 'Common'},
  8890: {'rank': 6056, 'rarity': 'Common'},
  8891: {'rank': 6911, 'rarity': 'Common'},
  8892: {'rank': 1095, 'rarity': 'Rare'},
  8893: {'rank': 7582, 'rarity': 'Common'},
  8894: {'rank': 7424, 'rarity': 'Common'},
  8895: {'rank': 1610, 'rarity': 'Rare'},
  8896: {'rank': 8528, 'rarity': 'Common'},
  8897: {'rank': 7549, 'rarity': 'Common'},
  8898: {'rank': 2338, 'rarity': 'Rare'},
  8899: {'rank': 984, 'rarity': 'Epic'},
  8900: {'rank': 5593, 'rarity': 'Common'},
  8901: {'rank': 5035, 'rarity': 'Common'},
  8902: {'rank': 2278, 'rarity': 'Rare'},
  8903: {'rank': 2799, 'rarity': 'Uncommon'},
  8904: {'rank': 5502, 'rarity': 'Common'},
  8905: {'rank': 2384, 'rarity': 'Rare'},
  8906: {'rank': 4945, 'rarity': 'Uncommon'},
  8907: {'rank': 5330, 'rarity': 'Common'},
  8908: {'rank': 4088, 'rarity': 'Uncommon'},
  8909: {'rank': 7385, 'rarity': 'Common'},
  8910: {'rank': 5793, 'rarity': 'Common'},
  8911: {'rank': 7941, 'rarity': 'Common'},
  8912: {'rank': 4036, 'rarity': 'Uncommon'},
  8913: {'rank': 1249, 'rarity': 'Rare'},
  8914: {'rank': 7716, 'rarity': 'Common'},
  8915: {'rank': 6424, 'rarity': 'Common'},
  8916: {'rank': 3166, 'rarity': 'Uncommon'},
  8917: {'rank': 3064, 'rarity': 'Uncommon'},
  8918: {'rank': 6649, 'rarity': 'Common'},
  8919: {'rank': 1056, 'rarity': 'Rare'},
  8920: {'rank': 3420, 'rarity': 'Uncommon'},
  8921: {'rank': 6429, 'rarity': 'Common'},
  8922: {'rank': 3470, 'rarity': 'Uncommon'},
  8923: {'rank': 4690, 'rarity': 'Uncommon'},
  8924: {'rank': 950, 'rarity': 'Epic'},
  8925: {'rank': 1885, 'rarity': 'Rare'},
  8926: {'rank': 6446, 'rarity': 'Common'},
  8927: {'rank': 6333, 'rarity': 'Common'},
  8928: {'rank': 3105, 'rarity': 'Uncommon'},
  8929: {'rank': 6584, 'rarity': 'Common'},
  8930: {'rank': 9691, 'rarity': 'Common'},
  8931: {'rank': 8507, 'rarity': 'Common'},
  8932: {'rank': 6882, 'rarity': 'Common'},
  8933: {'rank': 9701, 'rarity': 'Common'},
  8934: {'rank': 6377, 'rarity': 'Common'},
  8935: {'rank': 2230, 'rarity': 'Rare'},
  8936: {'rank': 6249, 'rarity': 'Common'},
  8937: {'rank': 8999, 'rarity': 'Common'},
  8938: {'rank': 4178, 'rarity': 'Uncommon'},
  8939: {'rank': 9128, 'rarity': 'Common'},
  8940: {'rank': 5246, 'rarity': 'Common'},
  8941: {'rank': 6892, 'rarity': 'Common'},
  8942: {'rank': 8396, 'rarity': 'Common'},
  8943: {'rank': 4485, 'rarity': 'Uncommon'},
  8944: {'rank': 9720, 'rarity': 'Common'},
  8945: {'rank': 7744, 'rarity': 'Common'},
  8946: {'rank': 6432, 'rarity': 'Common'},
  8947: {'rank': 3934, 'rarity': 'Uncommon'},
  8948: {'rank': 6001, 'rarity': 'Common'},
  8949: {'rank': 2312, 'rarity': 'Rare'},
  8950: {'rank': 3267, 'rarity': 'Uncommon'},
  8951: {'rank': 8491, 'rarity': 'Common'},
  8952: {'rank': 4680, 'rarity': 'Uncommon'},
  8953: {'rank': 739, 'rarity': 'Epic'},
  8954: {'rank': 6939, 'rarity': 'Common'},
  8955: {'rank': 9243, 'rarity': 'Common'},
  8956: {'rank': 5406, 'rarity': 'Common'},
  8957: {'rank': 5255, 'rarity': 'Common'},
  8958: {'rank': 7683, 'rarity': 'Common'},
  8959: {'rank': 5849, 'rarity': 'Common'},
  8960: {'rank': 4140, 'rarity': 'Uncommon'},
  8961: {'rank': 262, 'rarity': 'Epic'},
  8962: {'rank': 1555, 'rarity': 'Rare'},
  8963: {'rank': 2311, 'rarity': 'Rare'},
  8964: {'rank': 8641, 'rarity': 'Common'},
  8965: {'rank': 2776, 'rarity': 'Uncommon'},
  8966: {'rank': 8091, 'rarity': 'Common'},
  8967: {'rank': 6203, 'rarity': 'Common'},
  8968: {'rank': 6916, 'rarity': 'Common'},
  8969: {'rank': 641, 'rarity': 'Epic'},
  8970: {'rank': 5923, 'rarity': 'Common'},
  8971: {'rank': 7552, 'rarity': 'Common'},
  8972: {'rank': 91, 'rarity': 'Legendary'},
  8973: {'rank': 595, 'rarity': 'Epic'},
  8974: {'rank': 3822, 'rarity': 'Uncommon'},
  8975: {'rank': 6415, 'rarity': 'Common'},
  8976: {'rank': 6737, 'rarity': 'Common'},
  8977: {'rank': 6434, 'rarity': 'Common'},
  8978: {'rank': 7844, 'rarity': 'Common'},
  8979: {'rank': 5842, 'rarity': 'Common'},
  8980: {'rank': 9984, 'rarity': 'Common'},
  8981: {'rank': 3848, 'rarity': 'Uncommon'},
  8982: {'rank': 464, 'rarity': 'Epic'},
  8983: {'rank': 254, 'rarity': 'Epic'},
  8984: {'rank': 7354, 'rarity': 'Common'},
  8985: {'rank': 8357, 'rarity': 'Common'},
  8986: {'rank': 7202, 'rarity': 'Common'},
  8987: {'rank': 2971, 'rarity': 'Uncommon'},
  8988: {'rank': 2497, 'rarity': 'Rare'},
  8989: {'rank': 125, 'rarity': 'Epic'},
  8990: {'rank': 7511, 'rarity': 'Common'},
  8991: {'rank': 2879, 'rarity': 'Uncommon'},
  8992: {'rank': 4607, 'rarity': 'Uncommon'},
  8993: {'rank': 2281, 'rarity': 'Rare'},
  8994: {'rank': 5829, 'rarity': 'Common'},
  8995: {'rank': 1255, 'rarity': 'Rare'},
  8996: {'rank': 2404, 'rarity': 'Rare'},
  8997: {'rank': 7627, 'rarity': 'Common'},
  8998: {'rank': 4156, 'rarity': 'Uncommon'},
  8999: {'rank': 4034, 'rarity': 'Uncommon'},
  9000: {'rank': 2468, 'rarity': 'Rare'},
  9001: {'rank': 1970, 'rarity': 'Rare'},
  9002: {'rank': 1437, 'rarity': 'Rare'},
  9003: {'rank': 15, 'rarity': 'Legendary'},
  9004: {'rank': 4002, 'rarity': 'Uncommon'},
  9005: {'rank': 2215, 'rarity': 'Rare'},
  9006: {'rank': 1768, 'rarity': 'Rare'},
  9007: {'rank': 6534, 'rarity': 'Common'},
  9008: {'rank': 983, 'rarity': 'Epic'},
  9009: {'rank': 5096, 'rarity': 'Common'},
  9010: {'rank': 4594, 'rarity': 'Uncommon'},
  9011: {'rank': 1590, 'rarity': 'Rare'},
  9012: {'rank': 7277, 'rarity': 'Common'},
  9013: {'rank': 8818, 'rarity': 'Common'},
  9014: {'rank': 4228, 'rarity': 'Uncommon'},
  9015: {'rank': 623, 'rarity': 'Epic'},
  9016: {'rank': 5427, 'rarity': 'Common'},
  9017: {'rank': 8499, 'rarity': 'Common'},
  9018: {'rank': 1155, 'rarity': 'Rare'},
  9019: {'rank': 8488, 'rarity': 'Common'},
  9020: {'rank': 4058, 'rarity': 'Uncommon'},
  9021: {'rank': 7303, 'rarity': 'Common'},
  9022: {'rank': 2926, 'rarity': 'Uncommon'},
  9023: {'rank': 197, 'rarity': 'Epic'},
  9024: {'rank': 137, 'rarity': 'Epic'},
  9025: {'rank': 5787, 'rarity': 'Common'},
  9026: {'rank': 1570, 'rarity': 'Rare'},
  9027: {'rank': 2284, 'rarity': 'Rare'},
  9028: {'rank': 4612, 'rarity': 'Uncommon'},
  9029: {'rank': 8720, 'rarity': 'Common'},
  9030: {'rank': 1880, 'rarity': 'Rare'},
  9031: {'rank': 8949, 'rarity': 'Common'},
  9032: {'rank': 7058, 'rarity': 'Common'},
  9033: {'rank': 308, 'rarity': 'Epic'},
  9034: {'rank': 9452, 'rarity': 'Common'},
  9035: {'rank': 667, 'rarity': 'Epic'},
  9036: {'rank': 9948, 'rarity': 'Common'},
  9037: {'rank': 7051, 'rarity': 'Common'},
  9038: {'rank': 9236, 'rarity': 'Common'},
  9039: {'rank': 6971, 'rarity': 'Common'},
  9040: {'rank': 4107, 'rarity': 'Uncommon'},
  9041: {'rank': 4466, 'rarity': 'Uncommon'},
  9042: {'rank': 455, 'rarity': 'Epic'},
  9043: {'rank': 325, 'rarity': 'Epic'},
  9044: {'rank': 143, 'rarity': 'Epic'},
  9045: {'rank': 1739, 'rarity': 'Rare'},
  9046: {'rank': 4820, 'rarity': 'Uncommon'},
  9047: {'rank': 849, 'rarity': 'Epic'},
  9048: {'rank': 7953, 'rarity': 'Common'},
  9049: {'rank': 3923, 'rarity': 'Uncommon'},
  9050: {'rank': 8069, 'rarity': 'Common'},
  9051: {'rank': 2313, 'rarity': 'Rare'},
  9052: {'rank': 60, 'rarity': 'Legendary'},
  9053: {'rank': 914, 'rarity': 'Epic'},
  9054: {'rank': 583, 'rarity': 'Epic'},
  9055: {'rank': 5118, 'rarity': 'Common'},
  9056: {'rank': 9849, 'rarity': 'Common'},
  9057: {'rank': 512, 'rarity': 'Epic'},
  9058: {'rank': 4378, 'rarity': 'Uncommon'},
  9059: {'rank': 9658, 'rarity': 'Common'},
  9060: {'rank': 207, 'rarity': 'Epic'},
  9061: {'rank': 3818, 'rarity': 'Uncommon'},
  9062: {'rank': 6051, 'rarity': 'Common'},
  9063: {'rank': 3789, 'rarity': 'Uncommon'},
  9064: {'rank': 4550, 'rarity': 'Uncommon'},
  9065: {'rank': 8581, 'rarity': 'Common'},
  9066: {'rank': 4328, 'rarity': 'Uncommon'},
  9067: {'rank': 5046, 'rarity': 'Common'},
  9068: {'rank': 3425, 'rarity': 'Uncommon'},
  9069: {'rank': 7462, 'rarity': 'Common'},
  9070: {'rank': 9910, 'rarity': 'Common'},
  9071: {'rank': 9791, 'rarity': 'Common'},
  9072: {'rank': 7830, 'rarity': 'Common'},
  9073: {'rank': 7699, 'rarity': 'Common'},
  9074: {'rank': 3418, 'rarity': 'Uncommon'},
  9075: {'rank': 4106, 'rarity': 'Uncommon'},
  9076: {'rank': 4812, 'rarity': 'Uncommon'},
  9077: {'rank': 2530, 'rarity': 'Uncommon'},
  9078: {'rank': 2465, 'rarity': 'Rare'},
  9079: {'rank': 1887, 'rarity': 'Rare'},
  9080: {'rank': 8468, 'rarity': 'Common'},
  9081: {'rank': 4243, 'rarity': 'Uncommon'},
  9082: {'rank': 4310, 'rarity': 'Uncommon'},
  9083: {'rank': 7340, 'rarity': 'Common'},
  9084: {'rank': 1679, 'rarity': 'Rare'},
  9085: {'rank': 2775, 'rarity': 'Uncommon'},
  9086: {'rank': 6904, 'rarity': 'Common'},
  9087: {'rank': 2862, 'rarity': 'Uncommon'},
  9088: {'rank': 3484, 'rarity': 'Uncommon'},
  9089: {'rank': 3141, 'rarity': 'Uncommon'},
  9090: {'rank': 8303, 'rarity': 'Common'},
  9091: {'rank': 951, 'rarity': 'Epic'},
  9092: {'rank': 297, 'rarity': 'Epic'},
  9093: {'rank': 7573, 'rarity': 'Common'},
  9094: {'rank': 9395, 'rarity': 'Common'},
  9095: {'rank': 534, 'rarity': 'Epic'},
  9096: {'rank': 9096, 'rarity': 'Common'},
  9097: {'rank': 5275, 'rarity': 'Common'},
  9098: {'rank': 5201, 'rarity': 'Common'},
  9099: {'rank': 8596, 'rarity': 'Common'},
  9100: {'rank': 3852, 'rarity': 'Uncommon'},
  9101: {'rank': 1849, 'rarity': 'Rare'},
  9102: {'rank': 5336, 'rarity': 'Common'},
  9103: {'rank': 2300, 'rarity': 'Rare'},
  9104: {'rank': 6350, 'rarity': 'Common'},
  9105: {'rank': 2227, 'rarity': 'Rare'},
  9106: {'rank': 4240, 'rarity': 'Uncommon'},
  9107: {'rank': 2044, 'rarity': 'Rare'},
  9108: {'rank': 1667, 'rarity': 'Rare'},
  9109: {'rank': 8562, 'rarity': 'Common'},
  9110: {'rank': 7900, 'rarity': 'Common'},
  9111: {'rank': 2099, 'rarity': 'Rare'},
  9112: {'rank': 1546, 'rarity': 'Rare'},
  9113: {'rank': 5520, 'rarity': 'Common'},
  9114: {'rank': 9533, 'rarity': 'Common'},
  9115: {'rank': 7568, 'rarity': 'Common'},
  9116: {'rank': 4870, 'rarity': 'Uncommon'},
  9117: {'rank': 3775, 'rarity': 'Uncommon'},
  9118: {'rank': 2054, 'rarity': 'Rare'},
  9119: {'rank': 7271, 'rarity': 'Common'},
  9120: {'rank': 5945, 'rarity': 'Common'},
  9121: {'rank': 1653, 'rarity': 'Rare'},
  9122: {'rank': 7348, 'rarity': 'Common'},
  9123: {'rank': 842, 'rarity': 'Epic'},
  9124: {'rank': 6646, 'rarity': 'Common'},
  9125: {'rank': 1287, 'rarity': 'Rare'},
  9126: {'rank': 6232, 'rarity': 'Common'},
  9127: {'rank': 3781, 'rarity': 'Uncommon'},
  9128: {'rank': 2039, 'rarity': 'Rare'},
  9129: {'rank': 4075, 'rarity': 'Uncommon'},
  9130: {'rank': 89, 'rarity': 'Legendary'},
  9131: {'rank': 2746, 'rarity': 'Uncommon'},
  9132: {'rank': 9583, 'rarity': 'Common'},
  9133: {'rank': 5128, 'rarity': 'Common'},
  9134: {'rank': 1544, 'rarity': 'Rare'},
  9135: {'rank': 4171, 'rarity': 'Uncommon'},
  9136: {'rank': 1093, 'rarity': 'Rare'},
  9137: {'rank': 3520, 'rarity': 'Uncommon'},
  9138: {'rank': 1816, 'rarity': 'Rare'},
  9139: {'rank': 3623, 'rarity': 'Uncommon'},
  9140: {'rank': 6526, 'rarity': 'Common'},
  9141: {'rank': 4547, 'rarity': 'Uncommon'},
  9142: {'rank': 1499, 'rarity': 'Rare'},
  9143: {'rank': 9917, 'rarity': 'Common'},
  9144: {'rank': 5691, 'rarity': 'Common'},
  9145: {'rank': 3326, 'rarity': 'Uncommon'},
  9146: {'rank': 671, 'rarity': 'Epic'},
  9147: {'rank': 6693, 'rarity': 'Common'},
  9148: {'rank': 5794, 'rarity': 'Common'},
  9149: {'rank': 7129, 'rarity': 'Common'},
  9150: {'rank': 1360, 'rarity': 'Rare'},
  9151: {'rank': 1678, 'rarity': 'Rare'},
  9152: {'rank': 9442, 'rarity': 'Common'},
  9153: {'rank': 2220, 'rarity': 'Rare'},
  9154: {'rank': 7526, 'rarity': 'Common'},
  9155: {'rank': 8333, 'rarity': 'Common'},
  9156: {'rank': 543, 'rarity': 'Epic'},
  9157: {'rank': 9998, 'rarity': 'Common'},
  9158: {'rank': 6636, 'rarity': 'Common'},
  9159: {'rank': 6313, 'rarity': 'Common'},
  9160: {'rank': 7808, 'rarity': 'Common'},
  9161: {'rank': 7837, 'rarity': 'Common'},
  9162: {'rank': 2754, 'rarity': 'Uncommon'},
  9163: {'rank': 6819, 'rarity': 'Common'},
  9164: {'rank': 4732, 'rarity': 'Uncommon'},
  9165: {'rank': 6384, 'rarity': 'Common'},
  9166: {'rank': 18, 'rarity': 'Legendary'},
  9167: {'rank': 1029, 'rarity': 'Rare'},
  9168: {'rank': 5052, 'rarity': 'Common'},
  9169: {'rank': 9393, 'rarity': 'Common'},
  9170: {'rank': 2738, 'rarity': 'Uncommon'},
  9171: {'rank': 1660, 'rarity': 'Rare'},
  9172: {'rank': 7480, 'rarity': 'Common'},
  9173: {'rank': 4791, 'rarity': 'Uncommon'},
  9174: {'rank': 7951, 'rarity': 'Common'},
  9175: {'rank': 890, 'rarity': 'Epic'},
  9176: {'rank': 1319, 'rarity': 'Rare'},
  9177: {'rank': 6138, 'rarity': 'Common'},
  9178: {'rank': 8593, 'rarity': 'Common'},
  9179: {'rank': 8661, 'rarity': 'Common'},
  9180: {'rank': 8906, 'rarity': 'Common'},
  9181: {'rank': 9490, 'rarity': 'Common'},
  9182: {'rank': 5513, 'rarity': 'Common'},
  9183: {'rank': 3962, 'rarity': 'Uncommon'},
  9184: {'rank': 8324, 'rarity': 'Common'},
  9185: {'rank': 8466, 'rarity': 'Common'},
  9186: {'rank': 9320, 'rarity': 'Common'},
  9187: {'rank': 2867, 'rarity': 'Uncommon'},
  9188: {'rank': 3991, 'rarity': 'Uncommon'},
  9189: {'rank': 3571, 'rarity': 'Uncommon'},
  9190: {'rank': 3149, 'rarity': 'Uncommon'},
  9191: {'rank': 784, 'rarity': 'Epic'},
  9192: {'rank': 1403, 'rarity': 'Rare'},
  9193: {'rank': 8250, 'rarity': 'Common'},
  9194: {'rank': 9150, 'rarity': 'Common'},
  9195: {'rank': 4884, 'rarity': 'Uncommon'},
  9196: {'rank': 1424, 'rarity': 'Rare'},
  9197: {'rank': 2471, 'rarity': 'Rare'},
  9198: {'rank': 5746, 'rarity': 'Common'},
  9199: {'rank': 5782, 'rarity': 'Common'},
  9200: {'rank': 9980, 'rarity': 'Common'},
  9201: {'rank': 1894, 'rarity': 'Rare'},
  9202: {'rank': 5399, 'rarity': 'Common'},
  9203: {'rank': 1531, 'rarity': 'Rare'},
  9204: {'rank': 1639, 'rarity': 'Rare'},
  9205: {'rank': 8704, 'rarity': 'Common'},
  9206: {'rank': 1085, 'rarity': 'Rare'},
  9207: {'rank': 4203, 'rarity': 'Uncommon'},
  9208: {'rank': 5197, 'rarity': 'Common'},
  9209: {'rank': 2912, 'rarity': 'Uncommon'},
  9210: {'rank': 6157, 'rarity': 'Common'},
  9211: {'rank': 6327, 'rarity': 'Common'},
  9212: {'rank': 9399, 'rarity': 'Common'},
  9213: {'rank': 209, 'rarity': 'Epic'},
  9214: {'rank': 2285, 'rarity': 'Rare'},
  9215: {'rank': 7022, 'rarity': 'Common'},
  9216: {'rank': 7569, 'rarity': 'Common'},
  9217: {'rank': 3040, 'rarity': 'Uncommon'},
  9218: {'rank': 7564, 'rarity': 'Common'},
  9219: {'rank': 806, 'rarity': 'Epic'},
  9220: {'rank': 740, 'rarity': 'Epic'},
  9221: {'rank': 8216, 'rarity': 'Common'},
  9222: {'rank': 5443, 'rarity': 'Common'},
  9223: {'rank': 9390, 'rarity': 'Common'},
  9224: {'rank': 9273, 'rarity': 'Common'},
  9225: {'rank': 7593, 'rarity': 'Common'},
  9226: {'rank': 971, 'rarity': 'Epic'},
  9227: {'rank': 529, 'rarity': 'Epic'},
  9228: {'rank': 9124, 'rarity': 'Common'},
  9229: {'rank': 8783, 'rarity': 'Common'},
  9230: {'rank': 6398, 'rarity': 'Common'},
  9231: {'rank': 5882, 'rarity': 'Common'},
  9232: {'rank': 8286, 'rarity': 'Common'},
  9233: {'rank': 5147, 'rarity': 'Common'},
  9234: {'rank': 9061, 'rarity': 'Common'},
  9235: {'rank': 8737, 'rarity': 'Common'},
  9236: {'rank': 5243, 'rarity': 'Common'},
  9237: {'rank': 50, 'rarity': 'Legendary'},
  9238: {'rank': 5230, 'rarity': 'Common'},
  9239: {'rank': 9038, 'rarity': 'Common'},
  9240: {'rank': 1123, 'rarity': 'Rare'},
  9241: {'rank': 3297, 'rarity': 'Uncommon'},
  9242: {'rank': 6492, 'rarity': 'Common'},
  9243: {'rank': 7115, 'rarity': 'Common'},
  9244: {'rank': 5299, 'rarity': 'Common'},
  9245: {'rank': 310, 'rarity': 'Epic'},
  9246: {'rank': 2549, 'rarity': 'Uncommon'},
  9247: {'rank': 1200, 'rarity': 'Rare'},
  9248: {'rank': 6984, 'rarity': 'Common'},
  9249: {'rank': 8259, 'rarity': 'Common'},
  9250: {'rank': 5990, 'rarity': 'Common'},
  9251: {'rank': 7621, 'rarity': 'Common'},
  9252: {'rank': 4294, 'rarity': 'Uncommon'},
  9253: {'rank': 1978, 'rarity': 'Rare'},
  9254: {'rank': 5382, 'rarity': 'Common'},
  9255: {'rank': 8125, 'rarity': 'Common'},
  9256: {'rank': 1192, 'rarity': 'Rare'},
  9257: {'rank': 2554, 'rarity': 'Uncommon'},
  9258: {'rank': 9137, 'rarity': 'Common'},
  9259: {'rank': 6944, 'rarity': 'Common'},
  9260: {'rank': 2830, 'rarity': 'Uncommon'},
  9261: {'rank': 2272, 'rarity': 'Rare'},
  9262: {'rank': 1219, 'rarity': 'Rare'},
  9263: {'rank': 1366, 'rarity': 'Rare'},
  9264: {'rank': 2767, 'rarity': 'Uncommon'},
  9265: {'rank': 6806, 'rarity': 'Common'},
  9266: {'rank': 9528, 'rarity': 'Common'},
  9267: {'rank': 9877, 'rarity': 'Common'},
  9268: {'rank': 3242, 'rarity': 'Uncommon'},
  9269: {'rank': 3447, 'rarity': 'Uncommon'},
  9270: {'rank': 6086, 'rarity': 'Common'},
  9271: {'rank': 5187, 'rarity': 'Common'},
  9272: {'rank': 9785, 'rarity': 'Common'},
  9273: {'rank': 1981, 'rarity': 'Rare'},
  9274: {'rank': 4091, 'rarity': 'Uncommon'},
  9275: {'rank': 4186, 'rarity': 'Uncommon'},
  9276: {'rank': 9627, 'rarity': 'Common'},
  9277: {'rank': 6588, 'rarity': 'Common'},
  9278: {'rank': 418, 'rarity': 'Epic'},
  9279: {'rank': 906, 'rarity': 'Epic'},
  9280: {'rank': 688, 'rarity': 'Epic'},
  9281: {'rank': 713, 'rarity': 'Epic'},
  9282: {'rank': 160, 'rarity': 'Epic'},
  9283: {'rank': 83, 'rarity': 'Legendary'},
  9284: {'rank': 3725, 'rarity': 'Uncommon'},
  9285: {'rank': 7434, 'rarity': 'Common'},
  9286: {'rank': 9027, 'rarity': 'Common'},
  9287: {'rank': 1603, 'rarity': 'Rare'},
  9288: {'rank': 2161, 'rarity': 'Rare'},
  9289: {'rank': 8337, 'rarity': 'Common'},
  9290: {'rank': 5076, 'rarity': 'Common'},
  9291: {'rank': 6976, 'rarity': 'Common'},
  9292: {'rank': 9828, 'rarity': 'Common'},
  9293: {'rank': 751, 'rarity': 'Epic'},
  9294: {'rank': 7695, 'rarity': 'Common'},
  9295: {'rank': 6126, 'rarity': 'Common'},
  9296: {'rank': 3032, 'rarity': 'Uncommon'},
  9297: {'rank': 2050, 'rarity': 'Rare'},
  9298: {'rank': 2660, 'rarity': 'Uncommon'},
  9299: {'rank': 3926, 'rarity': 'Uncommon'},
  9300: {'rank': 8843, 'rarity': 'Common'},
  9301: {'rank': 7802, 'rarity': 'Common'},
  9302: {'rank': 2887, 'rarity': 'Uncommon'},
  9303: {'rank': 5245, 'rarity': 'Common'},
  9304: {'rank': 4509, 'rarity': 'Uncommon'},
  9305: {'rank': 2993, 'rarity': 'Uncommon'},
  9306: {'rank': 3959, 'rarity': 'Uncommon'},
  9307: {'rank': 4324, 'rarity': 'Uncommon'},
  9308: {'rank': 4132, 'rarity': 'Uncommon'},
  9309: {'rank': 7111, 'rarity': 'Common'},
  9310: {'rank': 5088, 'rarity': 'Common'},
  9311: {'rank': 8048, 'rarity': 'Common'},
  9312: {'rank': 572, 'rarity': 'Epic'},
  9313: {'rank': 5688, 'rarity': 'Common'},
  9314: {'rank': 7585, 'rarity': 'Common'},
  9315: {'rank': 6396, 'rarity': 'Common'},
  9316: {'rank': 8478, 'rarity': 'Common'},
  9317: {'rank': 3853, 'rarity': 'Uncommon'},
  9318: {'rank': 2757, 'rarity': 'Uncommon'},
  9319: {'rank': 8422, 'rarity': 'Common'},
  9320: {'rank': 1553, 'rarity': 'Rare'},
  9321: {'rank': 9345, 'rarity': 'Common'},
  9322: {'rank': 6273, 'rarity': 'Common'},
  9323: {'rank': 8984, 'rarity': 'Common'},
  9324: {'rank': 4753, 'rarity': 'Uncommon'},
  9325: {'rank': 6969, 'rarity': 'Common'},
  9326: {'rank': 9772, 'rarity': 'Common'},
  9327: {'rank': 8518, 'rarity': 'Common'},
  9328: {'rank': 1201, 'rarity': 'Rare'},
  9329: {'rank': 6147, 'rarity': 'Common'},
  9330: {'rank': 8388, 'rarity': 'Common'},
  9331: {'rank': 2423, 'rarity': 'Rare'},
  9332: {'rank': 7502, 'rarity': 'Common'},
  9333: {'rank': 9552, 'rarity': 'Common'},
  9334: {'rank': 6038, 'rarity': 'Common'},
  9335: {'rank': 2353, 'rarity': 'Rare'},
  9336: {'rank': 728, 'rarity': 'Epic'},
  9337: {'rank': 2773, 'rarity': 'Uncommon'},
  9338: {'rank': 5665, 'rarity': 'Common'},
  9339: {'rank': 5351, 'rarity': 'Common'},
  9340: {'rank': 8857, 'rarity': 'Common'},
  9341: {'rank': 7047, 'rarity': 'Common'},
  9342: {'rank': 6815, 'rarity': 'Common'},
  9343: {'rank': 6314, 'rarity': 'Common'},
  9344: {'rank': 9560, 'rarity': 'Common'},
  9345: {'rank': 6213, 'rarity': 'Common'},
  9346: {'rank': 2086, 'rarity': 'Rare'},
  9347: {'rank': 2627, 'rarity': 'Uncommon'},
  9348: {'rank': 5767, 'rarity': 'Common'},
  9349: {'rank': 3992, 'rarity': 'Uncommon'},
  9350: {'rank': 5356, 'rarity': 'Common'},
  9351: {'rank': 5981, 'rarity': 'Common'},
  9352: {'rank': 4207, 'rarity': 'Uncommon'},
  9353: {'rank': 6891, 'rarity': 'Common'},
  9354: {'rank': 9545, 'rarity': 'Common'},
  9355: {'rank': 9492, 'rarity': 'Common'},
  9356: {'rank': 2683, 'rarity': 'Uncommon'},
  9357: {'rank': 3933, 'rarity': 'Uncommon'},
  9358: {'rank': 3766, 'rarity': 'Uncommon'},
  9359: {'rank': 3895, 'rarity': 'Uncommon'},
  9360: {'rank': 1953, 'rarity': 'Rare'},
  9361: {'rank': 3081, 'rarity': 'Uncommon'},
  9362: {'rank': 3419, 'rarity': 'Uncommon'},
  9363: {'rank': 8454, 'rarity': 'Common'},
  9364: {'rank': 3410, 'rarity': 'Uncommon'},
  9365: {'rank': 7893, 'rarity': 'Common'},
  9366: {'rank': 4276, 'rarity': 'Uncommon'},
  9367: {'rank': 4404, 'rarity': 'Uncommon'},
  9368: {'rank': 9928, 'rarity': 'Common'},
  9369: {'rank': 4618, 'rarity': 'Uncommon'},
  9370: {'rank': 7278, 'rarity': 'Common'},
  9371: {'rank': 6317, 'rarity': 'Common'},
  9372: {'rank': 8764, 'rarity': 'Common'},
  9373: {'rank': 1565, 'rarity': 'Rare'},
  9374: {'rank': 3183, 'rarity': 'Uncommon'},
  9375: {'rank': 1314, 'rarity': 'Rare'},
  9376: {'rank': 2760, 'rarity': 'Uncommon'},
  9377: {'rank': 7019, 'rarity': 'Common'},
  9378: {'rank': 5273, 'rarity': 'Common'},
  9379: {'rank': 4478, 'rarity': 'Uncommon'},
  9380: {'rank': 8890, 'rarity': 'Common'},
  9381: {'rank': 2784, 'rarity': 'Uncommon'},
  9382: {'rank': 341, 'rarity': 'Epic'},
  9383: {'rank': 8280, 'rarity': 'Common'},
  9384: {'rank': 6391, 'rarity': 'Common'},
  9385: {'rank': 1170, 'rarity': 'Rare'},
  9386: {'rank': 5711, 'rarity': 'Common'},
  9387: {'rank': 8028, 'rarity': 'Common'},
  9388: {'rank': 3786, 'rarity': 'Uncommon'},
  9389: {'rank': 8296, 'rarity': 'Common'},
  9390: {'rank': 5323, 'rarity': 'Common'},
  9391: {'rank': 3091, 'rarity': 'Uncommon'},
  9392: {'rank': 518, 'rarity': 'Epic'},
  9393: {'rank': 2831, 'rarity': 'Uncommon'},
  9394: {'rank': 77, 'rarity': 'Legendary'},
  9395: {'rank': 5282, 'rarity': 'Common'},
  9396: {'rank': 3650, 'rarity': 'Uncommon'},
  9397: {'rank': 977, 'rarity': 'Epic'},
  9398: {'rank': 4329, 'rarity': 'Uncommon'},
  9399: {'rank': 662, 'rarity': 'Epic'},
  9400: {'rank': 5485, 'rarity': 'Common'},
  9401: {'rank': 8599, 'rarity': 'Common'},
  9402: {'rank': 5358, 'rarity': 'Common'},
  9403: {'rank': 9978, 'rarity': 'Common'},
  9404: {'rank': 9716, 'rarity': 'Common'},
  9405: {'rank': 8527, 'rarity': 'Common'},
  9406: {'rank': 1964, 'rarity': 'Rare'},
  9407: {'rank': 7060, 'rarity': 'Common'},
  9408: {'rank': 4105, 'rarity': 'Uncommon'},
  9409: {'rank': 7448, 'rarity': 'Common'},
  9410: {'rank': 6574, 'rarity': 'Common'},
  9411: {'rank': 2028, 'rarity': 'Rare'},
  9412: {'rank': 889, 'rarity': 'Epic'},
  9413: {'rank': 1345, 'rarity': 'Rare'},
  9414: {'rank': 6532, 'rarity': 'Common'},
  9415: {'rank': 3372, 'rarity': 'Uncommon'},
  9416: {'rank': 3196, 'rarity': 'Uncommon'},
  9417: {'rank': 982, 'rarity': 'Epic'},
  9418: {'rank': 2733, 'rarity': 'Uncommon'},
  9419: {'rank': 1002, 'rarity': 'Rare'},
  9420: {'rank': 9291, 'rarity': 'Common'},
  9421: {'rank': 9122, 'rarity': 'Common'},
  9422: {'rank': 6913, 'rarity': 'Common'},
  9423: {'rank': 2578, 'rarity': 'Uncommon'},
  9424: {'rank': 8577, 'rarity': 'Common'},
  9425: {'rank': 1277, 'rarity': 'Rare'},
  9426: {'rank': 5577, 'rarity': 'Common'},
  9427: {'rank': 8626, 'rarity': 'Common'},
  9428: {'rank': 7357, 'rarity': 'Common'},
  9429: {'rank': 1373, 'rarity': 'Rare'},
  9430: {'rank': 6365, 'rarity': 'Common'},
  9431: {'rank': 200, 'rarity': 'Epic'},
  9432: {'rank': 1290, 'rarity': 'Rare'},
  9433: {'rank': 361, 'rarity': 'Epic'},
  9434: {'rank': 9653, 'rarity': 'Common'},
  9435: {'rank': 9514, 'rarity': 'Common'},
  9436: {'rank': 7073, 'rarity': 'Common'},
  9437: {'rank': 7116, 'rarity': 'Common'},
  9438: {'rank': 1451, 'rarity': 'Rare'},
  9439: {'rank': 8431, 'rarity': 'Common'},
  9440: {'rank': 5939, 'rarity': 'Common'},
  9441: {'rank': 3780, 'rarity': 'Uncommon'},
  9442: {'rank': 6777, 'rarity': 'Common'},
  9443: {'rank': 5996, 'rarity': 'Common'},
  9444: {'rank': 5481, 'rarity': 'Common'},
  9445: {'rank': 7231, 'rarity': 'Common'},
  9446: {'rank': 7046, 'rarity': 'Common'},
  9447: {'rank': 6585, 'rarity': 'Common'},
  9448: {'rank': 2030, 'rarity': 'Rare'},
  9449: {'rank': 2763, 'rarity': 'Uncommon'},
  9450: {'rank': 324, 'rarity': 'Epic'},
  9451: {'rank': 3004, 'rarity': 'Uncommon'},
  9452: {'rank': 5494, 'rarity': 'Common'},
  9453: {'rank': 9687, 'rarity': 'Common'},
  9454: {'rank': 6167, 'rarity': 'Common'},
  9455: {'rank': 3164, 'rarity': 'Uncommon'},
  9456: {'rank': 8012, 'rarity': 'Common'},
  9457: {'rank': 5416, 'rarity': 'Common'},
  9458: {'rank': 2481, 'rarity': 'Rare'},
  9459: {'rank': 95, 'rarity': 'Legendary'},
  9460: {'rank': 2874, 'rarity': 'Uncommon'},
  9461: {'rank': 8026, 'rarity': 'Common'},
  9462: {'rank': 9422, 'rarity': 'Common'},
  9463: {'rank': 8161, 'rarity': 'Common'},
  9464: {'rank': 6541, 'rarity': 'Common'},
  9465: {'rank': 1444, 'rarity': 'Rare'},
  9466: {'rank': 7968, 'rarity': 'Common'},
  9467: {'rank': 1342, 'rarity': 'Rare'},
  9468: {'rank': 3917, 'rarity': 'Uncommon'},
  9469: {'rank': 2156, 'rarity': 'Rare'},
  9470: {'rank': 8214, 'rarity': 'Common'},
  9471: {'rank': 2217, 'rarity': 'Rare'},
  9472: {'rank': 4138, 'rarity': 'Uncommon'},
  9473: {'rank': 3098, 'rarity': 'Uncommon'},
  9474: {'rank': 6358, 'rarity': 'Common'},
  9475: {'rank': 4026, 'rarity': 'Uncommon'},
  9476: {'rank': 4252, 'rarity': 'Uncommon'},
  9477: {'rank': 3361, 'rarity': 'Uncommon'},
  9478: {'rank': 8751, 'rarity': 'Common'},
  9479: {'rank': 7013, 'rarity': 'Common'},
  9480: {'rank': 1243, 'rarity': 'Rare'},
  9481: {'rank': 9280, 'rarity': 'Common'},
  9482: {'rank': 8688, 'rarity': 'Common'},
  9483: {'rank': 8553, 'rarity': 'Common'},
  9484: {'rank': 1801, 'rarity': 'Rare'},
  9485: {'rank': 6931, 'rarity': 'Common'},
  9486: {'rank': 1947, 'rarity': 'Rare'},
  9487: {'rank': 4278, 'rarity': 'Uncommon'},
  9488: {'rank': 7441, 'rarity': 'Common'},
  9489: {'rank': 120, 'rarity': 'Epic'},
  9490: {'rank': 8625, 'rarity': 'Common'},
  9491: {'rank': 7649, 'rarity': 'Common'},
  9492: {'rank': 222, 'rarity': 'Epic'},
  9493: {'rank': 7106, 'rarity': 'Common'},
  9494: {'rank': 2457, 'rarity': 'Rare'},
  9495: {'rank': 1019, 'rarity': 'Rare'},
  9496: {'rank': 9620, 'rarity': 'Common'},
  9497: {'rank': 7831, 'rarity': 'Common'},
  9498: {'rank': 1423, 'rarity': 'Rare'},
  9499: {'rank': 2765, 'rarity': 'Uncommon'},
  9500: {'rank': 8050, 'rarity': 'Common'},
  9501: {'rank': 8841, 'rarity': 'Common'},
  9502: {'rank': 2038, 'rarity': 'Rare'},
  9503: {'rank': 4840, 'rarity': 'Uncommon'},
  9504: {'rank': 3277, 'rarity': 'Uncommon'},
  9505: {'rank': 871, 'rarity': 'Epic'},
  9506: {'rank': 5791, 'rarity': 'Common'},
  9507: {'rank': 348, 'rarity': 'Epic'},
  9508: {'rank': 5717, 'rarity': 'Common'},
  9509: {'rank': 2979, 'rarity': 'Uncommon'},
  9510: {'rank': 106, 'rarity': 'Epic'},
  9511: {'rank': 9330, 'rarity': 'Common'},
  9512: {'rank': 9520, 'rarity': 'Common'},
  9513: {'rank': 6024, 'rarity': 'Common'},
  9514: {'rank': 1809, 'rarity': 'Rare'},
  9515: {'rank': 1273, 'rarity': 'Rare'},
  9516: {'rank': 1262, 'rarity': 'Rare'},
  9517: {'rank': 597, 'rarity': 'Epic'},
  9518: {'rank': 6450, 'rarity': 'Common'},
  9519: {'rank': 7356, 'rarity': 'Common'},
  9520: {'rank': 7099, 'rarity': 'Common'},
  9521: {'rank': 7173, 'rarity': 'Common'},
  9522: {'rank': 3051, 'rarity': 'Uncommon'},
  9523: {'rank': 4571, 'rarity': 'Uncommon'},
  9524: {'rank': 2, 'rarity': 'Legendary'},
  9525: {'rank': 934, 'rarity': 'Epic'},
  9526: {'rank': 9080, 'rarity': 'Common'},
  9527: {'rank': 9046, 'rarity': 'Common'},
  9528: {'rank': 6602, 'rarity': 'Common'},
  9529: {'rank': 2936, 'rarity': 'Uncommon'},
  9530: {'rank': 8408, 'rarity': 'Common'},
  9531: {'rank': 5871, 'rarity': 'Common'},
  9532: {'rank': 3122, 'rarity': 'Uncommon'},
  9533: {'rank': 4728, 'rarity': 'Uncommon'},
  9534: {'rank': 6389, 'rarity': 'Common'},
  9535: {'rank': 7920, 'rarity': 'Common'},
  9536: {'rank': 617, 'rarity': 'Epic'},
  9537: {'rank': 6241, 'rarity': 'Common'},
  9538: {'rank': 6664, 'rarity': 'Common'},
  9539: {'rank': 7098, 'rarity': 'Common'},
  9540: {'rank': 2575, 'rarity': 'Uncommon'},
  9541: {'rank': 8814, 'rarity': 'Common'},
  9542: {'rank': 7216, 'rarity': 'Common'},
  9543: {'rank': 2614, 'rarity': 'Uncommon'},
  9544: {'rank': 5457, 'rarity': 'Common'},
  9545: {'rank': 2670, 'rarity': 'Uncommon'},
  9546: {'rank': 1298, 'rarity': 'Rare'},
  9547: {'rank': 8781, 'rarity': 'Common'},
  9548: {'rank': 3794, 'rarity': 'Uncommon'},
  9549: {'rank': 3071, 'rarity': 'Uncommon'},
  9550: {'rank': 6992, 'rarity': 'Common'},
  9551: {'rank': 7563, 'rarity': 'Common'},
  9552: {'rank': 9902, 'rarity': 'Common'},
  9553: {'rank': 2375, 'rarity': 'Rare'},
  9554: {'rank': 5876, 'rarity': 'Common'},
  9555: {'rank': 8284, 'rarity': 'Common'},
  9556: {'rank': 8908, 'rarity': 'Common'},
  9557: {'rank': 4909, 'rarity': 'Uncommon'},
  9558: {'rank': 1274, 'rarity': 'Rare'},
  9559: {'rank': 8444, 'rarity': 'Common'},
  9560: {'rank': 8166, 'rarity': 'Common'},
  9561: {'rank': 5332, 'rarity': 'Common'},
  9562: {'rank': 9338, 'rarity': 'Common'},
  9563: {'rank': 9088, 'rarity': 'Common'},
  9564: {'rank': 3885, 'rarity': 'Uncommon'},
  9565: {'rank': 7721, 'rarity': 'Common'},
  9566: {'rank': 5512, 'rarity': 'Common'},
  9567: {'rank': 5125, 'rarity': 'Common'},
  9568: {'rank': 8383, 'rarity': 'Common'},
  9569: {'rank': 3244, 'rarity': 'Uncommon'},
  9570: {'rank': 4785, 'rarity': 'Uncommon'},
  9571: {'rank': 6954, 'rarity': 'Common'},
  9572: {'rank': 3296, 'rarity': 'Uncommon'},
  9573: {'rank': 4726, 'rarity': 'Uncommon'},
  9574: {'rank': 6877, 'rarity': 'Common'},
  9575: {'rank': 9394, 'rarity': 'Common'},
  9576: {'rank': 5237, 'rarity': 'Common'},
  9577: {'rank': 2745, 'rarity': 'Uncommon'},
  9578: {'rank': 9094, 'rarity': 'Common'},
  9579: {'rank': 3129, 'rarity': 'Uncommon'},
  9580: {'rank': 8886, 'rarity': 'Common'},
  9581: {'rank': 2290, 'rarity': 'Rare'},
  9582: {'rank': 5908, 'rarity': 'Common'},
  9583: {'rank': 650, 'rarity': 'Epic'},
  9584: {'rank': 6237, 'rarity': 'Common'},
  9585: {'rank': 5170, 'rarity': 'Common'},
  9586: {'rank': 4756, 'rarity': 'Uncommon'},
  9587: {'rank': 6790, 'rarity': 'Common'},
  9588: {'rank': 2981, 'rarity': 'Uncommon'},
  9589: {'rank': 4766, 'rarity': 'Uncommon'},
  9590: {'rank': 7436, 'rarity': 'Common'},
  9591: {'rank': 4136, 'rarity': 'Uncommon'},
  9592: {'rank': 8327, 'rarity': 'Common'},
  9593: {'rank': 8713, 'rarity': 'Common'},
  9594: {'rank': 3575, 'rarity': 'Uncommon'},
  9595: {'rank': 9963, 'rarity': 'Common'},
  9596: {'rank': 6751, 'rarity': 'Common'},
  9597: {'rank': 6188, 'rarity': 'Common'},
  9598: {'rank': 7843, 'rarity': 'Common'},
  9599: {'rank': 3467, 'rarity': 'Uncommon'},
  9600: {'rank': 6393, 'rarity': 'Common'},
  9601: {'rank': 9939, 'rarity': 'Common'},
  9602: {'rank': 699, 'rarity': 'Epic'},
  9603: {'rank': 9400, 'rarity': 'Common'},
  9604: {'rank': 5518, 'rarity': 'Common'},
  9605: {'rank': 8386, 'rarity': 'Common'},
  9606: {'rank': 6163, 'rarity': 'Common'},
  9607: {'rank': 4582, 'rarity': 'Uncommon'},
  9608: {'rank': 1382, 'rarity': 'Rare'},
  9609: {'rank': 8188, 'rarity': 'Common'},
  9610: {'rank': 9104, 'rarity': 'Common'},
  9611: {'rank': 7867, 'rarity': 'Common'},
  9612: {'rank': 5411, 'rarity': 'Common'},
  9613: {'rank': 3633, 'rarity': 'Uncommon'},
  9614: {'rank': 5428, 'rarity': 'Common'},
  9615: {'rank': 424, 'rarity': 'Epic'},
  9616: {'rank': 4929, 'rarity': 'Uncommon'},
  9617: {'rank': 6114, 'rarity': 'Common'},
  9618: {'rank': 4957, 'rarity': 'Uncommon'},
  9619: {'rank': 9366, 'rarity': 'Common'},
  9620: {'rank': 579, 'rarity': 'Epic'},
  9621: {'rank': 2649, 'rarity': 'Uncommon'},
  9622: {'rank': 845, 'rarity': 'Epic'},
  9623: {'rank': 5164, 'rarity': 'Common'},
  9624: {'rank': 7239, 'rarity': 'Common'},
  9625: {'rank': 1924, 'rarity': 'Rare'},
  9626: {'rank': 5722, 'rarity': 'Common'},
  9627: {'rank': 6437, 'rarity': 'Common'},
  9628: {'rank': 9625, 'rarity': 'Common'},
  9629: {'rank': 366, 'rarity': 'Epic'},
  9630: {'rank': 5998, 'rarity': 'Common'},
  9631: {'rank': 3768, 'rarity': 'Uncommon'},
  9632: {'rank': 5617, 'rarity': 'Common'},
  9633: {'rank': 9293, 'rarity': 'Common'},
  9634: {'rank': 2207, 'rarity': 'Rare'},
  9635: {'rank': 8831, 'rarity': 'Common'},
  9636: {'rank': 5604, 'rarity': 'Common'},
  9637: {'rank': 4313, 'rarity': 'Uncommon'},
  9638: {'rank': 6756, 'rarity': 'Common'},
  9639: {'rank': 6749, 'rarity': 'Common'},
  9640: {'rank': 5350, 'rarity': 'Common'},
  9641: {'rank': 1331, 'rarity': 'Rare'},
  9642: {'rank': 9319, 'rarity': 'Common'},
  9643: {'rank': 9804, 'rarity': 'Common'},
  9644: {'rank': 7121, 'rarity': 'Common'},
  9645: {'rank': 9179, 'rarity': 'Common'},
  9646: {'rank': 4718, 'rarity': 'Uncommon'},
  9647: {'rank': 1236, 'rarity': 'Rare'},
  9648: {'rank': 5108, 'rarity': 'Common'},
  9649: {'rank': 8358, 'rarity': 'Common'},
  9650: {'rank': 9600, 'rarity': 'Common'},
  9651: {'rank': 3366, 'rarity': 'Uncommon'},
  9652: {'rank': 4986, 'rarity': 'Uncommon'},
  9653: {'rank': 1919, 'rarity': 'Rare'},
  9654: {'rank': 6678, 'rarity': 'Common'},
  9655: {'rank': 3451, 'rarity': 'Uncommon'},
  9656: {'rank': 511, 'rarity': 'Epic'},
  9657: {'rank': 1012, 'rarity': 'Rare'},
  9658: {'rank': 3752, 'rarity': 'Uncommon'},
  9659: {'rank': 8130, 'rarity': 'Common'},
  9660: {'rank': 5317, 'rarity': 'Common'},
  9661: {'rank': 7727, 'rarity': 'Common'},
  9662: {'rank': 9752, 'rarity': 'Common'},
  9663: {'rank': 8840, 'rarity': 'Common'},
  9664: {'rank': 5347, 'rarity': 'Common'},
  9665: {'rank': 2542, 'rarity': 'Uncommon'},
  9666: {'rank': 7847, 'rarity': 'Common'},
  9667: {'rank': 4407, 'rarity': 'Uncommon'},
  9668: {'rank': 6499, 'rarity': 'Common'},
  9669: {'rank': 5326, 'rarity': 'Common'},
  9670: {'rank': 149, 'rarity': 'Epic'},
  9671: {'rank': 933, 'rarity': 'Epic'},
  9672: {'rank': 3838, 'rarity': 'Uncommon'},
  9673: {'rank': 8424, 'rarity': 'Common'},
  9674: {'rank': 9613, 'rarity': 'Common'},
  9675: {'rank': 3984, 'rarity': 'Uncommon'},
  9676: {'rank': 2913, 'rarity': 'Uncommon'},
  9677: {'rank': 7865, 'rarity': 'Common'},
  9678: {'rank': 8897, 'rarity': 'Common'},
  9679: {'rank': 6616, 'rarity': 'Common'},
  9680: {'rank': 5951, 'rarity': 'Common'},
  9681: {'rank': 2662, 'rarity': 'Uncommon'},
  9682: {'rank': 449, 'rarity': 'Epic'},
  9683: {'rank': 850, 'rarity': 'Epic'},
  9684: {'rank': 5129, 'rarity': 'Common'},
  9685: {'rank': 1620, 'rarity': 'Rare'},
  9686: {'rank': 8839, 'rarity': 'Common'},
  9687: {'rank': 1517, 'rarity': 'Rare'},
  9688: {'rank': 7461, 'rarity': 'Common'},
  9689: {'rank': 6227, 'rarity': 'Common'},
  9690: {'rank': 488, 'rarity': 'Epic'},
  9691: {'rank': 5997, 'rarity': 'Common'},
  9692: {'rank': 7188, 'rarity': 'Common'},
  9693: {'rank': 880, 'rarity': 'Epic'},
  9694: {'rank': 7240, 'rarity': 'Common'},
  9695: {'rank': 430, 'rarity': 'Epic'},
  9696: {'rank': 57, 'rarity': 'Legendary'},
  9697: {'rank': 6073, 'rarity': 'Common'},
  9698: {'rank': 2535, 'rarity': 'Uncommon'},
  9699: {'rank': 6784, 'rarity': 'Common'},
  9700: {'rank': 7228, 'rarity': 'Common'},
  9701: {'rank': 7633, 'rarity': 'Common'},
  9702: {'rank': 7572, 'rarity': 'Common'},
  9703: {'rank': 2679, 'rarity': 'Uncommon'},
  9704: {'rank': 7643, 'rarity': 'Common'},
  9705: {'rank': 9882, 'rarity': 'Common'},
  9706: {'rank': 9, 'rarity': 'Legendary'},
  9707: {'rank': 819, 'rarity': 'Epic'},
  9708: {'rank': 1296, 'rarity': 'Rare'},
  9709: {'rank': 3705, 'rarity': 'Uncommon'},
  9710: {'rank': 9152, 'rarity': 'Common'},
  9711: {'rank': 9553, 'rarity': 'Common'},
  9712: {'rank': 9562, 'rarity': 'Common'},
  9713: {'rank': 999, 'rarity': 'Epic'},
  9714: {'rank': 5102, 'rarity': 'Common'},
  9715: {'rank': 1736, 'rarity': 'Rare'},
  9716: {'rank': 5488, 'rarity': 'Common'},
  9717: {'rank': 6151, 'rarity': 'Common'},
  9718: {'rank': 9375, 'rarity': 'Common'},
  9719: {'rank': 3348, 'rarity': 'Uncommon'},
  9720: {'rank': 7890, 'rarity': 'Common'},
  9721: {'rank': 8659, 'rarity': 'Common'},
  9722: {'rank': 9809, 'rarity': 'Common'},
  9723: {'rank': 7087, 'rarity': 'Common'},
  9724: {'rank': 3486, 'rarity': 'Uncommon'},
  9725: {'rank': 2092, 'rarity': 'Rare'},
  9726: {'rank': 229, 'rarity': 'Epic'},
  9727: {'rank': 3656, 'rarity': 'Uncommon'},
  9728: {'rank': 551, 'rarity': 'Epic'},
  9729: {'rank': 181, 'rarity': 'Epic'},
  9730: {'rank': 2844, 'rarity': 'Uncommon'},
  9731: {'rank': 3119, 'rarity': 'Uncommon'},
  9732: {'rank': 1101, 'rarity': 'Rare'},
  9733: {'rank': 6994, 'rarity': 'Common'},
  9734: {'rank': 431, 'rarity': 'Epic'},
  9735: {'rank': 7644, 'rarity': 'Common'},
  9736: {'rank': 1511, 'rarity': 'Rare'},
  9737: {'rank': 7966, 'rarity': 'Common'},
  9738: {'rank': 6490, 'rarity': 'Common'},
  9739: {'rank': 9223, 'rarity': 'Common'},
  9740: {'rank': 769, 'rarity': 'Epic'},
  9741: {'rank': 3994, 'rarity': 'Uncommon'},
  9742: {'rank': 9745, 'rarity': 'Common'},
  9743: {'rank': 1871, 'rarity': 'Rare'},
  9744: {'rank': 4669, 'rarity': 'Uncommon'},
  9745: {'rank': 7657, 'rarity': 'Common'},
  9746: {'rank': 5527, 'rarity': 'Common'},
  9747: {'rank': 6713, 'rarity': 'Common'},
  9748: {'rank': 8479, 'rarity': 'Common'},
  9749: {'rank': 716, 'rarity': 'Epic'},
  9750: {'rank': 1023, 'rarity': 'Rare'},
  9751: {'rank': 2492, 'rarity': 'Rare'},
  9752: {'rank': 3505, 'rarity': 'Uncommon'},
  9753: {'rank': 1015, 'rarity': 'Rare'},
  9754: {'rank': 2231, 'rarity': 'Rare'},
  9755: {'rank': 4773, 'rarity': 'Uncommon'},
  9756: {'rank': 7542, 'rarity': 'Common'},
  9757: {'rank': 4427, 'rarity': 'Uncommon'},
  9758: {'rank': 4915, 'rarity': 'Uncommon'},
  9759: {'rank': 135, 'rarity': 'Epic'},
  9760: {'rank': 9214, 'rarity': 'Common'},
  9761: {'rank': 1088, 'rarity': 'Rare'},
  9762: {'rank': 6409, 'rarity': 'Common'},
  9763: {'rank': 64, 'rarity': 'Legendary'},
  9764: {'rank': 4017, 'rarity': 'Uncommon'},
  9765: {'rank': 8316, 'rarity': 'Common'},
  9766: {'rank': 2482, 'rarity': 'Rare'},
  9767: {'rank': 8514, 'rarity': 'Common'},
  9768: {'rank': 8859, 'rarity': 'Common'},
  9769: {'rank': 1512, 'rarity': 'Rare'},
  9770: {'rank': 6206, 'rarity': 'Common'},
  9771: {'rank': 3763, 'rarity': 'Uncommon'},
  9772: {'rank': 6105, 'rarity': 'Common'},
  9773: {'rank': 3570, 'rarity': 'Uncommon'},
  9774: {'rank': 5792, 'rarity': 'Common'},
  9775: {'rank': 3935, 'rarity': 'Uncommon'},
  9776: {'rank': 6030, 'rarity': 'Common'},
  9777: {'rank': 9241, 'rarity': 'Common'},
  9778: {'rank': 9993, 'rarity': 'Common'},
  9779: {'rank': 6953, 'rarity': 'Common'},
  9780: {'rank': 8725, 'rarity': 'Common'},
  9781: {'rank': 9153, 'rarity': 'Common'},
  9782: {'rank': 8374, 'rarity': 'Common'},
  9783: {'rank': 6368, 'rarity': 'Common'},
  9784: {'rank': 5311, 'rarity': 'Common'},
  9785: {'rank': 9298, 'rarity': 'Common'},
  9786: {'rank': 1120, 'rarity': 'Rare'},
  9787: {'rank': 7468, 'rarity': 'Common'},
  9788: {'rank': 920, 'rarity': 'Epic'},
  9789: {'rank': 8243, 'rarity': 'Common'},
  9790: {'rank': 8457, 'rarity': 'Common'},
  9791: {'rank': 8300, 'rarity': 'Common'},
  9792: {'rank': 2513, 'rarity': 'Uncommon'},
  9793: {'rank': 6258, 'rarity': 'Common'},
  9794: {'rank': 9325, 'rarity': 'Common'},
  9795: {'rank': 7736, 'rarity': 'Common'},
  9796: {'rank': 8254, 'rarity': 'Common'},
  9797: {'rank': 9012, 'rarity': 'Common'},
  9798: {'rank': 1840, 'rarity': 'Rare'},
  9799: {'rank': 9796, 'rarity': 'Common'},
  9800: {'rank': 8174, 'rarity': 'Common'},
  9801: {'rank': 8673, 'rarity': 'Common'},
  9802: {'rank': 879, 'rarity': 'Epic'},
  9803: {'rank': 1799, 'rarity': 'Rare'},
  9804: {'rank': 3733, 'rarity': 'Uncommon'},
  9805: {'rank': 2967, 'rarity': 'Uncommon'},
  9806: {'rank': 711, 'rarity': 'Epic'},
  9807: {'rank': 7950, 'rarity': 'Common'},
  9808: {'rank': 9068, 'rarity': 'Common'},
  9809: {'rank': 4948, 'rarity': 'Uncommon'},
  9810: {'rank': 1426, 'rarity': 'Rare'},
  9811: {'rank': 8628, 'rarity': 'Common'},
  9812: {'rank': 1404, 'rarity': 'Rare'},
  9813: {'rank': 9748, 'rarity': 'Common'},
  9814: {'rank': 8238, 'rarity': 'Common'},
  9815: {'rank': 4141, 'rarity': 'Uncommon'},
  9816: {'rank': 333, 'rarity': 'Epic'},
  9817: {'rank': 1763, 'rarity': 'Rare'},
  9818: {'rank': 93, 'rarity': 'Legendary'},
  9819: {'rank': 3526, 'rarity': 'Uncommon'},
  9820: {'rank': 5725, 'rarity': 'Common'},
  9821: {'rank': 4461, 'rarity': 'Uncommon'},
  9822: {'rank': 500, 'rarity': 'Epic'},
  9823: {'rank': 2628, 'rarity': 'Uncommon'},
  9824: {'rank': 4096, 'rarity': 'Uncommon'},
  9825: {'rank': 4788, 'rarity': 'Uncommon'},
  9826: {'rank': 8249, 'rarity': 'Common'},
  9827: {'rank': 1715, 'rarity': 'Rare'},
  9828: {'rank': 2442, 'rarity': 'Rare'},
  9829: {'rank': 6469, 'rarity': 'Common'},
  9830: {'rank': 5242, 'rarity': 'Common'},
  9831: {'rank': 6260, 'rarity': 'Common'},
  9832: {'rank': 5503, 'rarity': 'Common'},
  9833: {'rank': 3590, 'rarity': 'Uncommon'},
  9834: {'rank': 9565, 'rarity': 'Common'},
  9835: {'rank': 3039, 'rarity': 'Uncommon'},
  9836: {'rank': 3260, 'rarity': 'Uncommon'},
  9837: {'rank': 9576, 'rarity': 'Common'},
  9838: {'rank': 3104, 'rarity': 'Uncommon'},
  9839: {'rank': 2704, 'rarity': 'Uncommon'},
  9840: {'rank': 2010, 'rarity': 'Rare'},
  9841: {'rank': 6291, 'rarity': 'Common'},
  9842: {'rank': 3507, 'rarity': 'Uncommon'},
  9843: {'rank': 9113, 'rarity': 'Common'},
  9844: {'rank': 4335, 'rarity': 'Uncommon'},
  9845: {'rank': 1329, 'rarity': 'Rare'},
  9846: {'rank': 8027, 'rarity': 'Common'},
  9847: {'rank': 2368, 'rarity': 'Rare'},
  9848: {'rank': 710, 'rarity': 'Epic'},
  9849: {'rank': 9362, 'rarity': 'Common'},
  9850: {'rank': 7927, 'rarity': 'Common'},
  9851: {'rank': 3521, 'rarity': 'Uncommon'},
  9852: {'rank': 1760, 'rarity': 'Rare'},
  9853: {'rank': 2989, 'rarity': 'Uncommon'},
  9854: {'rank': 3563, 'rarity': 'Uncommon'},
  9855: {'rank': 4376, 'rarity': 'Uncommon'},
  9856: {'rank': 726, 'rarity': 'Epic'},
  9857: {'rank': 4984, 'rarity': 'Uncommon'},
  9858: {'rank': 7945, 'rarity': 'Common'},
  9859: {'rank': 3620, 'rarity': 'Uncommon'},
  9860: {'rank': 5540, 'rarity': 'Common'},
  9861: {'rank': 9726, 'rarity': 'Common'},
  9862: {'rank': 9783, 'rarity': 'Common'},
  9863: {'rank': 3701, 'rarity': 'Uncommon'},
  9864: {'rank': 6246, 'rarity': 'Common'},
  9865: {'rank': 6475, 'rarity': 'Common'},
  9866: {'rank': 4604, 'rarity': 'Uncommon'},
  9867: {'rank': 8019, 'rarity': 'Common'},
  9868: {'rank': 4065, 'rarity': 'Uncommon'},
  9869: {'rank': 860, 'rarity': 'Epic'},
  9870: {'rank': 5807, 'rarity': 'Common'},
  9871: {'rank': 8509, 'rarity': 'Common'},
  9872: {'rank': 3455, 'rarity': 'Uncommon'},
  9873: {'rank': 3110, 'rarity': 'Uncommon'},
  9874: {'rank': 1709, 'rarity': 'Rare'},
  9875: {'rank': 8311, 'rarity': 'Common'},
  9876: {'rank': 2547, 'rarity': 'Uncommon'},
  9877: {'rank': 3085, 'rarity': 'Uncommon'},
  9878: {'rank': 6098, 'rarity': 'Common'},
  9879: {'rank': 9126, 'rarity': 'Common'},
  9880: {'rank': 385, 'rarity': 'Epic'},
  9881: {'rank': 2647, 'rarity': 'Uncommon'},
  9882: {'rank': 5143, 'rarity': 'Common'},
  9883: {'rank': 2017, 'rarity': 'Rare'},
  9884: {'rank': 4531, 'rarity': 'Uncommon'},
  9885: {'rank': 4054, 'rarity': 'Uncommon'},
  9886: {'rank': 2364, 'rarity': 'Rare'},
  9887: {'rank': 9864, 'rarity': 'Common'},
  9888: {'rank': 846, 'rarity': 'Epic'},
  9889: {'rank': 9437, 'rarity': 'Common'},
  9890: {'rank': 4852, 'rarity': 'Uncommon'},
  9891: {'rank': 2011, 'rarity': 'Rare'},
  9892: {'rank': 8448, 'rarity': 'Common'},
  9893: {'rank': 7313, 'rarity': 'Common'},
  9894: {'rank': 2888, 'rarity': 'Uncommon'},
  9895: {'rank': 4415, 'rarity': 'Uncommon'},
  9896: {'rank': 2681, 'rarity': 'Uncommon'},
  9897: {'rank': 5447, 'rarity': 'Common'},
  9898: {'rank': 5390, 'rarity': 'Common'},
  9899: {'rank': 6628, 'rarity': 'Common'},
  9900: {'rank': 5203, 'rarity': 'Common'},
  9901: {'rank': 4298, 'rarity': 'Uncommon'},
  9902: {'rank': 8778, 'rarity': 'Common'},
  9903: {'rank': 4263, 'rarity': 'Uncommon'},
  9904: {'rank': 8817, 'rarity': 'Common'},
  9905: {'rank': 8920, 'rarity': 'Common'},
  9906: {'rank': 4960, 'rarity': 'Uncommon'},
  9907: {'rank': 2340, 'rarity': 'Rare'},
  9908: {'rank': 9446, 'rarity': 'Common'},
  9909: {'rank': 1159, 'rarity': 'Rare'},
  9910: {'rank': 9391, 'rarity': 'Common'},
  9911: {'rank': 3569, 'rarity': 'Uncommon'},
  9912: {'rank': 4599, 'rarity': 'Uncommon'},
  9913: {'rank': 7378, 'rarity': 'Common'},
  9914: {'rank': 1060, 'rarity': 'Rare'},
  9915: {'rank': 814, 'rarity': 'Epic'},
  9916: {'rank': 7752, 'rarity': 'Common'},
  9917: {'rank': 4162, 'rarity': 'Uncommon'},
  9918: {'rank': 6011, 'rarity': 'Common'},
  9919: {'rank': 9262, 'rarity': 'Common'},
  9920: {'rank': 8597, 'rarity': 'Common'},
  9921: {'rank': 5421, 'rarity': 'Common'},
  9922: {'rank': 8777, 'rarity': 'Common'},
  9923: {'rank': 7030, 'rarity': 'Common'},
  9924: {'rank': 7454, 'rarity': 'Common'},
  9925: {'rank': 2825, 'rarity': 'Uncommon'},
  9926: {'rank': 6125, 'rarity': 'Common'},
  9927: {'rank': 3218, 'rarity': 'Uncommon'},
  9928: {'rank': 3128, 'rarity': 'Uncommon'},
  9929: {'rank': 2557, 'rarity': 'Uncommon'},
  9930: {'rank': 6761, 'rarity': 'Common'},
  9931: {'rank': 3246, 'rarity': 'Uncommon'},
  9932: {'rank': 4082, 'rarity': 'Uncommon'},
  9933: {'rank': 5548, 'rarity': 'Common'},
  9934: {'rank': 1635, 'rarity': 'Rare'},
  9935: {'rank': 8266, 'rarity': 'Common'},
  9936: {'rank': 7708, 'rarity': 'Common'},
  9937: {'rank': 6786, 'rarity': 'Common'},
  9938: {'rank': 9459, 'rarity': 'Common'},
  9939: {'rank': 2562, 'rarity': 'Uncommon'},
  9940: {'rank': 4183, 'rarity': 'Uncommon'},
  9941: {'rank': 749, 'rarity': 'Epic'},
  9942: {'rank': 9011, 'rarity': 'Common'},
  9943: {'rank': 97, 'rarity': 'Legendary'},
  9944: {'rank': 1142, 'rarity': 'Rare'},
  9945: {'rank': 9706, 'rarity': 'Common'},
  9946: {'rank': 9859, 'rarity': 'Common'},
  9947: {'rank': 9329, 'rarity': 'Common'},
  9948: {'rank': 9161, 'rarity': 'Common'},
  9949: {'rank': 8157, 'rarity': 'Common'},
  9950: {'rank': 6779, 'rarity': 'Common'},
  9951: {'rank': 374, 'rarity': 'Epic'},
  9952: {'rank': 4299, 'rarity': 'Uncommon'},
  9953: {'rank': 9718, 'rarity': 'Common'},
  9954: {'rank': 6263, 'rarity': 'Common'},
  9955: {'rank': 7165, 'rarity': 'Common'},
  9956: {'rank': 6741, 'rarity': 'Common'},
  9957: {'rank': 2321, 'rarity': 'Rare'},
  9958: {'rank': 6053, 'rarity': 'Common'},
  9959: {'rank': 7654, 'rarity': 'Common'},
  9960: {'rank': 8740, 'rarity': 'Common'},
  9961: {'rank': 2371, 'rarity': 'Rare'},
  9962: {'rank': 2197, 'rarity': 'Rare'},
  9963: {'rank': 8143, 'rarity': 'Common'},
  9964: {'rank': 9261, 'rarity': 'Common'},
  9965: {'rank': 9570, 'rarity': 'Common'},
  9966: {'rank': 9933, 'rarity': 'Common'},
  9967: {'rank': 4771, 'rarity': 'Uncommon'},
  9968: {'rank': 3136, 'rarity': 'Uncommon'},
  9969: {'rank': 3207, 'rarity': 'Uncommon'},
  9970: {'rank': 2665, 'rarity': 'Uncommon'},
  9971: {'rank': 8058, 'rarity': 'Common'},
  9972: {'rank': 7474, 'rarity': 'Common'},
  9973: {'rank': 2974, 'rarity': 'Uncommon'},
  9974: {'rank': 3415, 'rarity': 'Uncommon'},
  9975: {'rank': 9957, 'rarity': 'Common'},
  9976: {'rank': 205, 'rarity': 'Epic'},
  9977: {'rank': 1911, 'rarity': 'Rare'},
  9978: {'rank': 6970, 'rarity': 'Common'},
  9979: {'rank': 1259, 'rarity': 'Rare'},
  9980: {'rank': 4247, 'rarity': 'Uncommon'},
  9981: {'rank': 9435, 'rarity': 'Common'},
  9982: {'rank': 8919, 'rarity': 'Common'},
  9983: {'rank': 6374, 'rarity': 'Common'},
  9984: {'rank': 4577, 'rarity': 'Uncommon'},
  9985: {'rank': 6887, 'rarity': 'Common'},
  9986: {'rank': 8959, 'rarity': 'Common'},
  9987: {'rank': 2474, 'rarity': 'Rare'},
  9988: {'rank': 5987, 'rarity': 'Common'},
  9989: {'rank': 6128, 'rarity': 'Common'},
  9990: {'rank': 8306, 'rarity': 'Common'},
  9991: {'rank': 2707, 'rarity': 'Uncommon'},
  9992: {'rank': 3126, 'rarity': 'Uncommon'},
  9993: {'rank': 7679, 'rarity': 'Common'},
  9994: {'rank': 3516, 'rarity': 'Uncommon'},
  9995: {'rank': 640, 'rarity': 'Epic'},
  9996: {'rank': 2779, 'rarity': 'Uncommon'},
  9997: {'rank': 7143, 'rarity': 'Common'},
  9998: {'rank': 6591, 'rarity': 'Common'},
  9999: {'rank': 7186, 'rarity': 'Common'}
}

export default rr
