import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Web3 from 'web3'
import rr from './utils/rr'

Vue.config.productionTip = false
Vue.filter('toBNB', function (value, digits= 2) {
  if (value) {
    const price = value.toString()
    return parseFloat(Web3.utils.fromWei(price)).toFixed(digits)
  } else {
    return value
  }
})

Vue.filter('ipfs', function (value) {
  if (value) {
    value = value.replace("https://ipfs.io/ipfs/", process.env.VUE_APP_IPFS_GLOBAL_URL)
    return value.replace('ipfs://', process.env.VUE_APP_IPFS_GLOBAL_URL)
  } else {
    return value
  }
})

Vue.filter('rr', function (value, key='rarity') {
  if (value) {
    return rr[Number(value)][key]
  } else {
    return {'rank': 'Unknown', 'rarity': 'Unknown'}
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

