<template>
  <div class="loader" v-if="loading || !listing.nft.collection">
    <div class="sk-fading-circle-large">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
  <main class="main" v-else>
    <div class="container">
      <div class="row row--grid">
        <div class="col-12">
          <div class="main__title main__title--page">
            <h1>{{ listing.nft.data.name }}</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="asset__item">
            <a class="asset__img" href="" id="nft_img_link">
              <img class="nft_image_item" alt="NFT_Image" id="nft_img" :src="listing.nft.data.image_url|ipfs">
            </a>

            <div class="share share--asset">
              <a href="#" class="share__link share__link--fb"><svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.56341 16.8197V8.65888H7.81615L8.11468 5.84663H5.56341L5.56724 4.43907C5.56724 3.70559 5.63693 3.31257 6.69042 3.31257H8.09873V0.5H5.84568C3.1394 0.5 2.18686 1.86425 2.18686 4.15848V5.84695H0.499939V8.6592H2.18686V16.8197H5.56341Z"/></svg> <span>share</span></a>
              <a href="#" class="share__link share__link--tw"><svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.55075 3.19219L7.58223 3.71122L7.05762 3.64767C5.14804 3.40404 3.47978 2.57782 2.06334 1.1902L1.37085 0.501686L1.19248 1.01013C0.814766 2.14353 1.05609 3.34048 1.843 4.14552C2.26269 4.5904 2.16826 4.65396 1.4443 4.38914C1.19248 4.3044 0.972149 4.24085 0.951164 4.27263C0.877719 4.34677 1.12953 5.31069 1.32888 5.69202C1.60168 6.22165 2.15777 6.74068 2.76631 7.04787L3.28043 7.2915L2.67188 7.30209C2.08432 7.30209 2.06334 7.31268 2.12629 7.53512C2.33613 8.22364 3.16502 8.95452 4.08833 9.2723L4.73884 9.49474L4.17227 9.8337C3.33289 10.321 2.34663 10.5964 1.36036 10.6175C0.888211 10.6281 0.5 10.6705 0.5 10.7023C0.5 10.8082 1.78005 11.4014 2.52499 11.6344C4.75983 12.3229 7.41435 12.0264 9.40787 10.8506C10.8243 10.0138 12.2408 8.35075 12.9018 6.74068C13.2585 5.88269 13.6152 4.315 13.6152 3.56293C13.6152 3.07567 13.6467 3.01212 14.2343 2.42953C14.5805 2.09056 14.9058 1.71983 14.9687 1.6139C15.0737 1.41264 15.0632 1.41264 14.5281 1.59272C13.6362 1.91049 13.5103 1.86812 13.951 1.39146C14.2762 1.0525 14.6645 0.438131 14.6645 0.258058C14.6645 0.22628 14.5071 0.279243 14.3287 0.374576C14.1398 0.480501 13.7202 0.639389 13.4054 0.734722L12.8388 0.914795L12.3247 0.565241C12.0414 0.374576 11.6427 0.162725 11.4329 0.0991699C10.8978 -0.0491255 10.0794 -0.0279404 9.59673 0.14154C8.2852 0.618204 7.45632 1.84694 7.55075 3.19219Z"/></svg> <span>tweet</span></a>
              <a href="#" class="share__link share__link--link"><svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z"/></svg> <span>copy link</span></a>
            </div>
            <!-- end share -->
          </div>
        </div>
        <!-- end content -->

        <!-- sidebar -->
        <div class="col-12 col-xl-4">
          <div class="asset__info">
            <div class="asset__desc">
              <div class="row row--grid" id="row_property">
                <div class="col-6" v-for="property in listing.nft.data.attributes" v-bind:key="property.trait_type" >
                  <div style="padding:10px" class="card text-center card-small">
                    <div class="description_trait">{{ property.trait_type }}</div>
                    <div class="description_value">{{ property.value }}</div>
                    <!--<div class="osStats">1.29% (not working)</div>-->
                  </div>
                </div>

                <div class="col-6">
                  <div style="padding:10px" class="card text-center card-small">
                    <div class="description_trait">Rank</div>
                    <div class="description_value">{{ listing.nft.data.rank }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="padding:10px" class="card text-center card-small">
                    <div class="description_trait">Rarity</div>
                    <div class="description_value">{{ listing.nft.data.rarity }}</div>
                  </div>
                </div>
              </div>
            </div>

            <ul class="asset__authors">
              <li>
                <span>Collection</span>
                <div class="asset__author ">
                  <img src="https://www.degenpunks.com/newpunks/punk-9196.png" alt="">
                  <a href="collection.html">DegenPunks</a>
                </div>
              </li>
              <li>
                <span>Seller</span>
                <div class="asset__author asset__author--verified">
                  <img src="@/assets/img/avatars/avatar5.jpg" alt="">
                  <a href="author.html">{{ listing.seller_address.substr(0, 15) + "..." }}</a>
                </div>
              </li>
            </ul>

            <!-- tabs -->
            <ul class="nav nav-tabs asset__tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">History</a>
              </li>
            </ul>

            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                <div class="asset__actions asset__actions--scroll" id="asset__actions--scroll">
                  <div class="asset__action asset__action--verified">
                    <img src="@/assets/img/avatars/avatar10.jpg" alt="">
                    <p>Bid placed for <b>11.00 BNB</b> 4 hours ago <br>by <a href="author.html">{{ "0x123456789123456789".substr(0, 15) + "..." }}</a></p>
                  </div>

                  <div class="asset__action asset__action--verified">
                    <img src="@/assets/img/avatars/avatar4.jpg" alt="">
                    <p>Bid placed for <b>10.00 BNB</b> 5 hours ago <br>by <a href="author.html">{{ "0x123456789123456789".substr(0, 15) + "..." }}</a></p>
                  </div>

                  <div class="asset__action asset__action--verified">
                    <img src="@/assets/img/avatars/avatar6.jpg" alt="">
                    <p>Bid placed for <b>2.508 BNB</b> 5 hours ago <br>by <a href="author.html">{{ "0x123456789123456789".substr(0, 15) + "..." }}</a></p>
                  </div>

                  <div class="asset__action asset__action--verified">
                    <img src="@/assets/img/avatars/avatar4.jpg" alt="">
                    <p>Bid placed for <b>2.2799 BNB</b> 6 hours ago <br>by <a href="author.html">{{ "0x123456789123456789".substr(0, 15) + "..." }}</a></p>
                  </div>

                  <div class="asset__action asset__action--verified">
                    <img src="@/assets/img/avatars/avatar5.jpg" alt="">
                    <p>Put on sale for <b>0.55 BNB</b> 1 days ago <br>by <a href="author.html">{{ "0x123456789123456789".substr(0, 15) + "..." }}</a></p>
                  </div>
                </div>
              </div>
            </div>
            <!-- end tabs -->

            <div class="asset__wrap">
              <div class="asset__timer">
                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.3,8.59l.91-.9a1,1,0,0,0-1.42-1.42l-.9.91a8,8,0,0,0-9.79,0l-.91-.92A1,1,0,0,0,4.77,7.69l.92.91A7.92,7.92,0,0,0,4,13.5,8,8,0,1,0,18.3,8.59ZM12,19.5a6,6,0,1,1,6-6A6,6,0,0,1,12,19.5Zm-2-15h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Zm3,6a1,1,0,0,0-2,0v1.89a1.5,1.5,0,1,0,2,0Z"/></svg> Auction ends in</span>
                <div class="asset__clock"></div>
              </div>

              <div class="asset__price">
                <span>Minimum bid</span>
                <span>0.20 BNB</span>
              </div>
            </div>

            <!-- actions -->
            <div class="asset__btns">
              <a href="#modal-bid" class="asset__btn asset__btn--full asset__btn--clr open-modal">Place a bid</a>
            </div>
            <!-- end actions -->
          </div>
        </div>
        <!-- end sidebar -->
      </div>
    </div>
  </main>
</template>


<script>
  import {getAuctionMarketplaceContract} from "@/utils/contract";
  import ModalInfo from "@/components/ModalInfo";
  import Web3ModalVue from "web3modal-vue";
  import Scrollbar from 'smooth-scrollbar';
  import {mapState} from "vuex";
  import axios from "axios"
  import Vue from "vue";

  const auctionMarketplace = getAuctionMarketplaceContract()

  export default {
    name: "AuctionMarketplaceItem",
    components: {
      Web3ModalVue,
      ModalInfo
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    methods: {
      async copyUrlClipboard () {
        await navigator.clipboard.writeText(window.location.href);
      },
      bid () {
      },
      finalize () {
      },
      cancel () {
      },
    },
    data () {
      return {
        explorerAddress: process.env.VUE_APP_EXPLORER_ADDRESS_URL,
        processing: false,
        listing: {
          nft: {
            collection: "",
            data: {
            }
          }
        },
        cancelFee: 0,
        loading: true
      }
    },
    async beforeCreate () {
      const forSale = true
      if (!forSale) {
        await this.$router.push({
          name: 'CollectionItem',
          params: {
            collection: this.$route.params.collectionAddress,
            id: this.$route.params.tokenId
          }
        })
      } else {

      }
    },
    mounted () {
      axios
        .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_DEGENPUNKS_ADDR + "/" + this.$route.params.tokenId)
        .then((response) => {
          this.listing = response.data
          this.loading = false
          Vue.nextTick(function(){
            jQuery('.asset__clock').countdown('2021/12/15', function(event) {
              $(this).html(event.strftime('%D days %H:%M:%S'));
            })

            if ($('#asset__actions--scroll').length) {
              Scrollbar.init(document.querySelector('#asset__actions--scroll'), {
                damping: 0.1,
                renderByPixels: true,
                alwaysShowTracks: true,
                continuousScrolling: false,
              });
            }
          })
        })
    }
  }
</script>
