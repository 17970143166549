<template>
  <div class="loader" v-if="loading">
    <div class="sk-fading-circle-large">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
  <main class="main" id="author_main" v-else>
    <div class="main__author home"></div>
    <div class="container">
      <div class="row row--grid">
        <div class="col-12 col-xl-3">
          <div class="author author--page">
            <div class="author__meta"> <router-link to="/profile" class="author__avatar"> <img :src="this.$store.getters.getAvatar" id="profileImg" alt=""> </router-link>
              <h1 class="author__name"><router-link to="/profile" class="disabled-link" id="author-name">{{ this.$store.getters.currentAccount.substring(0, 10) + "..." }}</router-link></h1>
              <h2 class="author__nickname" id="degenPunkVal">{{ this.$store.getters.getPunkBalance }} Degen Punks</h2>
              <p class="author__text">The more Degen Punks you hold the larger your percentage of sales.</p>
              <div class="author__code">
                <input v-on:focus="$event.target.select()"  ref="myAddress"  type="text" :value="this.$store.getters.currentAccount" id="author-code">
                <button type="button" @click="copy"> <span>Copied</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M18,19H6a3,3,0,0,1-3-3V8A1,1,0,0,0,1,8v8a5,5,0,0,0,5,5H18a1,1,0,0,0,0-2Zm5-9.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L16.06,3H8A3,3,0,0,0,5,6v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V10S23,10,23,9.94ZM17,6.41,19.59,9H18a1,1,0,0,1-1-1ZM21,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V6A1,1,0,0,1,8,5h7V8a3,3,0,0,0,3,3h3Z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-9">
          <div class="profile">
            <ul class="nav nav-tabs profile__tabs bottom-margin" id="profile__tabs" role="tablist">
              <li class="nav-item"> <a class="nav-link" v-bind:class="{ active: this.$route.params.tab === 'sale' }" data-toggle="tab" href="#onsale" role="tab" id="tab_onsale" aria-controls="tab-1" :aria-selected="this.$route.params.tab === 'sale'">On Sale</a> </li>
              <li class="nav-item"> <a class="nav-link" v-bind:class="{ active: !this.$route.params.tab }" data-toggle="tab" href="#portfolio" role="tab" id="tab_portfolio" aria-controls="tab-2" :aria-selected="!this.$route.params.tab">Portfolio</a> </li>
              <li class="nav-item"> <a class="nav-link" v-bind:class="{ active: this.$route.params.tab === 'rewards' }" data-toggle="tab" href="#rewards" role="tab" id="tab_rewards" aria-controls="tab-4" :aria-selected="this.$route.params.tab === 'rewards'">Rewards</a> </li>
            </ul>
          </div>

          <div class="tab-content">
            <div class="tab-pane" v-bind:class="{ active: this.$route.params.tab === 'sale' }" id="onsale" role="tabpanel">
              <div class="row row--grid" id="sale_gallery">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3 main_card" v-for="(item) in this.$store.getters.getListedTokens">
                  <router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}" class="card__cover">
                    <img class="card_image" :src="item.nft.data.image_url" alt="">
                    <span class="card__time">{{ item.nft.data.rarity }}</span>
                    <span class="card__time rank">Rank: {{ item.nft.data.rank }}</span>
                  </router-link>
                  <h3 class="card__title">
                    <router-link :to="{ name: 'MarketplaceItem', params: { id: item.id }}" name="title">{{ item.nft.data.name }}</router-link>
                  </h3>
                  <div class="card__info">
                    <div class="card__price">
                      <span>Current Price</span><span>{{ parseFloat(item.ask_price).toFixed(2) }} BNB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane" v-bind:class="{ active: !this.$route.params.tab }" id="portfolio" role="tabpanel">
              <div class="row row--grid" id="main_gallery">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3 main_card" v-for="(item) in this.$store.getters.getOwnedTokens">
                  <router-link :to="{ name: 'CollectionItem', params: { collection: 'degenpunks', id: item.name.split('#')[1] }}" class="card__cover">
                    <img class="card_image" :src="item.image_url" alt="">
                    <span class="card__time" v-if="!$parent.$store.getters.getOldOwnedTokenIds.includes(Number(item.name.split('#')[1]))">{{ item.name.split('#')[1]|rr('rarity') }}</span>
                    <span class="card__time rank" v-if="!$parent.$store.getters.getOldOwnedTokenIds.includes(Number(item.name.split('#')[1]))">Rank: {{ item.name.split('#')[1]|rr('rank') }}</span>

                    <span class="card__time card__time--clock listed" v-if="$parent.$store.getters.getOldOwnedTokenIds.includes(Number(item.name.split('#')[1]))">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                      ! ! Non Wrapped ! ! &nbsp;&nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                    </span>

                    <span class="card__time" v-if="$parent.$store.getters.getOldOwnedTokenIds.includes(Number(item.name.split('#')[1]))">
                      Plz Wrap me fast, I am sad. :'(
                    </span>

                  </router-link>
                  <h3 class="card__title">
                    <router-link :to="{ name: 'CollectionItem', params: {  collection: 'degenpunks', id: item.name.split('#')[1]  }}" class="card__cover">
                      {{ item.name }}
                    </router-link>
                  </h3>
                </div>
              </div>
            </div>

            <div class="tab-pane" v-bind:class="{ active: this.$route.params.tab === 'rewards' }" id="rewards" role="tabpanel">
              <div class="row row--grid">
                <div class="col-12 col-lg-6 text-center">
                  <form action="#" class="sign__form sign__form--profile">
                    <div class="reward_header" id="rew_header">Your Available Rewards</div>
                    <div class="reward_header_entry" id="rew_available">{{ this.$store.getters.getRewards | toBNB(6) }} BNB</div>
                    <div class="reward_button_div">
                      <button class="asset__btn--clr ld-ext running rew_button" type="button" v-on:click="claim" :disabled="claiming">
                        <template v-if="!claiming">
                          Claim Rewards
                        </template>
                        <template v-else>
                          <div class="ld ld-ring ld-spin"></div>
                        </template>
                      </button>
                    </div>
                    <small class="reward_tiny_text">3% Current Holder Rewards and 5% Original Minter Royalties from sale price of DegenPunks NFTs</small>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Web3ModalVue from "web3modal-vue";
  import {mapState} from "vuex";
  import {getMarketplaceContract} from "@/utils/contract";

  const marketplace = getMarketplaceContract()

  export default {
    name: "Profile",
    components: {
      Web3ModalVue
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    methods: {
      claim () {
        this.claiming = true;
        const signer = marketplace.connect(this.web3Modal.signer)
        signer["claimCombinedRewards"]()
          .then((tx) => {
            return tx.wait().then(() => {
              this.claiming = false
            }, (error) => {
              console.log(error)
              this.claiming = false
            });
          })
          .catch(()=>{
            this.claiming = false
          });
      },
      copy() {
        this.$refs.myAddress.focus();
        document.execCommand('copy');
      }
    },
    data () {
      return {
        listedPunks: [],
        portfolioPunks: [],
        claiming: false,
        loading: false
      }
    }
  }
</script>
