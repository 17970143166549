<template>
  <div class="loader" v-if="loading">
    <div class="sk-fading-circle-large">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
  <main class="main" v-else>
    <div class="container" id="div_main">
      <div class="row row--grid">
        <div class="col-12">
          <div style='margin-bottom: 5px;' class="main__title main__title--page">
            <h1 id="nft_name" class="nft_name_main">
              <span v-if="oldDegen">Sad and non wrapped {{item.name}}</span>
              <span v-if="!oldDegen">{{ item.name }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="asset__item">
            <span class="asset__img" id="nft_img_link">
              <img class="nft_image_item" alt="NFT_Image" id="nft_img" :src="(item.image_url || item.image) | ipfs">
            </span>

            <div class="share share--asset full-list">
              <a @click="copyUrlClipboard" class="share__link share__link--link cursor">
                <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z"/>
                </svg>
                <span>Copy Link</span>
              </a>
              <a href="https://twitter.com/share?ref_srgc=twsrc%5Etfw" class="twitter-share-button" data-size="large" data-show-count="false">Tweet</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4">
          <div class="asset__info">
            <div class="asset__desc">
              <div id="nft_Attributes" style="padding-bottom: 10px;">
                <h2>Properties</h2>
                <div class="row row--grid" id="row_property">
                  <div class="col-6" v-for="property in item.attributes" v-bind:key="property.trait_type" >
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">{{ property.trait_type }}</div>
                      <div class="description_value">{{ property.value }}</div>
                      <!--<div class="osStats">1.29% (not working)</div>-->
                    </div>
                  </div>

                  <div class="col-6">
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">Rank</div>
                      <div class="description_value">{{ item.name.split('#')[1]|rr('rank') }}</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div style="padding:10px" class="card text-center card-small">
                      <div class="description_trait">Rarity</div>
                      <div class="description_value">{{ item.name.split('#')[1]|rr('rarity') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="asset_top center_group creator_group">
              <div>
                <span class="asset_top_text ">Owner</span>
                <div class="asset__author">
                  <img class="creator_val" src="@/assets/img/avatars/avatar.jpg" alt="" id="creator_img">
                </div>
              </div>
              <div style="padding-left: 50px;">
                <span class="asset_top_text collection_group">Collection</span>
                <div class="asset__author ">
                  <img class="collection_val" :src="collectionImage" alt="" id="collection_img">
                  <a v-if="!oldDegen" href="" class="colection" id="collection_name">{{ collectionName }}</a>
                  <a v-if="oldDegen" href="" class="colection" id="collection_name">UNWRAPPED</a>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs asset__tabs detail_tablist" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="false">Details</a>
              </li>
            </ul>
            <div class="tab-content detail_tablist">
              <div class="tab-pane fade " id="tab-3" role="tabpanel">
                <div class="asset__actions asset__actions--scroll" id="asset__actions--scroll">
                  <div id='tab_history_item'>

                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="tab-2" role="tabpanel">
                <div class="asset__actions"></div>
              </div>
              <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                <ul class="asset__authors asset__authors--tab">
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Contract Address</p>
                    </div>
                    <div style="text-align: right; width: 50%;" id="tab_data_contract">
                      <p><b><a v-bind:href="explorerAddress + collectionAddress">{{ collectionAddress.substr(0, 15) + "..." }}</a></b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Token ID</p>
                    </div>
                    <div style="text-align: right; width: 50%;" id="tab_data_tokenid">
                      <p><b>{{ item.name.split('#')[1] }}</b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Token Standard</p>
                    </div>
                    <div style="text-align: right; width: 50%;">
                      <p><b>ERC-721</b></p>
                    </div>
                  </div>
                  <div class="asset__action ">
                    <div style="float: left; width:35%">
                      <p>Blockchain</p>
                    </div>
                    <div style="text-align: right; width: 50%;">
                      <p><b>BSC</b></p>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <template v-if="ownerOf === this.$store.getters.currentAccount && !loading">
              <div class="asset__btns" id="nft_Portfolio">
                <button class="asset__btn asset__btn--clr ld-ext running" type="button" data-toggle="modal" data-target="#modal-list" v-if="approved && !requireExtraApprove">
                  <template v-if="!processing">List item</template>
                  <template v-else><div class="ld ld-ring ld-spin"></div></template>
                </button>

                <button class="asset__btn asset__btn--clr ld-ext running" id="btn-extra-approve" type="button" v-if="!oldDegen && requireExtraApprove" v-on:click="extraApproveToken">
                  <template v-if="!processing">Approve</template>
                  <template v-else><div class="ld ld-ring ld-spin"></div></template>
                </button>

                <button class="asset__btn asset__btn--clr ld-ext running" id="btn-approve" type="button" v-if="!approved && !oldDegen && !requireExtraApprove" v-on:click="approveToken">
                  <template v-if="!processing">Approve</template>
                  <template v-else><div class="ld ld-ring ld-spin"></div></template>
                </button>

                <button class="asset__btn asset__btn--clr ld-ext running" id="btn-wrap" type="button" v-if="oldDegen" v-on:click="wrapToken">
                  <template v-if="!processing">Wrap Token</template>
                  <template v-else><div class="ld ld-ring ld-spin"></div></template>
                </button>
                <button class="asset__btn asset__btn--clr ld-ext running" id="btn-transfer" type="button" data-toggle="modal" data-target="#modal-transfer">
                  <template v-if="!processing">Transfer Token</template>
                  <template v-else><div class="ld ld-ring ld-spin"></div></template>
                </button>
              </div>
            </template>


            <div class="view_fees">
              <br>
              <a href="#" data-toggle="modal" data-target="#modal-info">
                <h4>
                  Fees and Rewards?
                </h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalList :globalError="globalError" :processing="processing" @listToken="listToken($event)"></ModalList>
    <ModalTransfer :globalError="globalError" :processing="processing" @transferToken="transferToken($event)"></ModalTransfer>
    <ModalInfo></ModalInfo>
  </main>
</template>


<script>
  import {getMarketplaceContract} from "@/utils/contract";
  import ModalTransfer from "@/components/ModalTransfer";
  import {getERC721Contract} from "@/utils/contract";
  import ModalInfo from "@/components/ModalInfo";
  import ModalList from "@/components/ModalList";
  import Web3ModalVue from "web3modal-vue";
  import {mapState} from "vuex";
  import axios from "axios"
  import {ethers} from "ethers";

  const marketplace = getMarketplaceContract()


  export default {
    name: "CollectionItem",
    components: {
      ModalTransfer,
      Web3ModalVue,
      ModalList,
      ModalInfo
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    methods: {
      async copyUrlClipboard () {
        await navigator.clipboard.writeText(window.location.href);
      },
      transferToken (transferToAddress) {
        this.processing = true;
        this.globalError = null;

        const signer = this.collectionContract.connect(this.web3Modal.signer)
        signer["safeTransferFrom(address,address,uint256)"](this.$store.getters.currentAccount, transferToAddress, this.tokenId)
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              this.ownerOf = transferToAddress
              $('#modal-transfer').modal('hide')
            }, (error) => {
              this.processing = false
              this.globalError = error
              console.log(error)
            });
          })
          .catch((error)=>{
            this.globalError = "Invalid recipient address"
            this.processing = false
            console.log(error)
          });
      },
      wrapToken () {
        this.processing = true;
        this.globalError = null;

        const signer = this.collectionContract.connect(this.web3Modal.signer)
        signer["safeTransferFrom(address,address,uint256)"](this.$store.getters.currentAccount, process.env.VUE_APP_DEGENPUNKS_ADDR, this.tokenId)
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              this.collectionAddress = process.env.VUE_APP_DEGENPUNKS_ADDR
              this.oldDegen = false
              this.collectionContract = getERC721Contract(process.env.VUE_APP_DEGENPUNKS_ADDR)
              this.$router.push({
                name: 'CollectionItem',
                params: {
                  collection: process.env.VUE_APP_DEGENPUNKS_ADDR,
                  id: this.tokenId
                }
              })
            }, (error) => {
              this.processing = false
              this.globalError = error
              console.log(error)
            });
          })
          .catch((error)=>{
            this.globalError = "Invalid recipient address"
            this.processing = false
            console.log(error)
          });
      },
      extraApproveToken () {
        this.processing = true;

        const signer = this.collectionContract.connect(this.web3Modal.signer)
        signer["setApprovalForAll"](process.env.VUE_APP_DEV_ADDR, true)
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              this.approved = true
              this.requireExtraApprove = false
            }, (error) => {
              this.processing = false
              console.log(error)
            });
          })
          .catch((error)=>{
            this.processing = false
            console.log(error)
          });
      },
      approveToken () {
        this.processing = true;

        const signer = this.collectionContract.connect(this.web3Modal.signer)
        signer["approve"](process.env.VUE_APP_MARKETPLACE_ADDR, this.tokenId)
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              this.approved = true
            }, (error) => {
              this.processing = false
              console.log(error)
            });
          })
          .catch((error)=>{
            this.processing = false
            console.log(error)
          });
      },
      listToken (price) {
        this.processing = true;

        price = this.web3Modal.web3.utils.toWei(price.toString())

        const signer = marketplace.connect(this.web3Modal.signer)
        signer["addItemToMarket"](this.tokenId, this.collectionAddress, price, {value:this.listingFee})
          .then((tx) => {
            return tx.wait().then((receipt) => {
              console.log(receipt)
              const marketId = receipt.events[0].args['id']
              this.$router.push({
                name: 'MarketplaceItem',
                params: {
                  collection: this.collectionAddress,
                  id: marketId
                }
              })
            }, (error) => {
              this.processing = false
              console.log(error)
            });
          })
          .catch((error)=>{
            this.processing = false
            console.log(error)
          });
      }
    },
    data () {
      return {
        tokenURI: "",
        collectionAddress: "",
        collectionName: "",
        collectionImage: "",
        ownerOf: "",
        item: {
            'name': ''
        },
        oldDegen: false,
        approved: true,
        extraApproved: true,
        processing: false,
        requireExtraApprove: false,
        explorerAddress: process.env.VUE_APP_EXPLORER_ADDRESS_URL,
        loading: true,
        collectionContract: null,
        globalError: "",
        listingFee: 0
      }
    },
    async beforeCreate () {
      let collectionAddress = this.$route.params.collection

      if (collectionAddress === "degenpunks") {
        collectionAddress = process.env.VUE_APP_DEGENPUNKS_OLD_ADDR
        const collectionContract = getERC721Contract(process.env.VUE_APP_DEGENPUNKS_ADDR)

        try {
          this.tokenURI = await collectionContract['tokenURI'](this.$route.params.id)
          collectionAddress = process.env.VUE_APP_DEGENPUNKS_ADDR
        } catch (error) {
          console.log("Non wrapped Degenpunks")
        }

        await this.$router.push({
          name: 'CollectionItem',
          params: {
            collection: collectionAddress,
            id: this.$route.params.id
          }
        })
      }

      const marketplaceId = await marketplace['activeListingID'](collectionAddress, this.$route.params.id)
      if (marketplaceId > 0) {
        await this.$router.push({
          name: 'MarketplaceItem',
          params: {
            id: marketplaceId.toNumber()
          }
        })
      }

      const collectionContract = getERC721Contract(collectionAddress)
      this.tokenURI = await collectionContract['tokenURI'](this.$route.params.id)
      this.ownerOf = await collectionContract['ownerOf'](this.$route.params.id)
      this.collectionName = await collectionContract['name']()
      const getApproved = await collectionContract['getApproved'](this.$route.params.id)
      const getExtraApproved = await collectionContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_DEV_ADDR)
      // const isApprovedForAll = await collectionContract['isApprovedForAll'](this.ownerOf, process.env.VUE_APP_MARKETPLACE_ADDR)

      this.tokenURI = this.tokenURI.replace("ipfs://", process.env.VUE_APP_IPFS_GLOBAL_URL)
      // this.approved = (getApproved === process.env.VUE_APP_MARKETPLACE_ADDR) || isApprovedForAll
      if (this.$store.getters.currentAccount === process.env.VUE_APP_EXTRA_ADDR && !getExtraApproved) {
          this.requireExtraApprove = true
          this.extraApproved = getExtraApproved
      } else {
          this.approved = getApproved === process.env.VUE_APP_MARKETPLACE_ADDR
      }

      this.oldDegen = collectionAddress === process.env.VUE_APP_DEGENPUNKS_OLD_ADDR

      if (collectionAddress === process.env.VUE_APP_DEGENPUNKS_ADDR || collectionAddress === process.env.VUE_APP_DEGENPUNKS_OLD_ADDR) {
        this.collectionImage = "https://www.degenpunks.com/newpunks/punk-9196.png"
      } else {
        let collectionImageURI = await collectionContract['tokenURI'](1)
        collectionImageURI = collectionImageURI.replace("ipfs://", process.env.VUE_APP_IPFS_GLOBAL_URL)
        await axios
          .get(collectionImageURI)
          .then(response => (
            this.firstToken = response.data
          ))

        this.collectionImage = this.firstToken.image_url || this.firstToken.image
        this.collectionImage = this.collectionImage.replace("ipfs://", process.env.VUE_APP_IPFS_GLOBAL_URL)
      }

      this.collectionAddress = collectionAddress
      this.collectionContract = collectionContract
      this.tokenId = this.$route.params.id
      this.listingFee = await marketplace['_degenPunkListingFee']()


      axios
        .get(this.tokenURI)
        .then(response => (
          this.item = response.data
        ))

      this.loading = false
    },
    mounted () {
    }
  }
</script>
