import degensAbi from '../config/abi/degenpunks.json'
import marketplaceAbi from '../config/abi/marketplace.json'
import auctionMarketplaceAbi from '../config/abi/auctionMarketplace.json'
import {ethers} from "ethers"
import {simpleRpcProvider} from "@/utils/web3"


export const getERC721Contract = (address) => {
  return getContract(degensAbi, address)
}

export const getMarketplaceContract = () => {
  return getContract(marketplaceAbi, process.env.VUE_APP_MARKETPLACE_ADDR)
}

export const getAuctionMarketplaceContract = () => {
  return getContract(auctionMarketplaceAbi, process.env.VUE_APP_AUCTION_MARKETPLACE_ADDR)
}

export const getDegensContract = () => {
  return getContract(degensAbi, process.env.VUE_APP_DEGENPUNKS_ADDR)
}

export const getOldDegensContract = () => {
  return getContract(degensAbi, process.env.VUE_APP_DEGENPUNKS_OLD_ADDR)
}

const getContract = (abi, address, signer = null) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}
