import {ethers} from "ethers"
import axios from "axios"
import Web3 from "web3";
import Vuex from 'vuex'
import Vue from 'vue'

import {getWeb3NoAccount} from "@/utils/web3";
import {getDegensContract, getOldDegensContract, getMarketplaceContract} from "@/utils/contract";

Vue.use(Vuex)

const degens = getDegensContract()
const oldDegens = getOldDegensContract()
const marketplace = getMarketplaceContract()


export default new Vuex.Store({
  state: {
    web3Modal: {
      web3Modal: null,
      web3: getWeb3NoAccount(),
      active: false,
      account: null,
      chainId: 0,
      punkBalance: 0,
      bnbBalance: 0,
      ownedTokens: [],
      listedTokens: [{'id': 0, 'image_url': '', 'ask_price': 0, 'nft_id': 0, 'nft': {'data': {}}}],
      oldOwnedTokenIds: [],
      avatar: require("@/assets/img/avatars/avatar.jpg"),
      rewards: 0,
      signer: null
    }
  },
  getters: {
    isActive: state => {
      return state.web3Modal.active
    },
    currentAccount: state => {
      if (state.web3Modal.account) {
        return state.web3Modal.account
      } else {
        return "";
      }
    },
    getPunkBalance: state => {
      return state.web3Modal.punkBalance
    },
    getBnbBalance: state => {
      return state.web3Modal.bnbBalance
    },
    getOwnedTokens: state => {
      return state.web3Modal.ownedTokens
    },
    getListedTokens: state => {
      return state.web3Modal.listedTokens
    },
    getAvatar: state => {
      if (state.web3Modal.ownedTokens.length > 0) {
        return state.web3Modal.ownedTokens[0].image_url
      }
      return state.web3Modal.avatar
    },
    getRewards: state => {
      return state.web3Modal.rewards
    },
    getSigner: state => {
      return state.web3Modal.signer
    },
    getOldOwnedTokenIds: state => {
      return state.web3Modal.oldOwnedTokenIds
    },
    getChainId: state => {
      return state.web3Modal.chainId
    },
  },
  mutations: {
    setWeb3Modal(state, web3Modal) {
      state.web3Modal.web3Modal = web3Modal
    },
    setWeb3(state, web3) {
      state.web3Modal.web3 = web3
    },
    setActive(state, active) {
      state.web3Modal.active = active
    },
    setAccount(state, account) {
      state.web3Modal.account = account
    },
    setChainId(state, chainId) {
      state.web3Modal.chainId = chainId
    },
    setPunkBalance(state, punkBalance) {
      state.web3Modal.punkBalance = punkBalance
    },
    setBnbBalance(state, bnbBalance) {
      state.web3Modal.bnbBalance = bnbBalance
    },
    setOwnedTokens(state, ownedTokens) {
      state.web3Modal.ownedTokens = ownedTokens
    },
    setOldOwnedTokenIds(state, oldOwnedTokenIds) {
      state.web3Modal.oldOwnedTokenIds = oldOwnedTokenIds
    },
    setListedTokens(state, listedTokens) {
      state.web3Modal.listedTokens = listedTokens
    },
    setRewards(state, rewards) {
      state.web3Modal.rewards = rewards
    },
    setSigner(state, signer) {
      state.web3Modal.signer = signer
    }
  },
  actions: {
    async connect({state, commit, dispatch}) {
      const ownedTokenIds = []
      const ownedTokens = []
      const listedTokens = []
      let tokenIdPromises = [];
      let oldTokenIdPromises = [];
      let oldOwnedTokenIds = [];

      commit('setListedTokens', listedTokens)

      const provider = await state.web3Modal.web3Modal.connect();
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner()
      const web3 = new Web3(provider)

      commit('setWeb3', web3)
      commit('setSigner', signer)

      const accounts = await web3.eth.getAccounts()
      if (accounts.length > 0) {
        commit('setAccount', accounts[0])
      }
      const chainId = await web3.eth.getChainId()
      commit('setChainId', chainId)
      commit('setActive', true)

      const oldBalance = await oldDegens['balanceOf'](accounts[0])
      const newBalance = await degens['balanceOf'](accounts[0])
      const totalBalance = oldBalance.toNumber() + newBalance.toNumber()
      commit('setPunkBalance', totalBalance)

      const bnbBalance = await web3.eth.getBalance(accounts[0])
      commit('setBnbBalance', bnbBalance)
      axios
        .get(process.env.VUE_APP_API_URL + "/marketplace/" + process.env.VUE_APP_DEGENPUNKS_ADDR + "?filters=sold:false;seller:" + accounts[0])
        .then(function (result) {
          result.data.forEach(async function(listedToken) {
            if(listedToken.seller_address.toLowerCase() === accounts[0].toLowerCase()) {
              const forSale = await marketplace['itemsForSale'](listedToken.id)
              if (!forSale.isSold) {
                listedTokens.push(listedToken)
              }
            }
          })
          commit('setListedTokens', listedTokens)
        })


      for (let step = 0; step < oldBalance; step++) {
        tokenIdPromises.push(oldDegens['tokenOfOwnerByIndex'](accounts[0], step))
        oldTokenIdPromises.push(oldDegens['tokenOfOwnerByIndex'](accounts[0], step))
      }
      for (let step = 0; step < newBalance; step++) {
        tokenIdPromises.push(degens['tokenOfOwnerByIndex'](accounts[0], step))
      }

      Promise.all(oldTokenIdPromises).then(function (results) {
        results.forEach(function (response) {
          oldOwnedTokenIds.push(response.toNumber())
        });
        commit('setOldOwnedTokenIds', oldOwnedTokenIds)
      })

      Promise.all(tokenIdPromises).then(function (results) {
        results.forEach(function (response) {
          ownedTokenIds.push(response.toNumber())
        });

        let tokenPromises = [];
        ownedTokenIds.forEach(function(tokenId) {
          tokenPromises.push(axios.get(process.env.VUE_APP_IPFS_URL + "" +tokenId))
        })

        Promise.all(tokenPromises).then(function (results) {
          results.forEach(function (response) {
            response.data.image_url = response.data.image_url.replace("https://ipfs.io/ipfs/", process.env.VUE_APP_IPFS_GLOBAL_URL)
            ownedTokens.push(response.data)
          });
          commit('setOwnedTokens', ownedTokens)
        });

        let rewardPromises = [];
        let rewards = 0
        ownedTokenIds.forEach(function(tokenId) {
          rewardPromises.push(marketplace['getReflectionBalance'](tokenId))
        })
        Promise.all(rewardPromises).then(function (results) {
          results.forEach(function (response) {
            rewards += Number(response.toString())
          });
          commit('setRewards', rewards)
        });
      });

      provider.on("connect", async (info) => {
        console.log("connect", info)
      });

      provider.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          commit('setAccount', accounts[0])
        } else {
          commit('setAccount', null)
          await dispatch('resetApp')
        }
        console.log("accountsChanged")
      });
      provider.on("chainChanged", async (chainId) => {
        commit('setChainId', chainId)
        console.log("chainChanged")
      });

    },
    async resetApp({state, commit}) {
      try {
        await state.web3Modal.web3Modal.clearCachedProvider();
      } catch (error) {
        console.error(error)
      }
      commit('setAccount', null)
      commit('setActive', false)
    },
  }
})
